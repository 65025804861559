import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ALLOWANCE_HISTORIES = 'GET_ALLOWANCE_HISTORIES'
export const GET_ALLOWANCE_HISTORIES_FULFILLED = 'GET_ALLOWANCE_HISTORIES_FULFILLED'
export const GET_ALLOWANCE_HISTORIES_REJECTED = 'GET_ALLOWANCE_HISTORIES_REJECTED'
export const GET_ALLOWANCE_HISTORIES_DEFAULT = 'GET_ALLOWANCE_HISTORIES_DEFAULT'

export const GET_ALLOWANCE_HISTORIES_BY_USER_ID = 'GET_ALLOWANCE_HISTORIES_BY_USER_ID'
export const GET_ALLOWANCE_HISTORIES_BY_USER_ID_FULFILLED = 'GET_ALLOWANCE_HISTORIES_BY_USER_ID_FULFILLED'
export const GET_ALLOWANCE_HISTORIES_BY_USER_ID_REJECTED = 'GET_ALLOWANCE_HISTORIES_BY_USER_ID_REJECTED'
export const GET_ALLOWANCE_HISTORIES_BY_USER_ID_DEFAULT = 'GET_ALLOWANCE_HISTORIES_BY_USER_ID_DEFAULT'

export const GET_ALLOWANCE_HISTORY = 'GET_ALLOWANCE_HISTORY'
export const GET_ALLOWANCE_HISTORY_FULFILLED = 'GET_ALLOWANCE_HISTORY_FULFILLED'
export const GET_ALLOWANCE_HISTORY_REJECTED = 'GET_ALLOWANCE_HISTORY_REJECTED'
export const GET_ALLOWANCE_HISTORY_DEFAULT = 'GET_ALLOWANCE_HISTORY_DEFAULT'

export const POST_ALLOWANCE_HISTORY = 'POST_ALLOWANCE_HISTORY'
export const POST_ALLOWANCE_HISTORY_FULFILLED = 'POST_ALLOWANCE_HISTORY_FULFILLED'
export const POST_ALLOWANCE_HISTORY_REJECTED = 'POST_ALLOWANCE_HISTORY_REJECTED'
export const POST_ALLOWANCE_HISTORY_DEFAULT = 'POST_ALLOWANCE_HISTORY_DEFAULT'

export const PUT_ALLOWANCE_HISTORY = 'PUT_ALLOWANCE_HISTORY'
export const PUT_ALLOWANCE_HISTORY_FULFILLED = 'PUT_ALLOWANCE_HISTORY_FULFILLED'
export const PUT_ALLOWANCE_HISTORY_REJECTED = 'PUT_ALLOWANCE_HISTORY_REJECTED'
export const PUT_ALLOWANCE_HISTORY_DEFAULT = 'PUT_ALLOWANCE_HISTORY_DEFAULT'

export const DELETE_ALLOWANCE_HISTORY = 'DELETE_ALLOWANCE_HISTORY'
export const DELETE_ALLOWANCE_HISTORY_FULFILLED = 'DELETE_ALLOWANCE_HISTORY_FULFILLED'
export const DELETE_ALLOWANCE_HISTORY_REJECTED = 'DELETE_ALLOWANCE_HISTORY_REJECTED'
export const DELETE_ALLOWANCE_HISTORY_DEFAULT = 'DELETE_ALLOWANCE_HISTORY_DEFAULT'

export const GET_ALLOWANCE_HISTORY_TYPES = 'GET_ALLOWANCE_HISTORY_TYPES'
export const GET_ALLOWANCE_HISTORY_TYPES_FULFILLED = 'GET_ALLOWANCE_HISTORY_TYPES_FULFILLED'
export const GET_ALLOWANCE_HISTORY_TYPES_REJECTED = 'GET_ALLOWANCE_HISTORY_TYPES_REJECTED'
export const GET_ALLOWANCE_HISTORY_TYPES_DEFAULT = 'GET_ALLOWANCE_HISTORY_TYPES_DEFAULT'

export const GET_ALLOWANCE_HISTORY_TYPE = 'GET_ALLOWANCE_HISTORY_TYPE'
export const GET_ALLOWANCE_HISTORY_TYPE_FULFILLED = 'GET_ALLOWANCE_HISTORY_TYPE_FULFILLED'
export const GET_ALLOWANCE_HISTORY_TYPE_REJECTED = 'GET_ALLOWANCE_HISTORY_TYPE_REJECTED'
export const GET_ALLOWANCE_HISTORY_TYPE_DEFAULT = 'GET_ALLOWANCE_HISTORY_TYPE_DEFAULT'

export const POST_ALLOWANCE_HISTORY_TYPE = 'POST_ALLOWANCE_HISTORY_TYPE'
export const POST_ALLOWANCE_HISTORY_TYPE_FULFILLED = 'POST_ALLOWANCE_HISTORY_TYPE_FULFILLED'
export const POST_ALLOWANCE_HISTORY_TYPE_REJECTED = 'POST_ALLOWANCE_HISTORY_TYPE_REJECTED'
export const POST_ALLOWANCE_HISTORY_TYPE_DEFAULT = 'POST_ALLOWANCE_HISTORY_TYPE_DEFAULT'

export const PUT_ALLOWANCE_HISTORY_TYPE = 'PUT_ALLOWANCE_HISTORY_TYPE'
export const PUT_ALLOWANCE_HISTORY_TYPE_FULFILLED = 'PUT_ALLOWANCE_HISTORY_TYPE_FULFILLED'
export const PUT_ALLOWANCE_HISTORY_TYPE_REJECTED = 'PUT_ALLOWANCE_HISTORY_TYPE_REJECTED'
export const PUT_ALLOWANCE_HISTORY_TYPE_DEFAULT = 'PUT_ALLOWANCE_HISTORY_TYPE_DEFAULT'

export const DELETE_ALLOWANCE_HISTORY_TYPE = 'DELETE_ALLOWANCE_HISTORY_TYPE'
export const DELETE_ALLOWANCE_HISTORY_TYPE_FULFILLED = 'DELETE_ALLOWANCE_HISTORY_TYPE_FULFILLED'
export const DELETE_ALLOWANCE_HISTORY_TYPE_REJECTED = 'DELETE_ALLOWANCE_HISTORY_TYPE_REJECTED'
export const DELETE_ALLOWANCE_HISTORY_TYPE_DEFAULT = 'DELETE_ALLOWANCE_HISTORY_TYPE_DEFAULT'

export const GET_SUPPLIER_TIN = 'GET_SUPPLIER_TIN'
export const GET_SUPPLIER_TIN_FULFILLED = 'GET_SUPPLIER_TIN_FULFILLED'
export const GET_SUPPLIER_TIN_REJECTED = 'GET_SUPPLIER_TIN_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadAllowanceAllotment = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_ALLOWANCE_HISTORIES,
      payload: {}
    })
    return Axios.get(`${API_URL}/group-allowance-histories?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ALLOWANCE_HISTORIES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALLOWANCE_HISTORIES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ALLOWANCE_HISTORIES_DEFAULT }))
  }
}

export const loadAllowanceAllotmentByUserId = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_ALLOWANCE_HISTORIES_BY_USER_ID,
      payload: {}
    })
    return Axios.get(`${API_URL}/allowance-histories-by-user-id?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ALLOWANCE_HISTORIES_BY_USER_ID_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALLOWANCE_HISTORIES_BY_USER_ID_REJECTED,
          payload: error
        })
      })
  }
}

export const loadAllowanceHistory = (id) => {
  return dispatch => {
    dispatch({
      type: GET_ALLOWANCE_HISTORY,
      payload: {}
    })
    return Axios.get(`${API_URL}/allowance-history/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_ALLOWANCE_HISTORY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_ALLOWANCE_HISTORY_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ALLOWANCE_HISTORY_DEFAULT }))
  }
}

export const submitAllowanceHistory = (data) => {
  return dispatch => {
    dispatch({
      type: POST_ALLOWANCE_HISTORY,
      payload: {}
    })
    return Axios.post(`${API_URL}/allowance-history`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_ALLOWANCE_HISTORY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_ALLOWANCE_HISTORY_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_ALLOWANCE_HISTORY_DEFAULT }))
  }
}

export const updateAllowanceHistory = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_ALLOWANCE_HISTORY,
      payload: {}
    })
    return Axios.put(`${API_URL}/allowance-history/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_ALLOWANCE_HISTORY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_ALLOWANCE_HISTORY_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_ALLOWANCE_HISTORY_DEFAULT }))
  }
}

export const deleteAllowanceHistory = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_ALLOWANCE_HISTORY,
      payload: {}
    })
    return Axios.delete(`${API_URL}/allowance-history/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_ALLOWANCE_HISTORY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_ALLOWANCE_HISTORY_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_ALLOWANCE_HISTORY_DEFAULT }))
  }
}




export const loadAllowanceHistoryTypes = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_ALLOWANCE_HISTORY_TYPES,
      payload: {}
    })
    return Axios.get(`${API_URL}/allowance-history-types?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ALLOWANCE_HISTORY_TYPES_FULFILLED,
          payload: response
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALLOWANCE_HISTORY_TYPES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ALLOWANCE_HISTORY_TYPES_DEFAULT }))
  }
}

export const loadAllowanceHistoryType = (id) => {
  return dispatch => {
    dispatch({
      type: GET_ALLOWANCE_HISTORY_TYPE,
      payload: {}
    })
    return Axios.get(`${API_URL}/allowance-history-type/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_ALLOWANCE_HISTORY_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_ALLOWANCE_HISTORY_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ALLOWANCE_HISTORY_TYPE_DEFAULT }))
  }
}

export const submitAllowanceHistoryType = (data) => {
  return dispatch => {
    dispatch({
      type: POST_ALLOWANCE_HISTORY_TYPE,
      payload: {}
    })
    return Axios.post(`${API_URL}/allowance-history-type`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_ALLOWANCE_HISTORY_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_ALLOWANCE_HISTORY_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_ALLOWANCE_HISTORY_TYPE_DEFAULT }))
  }
}

export const updateAllowanceHistoryType = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_ALLOWANCE_HISTORY_TYPE,
      payload: {}
    })
    return Axios.put(`${API_URL}/allowance-history-type/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_ALLOWANCE_HISTORY_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_ALLOWANCE_HISTORY_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_ALLOWANCE_HISTORY_TYPE_DEFAULT }))
  }
}

export const deleteAllowanceHistoryType = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_ALLOWANCE_HISTORY_TYPE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/allowance-history-type/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_ALLOWANCE_HISTORY_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_ALLOWANCE_HISTORY_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_ALLOWANCE_HISTORY_TYPE_DEFAULT }))
  }
}

export const loadSupplierTin = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_SUPPLIER_TIN,
      payload: {}
    })
    return Axios.get(`${API_URL}/suppliers?${query}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SUPPLIER_TIN_FULFILLED,
          payload: response
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SUPPLIER_TIN_REJECTED,
          payload: error
        })
      })
  }
}













