import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select } from 'antd'
import { loadPosition, updatePosition, submitPosition } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import access from '../../../../utilities/access'

import './index.scss'

const { Option } = Select
const FormPosition = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({ ...props.selectedRow })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = { ...value }

        if (props.actionType === ADD) {
            props.onSubmitPosition({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const positionId = props.selectedRow._id
            props.onUpdatePosition({ id: positionId, ...submitData }).then(res => {
                setLoading(false)
            })
        }

    }

    const isAccessDisabled = (!access.check('positions', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`position-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Job Level"
                            name="jobLevel"
                            rules={[{ required: true, message: 'Please input job level!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                <Option value={4}>4</Option>
                                <Option value={5}>5</Option>
                                <Option value={6}>6</Option>
                                <Option value={7}>7</Option>
                                <Option value={8}>8</Option>
                                <Option value={9}>9</Option>
                                <Option value={10}>10</Option>
                                <Option value={11}>11</Option>
                                <Option value={12}>12</Option>
                                <Option value={13}>13</Option>
                                <Option value={14}>14</Option>
                                <Option value={15}>15</Option>
                                <Option value={16}>16</Option>
                                <Option value={17}>17</Option>
                                <Option value={18}>18</Option>
                                <Option value={19}>19</Option>
                                <Option value={20}>20</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    ((access.check('positions', 'add') && (props.actionType === ADD)) || (access.check('positions', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit" loading={props.updatePositionLoading || loading}>Submit Position</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updatePositionLoading: state.admin.position.updatePositionLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPosition: (id) => dispatch(loadPosition(id)),
        onUpdatePosition: (data) => dispatch(updatePosition(data)),
        onSubmitPosition: (data) => dispatch(submitPosition(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormPosition)