import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker } from 'antd'
import { loadMedicalHistory, updateMedicalHistory, submitMedicalHistory } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import enumMedicalHistoryType from '../../../../utilities/enumMedicalHistoryType'
import moment from 'moment'
import './index.scss'
import access from '../../../../utilities/access'

const { Option } = Select

const FormMedicalHistory = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: props.selectedRow && moment(props.selectedRow.date),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            props.onSubmitMedicalHistory({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const medicalHistoryId = props.selectedRow._id
            props.onUpdateMedicalHistory({ id: medicalHistoryId, ...submitData }).then(res => {
                setLoading(false)
            })
        }

    }

    const isAccessDisabled = (!access.check('medicalHistories', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`medicalHistory-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please input type!' }]}
                        >
                            <Select disabled={isAccessDisabled}  >
                                {
                                    Object.keys(enumMedicalHistoryType).map(function (key, index) {
                                        return (
                                            <Option value={enumMedicalHistoryType[key]} key={index}>{key}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="date"
                            name="date"
                            rules={[{ required: true, message: 'Please input date!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ required: true, message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[{ required: true, message: 'Please input status!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                <Option value={1}>FOR APPROVAL</Option>
                                {
                                    access.check('medicalHistories', 'approve') &&
                                    <Option value={2}>APPROVED</Option>
                                }
                                {
                                    access.check('medicalHistories', 'cancel') &&
                                    <Option value={5}>CANCELLED</Option>
                                }
                                {
                                    access.check('medicalHistories', 'reject') &&
                                    <Option value={6}>REJECTED</Option>
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    ((access.check('medicalHistories', 'add') && (props.actionType === ADD)) || (access.check('medicalHistories', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateMedicalHistoryLoading || loading}   >
                                    Submit medical history
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateMedicalHistoryLoading: state.admin.medicalHistory.updateMedicalHistoryLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadMedicalHistory: (id) => dispatch(loadMedicalHistory(id)),
        onUpdateMedicalHistory: (data) => dispatch(updateMedicalHistory(data)),
        onSubmitMedicalHistory: (data) => dispatch(submitMedicalHistory(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormMedicalHistory)