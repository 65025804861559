import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, notification } from 'antd'
import List from './list'
import View from './view'
import { VIEW, EDIT, ADD, NONE } from '../../../../utilities/actionEnums'
import './index.scss'
import Viewer from '../../shared/components/viewer'
const Reference = (props) => {
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState()

    useEffect(() => {
        if (actionType === NONE) setSelectedRow()
    }, [actionType])

    useEffect(() => {
        if (props.updateReferenceSuccess) {
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the reference information.',
            });
        }
    }, [props.updateReferenceSuccess])

    useEffect(() => {
        if (props.addReferenceSuccess) {
            notification.success({
                message: `Added`,
                description: 'You have successfully added new reference information.',
            });
        }
    }, [props.addReferenceSuccess])

    return (
        <Viewer
            isView={(actionType === VIEW) || (actionType === ADD)}
            listComponent={
                <List
                    setActionType={setActionType}
                    setSelectedRow={setSelectedRow}
                />
            }
            viewComponent={
                <View
                    actionType={actionType}
                    setActionType={setActionType}
                    selectedRow={selectedRow}
                />
            }
        />

    )
}

function mapStateToProps(state) {
    return {
        updateReferenceSuccess: state.admin.reference.updateReferenceSuccess,
        addReferenceSuccess: state.admin.reference.addReferenceSuccess,

    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Reference)