import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm } from 'antd'
import { loadMemorandums, deleteMemorandum } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import Toolbar from '../../shared/components/toolbar'
import access from '../../../../utilities/access'
import Papa from 'papaparse'
const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadMemorandums } = props
        onLoadMemorandums(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        const { onLoadMemorandums } = props
        onLoadMemorandums(pagination)
        // eslint-disable-next-line
    }, [props.updateMemorandumSuccess, props.deleteMemorandumSuccess, props.addMemorandumSuccess])

    useEffect(() => {
        if (props.memorandumsData.data) {
            setTableData([...props.memorandumsData.data])
        }
    }, [props.memorandumsData])


    function handleDelete(id) {
        props.onDeleteMemorandum(id)
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Memo Number": data.memoNumber,
                "title": data.title,
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `memorandums_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `memorandums_${unix}.csv`);
        tempLink.click();
    }
    const dataSource = tableData
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Memorandum Number',
            dataIndex: 'memoNumber',
            key: 'memoNumber'
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LLLL'))
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                            }}>
                                View
                            </Menu.Item>
                            {
                                access.check('memorandums', 'delete') &&
                                <Menu.Item key="1" >
                                    <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                        Delete
                                    </Popconfirm>
                                </Menu.Item>
                            }
                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout className='page' >
            {
                access.check('memorandums', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Memorandums'
                                handleAdd={() => props.setActionType(ADD)}
                                removeAdd={!access.check('memorandums', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                // handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.memorandumsData.memorandumsLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.memorandumsData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        memorandumsData: state.admin.memorandum.memorandumsData,
        memorandumsLoading: state.admin.memorandum.memorandumsLoading,
        updateMemorandumSuccess: state.admin.memorandum.updateMemorandumSuccess,
        addMemorandumSuccess: state.admin.memorandum.addMemorandumSuccess,
        deleteMemorandumSuccess: state.admin.memorandum.deleteMemorandumSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadMemorandums: data => dispatch(loadMemorandums(data)),
        onDeleteMemorandum: id => dispatch(deleteMemorandum(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)