import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const GET_EMPLOYEES_FULFILLED = 'GET_EMPLOYEES_FULFILLED'
export const GET_EMPLOYEES_REJECTED = 'GET_EMPLOYEES_REJECTED'
export const GET_EMPLOYEES_DEFAULT = 'GET_EMPLOYEES_DEFAULT'

export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEE_FULFILLED = 'GET_EMPLOYEE_FULFILLED'
export const GET_EMPLOYEE_REJECTED = 'GET_EMPLOYEE_REJECTED'
export const GET_EMPLOYEE_DEFAULT = 'GET_EMPLOYEE_DEFAULT'

export const PUT_EMPLOYEE = 'PUT_EMPLOYEE'
export const PUT_EMPLOYEE_FULFILLED = 'PUT_EMPLOYEE_FULFILLED'
export const PUT_EMPLOYEE_REJECTED = 'PUT_EMPLOYEE_REJECTED'
export const PUT_EMPLOYEE_DEFAULT = 'PUT_EMPLOYEE_DEFAULT'


const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');
export const loadEmployees = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_EMPLOYEES,
      payload: {}
    })
    return Axios.get(`${API_URL}/users?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_EMPLOYEES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_EMPLOYEES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_EMPLOYEES_DEFAULT }))
  }
}

export const loadEmployee = (id) => {
  return dispatch => {
    dispatch({
      type: GET_EMPLOYEE,
      payload: {}
    })
    return Axios.get(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_EMPLOYEE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_EMPLOYEE_DEFAULT }))
  }
}


export const updateEmployee = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_EMPLOYEE,
      payload: {}
    })
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_EMPLOYEE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_EMPLOYEE_DEFAULT }))
  }
}






