import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_PROJECTS_FULFILLED = 'GET_PROJECTS_FULFILLED'
export const GET_PROJECTS_REJECTED = 'GET_PROJECTS_REJECTED'
export const GET_PROJECTS_DEFAULT = 'GET_PROJECTS_DEFAULT'

export const GET_PROJECT = 'GET_PROJECT'
export const GET_PROJECT_FULFILLED = 'GET_PROJECT_FULFILLED'
export const GET_PROJECT_REJECTED = 'GET_PROJECT_REJECTED'
export const GET_PROJECT_DEFAULT = 'GET_PROJECT_DEFAULT'

export const POST_PROJECT = 'POST_PROJECT'
export const POST_PROJECT_FULFILLED = 'POST_PROJECT_FULFILLED'
export const POST_PROJECT_REJECTED = 'POST_PROJECT_REJECTED'
export const POST_PROJECT_DEFAULT = 'POST_PROJECT_DEFAULT'

export const PUT_PROJECT = 'PUT_PROJECT'
export const PUT_PROJECT_FULFILLED = 'PUT_PROJECT_FULFILLED'
export const PUT_PROJECT_REJECTED = 'PUT_PROJECT_REJECTED'
export const PUT_PROJECT_DEFAULT = 'PUT_PROJECT_DEFAULT'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_FULFILLED = 'DELETE_PROJECT_FULFILLED'
export const DELETE_PROJECT_REJECTED = 'DELETE_PROJECT_REJECTED'
export const DELETE_PROJECT_DEFAULT = 'DELETE_PROJECT_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadProjects = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_PROJECTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/projects?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_PROJECTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_PROJECTS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_PROJECTS_DEFAULT }))
  }
}

export const loadProject = (id) => {
  return dispatch => {
    dispatch({
      type: GET_PROJECT,
      payload: {}
    })
    return Axios.get(`${API_URL}/project/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_PROJECT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_PROJECT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_PROJECT_DEFAULT }))
  }
}

export const submitProject = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PROJECT,
      payload: {}
    })
    return Axios.post(`${API_URL}/project`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_PROJECT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_PROJECT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_PROJECT_DEFAULT }))
  }
}

export const updateProject = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_PROJECT,
      payload: {}
    })
    return Axios.put(`${API_URL}/project/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_PROJECT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_PROJECT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_PROJECT_DEFAULT }))
  }
}

export const deleteProject = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_PROJECT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/project/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_PROJECT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_PROJECT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_PROJECT_DEFAULT }))
  }
}











