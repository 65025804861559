import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_MEMORANDUMS = 'GET_MEMORANDUMS'
export const GET_MEMORANDUMS_FULFILLED = 'GET_MEMORANDUMS_FULFILLED'
export const GET_MEMORANDUMS_REJECTED = 'GET_MEMORANDUMS_REJECTED'

export const VIEWER = 'VIEWER'
export const VIEWER_FULFILLED = 'VIEWER_FULFILLED'
export const VIEWER_REJECTED = 'VIEWER_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadMemorandums = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_MEMORANDUMS,
            payload: {}
        })
        return Axios.get(`${API_URL}/memorandums?${query}`, { headers: headers })
            .then(function (response) {
                dispatch({
                    type: GET_MEMORANDUMS_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                dispatch({
                    type: GET_MEMORANDUMS_REJECTED,
                    payload: error
                })
            })
    }
}

export const viewerMemorandum = (data) => {
    return dispatch => {
        dispatch({
            type: VIEWER,
            payload: {}
        })
        return Axios.post(`${API_URL}/memorandum-viewer`, { ...data }, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: VIEWER_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: VIEWER_REJECTED,
                    payload: error
                })
            })
    }
}

