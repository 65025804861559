import {
  GET_MEMORANDUMS,
  GET_MEMORANDUMS_FULFILLED,
  GET_MEMORANDUMS_REJECTED,
  PUT_MEMORANDUM,
  PUT_MEMORANDUM_FULFILLED,
  PUT_MEMORANDUM_REJECTED,
  POST_MEMORANDUM,
  POST_MEMORANDUM_FULFILLED,
  POST_MEMORANDUM_REJECTED,
  DELETE_MEMORANDUM,
  DELETE_MEMORANDUM_FULFILLED,
  DELETE_MEMORANDUM_REJECTED,
  GET_MEMORANDUMS_DEFAULT,
  POST_MEMORANDUM_DEFAULT,
  PUT_MEMORANDUM_DEFAULT,
  DELETE_MEMORANDUM_DEFAULT
} from '../actions'

export default function memorandum(state = {
  memorandumsLoading: false,
  memorandumsSuccess: false,
  memorandumsRejected: false,
  memorandumsData: {
    data: []
  },
  updateMemorandumLoading: false,
  updateMemorandumSuccess: false,
  updateMemorandumRejected: false,
  addMemorandumLoading: false,
  addMemorandumSuccess: false,
  addMemorandumRejected: false,
  deleteMemorandumLoading: false,
  deleteMemorandumSuccess: false,
  deleteMemorandumRejected: false
}, action) {
  switch (action.type) {
    case GET_MEMORANDUMS:
      return {
        ...state,
        memorandumsLoading: true,
        memorandumsSuccess: false,
        memorandumsRejected: false
      }
    case GET_MEMORANDUMS_FULFILLED:
      return {
        ...state,
        memorandumsLoading: false,
        memorandumsSuccess: true,
        memorandumsData: action.payload
      }
    case GET_MEMORANDUMS_REJECTED:
      return {
        ...state,
        memorandumsLoading: false,
        memorandumsRejected: true
      }
    case GET_MEMORANDUMS_DEFAULT:
      return {
        ...state,
        memorandumsLoading: false,
        memorandumsSuccess: false,
        memorandumsRejected: false
      }
    case POST_MEMORANDUM:
      return {
        ...state,
        addMemorandumLoading: true,
        addMemorandumSuccess: false,
        addMemorandumRejected: false
      }
    case POST_MEMORANDUM_FULFILLED:
      return {
        ...state,
        addMemorandumLoading: false,
        addMemorandumSuccess: true,
        addMemorandumData: action.payload
      }
    case POST_MEMORANDUM_REJECTED:
      return {
        ...state,
        addMemorandumLoading: false,
        addMemorandumRejected: true
      }
    case POST_MEMORANDUM_DEFAULT:
      return {
        ...state,
        addMemorandumLoading: false,
        addMemorandumSuccess: false,
        addMemorandumRejected: false
      }
    case PUT_MEMORANDUM:
      return {
        ...state,
        updateMemorandumLoading: true,
        updateMemorandumSuccess: false,
        updateMemorandumRejected: false
      }
    case PUT_MEMORANDUM_FULFILLED:
      return {
        ...state,
        updateMemorandumLoading: false,
        updateMemorandumSuccess: true,
        updateMemorandumData: action.payload
      }
    case PUT_MEMORANDUM_REJECTED:
      return {
        ...state,
        updateMemorandumLoading: false,
        updateMemorandumRejected: true
      }
    case PUT_MEMORANDUM_DEFAULT:
      return {
        ...state,
        updateMemorandumLoading: false,
        updateMemorandumSuccess: false,
        updateMemorandumRejected: false
      }
    case DELETE_MEMORANDUM:
      return {
        ...state,
        deleteMemorandumLoading: true,
        deleteMemorandumSuccess: false,
        deleteMemorandumRejected: false
      }
    case DELETE_MEMORANDUM_FULFILLED:
      return {
        ...state,
        deleteMemorandumLoading: false,
        deleteMemorandumSuccess: true,
      }
    case DELETE_MEMORANDUM_REJECTED:
      return {
        ...state,
        deleteMemorandumLoading: false,
        deleteMemorandumRejected: true
      }
    case DELETE_MEMORANDUM_DEFAULT:
      return {
        ...state,
        deleteMemorandumLoading: false,
        deleteMemorandumSuccess: false,
        deleteMemorandumRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
