import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm, Avatar, Rate } from 'antd'
import { loadEmployees } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import moment from 'moment'
import Toolbar from '../../shared/components/toolbar'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadEmployees } = props
        onLoadEmployees(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        const { onLoadEmployees } = props
        onLoadEmployees(pagination)
        // eslint-disable-next-line
    }, [props.updateEmployeeSuccess, props.deleteEmployeeSuccess, props.addEmployeeSuccess])

    useEffect(() => {
        setTableData([...props.employeesData.data])
    }, [props.employeesData])

    const dataSource = tableData
    const columns = [
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            width: 350,
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <Avatar size={64} src={<ImagePresignedUrl alt='user-profile' src={data.image && data.image[0] && data.image[0].url} objectFit='cover' />} />
                        <div className='content' >
                            <span className='name'>{data.firstName} {data.lastName}</span><br />
                            <span className='email'>{data.email}</span>
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Employee Number',
            dataIndex: 'employeeNumber',
            key: 'employeeNumber'
        },
        {
            title: 'Date Hired',
            dataIndex: 'dateHired',
            key: 'dateHired',
            render: (item, data) => {
                return (
                    <span>{moment(item).format('LL')}</span>
                )
            }
        },
        {
            title: 'Monthly Rate',
            dataIndex: 'ratePerMonth',
            key: 'ratePerMonth',
            render: (item) => {
                return (
                    <span>{item}</span>
                )
            }
        },
        {
            title: 'Bonus Rate',
            dataIndex: 'companyBonusRate',
            key: 'companyBonusRate',
            render: (item) => {
                return (
                    <span>{item}</span>
                )
            }
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'net'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedId(data._id)
                            }}>
                                View
                            </Menu.Item>
                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout>
            <Row gutter={32}>
                <Col md={24} lg={24} xs={24} sm={24}>
                    <Toolbar
                        title='Company Bonuses'
                        handleSearch={(search) => setPagination({ ...pagination, search })}
                        handleSort={(sort) => setPagination({ ...pagination, sort })}
                        // handleStatus={(status) => setPagination({ ...pagination, status })}
                        statusArray={[
                            { id: 0, label: 'All' },
                            { id: 1, label: 'For Approval' },
                            { id: 2, label: 'Approved' },
                            { id: 5, label: 'Cancelled' },
                            { id: 6, label: 'Rejected' },
                        ]}
                    />
                    <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.employeesData.employeesLoading} />
                    <Row style={{ marginTop: 30, width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24} align="right" >
                            <Pagination
                                onChange={(page, limit) => onChangePagination(page, limit)} total={props.employeesData.total} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        employeesData: state.adminPms.thirteenMonthPay.employeesData,
        employeesLoading: state.adminPms.thirteenMonthPay.employeesLoading,
        updateEmployeeSuccess: state.adminPms.thirteenMonthPay.updateEmployeeSuccess,
        addEmployeeSuccess: state.adminPms.thirteenMonthPay.addEmployeeSuccess,
        deleteEmployeeSuccess: state.adminPms.thirteenMonthPay.deleteEmployeeSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmployees: data => dispatch(loadEmployees(data)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)