import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Modal, notification, DatePicker, Alert, Select, Divider, Card } from 'antd'
import { updateAllowance, submitAllowance, loadAllowanceHistoryTypes, loadSupplierTin, POST_ALLOWANCE_FULFILLED, PUT_ALLOWANCE_FULFILLED } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { getUser } from '../../../../utilities/token'
import { upload } from '../../../../utilities/filesController'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import './index.scss'
import moment from 'moment'
import _, { subtract } from 'lodash'
const { Option } = Select

const FormAllowance = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])
    const [search, setSearch] = useState('')
    const [listOfImageObject, setListOfImageObject] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 1,
        sort: 'desc',
        search: '',
    })
    const [tinValue, setTinValue] = useState('')
    const [supplierData, setSupplierData] = useState([])
    const [showSupplierDataItems, setShowSupplierDataItems] = useState(false)
    const [selectedSupplier, setSelectedSupplier] = useState({})
    const [supplierLoading, setSupplierLoading] = useState(false)
    const [supplierDisabled, setSupplierDisabled] = useState(false)

    useEffect(() => {
        const subTotalAmount = (+(props.selectedRow && props.selectedRow.vatableAmount) || 0) + (+(props.selectedRow && props.selectedRow.vatableExemptAmount) || 0)
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: props.selectedRow && moment(props.selectedRow.date) || new Date(),
            orDate: props.selectedRow && moment(props.selectedRow.orDate) || new Date(),
            applicationMonth: props.selectedRow && moment(props.selectedRow.applicationMonth) || new Date(),
            supplierName: props.selectedRow && props.selectedRow.supplier && props.selectedRow.supplier.name,
            supplierAddress: props.selectedRow && props.selectedRow.supplier && props.selectedRow.supplier.address,
            subTotalAmount: subTotalAmount
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)
        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            const userId = await getUser().id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onSubmitAllowance({ userId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        if (res.type === POST_ALLOWANCE_FULFILLED) {
                            form.resetFields()
                            props.setActionType(NONE)
                        }
                        setLoading(false)
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const allowanceId = props.selectedRow._id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onUpdateAllowance({ id: allowanceId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        if (res.type === PUT_ALLOWANCE_FULFILLED) {
                            form.resetFields()
                        }
                        setLoading(false)
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {

                    upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    useEffect(() => {
        props.onLoadAllowanceHistoryTypes({
            page: 1,
            limit: 100,
        })
    }, [])


    useEffect(() => {
        if (!tinValue.length) return
        const delayDebounceFn = setTimeout(() => {
            props.onLoadSupplierTin({ ...pagination, search: tinValue }).then(res => {
                setSupplierLoading(true)
                setSupplierData(res.payload.data.data)
                const foundTin = res.payload.data.data.find(data => data.tin === tinValue)
                if (foundTin) {
                    form.setFieldsValue({
                        supplierName: foundTin.name,
                        supplierAddress: foundTin.address
                    })
                    setSupplierDisabled(true)
                    setShowSupplierDataItems(true)
                } else {
                    form.setFieldsValue({
                        supplierName: '',
                        supplierAddress: ''
                    })
                    setSupplierDisabled(false)
                }
            }).finally(() => {
                setSupplierLoading(false)
            })

        }, 700)
        return () => clearTimeout(delayDebounceFn)
    }, [tinValue])

    function handleSupplierSelect(data) {
        setSupplierData([])
        setSelectedSupplier(data)
        setSupplierDisabled(true)
        form.setFieldsValue({
            tin: data.tin,
            supplierName: data.name,
            supplierAddress: data.address
        })
    }

    function onBlurSupplier(value) {
        var container = document.getElementsByClassName('list')[0];
        document.addEventListener('click', function (event) {
            if (container !== event.target && !container.contains(event.target)) {
                setShowSupplierDataItems(false)
            }
        });
    }

    function onChangeInAmountFields() {
        const vatableAmount = form.getFieldValue('vatableAmount') || 0
        const vatableExemptAmount = form.getFieldValue('vatableExemptAmount') || 0
        const subTotalAmount = (+vatableAmount || 0) + (+vatableExemptAmount || 0)
        const vat = (+vatableAmount) * .12
        form.setFieldsValue({
            subTotalAmount: subTotalAmount,
            vat: vat,
            totalAmount: subTotalAmount + vat
        })
    }

    const isDeducted = (props.selectedRow && props.selectedRow.isDeducted)
    return (
        <Modal title={props.selectedRow && props.selectedRow.company || 'Allowance'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                {/* {
                    isDeducted ?
                        <Row gutter={12} style={{ width: '100%' }}>
                            <Col md={24} lg={24} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                                <Alert
                                    message={'Amount'}
                                    description={'The amount could not be edited because it has been already deducted to the allowance of the user.'}
                                    type={"info"}
                                    showIcon
                                    closable
                                />
                            </Col>
                        </Row> : null
                } */}

                <Form
                    name={`allowance`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="allowanceTypeId"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <Select placeholder='Search Allowance Type' style={{ width: '100%' }} defaultValue={props.selectedRow && props.selectedRow.allowanceTypeId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}  >
                                    {
                                        props.allowanceHistoryTypesData.data.map((data, index) => {
                                            return (
                                                <Option key={data._id} value={data._id} data={data} >{data.title} </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="OR No."
                                name="orNumber"
                                rules={[{ required: true, message: 'Please input OR No.!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="OR Date"
                                name="orDate"
                                rules={[{ required: true, message: 'Please input OR date!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Transaction Date"
                                name="date"
                                rules={[{ required: true, message: 'Please input date!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Application Month"
                                name="applicationMonth"
                                rules={[{ required: true, message: 'Please input application month!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="MMMM"
                                    style={{ width: '100%' }}
                                    picker="month"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <h1>Amount / Tax</h1>
                    <Row gutter={12}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Vatable Amount"
                                name="vatableAmount"
                                rules={[{ required: true, message: 'Please input vatable amount!' }]}
                            >
                                <Input type='number' onChange={onChangeInAmountFields} />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Vatable Exempt"
                                name="vatableExemptAmount"
                                rules={[{ required: true, message: 'Please input vatable exempt amount!' }]}
                            >
                                <Input type='number' onChange={onChangeInAmountFields} />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Total Amount"
                                name="subTotalAmount"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="VAT (12%)"
                                name="vat"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Total Amount"
                                name="totalAmount"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <h1>Supplier</h1>
                    <Row gutter={12}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <div className='supplier-select'>
                                <Form.Item
                                    label="Suppier TIN"
                                    name="tin"
                                    rules={[{ required: true, message: 'Please input tin!' }]}
                                >
                                    <Input onChange={(e) => setTinValue(e.target.value)} autoComplete="off" onBlur={(e) => onBlurSupplier(e.target.value)} />
                                </Form.Item>
                                <div className='list'>
                                    {
                                        (showSupplierDataItems && supplierData.length) ?
                                            <Card>
                                                {
                                                    supplierData.map((data, index) => {
                                                        return <h4 onClick={() => handleSupplierSelect(data)} key={index}>{data.tin}</h4>
                                                    })
                                                }
                                            </Card> : null
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Card className='supplier-card' bordered={false} loading={supplierLoading}>
                        <Row gutter={12}>

                            <Col md={12} lg={12} sm={24} xs={24}>
                                <Form.Item
                                    label="Supplier Name"
                                    name="supplierName"
                                    rules={[{ required: true, message: 'Please input supplier name!' }]}
                                >
                                    <Input disabled={supplierDisabled} />
                                </Form.Item>
                            </Col>
                            <Col md={12} lg={12} sm={24} xs={24}>
                                <Form.Item
                                    label="Supplier Address"
                                    name="supplierAddress"
                                    rules={[{ required: true, message: 'Please input supplier address!' }]}
                                >
                                    <Input disabled={supplierDisabled} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    {/* <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <CustomDropzoneMultiple
                                fileName='allowances'
                                uploadDir='allowances'
                                maxFiles={2}
                                height={200}
                                width={200}
                                getFile={file => { getFileImage(file) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className='d-flex'>
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div className='p2'>
                                                <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateAllowanceLoading || loading}   >
                                    Submit allowance
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateAllowanceLoading: state.client.allowance.updateAllowanceLoading,
        allowanceHistoryTypesData: state.client.allowance.allowanceHistoryTypesData,
        employeesData: state.client.employee.employeesData,
        employeesLoading: state.client.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAllowance: (data) => dispatch(updateAllowance(data)),
        onSubmitAllowance: (data) => dispatch(submitAllowance(data)),
        onLoadAllowanceHistoryTypes: (pagination) => dispatch(loadAllowanceHistoryTypes(pagination)),
        onLoadSupplierTin: (pagination) => dispatch(loadSupplierTin(pagination)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormAllowance)