import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form } from 'antd'
import { loadEmployee, updateEmployee, GET_EMPLOYEE_FULFILLED } from '../actions'
import './index.scss'
import { VIEW } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'

const FormEmployee = (props) => {
    const [form] = Form.useForm()
    const [userDetails, setUserDetails] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const userId = props.selectedId
        if (userId) {
            props.onLoadEmployee(userId).then(res => {
                if (res.type === GET_EMPLOYEE_FULFILLED) {
                    form.setFieldsValue({
                        ...res.payload,
                    })
                    setUserDetails(res.payload)
                }
            })
        }
        // eslint-disable-next-line
    }, [props.selectedId])

    function handleSubmit(value) {
        setLoading(true)
        if (props.actionType === VIEW) {
            const userId = props.selectedId
            const submitData = { id: userId, ...value, postById: getUser().id }
            props.onUpdateEmployee({ ...submitData }).then(res => {
                setLoading(false)
            })
        }
    }

    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`employee-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Company Bonus Rate (%)"
                            name="companyBonusRate"
                            rules={[{ required: true, message: 'Please input Company Bonus Rate!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' type="primary" htmlType="submit" loading={props.updateEmployeeLoading || loading} >
                                Update Bonus Rate
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateEmployeeLoading: state.adminPms.thirteenMonthPay.updateEmployeeLoading,
        employeesData: state.adminPms.thirteenMonthPay.employeesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmployee: (id) => dispatch(loadEmployee(id)),
        onUpdateEmployee: (data) => dispatch(updateEmployee(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormEmployee)