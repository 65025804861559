import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, notification, Tooltip, Avatar, DatePicker } from 'antd'
import { loadMemorandum, updateMemorandum, submitMemorandum } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { upload } from '../../../../utilities/filesController'
import './index.scss'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { IframePresignedUrl, ImagePresignedUrl } from '../../../../utilities/renderer'
import access from '../../../../utilities/access'
import moment from 'moment'

const FormMemorandum = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState()
    const [files, setFiles] = useState([])
    const [listOfFileObject, setListOfFileObject] = useState([])

    useEffect(() => {
        const content = (props.selectedRow && props.selectedRow.content) ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.selectedRow.content))) : EditorState.createEmpty()
        setEditorState(content)
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: props.selectedRow && moment(props.selectedRow.date),
        })
        props.selectedRow && props.selectedRow.files && setFiles(props.selectedRow.files)
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value, content: convertToRaw(editorState.getCurrentContent()),
        }

        console.log("listOfFileObject", listOfFileObject)

        if (props.actionType === ADD) {
            Promise.all([
                uploadFileFile(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onSubmitMemorandum({ files: [...files, ...listOfFileObject], ...submitData }).then(res => {
                        props.setActionType(NONE)
                        setLoading(false)
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const memorandumId = props.selectedRow._id
            Promise.all([
                uploadFileFile(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onUpdateMemorandum({ id: memorandumId, files: [...files, ...listOfFileObject], ...submitData }).then(res => {
                        setLoading(false)
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileFile() {
        return new Promise((resolve, reject) => {
            if (listOfFileObject.length) {
                listOfFileObject.map(item => {

                    upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function getFileFile(arrayFileObj) {
        setListOfFileObject(arrayFileObj)
    }

    function handleRemoveSelectedFile(id) {
        const fiteredFile = files.filter(image => image.id !== id)
        setFiles(fiteredFile)
    }

    const isAccessDisabled = (!access.check('memorandums', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`memorandum-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row>
                    <Col md={24} lg={24} sm={24} xs={24}>
                        <p style={{ margin: 0, color: 'gray', fontSize: 11 }}>Your post have seen by</p>
                        <Avatar.Group maxCount={2} style={{ marginBottom: 12 }} >
                            {
                                props.selectedRow && props.selectedRow.viewer.map((item, index) => {
                                    return (
                                        <Tooltip title={`${item.firstName} ${item.lastName}`} placement="top">
                                            <Avatar icon={<ImagePresignedUrl src={item.image && item.image[0].url} style={{ height: '100%' }} objectFit='cover' preview={false} />} />
                                        </Tooltip>
                                    )
                                })
                            }
                        </Avatar.Group>
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Memorandum Number"
                            name="memoNumber"
                            rules={[{ required: true, message: 'Please input memorandum number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Date"
                            name="date"
                            rules={[{ required: true, message: 'Please input date!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <CustomDropzoneMultiple
                        disabled={isAccessDisabled}
                        type='pdf'
                        fileName='memorandums'
                        uploadDir='memorandums'
                        height={200}
                        width={200}
                        getFile={file => { getFileFile(file) }}
                    />
                </Row>
                <Row>
                    <div className='d-flex'>
                        {
                            files.map((item, index) => {
                                return (
                                    <div className='p2'>
                                        <IframePresignedUrl key={index} src={item.url} style={{ width: 200, height: 250 }} /><br />
                                        {
                                            ((access.check('memorandums', 'add') && (props.actionType === ADD)) || (access.check('memorandums', 'edit') && (props.actionType === VIEW))) &&
                                            <span className='red pointer' onClick={() => handleRemoveSelectedFile(item.id)}>Delete</span>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Row>
                {
                    ((access.check('memorandums', 'add') && (props.actionType === ADD)) || (access.check('memorandums', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateMemorandumLoading || loading}   >
                                    Submit Memorandum
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateMemorandumLoading: state.admin.memorandum.updateMemorandumLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadMemorandum: (id) => dispatch(loadMemorandum(id)),
        onUpdateMemorandum: (data) => dispatch(updateMemorandum(data)),
        onSubmitMemorandum: (data) => dispatch(submitMemorandum(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormMemorandum)