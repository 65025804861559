import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Layout, Form, Select, DatePicker, Input, InputNumber } from 'antd'
import { loadPerformance, updatePerformance, submitPerformance, verifyPerformance } from '../actions'
import { loadEmployees } from '../../employee/actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import VerifierCheck from '../../shared/components/verifierCheck'
import './index.scss'
import moment from 'moment'
import access from '../../../../utilities/access'

const { Option } = Select
const FormPerformance = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const [ratings, setRatings] = useState(0)
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            dateCoveredStart: props.selectedRow && props.selectedRow.dateCoveredStart && moment(props.selectedRow.dateCoveredStart),
            dateCoveredEnd: props.selectedRow && props.selectedRow.dateCoveredEnd && moment(props.selectedRow.dateCoveredEnd),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            props.onSubmitPerformance({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const performanceId = props.selectedRow._id
            props.onUpdatePerformance({ id: performanceId, ...submitData }).then(res => {
                setLoading(false)
                setResetVerifier(true)
            })
        }
    }
    useEffect(() => {
        if (props.actionType === VIEW) {
            if (!(userId)) return
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                id: userId,
            })
        }
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    function performanceAverage(value) {
        if (value <= 69) {
            return <span style={{ color: 'red' }}>BELOW POOR PERFORMANCE</span>
        } else if (value >= 70 && value <= 74) {
            return <span style={{ color: '#e84f4f' }}>NEEDS IMPROVEMENT</span>
        } else if (value >= 75 && value <= 84) {
            return <span style={{ color: '#a0a651' }}>AVERAGE</span>
        } else if (value >= 85 && value <= 94) {
            return <span style={{ color: '#499141' }}>ABOVE AVERAGE</span>
        } else if (value >= 95 && value <= 100) {
            return <span style={{ color: 'green' }}>EXCELLENT</span>
        } else {
            setRatings(0)
            return ''
        }
    }


    const isAccessDisabled = (!access.check('appraisalPerformances', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {
                ((props.actionType === VIEW) && access.check('appraisalPerformances', 'verify')) &&
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                        <VerifierCheck id={props.selectedRow && props.selectedRow._id} verifier={props.selectedRow && props.selectedRow.verifier} onSubmit={props.onVerifyPerformance} reset={resetVerifier} />
                    </Col>
                </Row>
            }
            <Form
                name={`performance`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            ratings ?
                                <h1 style={{ fontSize: 16, color: 'gray', marginBottom: 0 }}>Performance: {performanceAverage(ratings)}</h1> : null
                        }
                        <Form.Item
                            label="Ratings (in Percentage %)"
                            name="ratings"
                            rules={[{ required: true, message: 'Please input ratings!' },
                            {
                                pattern: /^(?:\d*)$/,
                                message: "Rating should contain numbers",
                            },
                            {
                                pattern: /\b(0*(?:[1-9][0-9]?|100))\b/,
                                message: 'Rating must be less than or equal to 100'
                            },
                            {
                                max: 3,
                                message: "Rating should be less than 3 Numeric Number 1 ~ 100",
                            },
                            ]}
                        >
                            <Input maxLength={3} onChange={(e) => setRatings(e.target.value)} disabled={isAccessDisabled} placeholder='100' />

                        </Form.Item>
                        <Form.Item
                            label="Date Covered (Start)"
                            name="dateCoveredStart"
                            rules={[{ required: true, message: 'Please input date covered start!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Date Covered (End)"
                            name="dateCoveredEnd"
                            rules={[{ required: true, message: 'Please input date covered end!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        {
                            (props.actionType === VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('appraisalPerformances', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('appraisalPerformances', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('appraisalPerformances', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                {
                    ((access.check('appraisalPerformances', 'add') && (props.actionType === ADD)) || (access.check('appraisalPerformances', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updatePerformanceLoading || loading}   >
                                    Submit performance
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updatePerformanceLoading: state.admin.performance.updatePerformanceLoading,
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPerformance: (id) => dispatch(loadPerformance(id)),
        onVerifyPerformance: (id) => dispatch(verifyPerformance(id)),
        onUpdatePerformance: (data) => dispatch(updatePerformance(data)),
        onSubmitPerformance: (data) => dispatch(submitPerformance(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormPerformance)