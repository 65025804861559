import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Card, Alert, Input, Select } from 'antd';
import './index.scss'
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import { loadEmployees } from '../../../admin/employee/actions'

const { Option } = Select
ChartJS.register(ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend);

const totalEmployessData = [2530, 3068]

export const employeesData = {
    labels: ['Male', 'Female'],
    datasets: [
        {
            data: totalEmployessData,
            backgroundColor: [
                'rgba(0, 122, 251,0.5)',
                'rgba(251, 0, 0,0.5)',
            ],
            borderColor: [
                '#007AFB',
                'red',
            ],
            borderWidth: 1,
        },
    ],
};

export const projectsData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
        {
            label: 'Dataset 1',
            data: [, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
    ],
};

const Dashboard = (props) => {
    const [search, setSearch] = useState('')

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    function handleSearchEmployee(id) {
        props.history.push(`/admin/employee?empId=${id}&view=true`)
    }
    return (
        <Layout className='dashboard' style={{ minHeight: '100vh', padding: 24 }}>
            <Row>
                <Col md={24} lg={24} sm={24} xs={24}>
                    <Card>
                        <h1 style={{ fontSize: 28, fontWeight: 'bold' }}>Search</h1>
                        <Select placeholder='Search Employee Name' style={{ width: '100%' }} showSearch onSearch={(value) => setSearch(value)} onChange={(id) => handleSearchEmployee(id)} optionFilterProp="children" loading={props.employeesLoading}>
                            {
                                props.employeesData.data.map((data, index) => {
                                    console.log("data", data.firstName)
                                    return (
                                        <Option key={data._id} value={data._id} >{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                    )
                                })
                            }
                        </Select>
                    </Card>
                </Col>
            </Row><br />
            <Row gutter={16}>
                <Col md={8} lg={8} sm={24} xs={24} >
                    <Card style={{ height: '100%' }}>
                        <Row justify='space-between'>
                            <h1 style={{ fontSize: 18, color: 'gray' }}>TOTAL EMPLOYEES</h1>
                            <h1 style={{ fontSize: 24, fontWeight: 'bold' }}>{(totalEmployessData[0] + totalEmployessData[1]).toLocaleString()}</h1>
                        </Row>

                        <Row style={{ width: '100%' }} justify='center'>
                            <Col>
                                <Doughnut data={employeesData} options={{
                                    cutout: '70%',
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                    }
                                }} style={{ width: 160 }} />
                            </Col>
                        </Row>
                        <Row style={{ width: '100%', marginTop: 12 }} gutter={12}>
                            <Col span={12}>
                                <div style={{ border: '1px solid lightgray', padding: 4, borderRadius: 4, }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ height: 15, width: 15, backgroundColor: '#007AFB', borderRadius: 10 }} />
                                        <h1 style={{ color: 'gray', margin: 0, marginLeft: 10 }}>Male: {((totalEmployessData[0] / (totalEmployessData[0] + totalEmployessData[1])) * 100).toFixed(2)}% </h1>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div style={{ border: '1px solid lightgray', padding: 4, borderRadius: 4, }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ height: 15, width: 15, backgroundColor: 'red', borderRadius: 10 }} />
                                        <h1 style={{ color: 'gray', margin: 0, marginLeft: 10 }}>Female: {((totalEmployessData[1] / (totalEmployessData[0] + totalEmployessData[1])) * 100).toFixed(2)}% </h1>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col md={9} lg={9} sm={24} xs={24}>
                    <Card style={{ height: '100%' }}>
                        <Row justify='space-between'>
                            <h1 style={{ fontSize: 18, color: 'gray' }}>TOTAL PROJECTS</h1>
                            <h1 style={{ fontSize: 24, fontWeight: 'bold' }}>158</h1>
                        </Row>

                        <Row style={{ width: '100%' }} justify='center'>
                            <Col span={24}>
                                <Line data={projectsData} options={{
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                        },
                                    },
                                }} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {/* <Col md={7} lg={7} sm={24} xs={24}>
                    <Card>
                        <Row justify='space-between'>
                            <h1 style={{ fontSize: 18, color: 'gray' }}>BIRTHDAY CELEBRANTS</h1>
                        </Row>
                    </Card>
                </Col> */}
            </Row>
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        employeesData: state.admin.employee.employeesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
