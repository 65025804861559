import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, notification } from 'antd'
import List from './list'
import './index.scss'

const Employee = (props) => {
    const [isView, setIsView] = useState(false)
    const [selectedId, setSelectedId] = useState()

    useEffect(() => {
        if (props.updateEmployeeSuccess) {
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the timelog information.',
            });
        }
    }, [props.updateEmployeeSuccess])

    return (
        <Layout>
            <List
                setIsView={setIsView}
                setSelectedId={setSelectedId}
            />
        </Layout>

    )
}

function mapStateToProps(state) {
    return {
        updateEmployeeSuccess: state.admin.timelog.updateEmployeeSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Employee)