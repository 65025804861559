import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd';
import './index.scss'

const logo  = require('../../../../assets/logo/logo.png')
const { Sider } = Layout;

const Sidebar = (props) => {
    const [collapsed, setCollapsed] = useState(false)
    const [currentIndexPath, setCurrentIndexPath] = useState('')
    function onCollapse(collapsed) {
        setCollapsed(collapsed)
    };

    useEffect(() => {
        const currentPath = props.location.pathname
        const index = props.paths.findIndex(data => data.route === currentPath)
        setCurrentIndexPath(index)
    }, [])

    return (
        <div className='sidebar'>
            <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                {
                    !collapsed && 
                    <div className='logo'>
                        <img src={logo} />
                    </div>
                }
                <Menu selectedKeys={[currentIndexPath.toString()]} mode="inline">
                    {
                        props.paths && props.paths.map((menu, index) => {
                            if (!menu.visible) return
                            return (
                                <Menu.Item icon={menu.icon} onClick={() => setCurrentIndexPath(index)} key={index}>
                                    <Link to={menu.route}>
                                        <h1 className='menu-title'>{menu.title}</h1>
                                    </Link>
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
            </Sider>
        </div>
    )
}

export default withRouter(Sidebar)