import React from 'react'
import { Button, Layout, Tooltip, Card, Tabs } from 'antd'
import './index.scss'
import FormAdmin from './form'
import ChangePassword from './changePassword'
import { NONE } from '../../../../utilities/actionEnums'
import { LeftOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

const View = (props) => {
    return (
        <Layout className='page'>
            <div className='back-btn-container'>
                <Tooltip title="Back"><Button className='btn-circle' shape="circle" icon={<LeftOutlined />} onClick={() => props.setActionType(NONE)} /></Tooltip>
            </div>
            <Card title={props.selectedRow && props.selectedRow.title} >
                <Tabs type="card">
                    <TabPane tab="Admin Data" key="1">
                        <div className='tab-content'>
                            <FormAdmin {...props} />
                        </div>
                    </TabPane>
                    <TabPane tab="Change Password" key="2">
                        <div className='tab-content'>
                            <ChangePassword {...props} />
                        </div>
                    </TabPane>
                </Tabs>

            </Card>
        </Layout>
    )
}

export default View
