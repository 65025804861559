import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form } from 'antd'
import { loadProject, updateProject, submitProject } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import access from '../../../../utilities/access'
import './index.scss'

const FormProject = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({ ...props.selectedRow })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = { ...value }

        if (props.actionType === ADD) {
            props.onSubmitProject({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const projectId = props.selectedRow._id
            props.onUpdateProject({ id: projectId, ...submitData }).then(res => {
                setLoading(false)
            })
        }
    }

    const isAccessDisabled = (!access.check('projects', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`project-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    ((access.check('projects', 'add') && (props.actionType === ADD)) || (access.check('projects', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit" loading={props.updateProjectLoading || loading}>Submit Project</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateProjectLoading: state.admin.project.updateProjectLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadProject: (id) => dispatch(loadProject(id)),
        onUpdateProject: (data) => dispatch(updateProject(data)),
        onSubmitProject: (data) => dispatch(submitProject(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormProject)