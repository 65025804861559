import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Avatar, Tooltip, Row, Col, notification } from 'antd'
import { connect } from 'react-redux'
import { loadEducations, deleteEducation } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import enumEducationType from '../../../../utilities/enumEducationType'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'

const EducationsComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updateEducationSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the education information.',
            });
        }
        if (props.addEducationSuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new education information.',
            });
        }
        if (props.deleteEducationSuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted education data.',
            });
        }

        props.onLoadEducations(pagination)
    }, [props.updateEducationSuccess, props.addEducationSuccess, props.deleteEducationSuccess])

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    function handleDelete(id) {
        props.onDeleteEducation(id)
    }

    return (
        <Layout className='educations-page'>
            <Card>
                <button className='add-item' onClick={() => {
                    setModalShow(true)
                    setActionType(ADD)
                    setSelectedRow({})
                }}>
                    <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer', fontSize: 20 }} icon={faPlusCircle} />
                    <span>Add a school</span>
                </button>
                {
                    props.educationsData.data.map((data, index) => {
                        return (
                            <Fragment>
                                <div className='row-item' key={index}>
                                    <div className='data'>
                                        <h1>{getKeyByValue(enumEducationType, data.type)} <StatusIconDisplay status={data.status} /></h1>
                                        <p className='school-name'>{data.school} (SY: {moment(data.yearFrom).format('YYYY')} - {moment(data.yearTo).format('YYYY')})</p>
                                        {
                                            data.type === 3 && // College
                                            <p className='course'>{data.course}</p>
                                        }
                                        <p className='address'>{data.schoolAddress}</p>
                                        {/* {
                                        data.licenseNumber &&
                                        <p>{data.licenseNumber && `(License No. ${data.licenseNumber})`}</p>
                                    }
                                    {
                                        data.expiration &&
                                        <p>Expiration: {moment(data.expiration).format('LL')}</p>
                                    } */}
                                    </div>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            setModalShow(true)
                                            setActionType(VIEW)
                                            setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>

                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                                <Row>
                                    <h1>Certifications / Documents</h1>
                                    <Col md={24} lg={24} xs={24} sm={24}>
                                        <div className='d-flex'>
                                            {
                                                data.images.map((item, index) => {
                                                    return (
                                                        <div className='p2'>
                                                            <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} lg={24} xs={24} sm={24}>
                                        <Avatar.Group
                                            maxCount={2}
                                        >
                                            {
                                                data.verifier.map((verify, index) => {
                                                    return (
                                                        <Tooltip title={`Verifier: ${verify.firstName}`} placement="top" key={index}>
                                                            {
                                                                Array.isArray(verify.image) &&
                                                                <Avatar size={20}
                                                                    icon={<ImagePresignedUrl objectFit='cover' src={verify.image && verify.image[0].url} style={{ width: 30, height: 30 }} key={index} preview={false} />}
                                                                />
                                                            }
                                                        </Tooltip>
                                                    )
                                                })
                                            }
                                        </Avatar.Group>
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    })
                }
            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        educationsData: state.client.education.educationsData,
        addEducationSuccess: state.client.education.addEducationSuccess,
        updateEducationSuccess: state.client.education.updateEducationSuccess,
        deleteEducationSuccess: state.client.education.deleteEducationSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEducations: data => dispatch(loadEducations(data)),
        onDeleteEducation: id => dispatch(deleteEducation(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EducationsComponent)