import {
  GET_PERSONAL_MOVEMENTS,
  GET_PERSONAL_MOVEMENTS_FULFILLED,
  GET_PERSONAL_MOVEMENTS_REJECTED,
  PUT_PERSONAL_MOVEMENT,
  PUT_PERSONAL_MOVEMENT_FULFILLED,
  PUT_PERSONAL_MOVEMENT_REJECTED,
  POST_PERSONAL_MOVEMENT,
  POST_PERSONAL_MOVEMENT_FULFILLED,
  POST_PERSONAL_MOVEMENT_REJECTED,
  DELETE_PERSONAL_MOVEMENT,
  DELETE_PERSONAL_MOVEMENT_FULFILLED,
  DELETE_PERSONAL_MOVEMENT_REJECTED,
  GET_PERSONAL_MOVEMENTS_DEFAULT,
  POST_PERSONAL_MOVEMENT_DEFAULT,
  PUT_PERSONAL_MOVEMENT_DEFAULT,
  DELETE_PERSONAL_MOVEMENT_DEFAULT,
} from '../actions'

export default function timeRequest(state = {
  timeRequestsLoading: false,
  timeRequestsSuccess: false,
  timeRequestsRejected: false,
  timeRequestsData: {
    data: []
  },
  verifiedSuccess: false,
  updateTimeRequestLoading: false,
  updateTimeRequestSuccess: false,
  updateTimeRequestRejected: false,
  addTimeRequestLoading: false,
  addTimeRequestSuccess: false,
  addTimeRequestRejected: false,
  deleteTimeRequestLoading: false,
  deleteTimeRequestSuccess: false,
  deleteTimeRequestRejected: false
}, action) {
  switch (action.type) {
    case GET_PERSONAL_MOVEMENTS:
      return {
        ...state,
        timeRequestsLoading: true,
        timeRequestsSuccess: false,
        timeRequestsRejected: false
      }
    case GET_PERSONAL_MOVEMENTS_FULFILLED:
      return {
        ...state,
        timeRequestsLoading: false,
        timeRequestsSuccess: true,
        timeRequestsData: action.payload
      }
    case GET_PERSONAL_MOVEMENTS_REJECTED:
      return {
        ...state,
        timeRequestsLoading: false,
        timeRequestsRejected: true
      }
    case GET_PERSONAL_MOVEMENTS_DEFAULT:
      return {
        ...state,
        timeRequestsLoading: false,
        timeRequestsSuccess: false,
        timeRequestsRejected: false
      }
    case POST_PERSONAL_MOVEMENT:
      return {
        ...state,
        addTimeRequestLoading: true,
        addTimeRequestSuccess: false,
        addTimeRequestRejected: false
      }
    case POST_PERSONAL_MOVEMENT_FULFILLED:
      return {
        ...state,
        addTimeRequestLoading: false,
        addTimeRequestSuccess: true,
        addTimeRequestData: action.payload
      }
    case POST_PERSONAL_MOVEMENT_REJECTED:
      return {
        ...state,
        addTimeRequestLoading: false,
        addTimeRequestRejected: true
      }
    case POST_PERSONAL_MOVEMENT_DEFAULT:
      return {
        ...state,
        addTimeRequestLoading: false,
        addTimeRequestSuccess: false,
        addTimeRequestRejected: false
      }
    case PUT_PERSONAL_MOVEMENT:
      return {
        ...state,
        updateTimeRequestLoading: true,
        updateTimeRequestSuccess: false,
        updateTimeRequestRejected: false
      }
    case PUT_PERSONAL_MOVEMENT_FULFILLED:
      return {
        ...state,
        updateTimeRequestLoading: false,
        updateTimeRequestSuccess: true,
        updateTimeRequestData: action.payload
      }
    case PUT_PERSONAL_MOVEMENT_REJECTED:
      return {
        ...state,
        updateTimeRequestLoading: false,
        updateTimeRequestRejected: true
      }
    case PUT_PERSONAL_MOVEMENT_DEFAULT:
      return {
        ...state,
        updateTimeRequestLoading: false,
        updateTimeRequestSuccess: false,
        updateTimeRequestRejected: false
      }
    case DELETE_PERSONAL_MOVEMENT:
      return {
        ...state,
        deleteTimeRequestLoading: true,
        deleteTimeRequestSuccess: false,
        deleteTimeRequestRejected: false
      }
    case DELETE_PERSONAL_MOVEMENT_FULFILLED:
      return {
        ...state,
        deleteTimeRequestLoading: false,
        deleteTimeRequestSuccess: true,
      }
    case DELETE_PERSONAL_MOVEMENT_REJECTED:
      return {
        ...state,
        deleteTimeRequestLoading: false,
        deleteTimeRequestRejected: true
      }
    case DELETE_PERSONAL_MOVEMENT_DEFAULT:
      return {
        ...state,
        deleteTimeRequestLoading: false,
        deleteTimeRequestSuccess: false,
        deleteTimeRequestRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
