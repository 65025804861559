import {
    GET_PERSONAL_MOVEMENTS,
    GET_PERSONAL_MOVEMENTS_FULFILLED,
    GET_PERSONAL_MOVEMENTS_REJECTED,
    PUT_PERSONAL_MOVEMENT,
    PUT_PERSONAL_MOVEMENT_FULFILLED,
    PUT_PERSONAL_MOVEMENT_REJECTED,
    POST_PERSONAL_MOVEMENT,
    POST_PERSONAL_MOVEMENT_FULFILLED,
    POST_PERSONAL_MOVEMENT_REJECTED,
    DELETE_PERSONAL_MOVEMENT,
    DELETE_PERSONAL_MOVEMENT_FULFILLED,
    DELETE_PERSONAL_MOVEMENT_REJECTED,
    DELETE_PERSONAL_MOVEMENT_DEFAULT,
    PUT_PERSONAL_MOVEMENT_DEFAULT,
    POST_PERSONAL_MOVEMENT_DEFAULT
} from '../actions'

export default function personalMovement(state = {
    personalMovementsLoading: false,
    personalMovementsSuccess: false,
    personalMovementsRejected: false,
    personalMovementsData: {
        data: []
    },
    verifiedSuccess: false,
    updatePersonalMovementLoading: false,
    updatePersonalMovementSuccess: false,
    updatePersonalMovementRejected: false,
    addPersonalMovementLoading: false,
    addPersonalMovementSuccess: false,
    addPersonalMovementRejected: false,
    deletePersonalMovementLoading: false,
    deletePersonalMovementSuccess: false,
    deletePersonalMovementRejected: false
}, action) {
    switch (action.type) {
        case GET_PERSONAL_MOVEMENTS:
            return {
                ...state,
                personalMovementsLoading: true,
                personalMovementsSuccess: false,
                personalMovementsRejected: false
            }
        case GET_PERSONAL_MOVEMENTS_FULFILLED:
            return {
                ...state,
                personalMovementsLoading: false,
                personalMovementsSuccess: true,
                personalMovementsData: action.payload
            }
        case GET_PERSONAL_MOVEMENTS_REJECTED:
            return {
                ...state,
                personalMovementsLoading: false,
                personalMovementsRejected: true
            }
        case POST_PERSONAL_MOVEMENT:
            return {
                ...state,
                addPersonalMovementLoading: true,
                addPersonalMovementSuccess: false,
                addPersonalMovementRejected: false
            }
        case POST_PERSONAL_MOVEMENT_FULFILLED:
            return {
                ...state,
                addPersonalMovementLoading: false,
                addPersonalMovementSuccess: true,
                addPersonalMovementData: action.payload
            }
        case POST_PERSONAL_MOVEMENT_REJECTED:
            return {
                ...state,
                addPersonalMovementLoading: false,
                addPersonalMovementRejected: true
            }
        case POST_PERSONAL_MOVEMENT_DEFAULT:
            return {
                ...state,
                addPersonalMovementLoading: false,
                addPersonalMovementSuccess: false,
                addPersonalMovementRejected: false
            }
        case PUT_PERSONAL_MOVEMENT:
            return {
                ...state,
                updatePersonalMovementLoading: true,
                updatePersonalMovementSuccess: false,
                updatePersonalMovementRejected: false
            }
        case PUT_PERSONAL_MOVEMENT_FULFILLED:
            return {
                ...state,
                updatePersonalMovementLoading: false,
                updatePersonalMovementSuccess: true,
                updatePersonalMovementData: action.payload
            }

        case PUT_PERSONAL_MOVEMENT_REJECTED:
            return {
                ...state,
                updatePersonalMovementLoading: false,
                updatePersonalMovementRejected: true
            }
        case PUT_PERSONAL_MOVEMENT_DEFAULT:
            return {
                ...state,
                updatePersonalMovementLoading: false,
                updatePersonalMovementSuccess: false,
                updatePersonalMovementRejected: false
            }

        case DELETE_PERSONAL_MOVEMENT:
            return {
                ...state,
                deletePersonalMovementLoading: true,
                deletePersonalMovementSuccess: false,
                deletePersonalMovementRejected: false
            }
        case DELETE_PERSONAL_MOVEMENT_FULFILLED:
            return {
                ...state,
                deletePersonalMovementLoading: false,
                deletePersonalMovementSuccess: true,
            }
        case DELETE_PERSONAL_MOVEMENT_REJECTED:
            return {
                ...state,
                deletePersonalMovementLoading: false,
                deletePersonalMovementRejected: true
            }
        case DELETE_PERSONAL_MOVEMENT_DEFAULT:
            return {
                ...state,
                deletePersonalMovementLoading: false,
                deletePersonalMovementSuccess: false,
                deletePersonalMovementRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
