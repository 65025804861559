import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_EDUCATIONS = 'GET_EDUCATIONS'
export const GET_EDUCATIONS_FULFILLED = 'GET_EDUCATIONS_FULFILLED'
export const GET_EDUCATIONS_REJECTED = 'GET_EDUCATIONS_REJECTED'
export const GET_EDUCATIONS_DEFAULT = 'GET_EDUCATIONS_DEFAULT'

export const GET_EDUCATION = 'GET_EDUCATION'
export const GET_EDUCATION_FULFILLED = 'GET_EDUCATION_FULFILLED'
export const GET_EDUCATION_REJECTED = 'GET_EDUCATION_REJECTED'
export const GET_EDUCATION_DEFAULT = 'GET_EDUCATION_DEFAULT'

export const POST_EDUCATION = 'POST_EDUCATION'
export const POST_EDUCATION_FULFILLED = 'POST_EDUCATION_FULFILLED'
export const POST_EDUCATION_REJECTED = 'POST_EDUCATION_REJECTED'
export const POST_EDUCATION_DEFAULT = 'POST_EDUCATION_DEFAULT'

export const VERIFY = 'VERIFY'
export const VERIFY_FULFILLED = 'VERIFY_FULFILLED'
export const VERIFY_REJECTED = 'VERIFY_REJECTED'
export const VERIFY_DEFAULT = 'VERIFY_DEFAULT'

export const PUT_EDUCATION = 'PUT_EDUCATION'
export const PUT_EDUCATION_FULFILLED = 'PUT_EDUCATION_FULFILLED'
export const PUT_EDUCATION_REJECTED = 'PUT_EDUCATION_REJECTED'
export const PUT_EDUCATION_DEFAULT = 'PUT_EDUCATION_DEFAULT'

export const DELETE_EDUCATION = 'DELETE_EDUCATION'
export const DELETE_EDUCATION_FULFILLED = 'DELETE_EDUCATION_FULFILLED'
export const DELETE_EDUCATION_REJECTED = 'DELETE_EDUCATION_REJECTED'
export const DELETE_EDUCATION_DEFAULT = 'DELETE_EDUCATION_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadEducations = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_EDUCATIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/educations?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_EDUCATIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_EDUCATIONS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_EDUCATIONS_DEFAULT }))
  }
}

export const loadEducation = (id) => {
  return dispatch => {
    dispatch({
      type: GET_EDUCATION,
      payload: {}
    })
    return Axios.get(`${API_URL}/education/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_EDUCATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_EDUCATION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_EDUCATION_DEFAULT }))
  }
}

export const submitEducation = (data) => {
  return dispatch => {
    dispatch({
      type: POST_EDUCATION,
      payload: {}
    })
    return Axios.post(`${API_URL}/education`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_EDUCATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_EDUCATION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_EDUCATION_DEFAULT }))
  }
}

export const updateEducation = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_EDUCATION,
      payload: {}
    })
    return Axios.put(`${API_URL}/education/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_EDUCATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_EDUCATION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_EDUCATION_DEFAULT }))
  }
}

export const deleteEducation = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_EDUCATION,
      payload: {}
    })
    return Axios.delete(`${API_URL}/education/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_EDUCATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_EDUCATION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_EDUCATION_DEFAULT }))
  }
}


export const verifyEducation = (data) => {
  return dispatch => {
    dispatch({
      type: VERIFY,
      payload: {}
    })
    return Axios.post(`${API_URL}/education-verify`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: VERIFY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: VERIFY_REJECTED,
          payload: error
        })
      })
  }
}













