import {
  GET_TIMELOGS,
  GET_TIMELOGS_FULFILLED,
  GET_TIMELOGS_REJECTED,
  PUT_TIMELOG,
  PUT_TIMELOG_FULFILLED,
  PUT_TIMELOG_REJECTED,
  POST_TIMELOG,
  POST_TIMELOG_FULFILLED,
  POST_TIMELOG_REJECTED,
  DELETE_TIMELOG,
  DELETE_TIMELOG_FULFILLED,
  DELETE_TIMELOG_REJECTED,
  GET_TIMELOGS_DEFAULT,
  POST_TIMELOG_DEFAULT,
  PUT_TIMELOG_DEFAULT,
  DELETE_TIMELOG_DEFAULT
} from '../actions'

export default function timelog(state = {
  timelogsLoading: false,
  timelogsSuccess: false,
  timelogsRejected: false,
  timelogsData: {
    data: []
  },
  updateTimelogLoading: false,
  updateTimelogSuccess: false,
  updateTimelogRejected: false,
  addTimelogLoading: false,
  addTimelogSuccess: false,
  addTimelogRejected: false,
  deleteTimelogLoading: false,
  deleteTimelogSuccess: false,
  deleteTimelogRejected: false
}, action) {
  switch (action.type) {
    case GET_TIMELOGS:
      return {
        ...state,
        timelogsLoading: true,
        timelogsSuccess: false,
        timelogsRejected: false
      }
    case GET_TIMELOGS_FULFILLED:
      return {
        ...state,
        timelogsLoading: false,
        timelogsSuccess: true,
        timelogsData: action.payload
      }
    case GET_TIMELOGS_REJECTED:
      return {
        ...state,
        timelogsLoading: false,
        timelogsRejected: true
      }
    case GET_TIMELOGS_DEFAULT:
      return {
        ...state,
        timelogsLoading: false,
        timelogsSuccess: false,
        timelogsRejected: false
      }
    case POST_TIMELOG:
      return {
        ...state,
        addTimelogLoading: true,
        addTimelogSuccess: false,
        addTimelogRejected: false
      }
    case POST_TIMELOG_FULFILLED:
      return {
        ...state,
        addTimelogLoading: false,
        addTimelogSuccess: true,
        addTimelogData: action.payload
      }
    case POST_TIMELOG_REJECTED:
      return {
        ...state,
        addTimelogLoading: false,
        addTimelogRejected: true
      }
    case POST_TIMELOG_DEFAULT:
      return {
        ...state,
        addTimelogLoading: false,
        addTimelogSuccess: false,
        addTimelogRejected: false
      }
    case PUT_TIMELOG:
      return {
        ...state,
        updateTimelogLoading: true,
        updateTimelogSuccess: false,
        updateTimelogRejected: false
      }
    case PUT_TIMELOG_FULFILLED:
      return {
        ...state,
        updateTimelogLoading: false,
        updateTimelogSuccess: true,
        updateTimelogData: action.payload
      }
    case PUT_TIMELOG_REJECTED:
      return {
        ...state,
        updateTimelogLoading: false,
        updateTimelogRejected: true
      }
    case PUT_TIMELOG_DEFAULT:
      return {
        ...state,
        updateTimelogLoading: false,
        updateTimelogSuccess: false,
        updateTimelogRejected: false
      }
    case DELETE_TIMELOG:
      return {
        ...state,
        deleteTimelogLoading: true,
        deleteTimelogSuccess: false,
        deleteTimelogRejected: false
      }
    case DELETE_TIMELOG_FULFILLED:
      return {
        ...state,
        deleteTimelogLoading: false,
        deleteTimelogSuccess: true,
      }
    case DELETE_TIMELOG_REJECTED:
      return {
        ...state,
        deleteTimelogLoading: false,
        deleteTimelogRejected: true
      }
    case DELETE_TIMELOG_DEFAULT:
      return {
        ...state,
        deleteTimelogLoading: false,
        deleteTimelogSuccess: false,
        deleteTimelogRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
