import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import login from '../login/reducers'
import announcement from '../announcements/reducers'
import employee from '../profile/reducers'
import education from '../educations/reducers'
import employmentHistory from '../employmentHistories/reducers'
import reference from '../references/reducers'
import performance from '../performances/reducers'
import dependent from '../dependents/reducers'
import disciplinaryAction from '../disciplinaryActions/reducers'
import accountability from '../accountabilities/reducers'
import medicalHistory from '../medicalHistories/reducers'
import leave from '../leaves/reducers'
import document from '../documents/reducers'
import allowance from '../allowances/reducers'
import personalMovement from '../personalMovements/reducers'
import businessTrip from '../businessTrips/reducers'
import memorandum from '../memorandums/reducers'
import timeRequest from '../timeRequests/reducers'
import overtime from '../overtimes/reducers'
import attendance from '../attendances/reducers'
import fileReport from '../fileReports/reducers'


const rootReducer = combineReducers({
  form,
  login,
  announcement,
  memorandum,
  employee,
  education,
  employmentHistory,
  reference,
  performance,
  dependent,
  disciplinaryAction,
  accountability,
  medicalHistory,
  leave,
  personalMovement,
  document,
  businessTrip,
  timeRequest,
  overtime,
  attendance,
  allowance,
  fileReport
})

export default rootReducer
