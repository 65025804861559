import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_FILE_REPORTS = 'GET_FILE_REPORTS'
export const GET_FILE_REPORTS_FULFILLED = 'GET_FILE_REPORTS_FULFILLED'
export const GET_FILE_REPORTS_REJECTED = 'GET_FILE_REPORTS_REJECTED'
export const GET_FILE_REPORTS_DEFAULT = 'GET_FILE_REPORTS_DEFAULT'

export const GET_FILE_REPORT = 'GET_FILE_REPORT'
export const GET_FILE_REPORT_FULFILLED = 'GET_FILE_REPORT_FULFILLED'
export const GET_FILE_REPORT_REJECTED = 'GET_FILE_REPORT_REJECTED'
export const GET_FILE_REPORT_DEFAULT = 'GET_FILE_REPORT_DEFAULT'

export const POST_FILE_REPORT = 'POST_FILE_REPORT'
export const POST_FILE_REPORT_FULFILLED = 'POST_FILE_REPORT_FULFILLED'
export const POST_FILE_REPORT_REJECTED = 'POST_FILE_REPORT_REJECTED'
export const POST_FILE_REPORT_DEFAULT = 'POST_FILE_REPORT_DEFAULT'

export const VERIFY = 'VERIFY'
export const VERIFY_FULFILLED = 'VERIFY_FULFILLED'
export const VERIFY_REJECTED = 'VERIFY_REJECTED'
export const VERIFY_DEFAULT = 'VERIFY_DEFAULT'

export const PUT_FILE_REPORT = 'PUT_FILE_REPORT'
export const PUT_FILE_REPORT_FULFILLED = 'PUT_FILE_REPORT_FULFILLED'
export const PUT_FILE_REPORT_REJECTED = 'PUT_FILE_REPORT_REJECTED'
export const PUT_FILE_REPORT_DEFAULT = 'PUT_FILE_REPORT_DEFAULT'

export const DELETE_FILE_REPORT = 'DELETE_FILE_REPORT'
export const DELETE_FILE_REPORT_FULFILLED = 'DELETE_FILE_REPORT_FULFILLED'
export const DELETE_FILE_REPORT_REJECTED = 'DELETE_FILE_REPORT_REJECTED'
export const DELETE_FILE_REPORT_DEFAULT = 'DELETE_FILE_REPORT_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadFileReports = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_FILE_REPORTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/file-reports?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_FILE_REPORTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_FILE_REPORTS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_FILE_REPORTS_DEFAULT }))
  }
}

export const loadFileReport = (id) => {
  return dispatch => {
    dispatch({
      type: GET_FILE_REPORT,
      payload: {}
    })
    return Axios.get(`${API_URL}/file-report/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_FILE_REPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_FILE_REPORT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_FILE_REPORT_DEFAULT }))
  }
}

export const submitFileReport = (data) => {
  return dispatch => {
    dispatch({
      type: POST_FILE_REPORT,
      payload: {}
    })
    return Axios.post(`${API_URL}/file-report`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_FILE_REPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_FILE_REPORT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_FILE_REPORT_DEFAULT }))
  }
}

export const updateFileReport = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_FILE_REPORT,
      payload: {}
    })
    return Axios.put(`${API_URL}/file-report/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_FILE_REPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_FILE_REPORT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_FILE_REPORT_DEFAULT }))
  }
}

export const deleteFileReport = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_FILE_REPORT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/file-report/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_FILE_REPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_FILE_REPORT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_FILE_REPORT_DEFAULT }))
  }
}


export const verifyFileReport = (data) => {
  return dispatch => {
    dispatch({
      type: VERIFY,
      payload: {}
    })
    return Axios.post(`${API_URL}/file-report-verify`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: VERIFY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: VERIFY_REJECTED,
          payload: error
        })
      })
  }
}













