import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Table, Select, Menu, DatePicker, Modal, Popconfirm, Dropdown, Avatar, Pagination } from 'antd'
import moment from 'moment'
import Toolbar from '../../../shared/components/toolbar'
import { ImagePresignedUrl } from '../../../../../utilities/renderer'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { VIEW, ADD, NONE } from '../../../../../utilities/actionEnums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadAllowanceAllotmentByUserId } from '../../actions'

let paginationDefault = {
    page: 1,
    limit: 10,
    status: 0,
    sort: 'desc',
    search: '',
}
const { Option } = Select
const { RangePicker } = DatePicker;
const AllowanceGroup = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState(paginationDefault)
    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        if (props.modalShow) {
            setPagination({ ...paginationDefault })
            const { onLoadAllowanceAllotmentByUserId } = props
            onLoadAllowanceAllotmentByUserId({ ...paginationDefault, userId: props.selectedUserId })
        }
    }, [props.modalShow])

    useEffect(() => {
        props.allowanceAllotmentByUserIdData &&
            setTableData([...props.allowanceAllotmentByUserIdData.data])
    }, [props.allowanceAllotmentByUserIdData])

    useEffect(() => {
        if (props.modalShow) {
            const { onLoadAllowanceAllotmentByUserId } = props
            onLoadAllowanceAllotmentByUserId({ ...pagination, userId: props.selectedUserId })
        }
    }, [pagination])

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, obj) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={obj.user && obj.user.image && obj.user.image[0] && obj.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, obj) => {
                return (<span style={{ textTransform: 'capitalize' }}>{obj.user && obj.user.firstName} {obj.user && obj.user.lastName}</span>)
            }
        },
        {
            title: 'Allowance Type',
            dataIndex: 'allowanceType',
            key: 'allowanceType',
            render: (item) => {
                return (<span>{item.title}</span>)
            }
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (item) => {
                return (<span>₱ {item}</span>)
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, obj) => {
                return (<span>{moment(item).format('LLLL')}</span>)
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                                props.setModalShow(false)
                            }}>
                                View
                            </Menu.Item>
                            {/* {
                                access.check('allowances', 'delete') &&
                                <Menu.Item key="1" disabled >
                                    <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                        Delete
                                    </Popconfirm>

                                </Menu.Item>
                            } */}
                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },

    ];
    return (
        <Modal title="View User Allowances" visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={1200}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Row gutter={32}>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        {/* <Toolbar
                            title='Allowances'
                            // handleAdd={() => props.setActionType(ADD)}
                            // removeAdd={!access.check('allowances', 'add')}
                            // handleSearch={(search) => setPagination({ ...pagination, search })}
                            handleSort={(sort) => setPagination({ ...pagination, sort })}
                            statusArray={[
                                { id: 0, label: 'All' },
                                { id: 1, label: 'For Approval' },
                                { id: 2, label: 'Approved' },
                                { id: 5, label: 'Cancelled' },
                                { id: 6, label: 'Rejected' },
                            ]}
                        /> */}
                        <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={false} />
                        <Row style={{ marginTop: 30, width: '100%' }}>
                            <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                <Pagination total={props.allowanceAllotmentByUserIdData.total} onChange={(page, limit) => onChangePagination(page, limit)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        allowanceAllotmentByUserIdData: state.admin.allowanceAllotment.allowanceAllotmentByUserIdData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAllowanceAllotmentByUserId: data => dispatch(loadAllowanceAllotmentByUserId(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AllowanceGroup)