import {
  GET_POSITIONS,
  GET_POSITIONS_FULFILLED,
  GET_POSITIONS_REJECTED,
  PUT_POSITION,
  PUT_POSITION_FULFILLED,
  PUT_POSITION_REJECTED,
  POST_POSITION,
  POST_POSITION_FULFILLED,
  POST_POSITION_REJECTED,
  DELETE_POSITION,
  DELETE_POSITION_FULFILLED,
  DELETE_POSITION_REJECTED,
  GET_POSITIONS_DEFAULT,
  POST_POSITION_DEFAULT,
  PUT_POSITION_DEFAULT,
  DELETE_POSITION_DEFAULT
} from '../actions'

export default function position(state = {
  positionsLoading: false,
  positionsSuccess: false,
  positionsRejected: false,
  positionsData: {
    data: []
  },
  updatePositionLoading: false,
  updatePositionSuccess: false,
  updatePositionRejected: false,
  addPositionLoading: false,
  addPositionSuccess: false,
  addPositionRejected: false,
  deletePositionLoading: false,
  deletePositionSuccess: false,
  deletePositionRejected: false
}, action) {
  switch (action.type) {
    case GET_POSITIONS:
      return {
        ...state,
        positionsLoading: true,
        positionsSuccess: false,
        positionsRejected: false
      }
    case GET_POSITIONS_FULFILLED:
      return {
        ...state,
        positionsLoading: false,
        positionsSuccess: true,
        positionsData: action.payload
      }
    case GET_POSITIONS_REJECTED:
      return {
        ...state,
        positionsLoading: false,
        positionsRejected: true
      }
    case GET_POSITIONS_DEFAULT:
      return {
        ...state,
        positionsLoading: false,
        positionsSuccess: false,
        positionsRejected: false
      }
    case POST_POSITION:
      return {
        ...state,
        addPositionLoading: true,
        addPositionSuccess: false,
        addPositionRejected: false
      }
    case POST_POSITION_FULFILLED:
      return {
        ...state,
        addPositionLoading: false,
        addPositionSuccess: true,
        addPositionData: action.payload
      }
    case POST_POSITION_REJECTED:
      return {
        ...state,
        addPositionLoading: false,
        addPositionRejected: true
      }
    case POST_POSITION_DEFAULT:
      return {
        ...state,
        addPositionLoading: false,
        addPositionSuccess: false,
        addPositionRejected: false
      }
    case PUT_POSITION:
      return {
        ...state,
        updatePositionLoading: true,
        updatePositionSuccess: false,
        updatePositionRejected: false
      }
    case PUT_POSITION_FULFILLED:
      return {
        ...state,
        updatePositionLoading: false,
        updatePositionSuccess: true,
        updatePositionData: action.payload
      }
    case PUT_POSITION_REJECTED:
      return {
        ...state,
        updatePositionLoading: false,
        updatePositionRejected: true
      }
    case PUT_POSITION_DEFAULT:
      return {
        ...state,
        updatePositionLoading: false,
        updatePositionSuccess: false,
        updatePositionRejected: false
      }
    case DELETE_POSITION:
      return {
        ...state,
        deletePositionLoading: true,
        deletePositionSuccess: false,
        deletePositionRejected: false
      }
    case DELETE_POSITION_FULFILLED:
      return {
        ...state,
        deletePositionLoading: false,
        deletePositionSuccess: true,
      }
    case DELETE_POSITION_REJECTED:
      return {
        ...state,
        deletePositionLoading: false,
        deletePositionRejected: true
      }
    case DELETE_POSITION_DEFAULT:
      return {
        ...state,
        deletePositionLoading: false,
        deletePositionSuccess: false,
        deletePositionRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
