import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Rate, Avatar, Tooltip, Row, Col, notification, List } from 'antd'
import { connect } from 'react-redux'
import { loadPerformances, deletePerformance } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'


const PerformancesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})
    const [ratings, setRatings] = useState(0)

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updatePerformanceSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the performance information.',
            });
        }
        if (props.addPerformanceSuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new performance information.',
            });
        }
        if (props.deletePerformanceSuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted performance data.',
            });
        }

        props.onLoadPerformances(pagination)
    }, [props.updatePerformanceSuccess, props.addPerformanceSuccess, props.deletePerformanceSuccess])

    function handleDelete(id) {
        props.onDeletePerformance(id)
    }

    function performanceAverage(value) {
        if (value <= 69) {
            return <span style={{ color: 'red' }}>BELOW POOR PERFORMANCE</span>
        } else if (value >= 70 && value <= 74) {
            return <span style={{ color: '#e84f4f' }}>NEEDS IMPROVEMENT</span>
        } else if (value >= 75 && value <= 84) {
            return <span style={{ color: '#a0a651' }}>AVERAGE</span>
        } else if (value >= 85 && value <= 94) {
            return <span style={{ color: '#499141' }}>ABOVE AVERAGE</span>
        } else if (value >= 95 && value <= 100) {
            return <span style={{ color: 'green' }}>EXCELLENT</span>
        } else {
            setRatings(0)
            return ''
        }
    }
    return (
        <Layout className='performances-page'>
            <Card>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Ratings
                            </Col>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Date Coverd From
                            </Col>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Date Coverd To
                            </Col>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Remarks
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={props.performancesData.data}
                    renderItem={data => <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item-ratings' >
                                    <div >
                                        <h1>({data.ratings}%) <br />{performanceAverage(data.ratings)}<StatusIconDisplay status={data.status} /></h1>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6} lg={6} xs={24} sm={24}>
                                <p className='date-covered'>{moment(data.dateCovered).format('LL')}</p>
                            </Col>

                            <Col md={6} lg={6} xs={24} sm={24}>
                                <p className='date-covered'>{moment(data.dateCovered).format('LL')}</p>
                            </Col>

                            <Col md={6} lg={6} xs={24} sm={24}>
                                <p className='remarks'>{data.remarks}</p>
                            </Col>

                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        performancesData: state.client.performance.performancesData,
        addPerformanceSuccess: state.client.performance.addPerformanceSuccess,
        updatePerformanceSuccess: state.client.performance.updatePerformanceSuccess,
        deletePerformanceSuccess: state.client.performance.deletePerformanceSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPerformances: data => dispatch(loadPerformances(data)),
        onDeletePerformance: id => dispatch(deletePerformance(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformancesComponent)

