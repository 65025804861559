import React, { Fragment, useState, useEffect } from 'react'
import { Layout, Tabs } from 'antd'
import Announcements from '../../announcements/components'
import Memorandums from '../../memorandums/components'
import Profile from '../../profile/components'
import Educations from '../../educations/components'
import EmploymentHistories from '../../employmentHistories/components'
import References from '../../references/components'
import Dependents from '../../dependents/components'
import TimeRequests from '../../timeRequests/components'
import FileReports from '../../fileReports/components'
import Performances from '../../performances/components'
import DisciplinaryActions from '../../disciplinaryActions/components'
import Accountabilities from '../../accountabilities/components'
import MedicalHistories from '../../medicalHistories/components'
import PersonalMovements from '../../personalMovements/components'
import Documents from '../../documents/components'
import Allowances from '../../allowances/components'
import BusinessTrips from '../../businessTrips/components'
import Overtimes from '../../overtimes/components'
import Attendances from '../../attendances/components'
import Leaves from '../../leaves/components'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { NotFound } from '../../notFound'

import * as _ from 'lodash'
import './index.scss'
const { TabPane } = Tabs;
const TabsComponent = (props) => {
    const [currentIndexPath, setCurrentIndexPath] = useState('')
    const [tabs, setTabs] = useState([
        {
            name: 'News Feed',
            route: '/news',
            component: Announcements,
        },
        {
            name: 'Memorandums',
            route: '/memorandums',
            component: Memorandums,
        },
        {
            name: 'Profile',
            route: '/profile',
            component: Profile,
        },
        {
            name: 'Attendances',
            route: '/attendance',
            component: Attendances,
        },
        {
            name: 'Allowances',
            route: '/allowances',
            component: Allowances
        },
        {
            name: 'Educations',
            route: '/educations',
            component: Educations,
        },
        {
            name: 'Employment Histories',
            route: '/employment-histories',
            component: EmploymentHistories,
        },
        {
            name: 'Contact References',
            route: '/contact-references',
            component: References,
        },
        {
            name: 'Appraisal Performances',
            route: '/appraisal-performances',
            component: Performances,
        },
        {
            name: 'Dependents',
            route: '/dependents',
            component: Dependents,
        },
        {
            name: 'Overtime',
            route: '/overtime',
            component: Overtimes,
        },
        {
            name: 'Disciplinary Actions',
            route: '/disciplinary-actions',
            component: DisciplinaryActions,
        },
        {
            name: 'Accountabilities',
            route: '/accountabilities',
            component: Accountabilities,
        },
        {
            name: 'Medical Histories',
            route: '/medical-histories',
            component: MedicalHistories,
        },
        {
            name: 'Leave Applications',
            route: '/leave-applications',
            component: Leaves,
        },
        {
            name: 'Personnel Movements',
            route: '/personal-movements',
            component: PersonalMovements
        },
        {
            name: 'Documents Credentials',
            route: '/documents',
            component: Documents
        },
        {
            name: 'Notice of Official Business',
            route: '/notice-of-official-business',
            component: BusinessTrips
        },
        {
            name: 'Filling Time Confirmation Requests',
            route: '/filling-time-confirmation-requests',
            component: TimeRequests
        },
        {
            name: 'Filling Reports',
            route: '/filling-reports',
            component: FileReports
        },

    ])

    function handleChangeTab(index) {
        index = +index
        props.history.push(tabs[index].route)
        setCurrentIndexPath(index)
    }

    useEffect(() => {
        const currentPath = props.location.pathname
        if (currentPath === '/') return props.history.push(tabs[0].route)
        const index = tabs.findIndex(data => data.route === currentPath)
        setCurrentIndexPath(index)
    }, [])

    return (
        <Fragment>
            <Layout.Content className='tabs-content' style={{ padding: '10vh 2vw' }}>
                <Switch>
                    {/* // this will scroll to top when switch changes. */}
                    {window.scrollTo(0, 0)}
                    {_.map(tabs, (path, index) => (
                        <Route
                            exact={path.exact}
                            path={path.route}
                            render={(prop) => <path.component
                                {...prop}
                                socket={props.socket}
                            />}
                            key={index}
                        />
                    ))}
                    <Route component={NotFound} />
                    <Redirect to='/404' />
                </Switch>
            </Layout.Content>
        </Fragment>

    )
}

export default withRouter(TabsComponent)