import React from 'react'
import { Layout } from 'antd'

const Viewer = (props) => {
    return (
        <Layout>
            <div style={{ display: (props.isView !== false) && 'none' }}>{props.listComponent}</div>
            {props.isView === true && props.viewComponent}
        </Layout>
    )
}

export default Viewer