import React from 'react'
import { Alert } from 'antd'
import enumServiceStatus from './enumServiceStatus'
import PropTypes from 'prop-types';
const RemarksRenderer = (props) => {

    function getType(status) {
        const { APPROVAL, APPROVED, CANCELLED, REJECTED } = enumServiceStatus
        switch (status) {
            case APPROVAL, CANCELLED, REJECTED:
                return 'error'
            case APPROVED:
                return 'success'
            default:
                return 'error'
        }
    }

    return (
        (props.remarks && props.remarks.length) ?
            <Alert
                message={`[Remarks]`}
                description={props.remarks}
                type={getType(props.status)}
                closable={props.closable}
            /> : null
    )
}

RemarksRenderer.defaultProps = {
    closable: false,
    status: 1
}

RemarksRenderer.propTypes = {
    status: PropTypes.any.isRequired,
    // remarks: PropTypes.any.isRequired
}

export default RemarksRenderer