import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker } from 'antd'
import { loadFileReport, updateFileReport, submitFileReport, verifyFileReport } from '../actions'
import { loadEmployees } from '../../employee/actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import VerifierCheck from '../../shared/components/verifierCheck'
import './index.scss'
import access from '../../../../utilities/access'
const { Option } = Select

const FormFileReport = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            props.onSubmitFileReport({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const fileReportId = props.selectedRow._id
            props.onUpdateFileReport({ id: fileReportId, ...submitData }).then(res => {
                setLoading(false)
                setResetVerifier(true)
            })
        }
    }
    useEffect(() => {
        if (props.actionType === VIEW) {
            if (!(userId)) return
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                id: userId,
            })
        }
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    const isAccessDisabled = (!access.check('fillingReports', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {
                ((props.actionType === VIEW) && access.check('fillingReports', 'verify')) &&
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                        <VerifierCheck id={props.selectedRow && props.selectedRow._id} verifier={props.selectedRow && props.selectedRow.verifier} onSubmit={props.onVerifyFileReport} reset={resetVerifier} />
                    </Col>
                </Row>
            }
            <Form
                name={`fileReport`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        {
                            (props.actionType === VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('fillingReports', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('fillingReports', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('fillingReports', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                {
                    ((access.check('fillingReports', 'add') && (props.actionType === ADD)) || (access.check('fillingReports', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateFileReportLoading || loading}   >
                                    Submit file report
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateFileReportLoading: state.admin.fileReport.updateFileReportLoading,
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadFileReport: (id) => dispatch(loadFileReport(id)),
        onVerifyFileReport: (id) => dispatch(verifyFileReport(id)),
        onUpdateFileReport: (data) => dispatch(updateFileReport(data)),
        onSubmitFileReport: (data) => dispatch(submitFileReport(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormFileReport)