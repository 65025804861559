import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_MEMORANDUMS = 'GET_MEMORANDUMS'
export const GET_MEMORANDUMS_FULFILLED = 'GET_MEMORANDUMS_FULFILLED'
export const GET_MEMORANDUMS_REJECTED = 'GET_MEMORANDUMS_REJECTED'
export const GET_MEMORANDUMS_DEFAULT = 'GET_MEMORANDUMS_DEFAULT'

export const GET_MEMORANDUM = 'GET_MEMORANDUM'
export const GET_MEMORANDUM_FULFILLED = 'GET_MEMORANDUM_FULFILLED'
export const GET_MEMORANDUM_REJECTED = 'GET_MEMORANDUM_REJECTED'
export const GET_MEMORANDUM_DEFAULT = 'GET_MEMORANDUM_DEFAULT'

export const POST_MEMORANDUM = 'POST_MEMORANDUM'
export const POST_MEMORANDUM_FULFILLED = 'POST_MEMORANDUM_FULFILLED'
export const POST_MEMORANDUM_REJECTED = 'POST_MEMORANDUM_REJECTED'
export const POST_MEMORANDUM_DEFAULT = 'POST_MEMORANDUM_DEFAULT'

export const PUT_MEMORANDUM = 'PUT_MEMORANDUM'
export const PUT_MEMORANDUM_FULFILLED = 'PUT_MEMORANDUM_FULFILLED'
export const PUT_MEMORANDUM_REJECTED = 'PUT_MEMORANDUM_REJECTED'
export const PUT_MEMORANDUM_DEFAULT = 'PUT_MEMORANDUM_DEFAULT'

export const DELETE_MEMORANDUM = 'DELETE_MEMORANDUM'
export const DELETE_MEMORANDUM_FULFILLED = 'DELETE_MEMORANDUM_FULFILLED'
export const DELETE_MEMORANDUM_REJECTED = 'DELETE_MEMORANDUM_REJECTED'
export const DELETE_MEMORANDUM_DEFAULT = 'DELETE_MEMORANDUM_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadMemorandums = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_MEMORANDUMS,
      payload: {}
    })
    return Axios.get(`${API_URL}/memorandums?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_MEMORANDUMS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_MEMORANDUMS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_MEMORANDUMS_DEFAULT }))
  }
}

export const loadMemorandum = (id) => {
  return dispatch => {
    dispatch({
      type: GET_MEMORANDUM,
      payload: {}
    })
    return Axios.get(`${API_URL}/memorandum/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_MEMORANDUM_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_MEMORANDUM_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_MEMORANDUM_DEFAULT }))
  }
}

export const submitMemorandum = (data) => {
  return dispatch => {
    dispatch({
      type: POST_MEMORANDUM,
      payload: {}
    })
    return Axios.post(`${API_URL}/memorandum`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_MEMORANDUM_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_MEMORANDUM_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_MEMORANDUM_DEFAULT }))
  }
}

export const updateMemorandum = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_MEMORANDUM,
      payload: {}
    })
    return Axios.put(`${API_URL}/memorandum/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_MEMORANDUM_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_MEMORANDUM_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_MEMORANDUM_DEFAULT }))
  }
}

export const deleteMemorandum = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_MEMORANDUM,
      payload: {}
    })
    return Axios.delete(`${API_URL}/memorandum/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_MEMORANDUM_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_MEMORANDUM_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_MEMORANDUM_DEFAULT }))
  }
}











