import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Rate, Menu, Dropdown, Popconfirm, Avatar } from 'antd'
import { loadAdmins, deleteAdmin } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import Toolbar from '../../shared/components/toolbar'
import { ImagePresignedUrl } from '../../../../utilities/renderer'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadAdmins } = props
        onLoadAdmins(pagination)
        // eslint-disable-next-line
    }, [props.updateAdminSuccess, props.deleteAdminSuccess, props.addAdminSuccess])

    useEffect(() => {
        const { onLoadAdmins } = props
        onLoadAdmins(pagination)
    }, [pagination])

    useEffect(() => {
        setTableData([...props.adminsData.data])
    }, [props.adminsData])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    function handleDelete(id) {
        props.onDeleteAdmin(id)
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={data && data.image[0] && data.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data && data.firstName} {data && data.lastName}</span>)
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <span>{item && item.title}</span>
                    </div>
                )
            }
        },
        {
            title: 'Job Level',
            dataIndex: 'jobLevel',
            key: 'jobLevel',
            width: 200,
            render: (item, data) => {
                return (
                    <span>{data.position && data.position.jobLevel}</span>
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LLLL'))
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                            }}>
                                View
                            </Menu.Item>
                            <Menu.Item key="1" >
                                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                    Delete
                                </Popconfirm>

                            </Menu.Item>
                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout className='page'>
            <Row gutter={32}>
                <Col md={24} lg={24} xs={24} sm={24}>
                    <Toolbar
                        title='Administrators'
                        handleAdd={() => props.setActionType(ADD)}
                        handleSearch={(search) => setPagination({ ...pagination, search })}
                        handleSort={(sort) => setPagination({ ...pagination, sort })}
                        handleStatus={(status) => setPagination({ ...pagination, status })}
                        statusArray={[
                            { id: 0, label: 'Active' },
                            { id: 1, label: 'Inactive' },
                        ]}
                    />
                    <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.adminsData.adminsLoading} />
                    <Row style={{ marginTop: 30, width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24} align="right" >
                            <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.adminsData.total} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        adminsData: state.admin.administrator.adminsData,
        adminsLoading: state.admin.administrator.adminsLoading,
        updateAdminSuccess: state.admin.administrator.updateAdminSuccess,
        addAdminSuccess: state.admin.administrator.addAdminSuccess,
        deleteAdminSuccess: state.admin.administrator.deleteAdminSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAdmins: data => dispatch(loadAdmins(data)),
        onDeleteAdmin: id => dispatch(deleteAdmin(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)