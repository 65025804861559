import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Checkbox, Alert, notification, Card, Space, Divider } from 'antd'
import { loadDeduction, updateDeduction, submitDeduction, POST_DEDUCTION_FULFILLED } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import './index.scss'
import moment from 'moment'
import { loadProjects } from '../../../admin/projects/actions'
import { loadEmployees, loadEmployee } from '../../../admin/employee/actions'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { upload } from '../../../../utilities/filesController'
import FormType from './modals/type'
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select

const FormDeduction = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])
    const [modalTypeShow, setModalTypeShow] = useState(false)
    const [userDetails, setUserDetails] = useState({})
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id


    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            grantedDate: props.selectedRow && moment(props.selectedRow.grantedDate),
            deductionStartDate: props.selectedRow && moment(props.selectedRow.deductionStartDate),
            deductionEndDate: props.selectedRow && moment(props.selectedRow.deductionEndDate),
            lastDeductionDate: props.selectedRow && moment(props.selectedRow.lastDeductionDate),
            deductionSchedule: props.selectedRow && moment(props.selectedRow.deductionSchedule),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)
        // eslint-disable-next-line
    }, [props.selectedRow])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        console.log("value", value)

        if (props.actionType === ADD) {
            props.onSubmitDeduction({ images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                if (res.type === POST_DEDUCTION_FULFILLED) {
                    props.setActionType(NONE)
                    form.resetFields()
                }
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const deductionId = props.selectedRow._id
            props.onUpdateDeduction({ id: deductionId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        props.onLoadEmployees({
            page: 1,
            limit: 50,
            id: userId,
        })

        if (props.actionType === VIEW) {
            props.onLoadEmployee(userId).then(res => {
                setUserDetails(res.payload)
            })
        }
    }, [])

    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`deduction-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Loan Type"
                            name="loanType"
                            rules={[{ required: true, message: 'Please input load type!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Principal Amount"
                            name="principalAmount"
                            rules={[{ required: true, message: 'Please input principal amount!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Number of Installment"
                            name="numberOfInstallment"
                            rules={[{ required: true, message: 'Please input number of installment!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Balance"
                            name="balance"
                            rules={[{ required: true, message: 'Please input balance!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Amortization"
                            name="amortization"
                            rules={[{ required: true, message: 'Please input amortization!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Total Deduction"
                            name="totalDeduction"
                            rules={[{ required: true, message: 'Please input total deduction!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="status"
                            valuePropName="checked"
                        >
                            <Checkbox  >Is Active?</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Row gutter={8} justify='center'>
                            <Col md={22} lg={22} sm={24} xs={24}>
                                <Form.Item
                                    label="Type"
                                    name="typeId"
                                    rules={[{ required: true, message: 'Please input type!' }]}
                                >
                                    <Select >
                                        {
                                            props.deductionTypesData.data.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item._id}>{item.title}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={2} lg={2} sm={24} xs={24}  >
                                <div className='type-options'>
                                    <Button className='btn-circle-type' onClick={() => setModalTypeShow(true)} shape="circle" icon={<PlusOutlined />} />
                                </div>
                            </Col>
                        </Row>
                        <Form.Item
                            label="Granted Date"
                            name="grantedDate"
                            rules={[{ required: true, message: 'Please input granted date!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Deduction Start Date"
                            name="deductionStartDate"
                            rules={[{ required: true, message: 'Please input deduction start date!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Deduction End Date"
                            name="deductionEndDate"
                            rules={[{ required: true, message: 'Please input deduction end date!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Deduction Schedule"
                            name="deductionSchedule"
                            rules={[{ required: true, message: 'Please input deduction schedule!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Last Deduction Date"
                            name="lastDeductionDate"
                            rules={[{ required: true, message: 'Please input last deduction date!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' type="primary" htmlType="submit"
                                loading={props.updateDeductionLoading || loading}   >
                                Submit Deduction
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <FormType visible={modalTypeShow} setModalTypeShow={setModalTypeShow} />
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateDeductionLoading: state.adminPms.deduction.updateDeductionLoading,
        deductionTypesData: state.adminPms.deduction.deductionTypesData,
        employeesData: state.admin.employee.employeesData,
        projectsData: state.admin.project.projectsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadDeduction: (id) => dispatch(loadDeduction(id)),
        onUpdateDeduction: (data) => dispatch(updateDeduction(data)),
        onSubmitDeduction: (data) => dispatch(submitDeduction(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
        onLoadProjects: (data) => dispatch(loadProjects(data)),
        onLoadEmployee: (id) => dispatch(loadEmployee(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormDeduction)