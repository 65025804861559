import React from 'react'
import { connect } from 'react-redux'
import { Button, Layout, Tooltip, Tabs } from 'antd'
import './index.scss'
import Form from './form'
import { NONE, VIEW } from '../../../../utilities/actionEnums'
import { LeftOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

const View = (props) => {
    console.log("props", props)
    return (
        <Layout className='page'>
            <div className='back-btn-container'>
                <Tooltip title="Back"><Button className='btn-circle' shape="circle" icon={<LeftOutlined />} onClick={() => {
                    props.setSelectedId(undefined)
                    props.setActionType(NONE)
                }} /></Tooltip>
            </div>
            <div className='tab-content'>
                <Form {...props} />
            </div>
        </Layout>

    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(View)