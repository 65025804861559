import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm, Avatar, Rate } from 'antd'
import { loadEmployees, deleteEmployee } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import moment from 'moment'
import Toolbar from '../../shared/components/toolbar'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'
import access from '../../../../utilities/access'
import Papa from 'papaparse'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadEmployees } = props
        onLoadEmployees(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        const { onLoadEmployees } = props
        onLoadEmployees(pagination)
        // eslint-disable-next-line
    }, [props.updateEmployeeSuccess, props.deleteEmployeeSuccess, props.addEmployeeSuccess])

    useEffect(() => {
        setTableData([...props.employeesData.data])
    }, [props.employeesData])

    function handleDelete(id) {
        const postById = getUser().id
        props.onDeleteEmployee(id, postById)
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.firstName} ${data.lastName}`,
                "Account Number": data.accountNumber,
                "Address": data.address,
                "Allowance": data.allowance,
                "Birthday": data.birthday,
                "Center Code": data.centerCode,
                "Citizenship": data.citizenship,
                "Contact": data.contact,
                "Date Hired": data.dateHired,
                "Date Resigned": data.dateResigned,
                "Division Code": data.divisionCode,
                "Email": data.email,
                "Employee Number": data.employeeNumber,
                "Employment Status": data.employmentStatus,
                "Gender": data.gender,
                "Marital Status": data.maritalStatus,
                "Pag Ibig Number": data.pagIbigNumber,
                "Pag Ibig Prem": data.pagIbigPrem,
                "Phil Health Number": data.philHealthNumber,
                "Position": data.position && data.position.title,
                "Position Date": data.positionDate,
                "Project": data.project && data.project.title,
                "Property Code": data.propertyCode,
                "Rate Per Hour": data.ratePerHour,
                "Religion": data.religion,
                "Section Code": data.sectionCode,
                "Sick Leave Days": data.sickLeaveDays,
                "SSS Number": data.sssNumber,
                "Tax Status": data.taxStatus,
                "Tin": data.tin,
                "Vacation Leave Days": data.vacationLeaveDays,
                "Working Days Per Month": data.workingDaysPerMonth,
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `employees_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `employees_${unix}.csv`);
        tempLink.click();
    }


    const dataSource = tableData
    const columns = [

        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            width: 350,
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <Avatar size={64} src={<ImagePresignedUrl alt='user-profile' src={data.image && data.image[0] && data.image[0].url} objectFit='cover' />} />
                        <div className='content' >
                            <span className='name'>{data.firstName} {data.lastName}</span><br />
                            <span className='email'>{data.email}</span>
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Employee Number',
            dataIndex: 'employeeNumber',
            key: 'employeeNumber'
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <span>{item && item.title}</span>
                    </div>
                )
            }
        },
        {
            title: 'Job Level',
            dataIndex: 'jobLevel',
            key: 'jobLevel',
            width: 200,
            // render: (item, data) => {
            //     return (
            //         <Rate value={data.position && data.position.jobLevel} style={{ color: '#108ee9' }} disabled />
            //     )
            // }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <Tag color="#108ee9">Active</Tag>
                    </div>
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LLLL'))
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedId(data._id)
                            }}>
                                View
                            </Menu.Item>
                            {
                                access.check('employeeMasterfile', 'delete') &&
                                <Menu.Item key="1" >
                                    <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                        Delete
                                    </Popconfirm>
                                </Menu.Item>
                            }

                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout className='page' >
            {
                access.check('employeeMasterfile', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Employee Masterfile'
                                handleAdd={() => props.setActionType(ADD)}
                                removeAdd={!access.check('employeeMasterfile', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                // handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.employeesData.employeesLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination
                                        //  defaultCurrent={pagination.page}
                                        onChange={(page, limit) => onChangePagination(page, limit)} total={props.employeesData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
        updateEmployeeSuccess: state.admin.employee.updateEmployeeSuccess,
        addEmployeeSuccess: state.admin.employee.addEmployeeSuccess,
        deleteEmployeeSuccess: state.admin.employee.deleteEmployeeSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmployees: data => dispatch(loadEmployees(data)),
        onDeleteEmployee: (id, postById) => dispatch(deleteEmployee(id, postById)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)