import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker } from 'antd'
import { loadTimeRequest, updateTimeRequest, submitTimeRequest, verifyTimeRequest } from '../actions'
import { loadEmployees } from '../../employee/actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { ArrowRightOutlined } from '@ant-design/icons'
import './index.scss'
import moment from 'moment'
import { loadProjects } from '../../projects/actions'
import { loadPositions } from '../../positions/actions'
import { getUser } from '../../../../utilities/token'
import access from '../../../../utilities/access'

const { Option } = Select
const FormTimeRequest = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})
    const [selectedPosition, setSelectedPosition] = useState({})
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            timeIn: props.selectedRow && moment(props.selectedRow.timeIn),
            timeOut: props.selectedRow && moment(props.selectedRow.timeOut),
        })



        props.onLoadPositions({
            page: 1,
            limit: 100,
            // status: 0
        })

        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            postById: getUser().id,
            ...value,
        }

        if (props.actionType === ADD) {
            props.onSubmitTimeRequest({
                ...submitData,
            }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const timeRequestId = props.selectedRow._id
            props.onUpdateTimeRequest({ id: timeRequestId, ...submitData }).then(res => {
                setLoading(false)
                setResetVerifier(true)
            })
        }
    }
    useEffect(() => {
        if (props.actionType === VIEW) {
            if (!(userId)) return
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                id: userId,
            })
        }
        props.onLoadProjects({
            page: 1,
            limit: 100,
        })

    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    function selectUser({ }, e) {
        setSelectedUser(e.data)
        console.log("employment status", e)
        form.setFieldsValue({
            fromPositionId: e.data && e.data.position && e.data.position.title,
            fromProjectId: e.data && e.data.project && e.data.project.title,
            fromSalary: e.data && e.data && e.data.ratePerMonth,
            fromEmploymentStatus: e.data && e.data && e.data.employmentStatus,
        })
    }

    function selectPosition({ }, e) {
        setSelectedPosition(e.data)
    }

    const isAccessDisabled = (!access.check('timeRequests', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>

            <Form
                name={`timeRequest`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input />
                        </Form.Item>

                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Time In"
                            name="timeIn"
                            rules={[{ required: true, message: 'Please input time in!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Time Out"
                            name="timeOut"
                            rules={[{ required: true, message: 'Please input time out!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    ((access.check('timeRequests', 'add') && (props.actionType === ADD)) || (access.check('timeRequests', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateTimeRequestLoading || loading}   >
                                    Submit time request
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        updateTimeRequestLoading: state.admin.timeRequest.updateTimeRequestLoading,
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
        positionsData: state.admin.position.positionsData,
        projectsData: state.admin.project.projectsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTimeRequest: (id) => dispatch(loadTimeRequest(id)),
        onUpdateTimeRequest: (data) => dispatch(updateTimeRequest(data)),
        onSubmitTimeRequest: (data) => dispatch(submitTimeRequest(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
        onLoadProjects: (data) => dispatch(loadProjects(data)),
        onLoadPositions: (data) => dispatch(loadPositions(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormTimeRequest)