import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_DEPENDENTS = 'GET_DEPENDENTS'
export const GET_DEPENDENTS_FULFILLED = 'GET_DEPENDENTS_FULFILLED'
export const GET_DEPENDENTS_REJECTED = 'GET_DEPENDENTS_REJECTED'
export const GET_DEPENDENTS_DEFAULT = 'GET_DEPENDENTS_DEFAULT'

export const GET_DEPENDENT = 'GET_DEPENDENT'
export const GET_DEPENDENT_FULFILLED = 'GET_DEPENDENT_FULFILLED'
export const GET_DEPENDENT_REJECTED = 'GET_DEPENDENT_REJECTED'
export const GET_DEPENDENT_DEFAULT = 'GET_DEPENDENT_DEFAULT'

export const POST_DEPENDENT = 'POST_DEPENDENT'
export const POST_DEPENDENT_FULFILLED = 'POST_DEPENDENT_FULFILLED'
export const POST_DEPENDENT_REJECTED = 'POST_DEPENDENT_REJECTED'
export const POST_DEPENDENT_DEFAULT = 'POST_DEPENDENT_DEFAULT'

export const VERIFY = 'VERIFY'
export const VERIFY_FULFILLED = 'VERIFY_FULFILLED'
export const VERIFY_REJECTED = 'VERIFY_REJECTED'
export const VERIFY_DEFAULT = 'VERIFY_DEFAULT'

export const PUT_DEPENDENT = 'PUT_DEPENDENT'
export const PUT_DEPENDENT_FULFILLED = 'PUT_DEPENDENT_FULFILLED'
export const PUT_DEPENDENT_REJECTED = 'PUT_DEPENDENT_REJECTED'
export const PUT_DEPENDENT_DEFAULT = 'PUT_DEPENDENT_DEFAULT'

export const DELETE_DEPENDENT = 'DELETE_DEPENDENT'
export const DELETE_DEPENDENT_FULFILLED = 'DELETE_DEPENDENT_FULFILLED'
export const DELETE_DEPENDENT_REJECTED = 'DELETE_DEPENDENT_REJECTED'
export const DELETE_DEPENDENT_DEFAULT = 'DELETE_DEPENDENT_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadDependents = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_DEPENDENTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/dependents?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_DEPENDENTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_DEPENDENTS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_DEPENDENTS_DEFAULT }))
  }
}

export const loadDependent = (id) => {
  return dispatch => {
    dispatch({
      type: GET_DEPENDENT,
      payload: {}
    })
    return Axios.get(`${API_URL}/dependent/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_DEPENDENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_DEPENDENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_DEPENDENT_DEFAULT }))
  }
}

export const submitDependent = (data) => {
  return dispatch => {
    dispatch({
      type: POST_DEPENDENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/dependent`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_DEPENDENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_DEPENDENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_DEPENDENT_DEFAULT }))
  }
}

export const updateDependent = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_DEPENDENT,
      payload: {}
    })
    return Axios.put(`${API_URL}/dependent/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_DEPENDENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_DEPENDENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_DEPENDENT_DEFAULT }))
  }
}

export const deleteDependent = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_DEPENDENT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/dependent/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_DEPENDENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_DEPENDENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_DEPENDENT_DEFAULT }))
  }
}


export const verifyDependent = (data) => {
  return dispatch => {
    dispatch({
      type: VERIFY,
      payload: {}
    })
    return Axios.post(`${API_URL}/dependent-verify`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: VERIFY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: VERIFY_REJECTED,
          payload: error
        })
      })
  }
}













