import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './index.scss'
import 'antd/dist/antd.css'
// import { NotFound } from './notFound'
import Login from './login/component'
import Dashboard from './dashboard/components'
import Axios from 'axios';
import { Modal } from 'antd'

const Client = (props) => {
  const [isAuthenticated, setIsLoggedIn] = useState(false)
  // const [isAuthenticated, setIsLoggedIn] = useState(undefined)

  const hasLocalStorage = () => {
    return localStorage.getItem('CLIENT_SESSION_TOKEN') !== null
  }

  const logoutError = () => {
    localStorage.removeItem('CLIENT_SESSION_TOKEN');
    window.location.replace('/');
    window.location.reload();
  };


  useEffect(() => {
    hasLocalStorage() && setIsLoggedIn(true)

    Axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (hasLocalStorage()) {
          // return only
          // show backend manual error.
          if ((error && error.response && error.response.status) === 403) {
            Modal.warning({
              title: 'Message.',
              content: `${error && error.response && error.response.data && error.response.data.message}`,
              onOk: () => { },
            });
          } else {
            // logout user if the error cant handle the message
            Modal.error({
              title: 'Oops! Something went wrong.',
              content: 'You will be redirected to login page.',
              onOk: () => logoutError(),
            });
          }
        }
        return Promise.reject(error);
      }
    );
    window.document.title = 'HRIS User Portal'
  }, [])

  useEffect(() => {
    if (props.loggedIn) {
      setIsLoggedIn(true)
      window.location.reload()
    }
  }, [props.loggedIn])

  switch (isAuthenticated) {
    case false:
      return <Login {...props} />
    case true:
      return <Dashboard  {...props} />
    default:
      return <div>Page not ready.</div>
      break
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.client.login.loggedIn,
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Client))
