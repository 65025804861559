
import React, { useEffect, useState } from 'react'
import { Alert, Button } from 'antd'
import { getUser } from '../../../../utilities/token'

const VERIFY_FULFILLED = 'VERIFY_FULFILLED'
const VerifierCheck = (props) => {
    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        async function checkVerified() {
            const userId = await getUser().id
            const isVerified = props.verifier.findIndex(data => data._id === userId) !== -1
            setIsVerified(isVerified)
        }
        if (props.verifier && props.verifier.length) {
            checkVerified()
        }
    }, [props.verifier])

    useEffect(() => {
        if (props.reset) {
            setIsVerified(false)
        }
    }, [props.reset])

    async function handleSubmit() {
        const userId = await getUser().id
        const submitData = {
            verifierId: userId,
            docId: props.id
        }
        props.onSubmit({ ...submitData }).then(res => {
            if (res.type === VERIFY_FULFILLED) {
                setIsVerified(true)
            }
        })
    }

    return (
        <Alert
            message={isVerified ? "VERIFIED" : "UNVERIFIED"}
            description={
                isVerified ?
                    <div>
                        <span>{props.successMessage || 'You have verified these informations.'}</span>
                    </div> :
                    <div>
                        <span>{props.warningMessage || `You haven't verified these Informations yet. Note: If the informations has been changed or edited, You will need to verify these data again.`}</span>
                        <Button className='btn-verify' onClick={handleSubmit}>VERIFIED</Button>
                    </div>
            }
            type={isVerified ? "success" : "info"}
            showIcon
            closable
        />
    )
}

export default VerifierCheck