import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Dropdown, Menu, Tooltip } from 'antd'
import { loadDisciplinaryAction, updateDisciplinaryAction, submitDisciplinaryAction, verifyDisciplinaryAction } from '../actions'
import { loadEmployees } from '../../employee/actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import VerifierCheck from '../../shared/components/verifierCheck'
import './index.scss'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons';
import FormType from './modals/type'
import access from '../../../../utilities/access'

const { Option } = Select
const FormDisciplinaryAction = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const [modalTypeShow, setModalTypeShow] = useState(false)
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: props.selectedRow && moment(props.selectedRow.date),
            dateFrom: props.selectedRow && moment(props.selectedRow.dateFrom),
            dateTo: props.selectedRow && moment(props.selectedRow.dateTo),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            props.onSubmitDisciplinaryAction({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const disciplinaryActionId = props.selectedRow._id
            props.onUpdateDisciplinaryAction({ id: disciplinaryActionId, ...submitData }).then(res => {
                setLoading(false)
                setResetVerifier(true)
            })
        }
    }
    useEffect(() => {
        props.onLoadEmployees({
            page: 1,
            limit: 50,
            id: userId,
        })
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    const isAccessDisabled = (!access.check('disciplinaryActions', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {
                ((props.actionType === VIEW) && access.check('disciplinaryActions', 'verify')) &&
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                        <VerifierCheck id={props.selectedRow && props.selectedRow._id} verifier={props.selectedRow && props.selectedRow.verifier} onSubmit={props.onVerifyDisciplinaryAction} reset={resetVerifier} />
                    </Col>
                </Row>
            }
            <Form
                name={`disciplinaryAction`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Date Serve"
                            name="date"
                            rules={[{ required: true, message: 'Please input date serve!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                        <Row gutter={8} justify='center'>
                            <Col md={22} lg={22} sm={24} xs={24}>
                                <Form.Item
                                    label="Type"
                                    name="typeId"
                                    rules={[{ required: true, message: 'Please input type!' }]}
                                >
                                    <Select disabled={isAccessDisabled}>
                                        {
                                            props.disciplinaryActionTypesData.data.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item._id}>{item.title}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={2} lg={2} sm={24} xs={24}  >
                                <div className='type-options'>
                                    <Button disabled={isAccessDisabled} className='btn-circle-type' onClick={() => setModalTypeShow(true)} shape="circle" icon={<PlusOutlined />} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Date From"
                            name="dateFrom"
                            rules={[{ required: true, message: 'Please input date from!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Date To"
                            name="dateTo"
                            rules={[{ required: true, message: 'Please input dateTo!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        {
                            (props.actionType === VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('disciplinaryActions', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('disciplinaryActions', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('disciplinaryActions', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row >
                {
                    ((access.check('disciplinaryActions', 'add') && (props.actionType === ADD)) || (access.check('disciplinaryActions', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateDisciplinaryActionLoading || loading}   >
                                    Submit disciplinary action
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form >
            <FormType visible={modalTypeShow} setModalTypeShow={setModalTypeShow} />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        updateDisciplinaryActionLoading: state.admin.disciplinaryAction.updateDisciplinaryActionLoading,
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
        disciplinaryActionTypesData: state.admin.disciplinaryAction.disciplinaryActionTypesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadDisciplinaryAction: (id) => dispatch(loadDisciplinaryAction(id)),
        onVerifyDisciplinaryAction: (id) => dispatch(verifyDisciplinaryAction(id)),
        onUpdateDisciplinaryAction: (data) => dispatch(updateDisciplinaryAction(data)),
        onSubmitDisciplinaryAction: (data) => dispatch(submitDisciplinaryAction(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDisciplinaryAction)