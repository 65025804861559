import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_OVERTIMES = 'GET_OVERTIMES'
export const GET_OVERTIMES_FULFILLED = 'GET_OVERTIMES_FULFILLED'
export const GET_OVERTIMES_REJECTED = 'GET_OVERTIMES_REJECTED'
export const GET_OVERTIMES_DEFAULT = 'GET_OVERTIMES_DEFAULT'

export const GET_OVERTIME = 'GET_OVERTIME'
export const GET_OVERTIME_FULFILLED = 'GET_OVERTIME_FULFILLED'
export const GET_OVERTIME_REJECTED = 'GET_OVERTIME_REJECTED'
export const GET_OVERTIME_DEFAULT = 'GET_OVERTIME_DEFAULT'

export const POST_OVERTIME = 'POST_OVERTIME'
export const POST_OVERTIME_FULFILLED = 'POST_OVERTIME_FULFILLED'
export const POST_OVERTIME_REJECTED = 'POST_OVERTIME_REJECTED'
export const POST_OVERTIME_DEFAULT = 'POST_OVERTIME_DEFAULT'

export const VERIFY = 'VERIFY'
export const VERIFY_FULFILLED = 'VERIFY_FULFILLED'
export const VERIFY_REJECTED = 'VERIFY_REJECTED'
export const VERIFY_DEFAULT = 'VERIFY_DEFAULT'

export const PUT_OVERTIME = 'PUT_OVERTIME'
export const PUT_OVERTIME_FULFILLED = 'PUT_OVERTIME_FULFILLED'
export const PUT_OVERTIME_REJECTED = 'PUT_OVERTIME_REJECTED'
export const PUT_OVERTIME_DEFAULT = 'PUT_OVERTIME_DEFAULT'

export const DELETE_OVERTIME = 'DELETE_OVERTIME'
export const DELETE_OVERTIME_FULFILLED = 'DELETE_OVERTIME_FULFILLED'
export const DELETE_OVERTIME_REJECTED = 'DELETE_OVERTIME_REJECTED'
export const DELETE_OVERTIME_DEFAULT = 'DELETE_OVERTIME_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadOvertimes = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_OVERTIMES,
      payload: {}
    })
    return Axios.get(`${API_URL}/overtimes?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_OVERTIMES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_OVERTIMES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_OVERTIMES_DEFAULT }))
  }
}

export const loadOvertime = (id) => {
  return dispatch => {
    dispatch({
      type: GET_OVERTIME,
      payload: {}
    })
    return Axios.get(`${API_URL}/overtime/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_OVERTIME_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_OVERTIME_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_OVERTIME_DEFAULT }))
  }
}

export const submitOvertime = (data) => {
  return dispatch => {
    dispatch({
      type: POST_OVERTIME,
      payload: {}
    })
    return Axios.post(`${API_URL}/overtime`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_OVERTIME_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_OVERTIME_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_OVERTIME_DEFAULT }))
  }
}

export const updateOvertime = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_OVERTIME,
      payload: {}
    })
    return Axios.put(`${API_URL}/overtime/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_OVERTIME_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_OVERTIME_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_OVERTIME_DEFAULT }))
  }
}

export const deleteOvertime = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_OVERTIME,
      payload: {}
    })
    return Axios.delete(`${API_URL}/overtime/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_OVERTIME_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_OVERTIME_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_OVERTIME_DEFAULT }))
  }
}


export const verifyOvertime = (data) => {
  return dispatch => {
    dispatch({
      type: VERIFY,
      payload: {}
    })
    return Axios.post(`${API_URL}/overtime-verify`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: VERIFY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: VERIFY_REJECTED,
          payload: error
        })
      })
  }
}













