import {
    GET_LEAVES,
    GET_LEAVES_FULFILLED,
    GET_LEAVES_REJECTED,
    PUT_LEAVE,
    PUT_LEAVE_FULFILLED,
    PUT_LEAVE_REJECTED,
    POST_LEAVE,
    POST_LEAVE_FULFILLED,
    POST_LEAVE_REJECTED,
    DELETE_LEAVE,
    DELETE_LEAVE_FULFILLED,
    DELETE_LEAVE_REJECTED,
    DELETE_LEAVE_DEFAULT,
    PUT_LEAVE_DEFAULT,
    POST_LEAVE_DEFAULT,
    GET_LEAVE_TYPES,
    GET_LEAVE_TYPES_FULFILLED,
    GET_LEAVE_TYPES_REJECTED,
    GET_LEAVE_TYPES_DEFAULT,
} from '../actions'

export default function leave(state = {
    leavesLoading: false,
    leavesSuccess: false,
    leavesRejected: false,
    leavesData: {
        data: []
    },
    verifiedSuccess: false,
    updateLeaveLoading: false,
    updateLeaveSuccess: false,
    updateLeaveRejected: false,
    addLeaveLoading: false,
    addLeaveSuccess: false,
    addLeaveRejected: false,
    deleteLeaveLoading: false,
    deleteLeaveSuccess: false,
    deleteLeaveRejected: false
}, action) {
    switch (action.type) {
        case GET_LEAVES:
            return {
                ...state,
                leavesLoading: true,
                leavesSuccess: false,
                leavesRejected: false
            }
        case GET_LEAVES_FULFILLED:
            return {
                ...state,
                leavesLoading: false,
                leavesSuccess: true,
                leavesData: action.payload
            }
        case GET_LEAVES_REJECTED:
            return {
                ...state,
                leavesLoading: false,
                leavesRejected: true
            }
        case POST_LEAVE:
            return {
                ...state,
                addLeaveLoading: true,
                addLeaveSuccess: false,
                addLeaveRejected: false
            }
        case POST_LEAVE_FULFILLED:
            return {
                ...state,
                addLeaveLoading: false,
                addLeaveSuccess: true,
                addLeaveData: action.payload
            }
        case POST_LEAVE_REJECTED:
            return {
                ...state,
                addLeaveLoading: false,
                addLeaveRejected: true
            }
        case POST_LEAVE_DEFAULT:
            return {
                ...state,
                addLeaveLoading: false,
                addLeaveSuccess: false,
                addLeaveRejected: false
            }
        case PUT_LEAVE:
            return {
                ...state,
                updateLeaveLoading: true,
                updateLeaveSuccess: false,
                updateLeaveRejected: false
            }
        case PUT_LEAVE_FULFILLED:
            return {
                ...state,
                updateLeaveLoading: false,
                updateLeaveSuccess: true,
                updateLeaveData: action.payload
            }

        case PUT_LEAVE_REJECTED:
            return {
                ...state,
                updateLeaveLoading: false,
                updateLeaveRejected: true
            }
        case PUT_LEAVE_DEFAULT:
            return {
                ...state,
                updateLeaveLoading: false,
                updateLeaveSuccess: false,
                updateLeaveRejected: false
            }

        case DELETE_LEAVE:
            return {
                ...state,
                deleteLeaveLoading: true,
                deleteLeaveSuccess: false,
                deleteLeaveRejected: false
            }
        case DELETE_LEAVE_FULFILLED:
            return {
                ...state,
                deleteLeaveLoading: false,
                deleteLeaveSuccess: true,
            }
        case DELETE_LEAVE_REJECTED:
            return {
                ...state,
                deleteLeaveLoading: false,
                deleteLeaveRejected: true
            }
        case DELETE_LEAVE_DEFAULT:
            return {
                ...state,
                deleteLeaveLoading: false,
                deleteLeaveSuccess: false,
                deleteLeaveRejected: false
            }
        case GET_LEAVE_TYPES:
            return {
                ...state,
                leaveTypesLoading: true,
                leaveTypesSuccess: false,
                leaveTypesRejected: false
            }
        case GET_LEAVE_TYPES_FULFILLED:
            return {
                ...state,
                leaveTypesLoading: false,
                leaveTypesSuccess: true,
                leaveTypesData: action.payload
            }
        case GET_LEAVE_TYPES_REJECTED:
            return {
                ...state,
                leaveTypesLoading: false,
                leaveTypesRejected: true
            }
        case GET_LEAVE_TYPES_DEFAULT:
            return {
                ...state,
                leaveTypesLoading: false,
                leaveTypesSuccess: false,
                leaveTypesRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
