
import { headers } from './token'
const API_URL = process.env.REACT_APP_API_BASE_URL

export function geoCode(address) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/geolocation/geocode?address=${address}`, {
            headers: headers
        }).then((response) => response.json())
            .then((responseData) => {
                return resolve(responseData.results[0])
            })
    }).catch((res) => console.log("err", res))
}

export function geoCodeReverse(latitude, longitude) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/geolocation/geocode-reverse?latitude=${latitude}&longitude=${longitude}`, {
            headers: headers
        }).then((response) => response.json())
            .then((responseData) => {
                return resolve(responseData.results[0])
            })
    }).catch((res) => console.log("err", res))
}



