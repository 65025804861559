import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_JOBS = 'GET_JOBS'
export const GET_JOBS_FULFILLED = 'GET_JOBS_FULFILLED'
export const GET_JOBS_REJECTED = 'GET_JOBS_REJECTED'
export const GET_JOBS_DEFAULT = 'GET_JOBS_DEFAULT'

export const GET_JOB = 'GET_JOB'
export const GET_JOB_FULFILLED = 'GET_JOB_FULFILLED'
export const GET_JOB_REJECTED = 'GET_JOB_REJECTED'
export const GET_JOB_DEFAULT = 'GET_JOB_DEFAULT'

export const POST_JOB = 'POST_JOB'
export const POST_JOB_FULFILLED = 'POST_JOB_FULFILLED'
export const POST_JOB_REJECTED = 'POST_JOB_REJECTED'
export const POST_JOB_DEFAULT = 'POST_JOB_DEFAULT'

export const PUT_JOB = 'PUT_JOB'
export const PUT_JOB_FULFILLED = 'PUT_JOB_FULFILLED'
export const PUT_JOB_REJECTED = 'PUT_JOB_REJECTED'
export const PUT_JOB_DEFAULT = 'PUT_JOB_DEFAULT'

export const DELETE_JOB = 'DELETE_JOB'
export const DELETE_JOB_FULFILLED = 'DELETE_JOB_FULFILLED'
export const DELETE_JOB_REJECTED = 'DELETE_JOB_REJECTED'
export const DELETE_JOB_DEFAULT = 'DELETE_JOB_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadJobs = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_JOBS,
      payload: {}
    })
    return Axios.get(`${API_URL}/jobs?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_JOBS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_JOBS_REJECTED,
          // payload: error
        })
      }).finally(() => dispatch({ type: GET_JOBS_DEFAULT }))
  }
}

export const loadJob = (id) => {
  return dispatch => {
    dispatch({
      type: GET_JOB,
      payload: {}
    })
    return Axios.get(`${API_URL}/job/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_JOB_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_JOB_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_JOB_DEFAULT }))
  }
}

export const submitJob = (data) => {
  return dispatch => {
    dispatch({
      type: POST_JOB,
      payload: {}
    })
    return Axios.post(`${API_URL}/job`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_JOB_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_JOB_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_JOB_DEFAULT }))
  }
}

export const updateJob = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_JOB,
      payload: {}
    })
    return Axios.put(`${API_URL}/job/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_JOB_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_JOB_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_JOB_DEFAULT }))
  }
}

export const deleteJob = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_JOB,
      payload: {}
    })
    return Axios.delete(`${API_URL}/job/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_JOB_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_JOB_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_JOB_DEFAULT }))
  }
}











