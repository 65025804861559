import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_POSITIONS = 'GET_POSITIONS'
export const GET_POSITIONS_FULFILLED = 'GET_POSITIONS_FULFILLED'
export const GET_POSITIONS_REJECTED = 'GET_POSITIONS_REJECTED'
export const GET_POSITIONS_DEFAULT = 'GET_POSITIONS_DEFAULT'

export const GET_POSITION = 'GET_POSITION'
export const GET_POSITION_FULFILLED = 'GET_POSITION_FULFILLED'
export const GET_POSITION_REJECTED = 'GET_POSITION_REJECTED'
export const GET_POSITION_DEFAULT = 'GET_POSITION_DEFAULT'

export const POST_POSITION = 'POST_POSITION'
export const POST_POSITION_FULFILLED = 'POST_POSITION_FULFILLED'
export const POST_POSITION_REJECTED = 'POST_POSITION_REJECTED'
export const POST_POSITION_DEFAULT = 'POST_POSITION_DEFAULT'

export const PUT_POSITION = 'PUT_POSITION'
export const PUT_POSITION_FULFILLED = 'PUT_POSITION_FULFILLED'
export const PUT_POSITION_REJECTED = 'PUT_POSITION_REJECTED'
export const PUT_POSITION_DEFAULT = 'PUT_POSITION_DEFAULT'

export const DELETE_POSITION = 'DELETE_POSITION'
export const DELETE_POSITION_FULFILLED = 'DELETE_POSITION_FULFILLED'
export const DELETE_POSITION_REJECTED = 'DELETE_POSITION_REJECTED'
export const DELETE_POSITION_DEFAULT = 'DELETE_POSITION_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadPositions = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_POSITIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/positions?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_POSITIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_POSITIONS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_POSITIONS_DEFAULT }))
  }
}

export const loadPosition = (id) => {
  return dispatch => {
    dispatch({
      type: GET_POSITION,
      payload: {}
    })
    return Axios.get(`${API_URL}/position/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_POSITION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_POSITION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_POSITION_DEFAULT }))
  }
}

export const submitPosition = (data) => {
  return dispatch => {
    dispatch({
      type: POST_POSITION,
      payload: {}
    })
    return Axios.post(`${API_URL}/position`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_POSITION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_POSITION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_POSITION_DEFAULT }))
  }
}

export const updatePosition = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_POSITION,
      payload: {}
    })
    return Axios.put(`${API_URL}/position/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_POSITION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_POSITION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_POSITION_DEFAULT }))
  }
}

export const deletePosition = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_POSITION,
      payload: {}
    })
    return Axios.delete(`${API_URL}/position/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_POSITION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_POSITION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_POSITION_DEFAULT }))
  }
}











