import React, { useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Avatar, Tooltip, Row, Col, notification, List } from 'antd'
import { connect } from 'react-redux'
import { loadMedicalHistories, deleteMedicalHistory } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'
import enumMedicalHistoryType from '../../../../utilities/enumMedicalHistoryType'
import RemarksRenderer from '../../../../utilities/remarksRenderer'

const MedicalHistoriesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updateMedicalHistorySuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the medical histories information.',
            });
        }
        if (props.addMedicalHistorySuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new medical histories information.',
            });
        }
        if (props.deleteMedicalHistorySuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted medical histories data.',
            });
        }

        props.onLoadMedicalHistories(pagination)
    }, [props.updateMedicalHistorySuccess, props.addMedicalHistorySuccess, props.deleteMedicalHistorySuccess])

    function handleDelete(id) {
        props.onDeleteMedicalHistory(id)
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    return (
        <Layout className='medicalHistories-page'>
            <Card>
                <button className='add-item' onClick={() => {
                    setModalShow(true)
                    setActionType(ADD)
                    setSelectedRow({})
                }}>
                    <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer', fontSize: 20 }} icon={faPlusCircle} />
                    <span>Add Medical History</span>
                </button>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Name
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Description
                            </Col>
                            <Col md={5} lg={5} xs={24} sm={24}>
                                Verifier
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={props.medicalHistoriesData.data}
                    renderItem={data => <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }}>
                                <Col md={24} lg={24} xs={24} sm={24}>
                                    <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                </Col>
                            </Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item' >
                                    <h1>{getKeyByValue(enumMedicalHistoryType, data.type)} <StatusIconDisplay status={data.status} /></h1>
                                    <p className='date'>Date: {moment(data.date).format('LL')}</p>
                                </div>
                                <Row>
                                    <div className='documents'>
                                        {data.images && data.images.length !== 0 && <h1>Images / Documents</h1>}
                                        <Col md={24} lg={24} xs={24} sm={24}>
                                            <div className='d-flex'>
                                                {
                                                    data.images && data.images.map((item, index) => {
                                                        return (
                                                            <div className='p2'>
                                                                <ImagePresignedUrl key={index} src={item.url} style={{ width: 80, height: 100 }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                            </Col>

                            <Col md={9} lg={9} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p >{data.description}</p>
                                </div>
                            </Col>

                            <Col md={5} lg={5} xs={24} sm={24}>
                                <div className='verifier'>
                                    <Avatar.Group maxCount={2} >
                                        {
                                            data.verifier.map((verify, index) => {
                                                return (
                                                    <Tooltip title={`${verify.firstName} ${verify.lastName}`} placement="top" key={index}>
                                                        {
                                                            Array.isArray(verify.image) &&
                                                            <Avatar icon={<ImagePresignedUrl objectFit='cover' src={verify.image && verify.image[0].url} style={{ width: 32, height: '100%' }} key={index} preview={false} />} />
                                                        }
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </Avatar.Group>
                                </div>

                            </Col>

                            <Col md={4} lg={4} xs={24} sm={24}>
                                <div className='actions'>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            setModalShow(true)
                                            setActionType(VIEW)
                                            setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>

                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        medicalHistoriesData: state.client.medicalHistory.medicalHistoriesData,
        addMedicalHistorySuccess: state.client.medicalHistory.addMedicalHistorySuccess,
        updateMedicalHistorySuccess: state.client.medicalHistory.updateMedicalHistorySuccess,
        deleteMedicalHistorySuccess: state.client.medicalHistory.deleteMedicalHistorySuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadMedicalHistories: data => dispatch(loadMedicalHistories(data)),
        onDeleteMedicalHistory: id => dispatch(deleteMedicalHistory(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistoriesComponent)
