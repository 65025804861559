import {
  GET_JOBS,
  GET_JOBS_FULFILLED,
  GET_JOBS_REJECTED,
  PUT_JOB,
  PUT_JOB_FULFILLED,
  PUT_JOB_REJECTED,
  POST_JOB,
  POST_JOB_FULFILLED,
  POST_JOB_REJECTED,
  DELETE_JOB,
  DELETE_JOB_FULFILLED,
  DELETE_JOB_REJECTED,
  GET_JOBS_DEFAULT,
  POST_JOB_DEFAULT,
  PUT_JOB_DEFAULT,
  DELETE_JOB_DEFAULT
} from '../actions'

export default function job(state = {
  jobsLoading: false,
  jobsSuccess: false,
  jobsRejected: false,
  jobsData: {
    data: []
  },
  updateJobLoading: false,
  updateJobSuccess: false,
  updateJobRejected: false,
  addJobLoading: false,
  addJobSuccess: false,
  addJobRejected: false,
  deleteJobLoading: false,
  deleteJobSuccess: false,
  deleteJobRejected: false
}, action) {
  switch (action.type) {
    case GET_JOBS:
      return {
        ...state,
        jobsLoading: true,
        jobsSuccess: false,
        jobsRejected: false
      }
    case GET_JOBS_FULFILLED:
      return {
        ...state,
        jobsLoading: false,
        jobsSuccess: true,
        jobsData: action.payload
      }
    case GET_JOBS_REJECTED:
      return {
        ...state,
        jobsLoading: false,
        jobsRejected: true
      }
    case GET_JOBS_DEFAULT:
      return {
        ...state,
        jobsLoading: false,
        jobsSuccess: false,
        jobsRejected: false
      }
    case POST_JOB:
      return {
        ...state,
        addJobLoading: true,
        addJobSuccess: false,
        addJobRejected: false
      }
    case POST_JOB_FULFILLED:
      return {
        ...state,
        addJobLoading: false,
        addJobSuccess: true,
        addJobData: action.payload
      }
    case POST_JOB_REJECTED:
      return {
        ...state,
        addJobLoading: false,
        addJobRejected: true
      }
    case POST_JOB_DEFAULT:
      return {
        ...state,
        addJobLoading: false,
        addJobSuccess: false,
        addJobRejected: false
      }
    case PUT_JOB:
      return {
        ...state,
        updateJobLoading: true,
        updateJobSuccess: false,
        updateJobRejected: false
      }
    case PUT_JOB_FULFILLED:
      return {
        ...state,
        updateJobLoading: false,
        updateJobSuccess: true,
        updateJobData: action.payload
      }
    case PUT_JOB_REJECTED:
      return {
        ...state,
        updateJobLoading: false,
        updateJobRejected: true
      }
    case PUT_JOB_DEFAULT:
      return {
        ...state,
        updateJobLoading: false,
        updateJobSuccess: false,
        updateJobRejected: false
      }
    case DELETE_JOB:
      return {
        ...state,
        deleteJobLoading: true,
        deleteJobSuccess: false,
        deleteJobRejected: false
      }
    case DELETE_JOB_FULFILLED:
      return {
        ...state,
        deleteJobLoading: false,
        deleteJobSuccess: true,
      }
    case DELETE_JOB_REJECTED:
      return {
        ...state,
        deleteJobLoading: false,
        deleteJobRejected: true
      }
    case DELETE_JOB_DEFAULT:
      return {
        ...state,
        deleteJobLoading: false,
        deleteJobSuccess: false,
        deleteJobRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
