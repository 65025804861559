import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Menu, Dropdown, Popconfirm, Avatar, Tooltip } from 'antd'
import { loadFileReports, deleteFileReport } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import { serviceStatusTag } from '../../shared/components/serviceStatusTag'
import Toolbar from '../../shared/components/toolbar'
import access from '../../../../utilities/access'
import moment from 'moment'
import Papa from 'papaparse'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadFileReports } = props
        onLoadFileReports(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        const { onLoadFileReports } = props
        onLoadFileReports(pagination)
        // eslint-disable-next-line
    }, [props.updateFileReportSuccess, props.deleteFileReportSuccess, props.addFileReportSuccess, props.verifiedSuccess])

    useEffect(() => {
        setTableData([...props.fileReportsData.data])
    }, [props.fileReportsData])


    function handleDelete(id) {
        props.onDeleteFileReport(id)
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.user && data.user.firstName} ${data.user && data.user.lastName}`,
                "Title": data.title,
                "Description": data.description,
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `file_reports_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `file_reports_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item) => {
                return (<span>{serviceStatusTag(item)}</span>)
            }
        },
        {
            title: 'Verifier',
            dataIndex: 'verifier',
            key: 'verifier',
            render: (item, data) => {

                return <div>
                    <Avatar.Group>
                        {
                            item.map((data, index) => {
                                return (
                                    <Tooltip title={`${data.firstName} ${data.lastName}`} placement="top" key={index}>
                                        <Avatar icon={<ImagePresignedUrl src={data && data.image[0].url} style={{ width: 32, height: '100%' }} objectFit='cover' preview={false} />} />
                                    </Tooltip>
                                )
                            })
                        }

                    </Avatar.Group>
                </div>
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                            }}>
                                View
                            </Menu.Item>
                            {
                                access.check('fillingReports', 'delete') &&
                                <Menu.Item key="1" >
                                    <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                        Delete
                                    </Popconfirm>

                                </Menu.Item>
                            }
                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout className='page' >
            {
                access.check('fillingReports', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='File Reports'
                                handleAdd={() => props.setActionType(ADD)}
                                removeAdd={!access.check('fillingReports', 'add')}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleDownload={() => handleDownload()}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.fileReportsData.fileReportsLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.fileReportsData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        fileReportsData: state.admin.fileReport.fileReportsData,
        fileReportsLoading: state.admin.fileReport.fileReportsLoading,
        updateFileReportSuccess: state.admin.fileReport.updateFileReportSuccess,
        addFileReportSuccess: state.admin.fileReport.addFileReportSuccess,
        deleteFileReportSuccess: state.admin.fileReport.deleteFileReportSuccess,
        verifiedSuccess: state.admin.fileReport.verifiedSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadFileReports: data => dispatch(loadFileReports(data)),
        onDeleteFileReport: id => dispatch(deleteFileReport(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)