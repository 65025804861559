import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Avatar, Tooltip, Row, Col, notification, List } from 'antd'
import { connect } from 'react-redux'
import { loadTimeRequests, deleteTimeRequest } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'
import RemarksRenderer from '../../../../utilities/remarksRenderer'

const TimeRequestsComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updateTimeRequestSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the time requests information.',
            });
        }
        if (props.addTimeRequestSuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new time requests information.',
            });
        }
        if (props.deleteTimeRequestSuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted time requests data.',
            });
        }

        props.onLoadTimeRequests(pagination)
    }, [props.updateTimeRequestSuccess, props.addTimeRequestSuccess, props.deleteTimeRequestSuccess])

    function handleDelete(id) {
        props.onDeleteTimeRequest(id)
    }

    function getAge(item) {
        var a = moment();
        var b = moment(item, 'YYYY');
        var age = a.diff(b, 'years');
        return age
    }

    return (
        <Layout className='timeRequests-page'>
            <Card>
                <button className='add-item' onClick={() => {
                    setModalShow(true)
                    setActionType(ADD)
                    setSelectedRow({})
                }}>
                    <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer', fontSize: 20 }} icon={faPlusCircle} />
                    <span>Add Time Request</span>
                </button>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Time Requests Information
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Description
                            </Col>
                            <Col md={5} lg={5} xs={24} sm={24}>
                                Verifier
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={props.timeRequestsData.data}
                    renderItem={data => <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }}>
                                <Col md={24} lg={24} xs={24} sm={24}>
                                    <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                </Col>
                            </Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item' >
                                    <h1>{data.title} <StatusIconDisplay status={data.status} /></h1>
                                    <p className='time'><b>Time In:</b> {moment(data.timeIn).format('LLLL')}</p>
                                    <p className='time'><b>Time Out:</b> {moment(data.timeOut).format('LLLL')}</p>

                                </div>
                            </Col>

                            <Col md={9} lg={9} xs={24} sm={24}>
                                <div className='row-item' >
                                    <h1 className='description'>{data.description}</h1>
                                </div>
                            </Col>

                            <Col md={5} lg={5} xs={24} sm={24}>
                                <div className='verifier'>
                                    <Avatar.Group maxCount={2} >
                                        {
                                            data.verifier.map((verify, index) => {
                                                return (
                                                    <Tooltip title={`${verify.firstName} ${verify.lastName}`} placement="top" key={index}>
                                                        {
                                                            Array.isArray(verify.image) &&
                                                            <Avatar icon={<ImagePresignedUrl objectFit='cover' src={verify.image && verify.image[0].url} style={{ width: 32, height: '100%' }} key={index} preview={false} />} />
                                                        }
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </Avatar.Group>
                                </div>

                            </Col>

                            <Col md={4} lg={4} xs={24} sm={24}>
                                <div className='actions'>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            setModalShow(true)
                                            setActionType(VIEW)
                                            setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>

                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        timeRequestsData: state.client.timeRequest.timeRequestsData,
        addTimeRequestSuccess: state.client.timeRequest.addTimeRequestSuccess,
        updateTimeRequestSuccess: state.client.timeRequest.updateTimeRequestSuccess,
        deleteTimeRequestSuccess: state.client.timeRequest.deleteTimeRequestSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTimeRequests: data => dispatch(loadTimeRequests(data)),
        onDeleteTimeRequest: id => dispatch(deleteTimeRequest(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeRequestsComponent)
