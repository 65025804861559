import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Avatar } from 'antd'
import { loadAudits, deleteAudit } from '../actions'
import './index.scss'
import moment from 'moment'
import Toolbar from '../../shared/components/toolbar'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import _ from 'lodash'
import Changes from './changes'
import access from '../../../../utilities/access'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadAudits } = props
        onLoadAudits(pagination)
        // eslint-disable-next-line
    }, [props.updateAuditSuccess, props.deleteAuditSuccess, props.addAuditSuccess])

    useEffect(() => {
        const { onLoadAudits } = props
        onLoadAudits(pagination)
    }, [pagination])

    useEffect(() => {
        setTableData([...props.auditsData.data])
    }, [props.auditsData])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    function actionLabel(action) {
        switch (action) {
            case 'PUT':
                return 'updated'
            case 'DELETE':
                return 'deleted'
            case 'POST':
                return 'created'

            default:
                break;
        }
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (data.user && <Avatar size={64} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (item, data) => {
                return (data.user && <span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            render: (item, data) => {
                return (<span>{data.user && data.user.firstName} {data.user && data.user.lastName} has <b>{actionLabel(data.action)}</b> the {_.startCase(data.module)} <a><u>{data.moduleId}</u></a>.</span>)
            }
        },
        // {
        //     title: 'Changes',
        //     dataIndex: 'changes',
        //     key: 'changes',
        //     render: (item) => {
        //         return (
        //             <Changes data={item} />
        //         )
        //     }
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action'
        },
        {
            title: 'Module',
            dataIndex: 'module',
            key: 'module',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{_.startCase(item)}</span>)
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LLLL'))
            }
        },
    ];
    return (
        <Layout className='page'>
            {
                access.check('auditTrails', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Audits'
                                // handleAdd={() => props.setActionType(ADD)}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'Active' },
                                    { id: 1, label: 'Inactive' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.auditsData.auditsLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.auditsData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        auditsData: state.admin.audit.auditsData,
        auditsLoading: state.admin.audit.auditsLoading,
        updateAuditSuccess: state.admin.audit.updateAuditSuccess,
        addAuditSuccess: state.admin.audit.addAuditSuccess,
        deleteAuditSuccess: state.admin.audit.deleteAuditSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAudits: data => dispatch(loadAudits(data)),
        onDeleteAudit: id => dispatch(deleteAudit(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)