import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Card, Rate, Alert } from 'antd'
import { loadPersonalMovement, updatePersonalMovement, submitPersonalMovement, verifyPersonalMovement } from '../actions'
import { loadEmployees } from '../../employee/actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import VerifierCheck from '../../shared/components/verifierCheck'
import enumPersonalMovementType from '../../../../utilities/enumPersonalMovementType'
import { ArrowRightOutlined } from '@ant-design/icons'
import './index.scss'
import moment from 'moment'
import { loadProjects } from '../../projects/actions'
import { loadPositions } from '../../positions/actions'
import { getUser } from '../../../../utilities/token'
import access from '../../../../utilities/access'

const { Option } = Select
const FormPersonalMovement = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})
    const [selectedPosition, setSelectedPosition] = useState({})
    const [type, setType] = useState((props.selectedRow && props.selectedRow.type) || enumPersonalMovementType.PROMOTION)
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            dateFrom: props.selectedRow && moment(props.selectedRow.dateFrom),
            dateTo: props.selectedRow && moment(props.selectedRow.dateTo),
            salaryFrom: props.selectedRow && props.selectedRow.ratePerMonth,
        })

        if (props.actionType === ADD) {
            form.setFieldsValue({
                type: enumPersonalMovementType.PROMOTION
            })
        }


        props.onLoadPositions({
            page: 1,
            limit: 100,
            // status: 0
        })

        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            postById: getUser().id,
            ...value,
        }

        console.log("props.actionType", props.actionType, submitData)

        if (props.actionType === ADD) {
            console.log("props.actionType add")

            delete submitData.fromPositionId
            delete submitData.fromProjectId
            props.onSubmitPersonalMovement({
                ...submitData,
                fromPositionId: selectedUser.position && selectedUser.position._id,
                fromProjectId: selectedUser.project && selectedUser.project._id,
            }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            console.log("props.actionType view")

            const personalMovementId = props.selectedRow._id
            props.onUpdatePersonalMovement({ id: personalMovementId, ...submitData }).then(res => {
                setLoading(false)
                setResetVerifier(true)
            })
        }
    }
    useEffect(() => {
        if (props.actionType === VIEW) {
            if (!(userId)) return
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                id: userId,
            })
        }
        props.onLoadProjects({
            page: 1,
            limit: 100,
        })

    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    function selectUser({ }, e) {
        setSelectedUser(e.data)
        console.log("employment status", e)
        form.setFieldsValue({
            fromPositionId: e.data && e.data.position && e.data.position.title,
            fromProjectId: e.data && e.data.project && e.data.project.title,
            fromSalary: e.data && e.data && e.data.ratePerMonth,
            fromEmploymentStatus: e.data && e.data && e.data.employmentStatus,
        })
    }

    function selectPosition({ }, e) {
        setSelectedPosition(e.data)
    }

    const isAccessDisabled = (!access.check('personalMovements', 'edit') && (props.actionType === VIEW))
    console.log("TYPE", type)
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {
                ((props.actionType === VIEW) && access.check('personalMovements', 'verify')) &&
                <Fragment>
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                            <Alert
                                message={"POSITION, PROJECT, EMPLOYMENT STATUS AND TYPE INFORMATIONS"}
                                description={<span>{'The position, project, position status and type fields are not editable. If you want to change or edit the current information in position or project fields, You must create another record for Personnel Movements. This is for record purposes. '}</span>}
                                type={'success'}
                                showIcon
                                closable
                            />
                        </Col>
                    </Row>
                </Fragment>
            }
            <Form
                name={`personalMovement`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} onChange={({ }, e) => selectUser({}, e)} optionFilterProp="children" loading={props.employeesLoading}  >
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            return (
                                                <Option key={data._id} value={data._id} data={data} >{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please input type!' }]}
                        >
                            <Select disabled={isAccessDisabled || (props.actionType === VIEW)} onChange={(e, data) => setType(e)} >
                                {
                                    Object.keys(enumPersonalMovementType).map(function (key, index) {
                                        return (
                                            <Option value={enumPersonalMovementType[key]} key={index} >{key}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Center Code"
                            name="centerCode"
                            rules={[{ required: true, message: 'Please input center code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (enumPersonalMovementType.PROMOTION === type) &&
                            <Card title={`Position Change ${(props.actionType === VIEW) ? '- (READ ONLY)' : ''}`} style={{ marginBottom: 24 }}>
                                <Row>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Current Position"
                                            name="fromPositionId"
                                            rules={[{ required: true, message: 'Please input from-position!' }]}
                                        >
                                            <Select disabled>
                                                {
                                                    props.positionsData.data.map((item, index) => {
                                                        return (
                                                            <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <strong>Job Level: {((selectedUser.position && selectedUser.position.jobLevel || (props.selectedRow && props.selectedRow.fromPosition && props.selectedRow.fromPosition.jobLevel))) || 0}</strong>
                                    </Col>
                                    <Col md={4} lg={4} sm={24} xs={24} align='center'>
                                        <ArrowRightOutlined style={{ fontSize: 30, marginTop: 6, color: 'green' }} />
                                    </Col>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="To Position"
                                            name="toPositionId"
                                            rules={[{ required: true, message: 'Please input to-position!' }]}
                                        >
                                            <Select onChange={({ }, e) => selectPosition({}, e)} disabled={(isAccessDisabled || (props.actionType === VIEW))}>
                                                {
                                                    props.positionsData.data.map((item, index) => {
                                                        return (
                                                            <Option disabled={selectedUser.position && selectedUser.position._id === item._id} value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <strong>Job Level: {((selectedPosition && selectedPosition.jobLevel || (props.selectedRow && props.selectedRow.toPosition && props.selectedRow.toPosition.jobLevel)))}</strong>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        {
                            (enumPersonalMovementType.LOCATION_TRANSFER === type) &&
                            <Card title={`Project Change ${(props.actionType === VIEW) ? '- (READ ONLY)' : ''}`} style={{ marginBottom: 24 }}>
                                <Row>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Current Project"
                                            name="fromProjectId"
                                            rules={[{ required: true, message: 'Please input from-project!' }]}
                                        >
                                            <Select disabled>
                                                {
                                                    props.projectsData.data.map((item, index) => {
                                                        return (
                                                            <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} lg={4} sm={24} xs={24} align='center'>
                                        <ArrowRightOutlined style={{ fontSize: 30, marginTop: 6, color: 'green' }} />
                                    </Col>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="To Project"
                                            name="toProjectId"
                                            rules={[{ required: true, message: 'Please input to-project!' }]}
                                        >
                                            <Select disabled={isAccessDisabled || (props.actionType === VIEW)}>
                                                {
                                                    props.projectsData.data.map((item, index) => {
                                                        return (
                                                            <Option disabled={selectedUser.project && selectedUser.project._id === item._id} value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        {
                            (enumPersonalMovementType.SALARY_ADJUSTMENT === type) &&
                            <Card title={`Salary Adjustment ${(props.actionType === VIEW) ? '- (READ ONLY)' : ''}`} style={{ marginBottom: 24 }}>
                                <Row>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Salary From"
                                            name="fromSalary"
                                            rules={[{ required: true, message: 'Please input salary from!' }]}
                                        >
                                            <Select disabled>
                                                {
                                                    props.projectsData.data.map((item, index) => {
                                                        return (
                                                            <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} lg={4} sm={24} xs={24} align='center'>
                                        <ArrowRightOutlined style={{ fontSize: 30, marginTop: 6, color: 'green' }} />
                                    </Col>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Salary To"
                                            name="toSalary"
                                            rules={[{ required: true, message: 'Please input salary to!' }]}
                                        >
                                            <Input type='number' disabled={isAccessDisabled || (props.actionType === VIEW)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        {
                            (enumPersonalMovementType.REGULARIZATION === type) &&
                            <Card title={`Employment Status ${(props.actionType === VIEW) ? '- (READ ONLY)' : ''}`} style={{ marginBottom: 24 }}>
                                <Row>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Employment Status From"
                                            name="fromEmploymentStatus"
                                            rules={[{ required: true, message: 'Please input employment status from!' }]}
                                        >
                                            <Select disabled >
                                                <Option value={1} key={1} data='Probationary'>Probationary</Option>
                                                <Option value={2} key={2} data='Regular'>Regular</Option>
                                                <Option value={3} key={3} data='Resigned'>Resigned</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} lg={4} sm={24} xs={24} align='center'>
                                        <ArrowRightOutlined style={{ fontSize: 30, marginTop: 6, color: 'green' }} />
                                    </Col>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Employment Status To"
                                            name="toEmploymentStatus"
                                            rules={[{ required: true, message: 'Please input employment status to!' }]}
                                        >
                                            <Select disabled={isAccessDisabled || (props.actionType === VIEW)}   >
                                                <Option value={1} disabled={selectedUser.employmentStatus === 1}>Probationary</Option>
                                                <Option value={2} disabled={selectedUser.employmentStatus === 2}>Regular</Option>
                                                <Option value={3} disabled={selectedUser.employmentStatus === 3}>Resigned</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        <Form.Item
                            label="Division Code"
                            name="divisionCode"
                            rules={[{ required: true, message: 'Please input division code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Property Code"
                            name="propertyCode"
                            rules={[{ required: true, message: 'Please input property code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            // label="Date From"
                            label="Effective Date"
                            name="dateFrom"
                            rules={[{ required: true, message: 'Please input date from!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Date To"
                            name="dateTo"
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        {/* {
                            (props.actionType === VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('personalMovements', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('personalMovements', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('personalMovements', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        } */}
                    </Col>
                </Row>
                {
                    ((access.check('personalMovements', 'add') && (props.actionType === ADD)) || (access.check('personalMovements', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updatePersonalMovementLoading || loading}   >
                                    Submit personal movement
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        updatePersonalMovementLoading: state.admin.personalMovement.updatePersonalMovementLoading,
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
        positionsData: state.admin.position.positionsData,
        projectsData: state.admin.project.projectsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPersonalMovement: (id) => dispatch(loadPersonalMovement(id)),
        onVerifyPersonalMovement: (id) => dispatch(verifyPersonalMovement(id)),
        onUpdatePersonalMovement: (data) => dispatch(updatePersonalMovement(data)),
        onSubmitPersonalMovement: (data) => dispatch(submitPersonalMovement(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
        onLoadProjects: (data) => dispatch(loadProjects(data)),
        onLoadPositions: (data) => dispatch(loadPositions(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormPersonalMovement)