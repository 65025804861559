import store from '../store'

const access = {
    check: (title, role) => {
        const adminRoles = store.getState().admin.administrator.adminRoles
        if (adminRoles.length === 0) return false
        const found = adminRoles.find(data => data.title === title)
        if (found !== -1) return (found && found.roles[role]) ? true : false
        else return false
    }
}

export default access
