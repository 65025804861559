import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal, notification } from 'antd'
import { updateTimeRequest, submitTimeRequest } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'
import './index.scss'
import moment from 'moment'
const FormTimeRequest = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            timeIn: props.selectedRow && moment(props.selectedRow.timeIn) || new Date(),
            timeOut: props.selectedRow && moment(props.selectedRow.timeOut) || new Date(),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)
        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            const userId = await getUser().id
            props.onSubmitTimeRequest({ userId, ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
                form.resetFields()
            })
        }

        if (props.actionType === VIEW) {
            const timeRequestId = props.selectedRow._id
            props.onUpdateTimeRequest({ id: timeRequestId, ...submitData }).then(res => {
                setLoading(false)
                form.resetFields()
            })
        }
    }

    return (
        <Modal title={props.selectedRow && props.selectedRow.company || 'Add TimeRequest'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`timeRequest`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please input title!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Time In"
                                name="timeIn"
                                rules={[{ required: true, message: 'Please input time in!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Time Out"
                                name="timeOut"
                                rules={[{ required: true, message: 'Please input time out!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateTimeRequestLoading || loading}   >
                                    Submit Time Request
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateTimeRequestLoading: state.client.timeRequest.updateTimeRequestLoading,
        employeesData: state.client.employee.employeesData,
        employeesLoading: state.client.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateTimeRequest: (data) => dispatch(updateTimeRequest(data)),
        onSubmitTimeRequest: (data) => dispatch(submitTimeRequest(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormTimeRequest)