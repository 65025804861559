import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Radio, Input, Select, Switch, message, Tabs, Divider, Layout } from 'antd'
import { connect } from 'react-redux'
import { updatePosition } from '../../actions'
import _ from 'lodash';
import '../index.scss'
import { defaultRoles } from '../.././../../../utilities/roles'

const { TabPane } = Tabs;
const Roles = (props) => {
  const [roles, setRoles] = useState([])

  function mergeDefaultAndExistingRoles() {
    let newDefaultRoles = []
    defaultRoles.map((defRoles, index) => {
      const found = props.selectedRow && props.selectedRow.roles.find(role => (role.title) === defRoles.title)
      if (found) newDefaultRoles.push(found)
      else newDefaultRoles.push(defRoles)
    })
    return newDefaultRoles
  }

  useEffect(() => {
    const roles = props.selectedRow && props.selectedRow.roles.length === 0 ? defaultRoles : mergeDefaultAndExistingRoles()
    setRoles(roles)
  }, [props.selectedRow])

  function handleChangeRoles(value, title, roleKey) {
    const index = roles.findIndex(data => data.title === title)
    roles[index].roles[roleKey] = value
    setRoles(roles)
  }

  function handlePageEnable(value, title) {
    const index = roles.findIndex(data => data.title === title)
    roles[index].visible = value
    setRoles(roles)
  }

  function handleSubmitRoles() {
    const submitData = {
      id: props.selectedRow._id,
      roles: roles
    }
    props.onUpdatePosition(submitData).then(res => {
      props.onCancel()
      setRoles([])
    })
  }

  function handleCancel() {
    props.onCancel()
    setRoles([])
  }

  return (
    <Modal
      title="Roles Management"
      className="roles-management-modal"
      centered
      visible={props.visible}
      // onOk={() => setVisible(false)}
      onCancel={() => handleCancel()}
      width={1000}
      footer={null}
    >
      <Tabs tabPosition='left' centered={true}>
        {
          roles.map((data, index) => {
            return (
              <TabPane tab={_.startCase(data.title)} key={index}>
                <h1>Page Settings <span>{`(${_.startCase(data.title)})`}</span></h1>
                <h4>Switching this to 'ON' that means you allow this POSITION to view the {_.startCase(data.title)} Page.</h4>
                <Switch defaultChecked={data.visible} onChange={(value) => handlePageEnable(value, data.title)} />
                <Divider style={{ backgroundColor: '#86bdf7' }} />
                <h1>Access Roles</h1>
                <Row align="bottom">
                  {
                    Object.keys(data.roles).map(function (key, index) {
                      return (
                        <Col span={4} style={{ marginBottom: 100 }} key={index}>
                          <h4>{_.startCase(key)}</h4>
                          <Switch defaultChecked={data.roles[key]} onChange={(value) => handleChangeRoles(value, data.title, key)} />
                        </Col>

                      )
                    })
                  }
                </Row>
              </TabPane>
            )
          })
        }
      </Tabs>
      <Row>
        <Col md={24} lg={24} sm={24} align={'right'}>
          <Button type="primary" onClick={() => handleSubmitRoles()} >
            Update Roles
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdatePosition: (data) => dispatch(updatePosition(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Roles))


