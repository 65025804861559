import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { NotFound } from './notFound'
import './index.scss'
import 'antd/dist/antd.css'
import Login from './login/component'
import Sidebar from './shared/sider'
import Dashboard from './dashboard/components'
import Announcements from './announcements/components'
import Memorandums from './memorandums/components'
import Educations from './educations/components'
import Dependents from './dependents/components'
import BusinessTrips from './businessTrips/components'
import Documents from './documents/components'
import PersonalMovements from './personalMovements/components'
import FileReports from './fileReports/components'
import DisciplinaryActions from './disciplinaryActions/components'
import EmploymentHistories from './employmentHistories/components'
import Performances from './performances/components'
import References from './references/components'
import Accountabilities from './accountabilities/components'
import Leaves from './leaves/components'
import MedicalHistories from './medicalHistories/components'
import Biometrics from './biometrics/components'
import Timelogs from './timelogs/components'
import Overtimes from './overtimes/components'
import Reports from './reports'
import Employee from './employee/components'
import Projects from './projects/components'
import Allowances from './allowanceHistories/components'
import Audits from './audits/components'
import Positions from './positions/components'
import TimeRequests from './timeRequests/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt, faAsterisk, faBullhorn, faCheckSquare, faClock, faCog, faCogs, faExclamation, faExclamationTriangle, faFlag, faGraduationCap, faHistory, faHSquare, faMale, faMoneyBillWave, faPaperclip, faStar, faTachometerAlt, faTasks, faUserCircle, faUsers } from '@fortawesome/free-solid-svg-icons'
import * as _ from 'lodash'
import Axios from 'axios';
import { Layout, Modal, Row, Col, Menu, Dropdown, Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getUser } from '../../utilities/token'
import { ImagePresignedUrl } from '../../utilities/renderer'
import Utilities from './utilities/components'
import { loadAdmin } from './admins/actions'

const { Content, Footer, Header } = Layout;
const Client = (props) => {
  const [isAuthenticated, setIsLoggedIn] = useState(false)
  let [paths, setPaths] = useState([
    {
      visible: false,
      title: 'Dashboard',
      route: '/admin',
      tag: 'dashboard',
      component: Dashboard,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faTachometerAlt} />
    },
    {
      visible: false,
      title: 'Timelogs',
      route: '/admin/timelogs',
      tag: 'timelogs',
      component: Timelogs,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faClock} />
    },
    {
      visible: false,
      title: 'Reports',
      route: '/admin/reports',
      tag: 'reports',
      component: Reports,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faCogs} />
    },
    {
      visible: false,
      title: 'Audit Trail',
      route: '/admin/audits',
      tag: 'auditTrails',
      component: Audits,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faCogs} />
    },
    {
      visible: false,
      title: 'Allowances',
      route: '/admin/allowances',
      tag: 'allowances',
      component: Allowances,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faMoneyBillWave} />
    },
    {
      visible: false,
      title: 'Projects',
      route: '/admin/projects',
      tag: 'projects',
      component: Projects,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faStar} />
    },
    {
      visible: false,
      title: 'Positions',
      route: '/admin/positions',
      tag: 'positions',
      component: Positions,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faUsers} />
    },
    {
      visible: false,
      title: 'Announcements',
      route: '/admin/announcements',
      tag: 'announcements',
      component: Announcements,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faBullhorn} />
    },
    {
      visible: false,
      title: 'Memorandums',
      route: '/admin/memorandums',
      tag: 'memorandums',
      component: Memorandums,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faExclamationTriangle} />
    },
    {
      visible: false,
      title: 'Overtimes',
      route: '/admin/overtimes',
      tag: 'overtimes',
      component: Overtimes,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faClock} />
    },
    {
      visible: false,
      title: 'Notice of Official Business',
      route: '/admin/notice-of-official-business',
      tag: 'noticeOfOfficialBusiness',
      component: BusinessTrips,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faBullhorn} />
    },
    {
      visible: false,
      title: 'Leave Applications',
      route: '/admin/leaves',
      tag: 'leaveApplications',
      component: Leaves,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faCheckSquare} />
    },
    {
      visible: false,
      title: 'Employee Masterfile',
      route: '/admin/employee',
      tag: 'employeeMasterfile',
      component: Employee,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faUsers} />
    },
    {
      visible: false,
      title: 'Employment Histories',
      route: '/admin/employment-histories',
      tag: 'employmentHistories',
      component: EmploymentHistories,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faHistory} />
    },
    {
      visible: false,
      title: 'Disciplinary Actions',
      route: '/admin/disciplinary-actions',
      tag: 'disciplinaryActions',
      component: DisciplinaryActions,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faExclamation} />
    },
    {
      visible: false,
      title: 'Educations',
      route: '/admin/educations',
      tag: 'educations',
      component: Educations,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faGraduationCap} />
    },
    {
      visible: false,
      title: 'Accountabilities',
      route: '/admin/accountabilities',
      tag: 'accountabilities',
      component: Accountabilities,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faMoneyBillWave} />
    },
    {
      visible: false,
      title: 'Dependents',
      route: '/admin/dependents',
      tag: 'dependents',
      component: Dependents,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faUserCircle} />
    },
    {
      visible: false,
      title: 'Documents',
      route: '/admin/documents',
      tag: 'documents',
      component: Documents,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faPaperclip} />
    },
    {
      visible: false,
      title: 'Personnel Movements',
      route: '/admin/personal-movements',
      tag: 'personalMovements',
      component: PersonalMovements,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faArrowsAlt} />
    },
    {
      visible: false,
      title: 'Filling Reports',
      route: '/admin/file-reports',
      tag: 'fillingReports',
      component: FileReports,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faFlag} />
    },
    {
      visible: false,
      title: 'Filling Time Confirmation Request',
      route: '/admin/time-requests',
      tag: 'timeRequests',
      component: TimeRequests,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faClock} />
    },
    {
      visible: false,
      title: 'Appraisal Performances',
      route: '/admin/performances',
      tag: 'appraisalPerformances',
      component: Performances,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faTasks} />
    },
    {
      visible: false,
      title: 'References',
      route: '/admin/references',
      tag: 'references',
      component: References,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faMale} />
    },
    {
      visible: false,
      title: 'Medical Histories',
      route: '/admin/medical-history',
      tag: 'medicalHistories',
      component: MedicalHistories,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faHSquare} />
    },
    // {
    //   visible: false,
    //   title: 'Biometrics',
    //   route: '/admin/biometrics',
    //   tag: 'biometrics',
    //   component: Biometrics,
    //   type: 'menu',
    //   icon: <FontAwesomeIcon icon={faAsterisk} />
    // },
    {
      visible: false,
      title: 'Utilities',
      route: '/admin/utilities',
      tag: 'utilities',
      component: Utilities,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faCog} />
    },
  ])
  const [userInfo, setUserInfo] = useState({})

  const logoutError = () => {
    localStorage.removeItem('ADMIN_SESSION_TOKEN');
    window.location.replace('/admin');
    window.location.reload();
  };

  const hasLocalStorage = () => {
    return localStorage.getItem('ADMIN_SESSION_TOKEN') !== null
  }

  useEffect(() => {
    hasLocalStorage() && setIsLoggedIn(true)

    Axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (hasLocalStorage()) {
          // return only
          // show backend manual error.
          if ((error && error.response && error.response.status) === 403) {
            Modal.warning({
              title: 'Message.',
              content: `${error && error.response && error.response.data && error.response.data.message}`,
              onOk: () => { },
            });
          } else {
            // logout user if the error cant handle the message
            Modal.error({
              title: 'Oops! Something went wrong.',
              content: 'You will be redirected to login page.',
              onOk: () => logoutError(),
            });
          }
        }
        return Promise.reject(error);
      }
    );
    window.document.title = 'HRIS Admin Management Portal'
  }, [])

  useEffect(() => {
    async function loadUserInfo() {
      const user = await getUser()
      setUserInfo(user)

      const adminId = await getUser() && getUser().id
      props.onLoadAdmin(adminId)
    }
    loadUserInfo()
  }, [])

  useEffect(() => {
    // set visibility of page in sidebar
    props.adminRoles && props.adminRoles.map(role => {
      if (role.visible) {
        const index = paths.findIndex((path) => path.tag === role.title)
        if (index !== -1) paths[index].visible = role.visible
      }
    })
    setPaths([...paths])
  }, [props.adminRoles])


  useEffect(() => {
    if (props.loggedIn) {
      setIsLoggedIn(true)
      window.location.reload()
    }
  }, [props.loggedIn])

  function logout() {
    localStorage.removeItem('ADMIN_SESSION_TOKEN')
    props.history.push('/admin')
    window.location.reload()
  }

  const menu = (
    <Menu style={{ marginTop: 24 }}>
      <Menu.Item key="0" onClick={() => (window.location.href = '/pms')}>
        Payroll Management System
      </Menu.Item>
      <Menu.Item key="1" onClick={() => logout()}>
        Logout
      </Menu.Item>
    </Menu>
  );

  switch (isAuthenticated) {
    case false:
      return <Login />
    case true:
      return (
        <Layout className='admin-layout'>
          <Sidebar paths={paths} style={{ backgroundColor: '#007AFB' }} />
          <Layout>
            <Header style={{ backgroundColor: 'white' }}>
              <Row >
                <Col md={24} lg={24} xs={24} sm={24} align='right'>
                  <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      <Avatar size={32} icon={<ImagePresignedUrl src={userInfo.image} preview={false} objectFit='cover' style={{ height: '100%' }} />} />
                      <span style={{ margin: 8 }}><b>{userInfo.firstName} {userInfo.lastName}</b> </span><DownOutlined />
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content className='admin-content' style={{ background: '#f0f2f5' }} >
              <Switch>
                {/* // this will scroll to top when switch changes. */}
                {window.scrollTo(0, 0)}
                {_.map(paths, (path) => {
                  if (!path.visible) return
                  return (
                    <Route
                      exact={true}
                      path={path.route}
                      render={(prop) => <path.component
                        {...prop}
                        socket={props.socket}
                      />}
                      key={path.route}
                    />
                  )
                })}
                <Route component={NotFound} />
                <Redirect to='/404' />
              </Switch>
            </Content>
            <Footer style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h1 style={{ color: 'gray' }}>Powered By Twintool Business Solutions. All Rights Reserved ©2022.</h1>
            </Footer>
          </Layout>
        </Layout>
      )
    default:
      break
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.admin.login.loggedIn,
    adminRoles: state.admin.administrator.adminRoles,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadAdmin: (id) => dispatch(loadAdmin(id)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Client))
