import {
    GET_MEDICAL_HISTORIES,
    GET_MEDICAL_HISTORIES_FULFILLED,
    GET_MEDICAL_HISTORIES_REJECTED,
    PUT_MEDICAL_HISTORY,
    PUT_MEDICAL_HISTORY_FULFILLED,
    PUT_MEDICAL_HISTORY_REJECTED,
    POST_MEDICAL_HISTORY,
    POST_MEDICAL_HISTORY_FULFILLED,
    POST_MEDICAL_HISTORY_REJECTED,
    DELETE_MEDICAL_HISTORY,
    DELETE_MEDICAL_HISTORY_FULFILLED,
    DELETE_MEDICAL_HISTORY_REJECTED,
    DELETE_MEDICAL_HISTORY_DEFAULT,
    PUT_MEDICAL_HISTORY_DEFAULT,
    POST_MEDICAL_HISTORY_DEFAULT
} from '../actions'

export default function medicalHistory(state = {
    medicalHistoriesLoading: false,
    medicalHistoriesSuccess: false,
    medicalHistoriesRejected: false,
    medicalHistoriesData: {
        data: []
    },
    verifiedSuccess: false,
    updateMedicalHistoryLoading: false,
    updateMedicalHistorySuccess: false,
    updateMedicalHistoryRejected: false,
    addMedicalHistoryLoading: false,
    addMedicalHistorySuccess: false,
    addMedicalHistoryRejected: false,
    deleteMedicalHistoryLoading: false,
    deleteMedicalHistorySuccess: false,
    deleteMedicalHistoryRejected: false
}, action) {
    switch (action.type) {
        case GET_MEDICAL_HISTORIES:
            return {
                ...state,
                medicalHistoriesLoading: true,
                medicalHistoriesSuccess: false,
                medicalHistoriesRejected: false
            }
        case GET_MEDICAL_HISTORIES_FULFILLED:
            return {
                ...state,
                medicalHistoriesLoading: false,
                medicalHistoriesSuccess: true,
                medicalHistoriesData: action.payload
            }
        case GET_MEDICAL_HISTORIES_REJECTED:
            return {
                ...state,
                medicalHistoriesLoading: false,
                medicalHistoriesRejected: true
            }
        case POST_MEDICAL_HISTORY:
            return {
                ...state,
                addMedicalHistoryLoading: true,
                addMedicalHistorySuccess: false,
                addMedicalHistoryRejected: false
            }
        case POST_MEDICAL_HISTORY_FULFILLED:
            return {
                ...state,
                addMedicalHistoryLoading: false,
                addMedicalHistorySuccess: true,
                addMedicalHistoryData: action.payload
            }
        case POST_MEDICAL_HISTORY_REJECTED:
            return {
                ...state,
                addMedicalHistoryLoading: false,
                addMedicalHistoryRejected: true
            }
        case POST_MEDICAL_HISTORY_DEFAULT:
            return {
                ...state,
                addMedicalHistoryLoading: false,
                addMedicalHistorySuccess: false,
                addMedicalHistoryRejected: false
            }
        case PUT_MEDICAL_HISTORY:
            return {
                ...state,
                updateMedicalHistoryLoading: true,
                updateMedicalHistorySuccess: false,
                updateMedicalHistoryRejected: false
            }
        case PUT_MEDICAL_HISTORY_FULFILLED:
            return {
                ...state,
                updateMedicalHistoryLoading: false,
                updateMedicalHistorySuccess: true,
                updateMedicalHistoryData: action.payload
            }

        case PUT_MEDICAL_HISTORY_REJECTED:
            return {
                ...state,
                updateMedicalHistoryLoading: false,
                updateMedicalHistoryRejected: true
            }
        case PUT_MEDICAL_HISTORY_DEFAULT:
            return {
                ...state,
                updateMedicalHistoryLoading: false,
                updateMedicalHistorySuccess: false,
                updateMedicalHistoryRejected: false
            }

        case DELETE_MEDICAL_HISTORY:
            return {
                ...state,
                deleteMedicalHistoryLoading: true,
                deleteMedicalHistorySuccess: false,
                deleteMedicalHistoryRejected: false
            }
        case DELETE_MEDICAL_HISTORY_FULFILLED:
            return {
                ...state,
                deleteMedicalHistoryLoading: false,
                deleteMedicalHistorySuccess: true,
            }
        case DELETE_MEDICAL_HISTORY_REJECTED:
            return {
                ...state,
                deleteMedicalHistoryLoading: false,
                deleteMedicalHistoryRejected: true
            }
        case DELETE_MEDICAL_HISTORY_DEFAULT:
            return {
                ...state,
                deleteMedicalHistoryLoading: false,
                deleteMedicalHistorySuccess: false,
                deleteMedicalHistoryRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
