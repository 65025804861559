import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Avatar } from 'antd'
import { loadBiometrics, deleteBiometric } from '../actions'
import './index.scss'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import moment from 'moment-business-time'
import access from '../../../../utilities/access'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadBiometrics } = props
        onLoadBiometrics(pagination)
        // eslint-disable-next-line
    }, [props.updateBiometricSuccess, props.deleteBiometricSuccess])

    useEffect(() => {
        setTableData([...props.biometricsData.data])
    }, [props.biometricsData])

    function onChangePagination(page, limit) {
        const { onLoadBiometrics } = props

        const newPagination = {
            page: page,
            limit: limit,
            search: pagination.search
        }
        onLoadBiometrics(newPagination)
        setPagination(prevState => ({
            ...prevState,
            page, limit
        }));
    }

    function handleDelete(id) {
        props.onDeleteBiometric(id)
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'In Time',
            dataIndex: 'login',
            key: 'login',
            render: (item, data) => {
                return (
                    <Fragment>
                        <span>{moment(item).format('LLLL')}</span>
                        <h1>{data.locationIn}</h1>
                    </Fragment>
                )
            }
        },
        {
            title: 'Out Time',
            dataIndex: 'logout',
            key: 'logout',
            render: (item, data) => {
                return (
                    <Fragment>
                        <span>{moment(item).format('LLLL')}</span>
                        <h1>{data.locationOut}</h1>
                    </Fragment>
                )
            }
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     key: 'actions',
        //     render: (item, data) => {
        //         return (
        //             <>
        //                 <Dropdown overlay={<Menu>
        //                     <Menu.Item key="0" onClick={() => {
        //                         props.setIsView(true)
        //                         props.setSelectedId(data.id)
        //                     }}>
        //                         View
        //                     </Menu.Item>
        //                     <Menu.Item key="1" >
        //                         <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data.id)} okText="Yes" cancelText="No">
        //                             Delete
        //                         </Popconfirm>

        //                     </Menu.Item>
        //                 </Menu>}>
        //                     <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
        //                 </Dropdown>
        //             </>
        //         )
        //     }
        // },
    ];
    return (
        <Layout className='page'>
            {
                access.check('biometrics', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.biometricsData.biometricsLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination
                                        //  defaultCurrent={pagination.page}
                                        onChange={(page, limit) => onChangePagination(page, limit)} total={props.biometricsData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        biometricsData: state.admin.biometric.biometricsData,
        biometricsLoading: state.admin.biometric.biometricsLoading,
        updateBiometricSuccess: state.admin.biometric.updateBiometricSuccess,
        deleteBiometricSuccess: state.admin.biometric.deleteBiometricSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadBiometrics: data => dispatch(loadBiometrics(data)),
        onDeleteBiometric: id => dispatch(deleteBiometric(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)