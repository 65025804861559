import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal } from 'antd'
import { updateOvertime, submitOvertime } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'
import { geoCodeReverse } from '../../../../utilities/googleapis'
import './index.scss'
import moment from 'moment'
import { loadProjects } from '../../../admin/projects/actions'
const { Option } = Select

const FormOvertime = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [location, setLocation] = useState({})
    const [address, setAddress] = useState('')
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            timeIn: props.selectedRow && moment(props.selectedRow.timeIn) || new Date(),
            timeOut: props.selectedRow && moment(props.selectedRow.timeOut) || new Date(),
        })

        if ((props.actionType === ADD) || (props.actionType === VIEW)) {
            navigator.geolocation.getCurrentPosition(function (position) {
                getGeoCodeReverse(position.coords.latitude, position.coords.longitude)
                const currLoc = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
                setLocation(currLoc)

            }, function (error) {
                setDisabled(true)
            })

            async function getGeoCodeReverse(latitude, longitude) {
                const address = await geoCodeReverse(latitude, longitude)
                // setAddress(address.formatted_address)
                // form.setFieldsValue({
                //     address: address.formatted_address
                // })
            }
        }

        props.onLoadProjects({
            page: 1,
            limit: 100,
        })

        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
            latitude: location.latitude,
            longitude: location.longitude
        }

        if (props.actionType === ADD) {
            const userId = await getUser().id
            props.onSubmitOvertime({ userId, ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
                form.resetFields()
            })
        }

        if (props.actionType === VIEW) {
            const overtimeId = props.selectedRow._id
            props.onUpdateOvertime({ id: overtimeId, ...submitData }).then(res => {
                setLoading(false)
                form.resetFields()
            })
        }
    }

    return (
        <Modal title={props.selectedRow && props.selectedRow.company || 'Add Overtime'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`overtime`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Time In"
                                name="timeIn"
                                rules={[{ required: true, message: 'Please input time in!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Time Out"
                                name="timeOut"
                                rules={[{ required: true, message: 'Please input time out!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Reason"
                                name="reason"
                                rules={[{ required: true, message: 'Please input time out!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Current Location"
                                name="address"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Projects"
                                name="projectId"
                                rules={[{ required: true, message: 'Please input project id!' }]}
                            >
                                <Select>
                                    {
                                        props.projectsData.data.map((data, index) => {
                                            return (
                                                <Option value={data._id}>{data.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateOvertimeLoading || loading} disabled={disabled}  >
                                    Submit overtime
                                </Button>
                                {
                                    disabled &&
                                    <span className='p1 red'>Can't Access User Location!</span>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateOvertimeLoading: state.client.overtime.updateOvertimeLoading,
        employeesData: state.client.employee.employeesData,
        employeesLoading: state.client.employee.employeesLoading,
        projectsData: state.admin.project.projectsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateOvertime: (data) => dispatch(updateOvertime(data)),
        onSubmitOvertime: (data) => dispatch(submitOvertime(data)),
        onLoadProjects: (data) => dispatch(loadProjects(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormOvertime)