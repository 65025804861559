import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker } from 'antd'
import { loadEmploymentHistory, updateEmploymentHistory, submitEmploymentHistory, verifyEmploymentHistory } from '../actions'
import { loadEmployees } from '../../employee/actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import VerifierCheck from '../../shared/components/verifierCheck'
import './index.scss'
import { getUser } from '../../../../utilities/token'
import moment from 'moment'
import access from '../../../../utilities/access'

const { Option } = Select
const FormEmploymentHistory = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            inclusiveDateFrom: props.selectedRow && moment(props.selectedRow.inclusiveDateFrom),
            inclusiveDateTo: props.selectedRow && moment(props.selectedRow.inclusiveDateTo),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
            postById: getUser().id,
        }

        if (props.actionType === ADD) {
            props.onSubmitEmploymentHistory({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const employmentHistoryId = props.selectedRow._id
            props.onUpdateEmploymentHistory({ id: employmentHistoryId, ...submitData }).then(res => {
                setLoading(false)
                setResetVerifier(true)
            })
        }
    }
    useEffect(() => {
        if (props.actionType === VIEW) {
            if (!(userId)) return
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                id: userId,
            })
        }
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    const isAccessDisabled = (!access.check('employmentHistories', 'edit') && (props.actionType === VIEW))

    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {
                ((props.actionType === VIEW) && access.check('employmentHistories', 'verify')) &&
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                        <VerifierCheck id={props.selectedRow && props.selectedRow._id} verifier={props.selectedRow && props.selectedRow.verifier} onSubmit={props.onVerifyEmploymentHistory} reset={resetVerifier} />
                    </Col>
                </Row>
            }
            <Form
                name={`employmentHistory`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            rules={[{ required: true, message: 'Please input company name!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Company Address"
                            name="companyAddress"
                            rules={[{ required: true, message: 'Please input company address!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Position"
                            name="position"
                            rules={[{ required: true, message: 'Please input company position!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Phone Number"
                            name="phoneNumber"
                            rules={[{ required: true, message: 'Please input phone number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Reason For Leaving"
                            name="reasonForLeaving"
                            rules={[{ required: true, message: 'Please input company address!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Inclusive Date From"
                            name="inclusiveDateFrom"
                            rules={[{ required: true, message: 'Please input inclusive date from!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                style={{ width: '100%' }}
                                picker="year"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Inclusive Date To"
                            name="inclusiveDateTo"
                            rules={[{ required: true, message: 'Please input inclusive date to!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                style={{ width: '100%' }}
                                picker="year"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        {
                            (props.actionType === VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('employmentHistories', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('employmentHistories', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('employmentHistories', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                {
                    ((access.check('employmentHistories', 'add') && (props.actionType === ADD)) || (access.check('employmentHistories', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateEmploymentHistoryLoading || loading}   >
                                    Submit employment history
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateEmploymentHistoryLoading: state.admin.employmentHistory.updateEmploymentHistoryLoading,
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmploymentHistory: (id) => dispatch(loadEmploymentHistory(id)),
        onVerifyEmploymentHistory: (id) => dispatch(verifyEmploymentHistory(id)),
        onUpdateEmploymentHistory: (data) => dispatch(updateEmploymentHistory(data)),
        onSubmitEmploymentHistory: (data) => dispatch(submitEmploymentHistory(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormEmploymentHistory)