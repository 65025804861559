import {
  GET_DISCIPLINARY_ACTIONS,
  GET_DISCIPLINARY_ACTIONS_FULFILLED,
  GET_DISCIPLINARY_ACTIONS_REJECTED,
  PUT_DISCIPLINARY_ACTION,
  PUT_DISCIPLINARY_ACTION_FULFILLED,
  PUT_DISCIPLINARY_ACTION_REJECTED,
  POST_DISCIPLINARY_ACTION,
  POST_DISCIPLINARY_ACTION_FULFILLED,
  POST_DISCIPLINARY_ACTION_REJECTED,
  DELETE_DISCIPLINARY_ACTION,
  DELETE_DISCIPLINARY_ACTION_FULFILLED,
  DELETE_DISCIPLINARY_ACTION_REJECTED,
  GET_DISCIPLINARY_ACTIONS_DEFAULT,
  POST_DISCIPLINARY_ACTION_DEFAULT,
  PUT_DISCIPLINARY_ACTION_DEFAULT,
  DELETE_DISCIPLINARY_ACTION_DEFAULT,

  GET_DISCIPLINARY_ACTION_TYPES,
  GET_DISCIPLINARY_ACTION_TYPES_FULFILLED,
  GET_DISCIPLINARY_ACTION_TYPES_REJECTED,
  PUT_DISCIPLINARY_ACTION_TYPE,
  PUT_DISCIPLINARY_ACTION_TYPE_FULFILLED,
  PUT_DISCIPLINARY_ACTION_TYPE_REJECTED,
  POST_DISCIPLINARY_ACTION_TYPE,
  POST_DISCIPLINARY_ACTION_TYPE_FULFILLED,
  POST_DISCIPLINARY_ACTION_TYPE_REJECTED,
  DELETE_DISCIPLINARY_ACTION_TYPE,
  DELETE_DISCIPLINARY_ACTION_TYPE_FULFILLED,
  DELETE_DISCIPLINARY_ACTION_TYPE_REJECTED,
  GET_DISCIPLINARY_ACTION_TYPES_DEFAULT,
  POST_DISCIPLINARY_ACTION_TYPE_DEFAULT,
  PUT_DISCIPLINARY_ACTION_TYPE_DEFAULT,
  DELETE_DISCIPLINARY_ACTION_TYPE_DEFAULT,

  VERIFY,
  VERIFY_FULFILLED,
  VERIFY_REJECTED,
  VERIFY_DEFAULT
} from '../actions'

export default function disciplinaryAction(state = {
  disciplinaryActionsLoading: false,
  disciplinaryActionsSuccess: false,
  disciplinaryActionsRejected: false,
  disciplinaryActionsData: {
    data: []
  },
  disciplinaryActionTypesData: {
    data: []
  },
  verifiedSuccess: false,
  updateDisciplinaryActionLoading: false,
  updateDisciplinaryActionSuccess: false,
  updateDisciplinaryActionRejected: false,
  addDisciplinaryActionLoading: false,
  addDisciplinaryActionSuccess: false,
  addDisciplinaryActionRejected: false,
  deleteDisciplinaryActionLoading: false,
  deleteDisciplinaryActionSuccess: false,
  deleteDisciplinaryActionRejected: false
}, action) {
  switch (action.type) {
    case GET_DISCIPLINARY_ACTIONS:
      return {
        ...state,
        disciplinaryActionsLoading: true,
        disciplinaryActionsSuccess: false,
        disciplinaryActionsRejected: false
      }
    case GET_DISCIPLINARY_ACTIONS_FULFILLED:
      return {
        ...state,
        disciplinaryActionsLoading: false,
        disciplinaryActionsSuccess: true,
        disciplinaryActionsData: action.payload
      }
    case GET_DISCIPLINARY_ACTIONS_REJECTED:
      return {
        ...state,
        disciplinaryActionsLoading: false,
        disciplinaryActionsRejected: true
      }
    case GET_DISCIPLINARY_ACTIONS_DEFAULT:
      return {
        ...state,
        disciplinaryActionsLoading: false,
        disciplinaryActionsSuccess: false,
        disciplinaryActionsRejected: false
      }
    case POST_DISCIPLINARY_ACTION:
      return {
        ...state,
        addDisciplinaryActionLoading: true,
        addDisciplinaryActionSuccess: false,
        addDisciplinaryActionRejected: false
      }
    case POST_DISCIPLINARY_ACTION_FULFILLED:
      return {
        ...state,
        addDisciplinaryActionLoading: false,
        addDisciplinaryActionSuccess: true,
        addDisciplinaryActionData: action.payload
      }
    case POST_DISCIPLINARY_ACTION_REJECTED:
      return {
        ...state,
        addDisciplinaryActionLoading: false,
        addDisciplinaryActionRejected: true
      }
    case POST_DISCIPLINARY_ACTION_DEFAULT:
      return {
        ...state,
        addDisciplinaryActionLoading: false,
        addDisciplinaryActionSuccess: false,
        addDisciplinaryActionRejected: false
      }
    case PUT_DISCIPLINARY_ACTION:
      return {
        ...state,
        updateDisciplinaryActionLoading: true,
        updateDisciplinaryActionSuccess: false,
        updateDisciplinaryActionRejected: false
      }
    case PUT_DISCIPLINARY_ACTION_FULFILLED:
      return {
        ...state,
        updateDisciplinaryActionLoading: false,
        updateDisciplinaryActionSuccess: true,
        updateDisciplinaryActionData: action.payload
      }
    case PUT_DISCIPLINARY_ACTION_REJECTED:
      return {
        ...state,
        updateDisciplinaryActionLoading: false,
        updateDisciplinaryActionRejected: true
      }
    case PUT_DISCIPLINARY_ACTION_DEFAULT:
      return {
        ...state,
        updateDisciplinaryActionLoading: false,
        updateDisciplinaryActionSuccess: false,
        updateDisciplinaryActionRejected: false
      }
    case DELETE_DISCIPLINARY_ACTION:
      return {
        ...state,
        deleteDisciplinaryActionLoading: true,
        deleteDisciplinaryActionSuccess: false,
        deleteDisciplinaryActionRejected: false
      }
    case DELETE_DISCIPLINARY_ACTION_FULFILLED:
      return {
        ...state,
        deleteDisciplinaryActionLoading: false,
        deleteDisciplinaryActionSuccess: true,
      }
    case DELETE_DISCIPLINARY_ACTION_REJECTED:
      return {
        ...state,
        deleteDisciplinaryActionLoading: false,
        deleteDisciplinaryActionRejected: true
      }
    case DELETE_DISCIPLINARY_ACTION_DEFAULT:
      return {
        ...state,
        deleteDisciplinaryActionLoading: false,
        deleteDisciplinaryActionSuccess: false,
        deleteDisciplinaryActionRejected: false
      }
    case VERIFY:
      return {
        ...state,
        verifiedSuccess: false,
      }
    case VERIFY_FULFILLED:
      return {
        ...state,
        verifiedSuccess: true,
      }
    case VERIFY_REJECTED:
      return {
        ...state,
        verifiedSuccess: false,
      }

    case GET_DISCIPLINARY_ACTION_TYPES:
      return {
        ...state,
        disciplinaryActionTypesLoading: true,
        disciplinaryActionTypesSuccess: false,
        disciplinaryActionTypesRejected: false
      }
    case GET_DISCIPLINARY_ACTION_TYPES_FULFILLED:
      return {
        ...state,
        disciplinaryActionTypesLoading: false,
        disciplinaryActionTypesSuccess: true,
        disciplinaryActionTypesData: action.payload
      }
    case GET_DISCIPLINARY_ACTION_TYPES_REJECTED:
      return {
        ...state,
        disciplinaryActionTypesLoading: false,
        disciplinaryActionTypesRejected: true
      }
    case GET_DISCIPLINARY_ACTION_TYPES_DEFAULT:
      return {
        ...state,
        disciplinaryActionTypesLoading: false,
        disciplinaryActionTypesSuccess: false,
        disciplinaryActionTypesRejected: false
      }
    case POST_DISCIPLINARY_ACTION_TYPE:
      return {
        ...state,
        addDisciplinaryActionTypeLoading: true,
        addDisciplinaryActionTypeSuccess: false,
        addDisciplinaryActionTypeRejected: false
      }
    case POST_DISCIPLINARY_ACTION_TYPE_FULFILLED:
      return {
        ...state,
        addDisciplinaryActionTypeLoading: false,
        addDisciplinaryActionTypeSuccess: true,
        addDisciplinaryActionTypeData: action.payload
      }
    case POST_DISCIPLINARY_ACTION_TYPE_REJECTED:
      return {
        ...state,
        addDisciplinaryActionTypeLoading: false,
        addDisciplinaryActionTypeRejected: true
      }
    case POST_DISCIPLINARY_ACTION_TYPE_DEFAULT:
      return {
        ...state,
        addDisciplinaryActionTypeLoading: false,
        addDisciplinaryActionTypeSuccess: false,
        addDisciplinaryActionTypeRejected: false
      }
    case PUT_DISCIPLINARY_ACTION_TYPE:
      return {
        ...state,
        updateDisciplinaryActionTypeLoading: true,
        updateDisciplinaryActionTypeSuccess: false,
        updateDisciplinaryActionTypeRejected: false
      }
    case PUT_DISCIPLINARY_ACTION_TYPE_FULFILLED:
      return {
        ...state,
        updateDisciplinaryActionTypeLoading: false,
        updateDisciplinaryActionTypeSuccess: true,
        updateDisciplinaryActionTypeData: action.payload
      }
    case PUT_DISCIPLINARY_ACTION_TYPE_REJECTED:
      return {
        ...state,
        updateDisciplinaryActionTypeLoading: false,
        updateDisciplinaryActionTypeRejected: true
      }
    case PUT_DISCIPLINARY_ACTION_TYPE_DEFAULT:
      return {
        ...state,
        updateDisciplinaryActionTypeLoading: false,
        updateDisciplinaryActionTypeSuccess: false,
        updateDisciplinaryActionTypeRejected: false
      }
    case DELETE_DISCIPLINARY_ACTION_TYPE:
      return {
        ...state,
        deleteDisciplinaryActionTypeLoading: true,
        deleteDisciplinaryActionTypeSuccess: false,
        deleteDisciplinaryActionTypeRejected: false
      }
    case DELETE_DISCIPLINARY_ACTION_TYPE_FULFILLED:
      return {
        ...state,
        deleteDisciplinaryActionTypeLoading: false,
        deleteDisciplinaryActionTypeSuccess: true,
      }
    case DELETE_DISCIPLINARY_ACTION_TYPE_REJECTED:
      return {
        ...state,
        deleteDisciplinaryActionTypeLoading: false,
        deleteDisciplinaryActionTypeRejected: true
      }
    case DELETE_DISCIPLINARY_ACTION_TYPE_DEFAULT:
      return {
        ...state,
        deleteDisciplinaryActionTypeLoading: false,
        deleteDisciplinaryActionTypeSuccess: false,
        deleteDisciplinaryActionTypeRejected: false
      }

    default:
      return {
        ...state
      }
  }
}
