import {
  GET_ALLOWANCE_HISTORIES,
  GET_ALLOWANCE_HISTORIES_FULFILLED,
  GET_ALLOWANCE_HISTORIES_REJECTED,
  GET_ALLOWANCE_HISTORIES_BY_USER_ID,
  GET_ALLOWANCE_HISTORIES_BY_USER_ID_FULFILLED,
  GET_ALLOWANCE_HISTORIES_BY_USER_ID_REJECTED,
  PUT_ALLOWANCE_HISTORY,
  PUT_ALLOWANCE_HISTORY_FULFILLED,
  PUT_ALLOWANCE_HISTORY_REJECTED,
  POST_ALLOWANCE_HISTORY,
  POST_ALLOWANCE_HISTORY_FULFILLED,
  POST_ALLOWANCE_HISTORY_REJECTED,
  DELETE_ALLOWANCE_HISTORY,
  DELETE_ALLOWANCE_HISTORY_FULFILLED,
  DELETE_ALLOWANCE_HISTORY_REJECTED,
  GET_ALLOWANCE_HISTORIES_DEFAULT,
  POST_ALLOWANCE_HISTORY_DEFAULT,
  PUT_ALLOWANCE_HISTORY_DEFAULT,
  DELETE_ALLOWANCE_HISTORY_DEFAULT,

  GET_ALLOWANCE_HISTORY_TYPES,
  GET_ALLOWANCE_HISTORY_TYPES_FULFILLED,
  GET_ALLOWANCE_HISTORY_TYPES_REJECTED,
  GET_ALLOWANCE_HISTORY_TYPES_DEFAULT,
  PUT_ALLOWANCE_HISTORY_TYPE,
  PUT_ALLOWANCE_HISTORY_TYPE_FULFILLED,
  PUT_ALLOWANCE_HISTORY_TYPE_REJECTED,
  POST_ALLOWANCE_HISTORY_TYPE,
  POST_ALLOWANCE_HISTORY_TYPE_FULFILLED,
  POST_ALLOWANCE_HISTORY_TYPE_REJECTED,
  DELETE_ALLOWANCE_HISTORY_TYPE,
  DELETE_ALLOWANCE_HISTORY_TYPE_FULFILLED,
  DELETE_ALLOWANCE_HISTORY_TYPE_REJECTED,
  POST_ALLOWANCE_HISTORY_TYPE_DEFAULT,
  PUT_ALLOWANCE_HISTORY_TYPE_DEFAULT,
  DELETE_ALLOWANCE_HISTORY_TYPE_DEFAULT,
} from '../actions'

export default function allowanceHistory(state = {
  allowanceAllotmentLoading: false,
  allowanceAllotmentSuccess: false,
  allowanceAllotmentRejected: false,
  allowanceAllotmentData: {
    data: []
  },
  updateAllowanceHistoryLoading: false,
  updateAllowanceHistorySuccess: false,
  updateAllowanceHistoryRejected: false,
  addAllowanceHistoryLoading: false,
  addAllowanceHistorySuccess: false,
  addAllowanceHistoryRejected: false,
  deleteAllowanceHistoryLoading: false,
  deleteAllowanceHistorySuccess: false,
  deleteAllowanceHistoryRejected: false,
  allowanceHistoryTypesData: {
    data: []
  },
  allowanceAllotmentByUserIdData: {
    data: []
  },
}, action) {
  switch (action.type) {
    case GET_ALLOWANCE_HISTORIES:
      return {
        ...state,
        allowanceAllotmentLoading: true,
        allowanceAllotmentSuccess: false,
        allowanceAllotmentRejected: false
      }
    case GET_ALLOWANCE_HISTORIES_FULFILLED:
      return {
        ...state,
        allowanceAllotmentLoading: false,
        allowanceAllotmentSuccess: true,
        allowanceAllotmentData: action.payload
      }
    case GET_ALLOWANCE_HISTORIES_REJECTED:
      return {
        ...state,
        allowanceAllotmentLoading: false,
        allowanceAllotmentRejected: true
      }
    case GET_ALLOWANCE_HISTORIES_DEFAULT:
      return {
        ...state,
        allowanceAllotmentLoading: false,
        allowanceAllotmentSuccess: false,
        allowanceAllotmentRejected: false
      }
    case GET_ALLOWANCE_HISTORIES_BY_USER_ID:
      return {
        ...state,
        allowanceAllotmentByUserIdLoading: true,
        allowanceAllotmentByUserIdSuccess: false,
        allowanceAllotmentByUserIdRejected: false
      }
    case GET_ALLOWANCE_HISTORIES_BY_USER_ID_FULFILLED:
      return {
        ...state,
        allowanceAllotmentByUserIdLoading: false,
        allowanceAllotmentByUserIdSuccess: true,
        allowanceAllotmentByUserIdData: action.payload
      }
    case GET_ALLOWANCE_HISTORIES_BY_USER_ID_REJECTED:
      return {
        ...state,
        allowanceAllotmentByUserIdLoading: false,
        allowanceAllotmentByUserIdRejected: true
      }
    case POST_ALLOWANCE_HISTORY:
      return {
        ...state,
        addAllowanceHistoryLoading: true,
        addAllowanceHistorySuccess: false,
        addAllowanceHistoryRejected: false
      }
    case POST_ALLOWANCE_HISTORY_FULFILLED:
      return {
        ...state,
        addAllowanceHistoryLoading: false,
        addAllowanceHistorySuccess: true,
        addAllowanceHistoryData: action.payload
      }
    case POST_ALLOWANCE_HISTORY_REJECTED:
      return {
        ...state,
        addAllowanceHistoryLoading: false,
        addAllowanceHistoryRejected: true
      }
    case POST_ALLOWANCE_HISTORY_DEFAULT:
      return {
        ...state,
        addAllowanceHistoryLoading: false,
        addAllowanceHistorySuccess: false,
        addAllowanceHistoryRejected: false
      }
    case PUT_ALLOWANCE_HISTORY:
      return {
        ...state,
        updateAllowanceHistoryLoading: true,
        updateAllowanceHistorySuccess: false,
        updateAllowanceHistoryRejected: false
      }
    case PUT_ALLOWANCE_HISTORY_FULFILLED:
      return {
        ...state,
        updateAllowanceHistoryLoading: false,
        updateAllowanceHistorySuccess: true,
        updateAllowanceHistoryData: action.payload
      }
    case PUT_ALLOWANCE_HISTORY_REJECTED:
      return {
        ...state,
        updateAllowanceHistoryLoading: false,
        updateAllowanceHistoryRejected: true
      }
    case PUT_ALLOWANCE_HISTORY_DEFAULT:
      return {
        ...state,
        updateAllowanceHistoryLoading: false,
        updateAllowanceHistorySuccess: false,
        updateAllowanceHistoryRejected: false
      }
    case DELETE_ALLOWANCE_HISTORY:
      return {
        ...state,
        deleteAllowanceHistoryLoading: true,
        deleteAllowanceHistorySuccess: false,
        deleteAllowanceHistoryRejected: false
      }
    case DELETE_ALLOWANCE_HISTORY_FULFILLED:
      return {
        ...state,
        deleteAllowanceHistoryLoading: false,
        deleteAllowanceHistorySuccess: true,
      }
    case DELETE_ALLOWANCE_HISTORY_REJECTED:
      return {
        ...state,
        deleteAllowanceHistoryLoading: false,
        deleteAllowanceHistoryRejected: true
      }
    case DELETE_ALLOWANCE_HISTORY_DEFAULT:
      return {
        ...state,
        deleteAllowanceHistoryLoading: false,
        deleteAllowanceHistorySuccess: false,
        deleteAllowanceHistoryRejected: false
      }

    case GET_ALLOWANCE_HISTORY_TYPES:
      return {
        ...state,
        allowanceHistoryTypesLoading: true,
        allowanceHistoryTypesSuccess: false,
        allowanceHistoryTypesRejected: false
      }
    case GET_ALLOWANCE_HISTORY_TYPES_FULFILLED:
      return {
        ...state,
        allowanceHistoryTypesLoading: false,
        allowanceHistoryTypesSuccess: true,
        allowanceHistoryTypesData: action.payload
      }
    case GET_ALLOWANCE_HISTORY_TYPES_REJECTED:
      return {
        ...state,
        allowanceHistoryTypesLoading: false,
        allowanceHistoryTypesRejected: true
      }
    case GET_ALLOWANCE_HISTORY_TYPES_DEFAULT:
      return {
        ...state,
        allowanceHistoryTypesLoading: false,
        allowanceHistoryTypesSuccess: false,
        allowanceHistoryTypesRejected: false
      }
    case POST_ALLOWANCE_HISTORY_TYPE:
      return {
        ...state,
        addDisciplinaryActionTypeLoading: true,
        addDisciplinaryActionTypeSuccess: false,
        addDisciplinaryActionTypeRejected: false
      }
    case POST_ALLOWANCE_HISTORY_TYPE_FULFILLED:
      return {
        ...state,
        addDisciplinaryActionTypeLoading: false,
        addDisciplinaryActionTypeSuccess: true,
        addDisciplinaryActionTypeData: action.payload
      }
    case POST_ALLOWANCE_HISTORY_TYPE_REJECTED:
      return {
        ...state,
        addDisciplinaryActionTypeLoading: false,
        addDisciplinaryActionTypeRejected: true
      }
    case POST_ALLOWANCE_HISTORY_TYPE_DEFAULT:
      return {
        ...state,
        addDisciplinaryActionTypeLoading: false,
        addDisciplinaryActionTypeSuccess: false,
        addDisciplinaryActionTypeRejected: false
      }
    case PUT_ALLOWANCE_HISTORY_TYPE:
      return {
        ...state,
        updateDisciplinaryActionTypeLoading: true,
        updateDisciplinaryActionTypeSuccess: false,
        updateDisciplinaryActionTypeRejected: false
      }
    case PUT_ALLOWANCE_HISTORY_TYPE_FULFILLED:
      return {
        ...state,
        updateDisciplinaryActionTypeLoading: false,
        updateDisciplinaryActionTypeSuccess: true,
        updateDisciplinaryActionTypeData: action.payload
      }
    case PUT_ALLOWANCE_HISTORY_TYPE_REJECTED:
      return {
        ...state,
        updateDisciplinaryActionTypeLoading: false,
        updateDisciplinaryActionTypeRejected: true
      }
    case PUT_ALLOWANCE_HISTORY_TYPE_DEFAULT:
      return {
        ...state,
        updateDisciplinaryActionTypeLoading: false,
        updateDisciplinaryActionTypeSuccess: false,
        updateDisciplinaryActionTypeRejected: false
      }
    case DELETE_ALLOWANCE_HISTORY_TYPE:
      return {
        ...state,
        deleteDisciplinaryActionTypeLoading: true,
        deleteDisciplinaryActionTypeSuccess: false,
        deleteDisciplinaryActionTypeRejected: false
      }
    case DELETE_ALLOWANCE_HISTORY_TYPE_FULFILLED:
      return {
        ...state,
        deleteDisciplinaryActionTypeLoading: false,
        deleteDisciplinaryActionTypeSuccess: true,
      }
    case DELETE_ALLOWANCE_HISTORY_TYPE_REJECTED:
      return {
        ...state,
        deleteDisciplinaryActionTypeLoading: false,
        deleteDisciplinaryActionTypeRejected: true
      }
    case DELETE_ALLOWANCE_HISTORY_TYPE_DEFAULT:
      return {
        ...state,
        deleteDisciplinaryActionTypeLoading: false,
        deleteDisciplinaryActionTypeSuccess: false,
        deleteDisciplinaryActionTypeRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
