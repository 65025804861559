import React, { useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Row, Col, notification, List } from 'antd'
import { connect } from 'react-redux'
import { loadAllowances, deleteAllowance } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import _ from 'lodash'
import RemarksRenderer from '../../../../utilities/remarksRenderer'
import moment from 'moment'
import { formatMoney } from '../../../../utilities/helper'


const AllowancesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updateAllowanceSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the allowances information.',
            });
        }
        if (props.addAllowanceSuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new allowances information.',
            });
        }
        if (props.deleteAllowanceSuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted allowances data.',
            });
        }

        props.onLoadAllowances(pagination)
    }, [props.updateAllowanceSuccess, props.addAllowanceSuccess, props.deleteAllowanceSuccess])

    function handleDelete(id) {
        props.onDeleteAllowance(id)
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    return (
        <Layout className='allowances-page'>
            <Card>
                <button className='add-item' onClick={() => {
                    setModalShow(true)
                    setActionType(ADD)
                    setSelectedRow({})
                }}>
                    <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer', fontSize: 20 }} icon={faPlusCircle} />
                    <span>Add Allowance History</span>
                </button>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Description
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Amount
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={props.allowancesData.data}
                    renderItem={(data, index) => <List.Item key={index}>
                        <Row style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }}>
                                <Col md={24} lg={24} xs={24} sm={24}>
                                    <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                </Col>
                            </Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item' >
                                    <h1>{data.allowanceType && data.allowanceType.title} <StatusIconDisplay status={'autoApproved'} /></h1>
                                    <p className='date'>{moment(data.date).format('LLL')}</p>
                                </div>
                                {/* <Row>
                                    <div className='allowances'>
                                        {data.images && data.images.length !== 0 && <h1>Images / Allowances</h1>}
                                        <Col md={24} lg={24} xs={24} sm={24}>
                                            <div className='d-flex'>
                                                {
                                                    data.images && data.images.map((item, index) => {
                                                        return (
                                                            <div className='p2'>
                                                                <ImagePresignedUrl key={index} src={item.url} style={{ width: 80, height: 100 }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </div>
                                </Row> */}
                            </Col>

                            <Col md={9} lg={9} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p className='amount'>₱ {formatMoney(data.totalAmount)}</p>
                                </div>
                            </Col>

                            <Col md={4} lg={4} xs={24} sm={24}>
                                <div className='actions'>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            setModalShow(true)
                                            setActionType(VIEW)
                                            setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>

                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        allowancesData: state.client.allowance.allowancesData,
        addAllowanceSuccess: state.client.allowance.addAllowanceSuccess,
        updateAllowanceSuccess: state.client.allowance.updateAllowanceSuccess,
        deleteAllowanceSuccess: state.client.allowance.deleteAllowanceSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAllowances: data => dispatch(loadAllowances(data)),
        onDeleteAllowance: id => dispatch(deleteAllowance(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllowancesComponent)
