import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_REFERENCES = 'GET_REFERENCES'
export const GET_REFERENCES_FULFILLED = 'GET_REFERENCES_FULFILLED'
export const GET_REFERENCES_REJECTED = 'GET_REFERENCES_REJECTED'
export const GET_REFERENCES_DEFAULT = 'GET_REFERENCES_DEFAULT'

export const GET_REFERENCE = 'GET_REFERENCE'
export const GET_REFERENCE_FULFILLED = 'GET_REFERENCE_FULFILLED'
export const GET_REFERENCE_REJECTED = 'GET_REFERENCE_REJECTED'
export const GET_REFERENCE_DEFAULT = 'GET_REFERENCE_DEFAULT'

export const POST_REFERENCE = 'POST_REFERENCE'
export const POST_REFERENCE_FULFILLED = 'POST_REFERENCE_FULFILLED'
export const POST_REFERENCE_REJECTED = 'POST_REFERENCE_REJECTED'
export const POST_REFERENCE_DEFAULT = 'POST_REFERENCE_DEFAULT'

export const VERIFY = 'VERIFY'
export const VERIFY_FULFILLED = 'VERIFY_FULFILLED'
export const VERIFY_REJECTED = 'VERIFY_REJECTED'
export const VERIFY_DEFAULT = 'VERIFY_DEFAULT'

export const PUT_REFERENCE = 'PUT_REFERENCE'
export const PUT_REFERENCE_FULFILLED = 'PUT_REFERENCE_FULFILLED'
export const PUT_REFERENCE_REJECTED = 'PUT_REFERENCE_REJECTED'
export const PUT_REFERENCE_DEFAULT = 'PUT_REFERENCE_DEFAULT'

export const DELETE_REFERENCE = 'DELETE_REFERENCE'
export const DELETE_REFERENCE_FULFILLED = 'DELETE_REFERENCE_FULFILLED'
export const DELETE_REFERENCE_REJECTED = 'DELETE_REFERENCE_REJECTED'
export const DELETE_REFERENCE_DEFAULT = 'DELETE_REFERENCE_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadReferences = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_REFERENCES,
      payload: {}
    })
    return Axios.get(`${API_URL}/references?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_REFERENCES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_REFERENCES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_REFERENCES_DEFAULT }))
  }
}

export const loadReference = (id) => {
  return dispatch => {
    dispatch({
      type: GET_REFERENCE,
      payload: {}
    })
    return Axios.get(`${API_URL}/reference/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_REFERENCE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_REFERENCE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_REFERENCE_DEFAULT }))
  }
}

export const submitReference = (data) => {
  return dispatch => {
    dispatch({
      type: POST_REFERENCE,
      payload: {}
    })
    return Axios.post(`${API_URL}/reference`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_REFERENCE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_REFERENCE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_REFERENCE_DEFAULT }))
  }
}

export const updateReference = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_REFERENCE,
      payload: {}
    })
    return Axios.put(`${API_URL}/reference/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_REFERENCE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_REFERENCE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_REFERENCE_DEFAULT }))
  }
}

export const deleteReference = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_REFERENCE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/reference/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_REFERENCE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_REFERENCE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_REFERENCE_DEFAULT }))
  }
}


export const verifyReference = (data) => {
  return dispatch => {
    dispatch({
      type: VERIFY,
      payload: {}
    })
    return Axios.post(`${API_URL}/reference-verify`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: VERIFY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: VERIFY_REJECTED,
          payload: error
        })
      })
  }
}













