import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_PERFORMANCES = 'GET_PERFORMANCES'
export const GET_PERFORMANCES_FULFILLED = 'GET_PERFORMANCES_FULFILLED'
export const GET_PERFORMANCES_REJECTED = 'GET_PERFORMANCES_REJECTED'

export const POST_PERFORMANCE = 'POST_PERFORMANCE'
export const POST_PERFORMANCE_FULFILLED = 'POST_PERFORMANCE_FULFILLED'
export const POST_PERFORMANCE_REJECTED = 'POST_PERFORMANCE_REJECTED'
export const POST_PERFORMANCE_DEFAULT = 'POST_PERFORMANCE_DEFAULT'

export const PUT_PERFORMANCE = 'PUT_PERFORMANCE'
export const PUT_PERFORMANCE_FULFILLED = 'PUT_PERFORMANCE_FULFILLED'
export const PUT_PERFORMANCE_REJECTED = 'PUT_PERFORMANCE_REJECTED'
export const PUT_PERFORMANCE_DEFAULT = 'PUT_PERFORMANCE_DEFAULT'

export const DELETE_PERFORMANCE = 'DELETE_PERFORMANCE'
export const DELETE_PERFORMANCE_FULFILLED = 'DELETE_PERFORMANCE_FULFILLED'
export const DELETE_PERFORMANCE_REJECTED = 'DELETE_PERFORMANCE_REJECTED'
export const DELETE_PERFORMANCE_DEFAULT = 'DELETE_PERFORMANCE_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadPerformances = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_PERFORMANCES,
            payload: {}
        })
        return Axios.get(`${API_URL}/performances?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_PERFORMANCES_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_PERFORMANCES_REJECTED,
                payload: error
            })
        })
    }
}

export const submitPerformance = (data) => {
    return dispatch => {
        dispatch({
            type: POST_PERFORMANCE,
            payload: {}
        })
        return Axios.post(`${API_URL}/performance`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_PERFORMANCE_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_PERFORMANCE_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_PERFORMANCE_DEFAULT }))
    }
}

export const updatePerformance = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_PERFORMANCE,
            payload: {}
        })
        return Axios.put(`${API_URL}/performance/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_PERFORMANCE_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_PERFORMANCE_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_PERFORMANCE_DEFAULT }))
    }
}


export const deletePerformance = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_PERFORMANCE,
            payload: {}
        })
        return Axios.delete(`${API_URL}/performance/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_PERFORMANCE_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_PERFORMANCE_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_PERFORMANCE_DEFAULT }))
    }
}
