import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker } from 'antd'
import { loadDependent, updateDependent, submitDependent, verifyDependent } from '../actions'
import { loadEmployees } from '../../employee/actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import VerifierCheck from '../../shared/components/verifierCheck'
import './index.scss'
import moment from 'moment'
import access from '../../../../utilities/access'

const { Option } = Select
const FormDependent = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            birthDate: props.selectedRow && moment(props.selectedRow.birthDate),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            props.onSubmitDependent({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const dependentId = props.selectedRow._id
            props.onUpdateDependent({ id: dependentId, ...submitData }).then(res => {
                setLoading(false)
                setResetVerifier(true)
            })
        }
    }
    useEffect(() => {
        if (props.actionType === VIEW) {
            if (!(userId)) return
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                id: userId,
            })
        }
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    const isAccessDisabled = (!access.check('dependents', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {
                ((props.actionType === VIEW) && access.check('dependents', 'verify')) &&
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                        <VerifierCheck id={props.selectedRow && props.selectedRow._id} verifier={props.selectedRow && props.selectedRow.verifier} onSubmit={props.onVerifyDependent} reset={resetVerifier} />
                    </Col>
                </Row>
            }
            <Form
                name={`dependent`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input first name!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input last name!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Birth Date"
                            name="birthDate"
                            rules={[{ required: true, message: 'Please input birth date!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Contact Number"
                            name="contact"
                            rules={[{ required: true, message: 'Please input contact number!' }]}
                        >
                            <Input type='number' disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        {
                            (props.actionType === VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('dependents', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('dependents', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('dependents', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                {
                    ((access.check('dependents', 'add') && (props.actionType === ADD)) || (access.check('dependents', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateDependentLoading || loading}   >
                                    Submit dependent
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateDependentLoading: state.admin.dependent.updateDependentLoading,
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadDependent: (id) => dispatch(loadDependent(id)),
        onVerifyDependent: (id) => dispatch(verifyDependent(id)),
        onUpdateDependent: (data) => dispatch(updateDependent(data)),
        onSubmitDependent: (data) => dispatch(submitDependent(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormDependent)