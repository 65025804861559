import React, { useEffect, useState } from 'react'
import { Row, Col, Avatar, Pagination, Tooltip, Collapse } from 'antd'
import { connect } from 'react-redux'
import './index.scss'
import { loadMemorandums, viewerMemorandum } from '../actions'
import { ImagePresignedUrl, IframePresignedUrl } from '../../../../utilities/renderer'
import { getUser } from '../../../../utilities/token'
import { CaretRightOutlined } from '@ant-design/icons';
import _ from 'lodash'
const { Panel } = Collapse;
const MemorandumComponents = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    useEffect(() => {
        const { onLoadMemorandums } = props
        onLoadMemorandums(pagination)
        // eslint-disable-next-line
    }, [])

    function onChangePagination(page, limit) {
        const { onLoadMemorandums } = props

        const newPagination = {
            page: page,
            limit: limit,
            search: pagination.search
        }
        onLoadMemorandums(newPagination)
        setPagination(prevState => ({
            ...prevState,
            page, limit
        }));
    }

    async function handleRowClick(e, data) {
        const userId = await getUser().id
        const submitData = {
            viewerId: userId,
            docId: data._id
        }
        // props.onViewerMemorandum({ ...submitData })
    }


    return (
        <div className='page-content'>
            {
                props.memorandumsData.data && props.memorandumsData.data.map((data, index) => {
                    return (
                        <div key={index} onClick={(e) => {
                            handleRowClick(e, data)
                        }} >
                            <Row>
                                <Col className='card' md={24} lg={24} sm={24} xs={24}>
                                    <h1>{data.title}</h1>
                                    <p className='memoNumber'> <b>Memorandum Number: </b>{data.memoNumber}</p>
                                    <Collapse
                                        bordered={false}
                                        // defaultActiveKey={['1']}
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                    >
                                        {
                                            data.files && data.files.map((item, index) => {
                                                return (
                                                    <Panel header={_.startCase(item.path)} key={index} style={{ backgroundColor: 'white' }}>
                                                        <IframePresignedUrl src={item.url} style={{ width: '100%', height: 740 }} /><br />
                                                    </Panel>
                                                )
                                            })
                                        }
                                    </Collapse>
                                    {/* {
                                        data.viewer.length &&
                                        <p className='seen'>Seen</p> || ''
                                    }
                                    <Avatar.Group maxCount={2}   >
                                        {
                                            data.viewer.map((item, index) => {
                                                console.log("item[0].url", item.image[0].url)
                                                return (
                                                    <Tooltip title={`${item.firstName} ${item.lastName}`} placement="top">
                                                        <Avatar icon={<ImagePresignedUrl src={item.image && item.image[0].url} style={{ height: '100%' }} objectFit='cover' preview={false} />} />
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </Avatar.Group> */}
                                </Col>
                            </Row>
                        </div>
                    )
                })
            }
            <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.memorandumsData.total} />
                </Col>
            </Row>
        </div >
    )
}

function mapStateToProps(state) {
    return {
        memorandumsData: state.client.memorandum.memorandumsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadMemorandums: data => dispatch(loadMemorandums(data)),
        onViewerMemorandum: data => dispatch(viewerMemorandum(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemorandumComponents)