import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_INCENTIVES = 'GET_INCENTIVES'
export const GET_INCENTIVES_FULFILLED = 'GET_INCENTIVES_FULFILLED'
export const GET_INCENTIVES_REJECTED = 'GET_INCENTIVES_REJECTED'
export const GET_INCENTIVES_DEFAULT = 'GET_INCENTIVES_DEFAULT'

export const GET_INCENTIVE = 'GET_INCENTIVE'
export const GET_INCENTIVE_FULFILLED = 'GET_INCENTIVE_FULFILLED'
export const GET_INCENTIVE_REJECTED = 'GET_INCENTIVE_REJECTED'
export const GET_INCENTIVE_DEFAULT = 'GET_INCENTIVE_DEFAULT'

export const POST_INCENTIVE = 'POST_INCENTIVE'
export const POST_INCENTIVE_FULFILLED = 'POST_INCENTIVE_FULFILLED'
export const POST_INCENTIVE_REJECTED = 'POST_INCENTIVE_REJECTED'
export const POST_INCENTIVE_DEFAULT = 'POST_INCENTIVE_DEFAULT'

export const PUT_INCENTIVE = 'PUT_INCENTIVE'
export const PUT_INCENTIVE_FULFILLED = 'PUT_INCENTIVE_FULFILLED'
export const PUT_INCENTIVE_REJECTED = 'PUT_INCENTIVE_REJECTED'
export const PUT_INCENTIVE_DEFAULT = 'PUT_INCENTIVE_DEFAULT'

export const DELETE_INCENTIVE = 'DELETE_INCENTIVE'
export const DELETE_INCENTIVE_FULFILLED = 'DELETE_INCENTIVE_FULFILLED'
export const DELETE_INCENTIVE_REJECTED = 'DELETE_INCENTIVE_REJECTED'
export const DELETE_INCENTIVE_DEFAULT = 'DELETE_INCENTIVE_DEFAULT'

export const GET_INCENTIVE_TYPES = 'GET_INCENTIVE_TYPES'
export const GET_INCENTIVE_TYPES_FULFILLED = 'GET_INCENTIVE_TYPES_FULFILLED'
export const GET_INCENTIVE_TYPES_REJECTED = 'GET_INCENTIVE_TYPES_REJECTED'
export const GET_INCENTIVE_TYPES_DEFAULT = 'GET_INCENTIVE_TYPES_DEFAULT'

export const GET_INCENTIVE_TYPE = 'GET_INCENTIVE_TYPE'
export const GET_INCENTIVE_TYPE_FULFILLED = 'GET_INCENTIVE_TYPE_FULFILLED'
export const GET_INCENTIVE_TYPE_REJECTED = 'GET_INCENTIVE_TYPE_REJECTED'
export const GET_INCENTIVE_TYPE_DEFAULT = 'GET_INCENTIVE_TYPE_DEFAULT'

export const POST_INCENTIVE_TYPE = 'POST_INCENTIVE_TYPE'
export const POST_INCENTIVE_TYPE_FULFILLED = 'POST_INCENTIVE_TYPE_FULFILLED'
export const POST_INCENTIVE_TYPE_REJECTED = 'POST_INCENTIVE_TYPE_REJECTED'
export const POST_INCENTIVE_TYPE_DEFAULT = 'POST_INCENTIVE_TYPE_DEFAULT'

export const PUT_INCENTIVE_TYPE = 'PUT_INCENTIVE_TYPE'
export const PUT_INCENTIVE_TYPE_FULFILLED = 'PUT_INCENTIVE_TYPE_FULFILLED'
export const PUT_INCENTIVE_TYPE_REJECTED = 'PUT_INCENTIVE_TYPE_REJECTED'
export const PUT_INCENTIVE_TYPE_DEFAULT = 'PUT_INCENTIVE_TYPE_DEFAULT'

export const DELETE_INCENTIVE_TYPE = 'DELETE_INCENTIVE_TYPE'
export const DELETE_INCENTIVE_TYPE_FULFILLED = 'DELETE_INCENTIVE_TYPE_FULFILLED'
export const DELETE_INCENTIVE_TYPE_REJECTED = 'DELETE_INCENTIVE_TYPE_REJECTED'
export const DELETE_INCENTIVE_TYPE_DEFAULT = 'DELETE_INCENTIVE_TYPE_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadIncentives = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_INCENTIVES,
      payload: {}
    })
    return Axios.get(`${API_URL}/incentives?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_INCENTIVES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_INCENTIVES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_INCENTIVES_DEFAULT }))
  }
}

export const loadIncentive = (id) => {
  return dispatch => {
    dispatch({
      type: GET_INCENTIVE,
      payload: {}
    })
    return Axios.get(`${API_URL}/incentive/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_INCENTIVE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_INCENTIVE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_INCENTIVE_DEFAULT }))
  }
}

export const submitIncentive = (data) => {
  return dispatch => {
    dispatch({
      type: POST_INCENTIVE,
      payload: {}
    })
    return Axios.post(`${API_URL}/incentive`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_INCENTIVE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_INCENTIVE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_INCENTIVE_DEFAULT }))
  }
}

export const updateIncentive = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_INCENTIVE,
      payload: {}
    })
    return Axios.put(`${API_URL}/incentive/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_INCENTIVE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_INCENTIVE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_INCENTIVE_DEFAULT }))
  }
}

export const deleteIncentive = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_INCENTIVE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/incentive/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_INCENTIVE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_INCENTIVE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_INCENTIVE_DEFAULT }))
  }
}









