import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_DISCIPLINARY_ACTIONS = 'GET_DISCIPLINARY_ACTIONS'
export const GET_DISCIPLINARY_ACTIONS_FULFILLED = 'GET_DISCIPLINARY_ACTIONS_FULFILLED'
export const GET_DISCIPLINARY_ACTIONS_REJECTED = 'GET_DISCIPLINARY_ACTIONS_REJECTED'

export const POST_DISCIPLINARY_ACTION = 'POST_DISCIPLINARY_ACTION'
export const POST_DISCIPLINARY_ACTION_FULFILLED = 'POST_DISCIPLINARY_ACTION_FULFILLED'
export const POST_DISCIPLINARY_ACTION_REJECTED = 'POST_DISCIPLINARY_ACTION_REJECTED'
export const POST_DISCIPLINARY_ACTION_DEFAULT = 'POST_DISCIPLINARY_ACTION_DEFAULT'

export const PUT_DISCIPLINARY_ACTION = 'PUT_DISCIPLINARY_ACTION'
export const PUT_DISCIPLINARY_ACTION_FULFILLED = 'PUT_DISCIPLINARY_ACTION_FULFILLED'
export const PUT_DISCIPLINARY_ACTION_REJECTED = 'PUT_DISCIPLINARY_ACTION_REJECTED'
export const PUT_DISCIPLINARY_ACTION_DEFAULT = 'PUT_DISCIPLINARY_ACTION_DEFAULT'

export const DELETE_DISCIPLINARY_ACTION = 'DELETE_DISCIPLINARY_ACTION'
export const DELETE_DISCIPLINARY_ACTION_FULFILLED = 'DELETE_DISCIPLINARY_ACTION_FULFILLED'
export const DELETE_DISCIPLINARY_ACTION_REJECTED = 'DELETE_DISCIPLINARY_ACTION_REJECTED'
export const DELETE_DISCIPLINARY_ACTION_DEFAULT = 'DELETE_DISCIPLINARY_ACTION_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadDisciplinaryActions = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_DISCIPLINARY_ACTIONS,
            payload: {}
        })
        return Axios.get(`${API_URL}/disciplinary-actions?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_DISCIPLINARY_ACTIONS_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_DISCIPLINARY_ACTIONS_REJECTED,
                payload: error
            })
        })
    }
}

export const submitDisciplinaryAction = (data) => {
    return dispatch => {
        dispatch({
            type: POST_DISCIPLINARY_ACTION,
            payload: {}
        })
        return Axios.post(`${API_URL}/disciplinary-action`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_DISCIPLINARY_ACTION_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_DISCIPLINARY_ACTION_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_DISCIPLINARY_ACTION_DEFAULT }))
    }
}

export const updateDisciplinaryAction = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_DISCIPLINARY_ACTION,
            payload: {}
        })
        return Axios.put(`${API_URL}/disciplinary-action/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_DISCIPLINARY_ACTION_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_DISCIPLINARY_ACTION_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_DISCIPLINARY_ACTION_DEFAULT }))
    }
}


export const deleteDisciplinaryAction = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_DISCIPLINARY_ACTION,
            payload: {}
        })
        return Axios.delete(`${API_URL}/disciplinary-action/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_DISCIPLINARY_ACTION_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_DISCIPLINARY_ACTION_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_DISCIPLINARY_ACTION_DEFAULT }))
    }
}
