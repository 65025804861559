import {
    GET_OVERTIMES,
    GET_OVERTIMES_FULFILLED,
    GET_OVERTIMES_REJECTED,
    PUT_OVERTIME,
    PUT_OVERTIME_FULFILLED,
    PUT_OVERTIME_REJECTED,
    POST_OVERTIME,
    POST_OVERTIME_FULFILLED,
    POST_OVERTIME_REJECTED,
    DELETE_OVERTIME,
    DELETE_OVERTIME_FULFILLED,
    DELETE_OVERTIME_REJECTED,
    DELETE_OVERTIME_DEFAULT,
    PUT_OVERTIME_DEFAULT,
    POST_OVERTIME_DEFAULT
} from '../actions'

export default function overtime(state = {
    overtimesLoading: false,
    overtimesSuccess: false,
    overtimesRejected: false,
    overtimesData: {
        data: []
    },
    verifiedSuccess: false,
    updateOvertimeLoading: false,
    updateOvertimeSuccess: false,
    updateOvertimeRejected: false,
    addOvertimeLoading: false,
    addOvertimeSuccess: false,
    addOvertimeRejected: false,
    deleteOvertimeLoading: false,
    deleteOvertimeSuccess: false,
    deleteOvertimeRejected: false
}, action) {
    switch (action.type) {
        case GET_OVERTIMES:
            return {
                ...state,
                overtimesLoading: true,
                overtimesSuccess: false,
                overtimesRejected: false
            }
        case GET_OVERTIMES_FULFILLED:
            return {
                ...state,
                overtimesLoading: false,
                overtimesSuccess: true,
                overtimesData: action.payload
            }
        case GET_OVERTIMES_REJECTED:
            return {
                ...state,
                overtimesLoading: false,
                overtimesRejected: true
            }
        case POST_OVERTIME:
            return {
                ...state,
                addOvertimeLoading: true,
                addOvertimeSuccess: false,
                addOvertimeRejected: false
            }
        case POST_OVERTIME_FULFILLED:
            return {
                ...state,
                addOvertimeLoading: false,
                addOvertimeSuccess: true,
                addOvertimeData: action.payload
            }
        case POST_OVERTIME_REJECTED:
            return {
                ...state,
                addOvertimeLoading: false,
                addOvertimeRejected: true
            }
        case POST_OVERTIME_DEFAULT:
            return {
                ...state,
                addOvertimeLoading: false,
                addOvertimeSuccess: false,
                addOvertimeRejected: false
            }
        case PUT_OVERTIME:
            return {
                ...state,
                updateOvertimeLoading: true,
                updateOvertimeSuccess: false,
                updateOvertimeRejected: false
            }
        case PUT_OVERTIME_FULFILLED:
            return {
                ...state,
                updateOvertimeLoading: false,
                updateOvertimeSuccess: true,
                updateOvertimeData: action.payload
            }

        case PUT_OVERTIME_REJECTED:
            return {
                ...state,
                updateOvertimeLoading: false,
                updateOvertimeRejected: true
            }
        case PUT_OVERTIME_DEFAULT:
            return {
                ...state,
                updateOvertimeLoading: false,
                updateOvertimeSuccess: false,
                updateOvertimeRejected: false
            }

        case DELETE_OVERTIME:
            return {
                ...state,
                deleteOvertimeLoading: true,
                deleteOvertimeSuccess: false,
                deleteOvertimeRejected: false
            }
        case DELETE_OVERTIME_FULFILLED:
            return {
                ...state,
                deleteOvertimeLoading: false,
                deleteOvertimeSuccess: true,
            }
        case DELETE_OVERTIME_REJECTED:
            return {
                ...state,
                deleteOvertimeLoading: false,
                deleteOvertimeRejected: true
            }
        case DELETE_OVERTIME_DEFAULT:
            return {
                ...state,
                deleteOvertimeLoading: false,
                deleteOvertimeSuccess: false,
                deleteOvertimeRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
