import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal } from 'antd'
import { updateAttendance, submitAttendance } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'
import { geoCodeReverse } from '../../../../utilities/googleapis'
import './index.scss'
const { Option } = Select
const { RangePicker } = DatePicker;
const FormAttendance = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [location, setLocation] = useState({})
    const [address, setAddress] = useState('')
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            projectId: 'HR Management Project'
        })
        if (props.actionType === ADD) {
            navigator.geolocation.getCurrentPosition(function (position) {
                getGeoCodeReverse(position.coords.latitude, position.coords.longitude)
                const currLoc = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
                setLocation(currLoc)

            }, function (error) {
                setDisabled(true)
            })

            async function getGeoCodeReverse(latitude, longitude) {
                const address = await geoCodeReverse(latitude, longitude)
                setAddress(address.formatted_address)
                form.setFieldsValue({
                    address: address.formatted_address
                })
            }
        }
        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        setLoading(true)
        const submitData = {
            ...value,
            projectId: "617e72ae2c459ced1c453f89",
            location: address,
            latitude: location.latitude,
            longitude: location.longitude,
        }

        if (props.actionType === ADD) {
            const userId = await getUser().id
            props.onSubmitAttendance({ userId, ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
                form.resetFields()
            })
        }
    }

    console.log("location", location)

    return (
        <Modal title={'Login'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`attendance`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24}>
                            <Form.Item
                                label="Project Name"
                                name="projectId"
                                rules={[{ message: 'Please input project!' }]}
                            >
                                <Input disabled={disabled} />
                            </Form.Item>

                            <Form.Item
                                label="Current Location"
                                name="address"
                            >
                                <Input readOnly disabled={disabled} />
                            </Form.Item>

                        </Col>

                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit" disabled={disabled}
                                    loading={props.updateAttendanceLoading || loading}   >
                                    Login
                                </Button>
                                {
                                    disabled &&
                                    <span className='p1 red'>Can't Access User Location!</span>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateAttendanceLoading: state.client.attendance.updateAttendanceLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitAttendance: (data) => dispatch(submitAttendance(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormAttendance)