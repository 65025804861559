import React, { useState } from 'react'
import { Form, Input, Button, Row, Col, Checkbox, Layout } from 'antd'
import { connect } from 'react-redux'
import { submitLogin } from '../actions'
import './index.scss'

const Login = (props) => {
  const [isAgree, setIsAgree] = useState(true)

  function handleSubmit(e) {
    props.onSubmitLogin(e)
  }

  function handleAgree() {
    setIsAgree(!isAgree)
  }

  const { loggingIn, loginRejected } = props
  return (
    <Layout className='admin-login-container'>
      <Layout.Content className='admin-login-content'>
        <div className='login-form'>
          <h1>Administrator Login</h1>
          {loginRejected &&
            <div className='login-error-message'>
              <span>Login Failed: Invalid Credentials</span>
            </div>}
          <Form
            name='basic'
            initialValues={{ email: 'johnlodantojot@gmail.com', password: '123456' }}
            onFinish={(e) => handleSubmit(e)}
          >
            <Form.Item
              label="Email"
              name='email'
              rules={[{ required: true, message: 'Please input your client code!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
            {/* <Checkbox checked={isAgree} onChange={() => handleAgree()}>Check here to indicate that you have read and <br /> agree to the<span onClick={e => this.handleViewTermsAndConditions(e)}><p > Terms and Conditions</p></span></Checkbox> */}
            <Form.Item>
              <div style={{ display: 'flex' }}>
                <Button type='primary' style={{ width: '100%', marginTop: 10 }} disabled={!isAgree} className='primary-btn' loading={loggingIn} htmlType='submit'>
                  Log in
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Layout.Content>
    </Layout>
  )
}

function mapStateToProps(state) {
  return {
    loginRejected: state.client.login.loginRejected,
    loggingIn: state.client.login.loggingIn,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitLogin: (data) => dispatch(submitLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
