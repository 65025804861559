import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ACCOUNTABILITIES = 'GET_ACCOUNTABILITIES'
export const GET_ACCOUNTABILITIES_FULFILLED = 'GET_ACCOUNTABILITIES_FULFILLED'
export const GET_ACCOUNTABILITIES_REJECTED = 'GET_ACCOUNTABILITIES_REJECTED'

export const POST_ACCOUNTABILITY = 'POST_ACCOUNTABILITY'
export const POST_ACCOUNTABILITY_FULFILLED = 'POST_ACCOUNTABILITY_FULFILLED'
export const POST_ACCOUNTABILITY_REJECTED = 'POST_ACCOUNTABILITY_REJECTED'
export const POST_ACCOUNTABILITY_DEFAULT = 'POST_ACCOUNTABILITY_DEFAULT'

export const PUT_ACCOUNTABILITY = 'PUT_ACCOUNTABILITY'
export const PUT_ACCOUNTABILITY_FULFILLED = 'PUT_ACCOUNTABILITY_FULFILLED'
export const PUT_ACCOUNTABILITY_REJECTED = 'PUT_ACCOUNTABILITY_REJECTED'
export const PUT_ACCOUNTABILITY_DEFAULT = 'PUT_ACCOUNTABILITY_DEFAULT'

export const DELETE_ACCOUNTABILITY = 'DELETE_ACCOUNTABILITY'
export const DELETE_ACCOUNTABILITY_FULFILLED = 'DELETE_ACCOUNTABILITY_FULFILLED'
export const DELETE_ACCOUNTABILITY_REJECTED = 'DELETE_ACCOUNTABILITY_REJECTED'
export const DELETE_ACCOUNTABILITY_DEFAULT = 'DELETE_ACCOUNTABILITY_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadAccountabilities = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_ACCOUNTABILITIES,
            payload: {}
        })
        return Axios.get(`${API_URL}/accountabilities?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_ACCOUNTABILITIES_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_ACCOUNTABILITIES_REJECTED,
                payload: error
            })
        })
    }
}

export const submitAccountability = (data) => {
    return dispatch => {
        dispatch({
            type: POST_ACCOUNTABILITY,
            payload: {}
        })
        return Axios.post(`${API_URL}/accountability`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_ACCOUNTABILITY_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_ACCOUNTABILITY_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_ACCOUNTABILITY_DEFAULT }))
    }
}

export const updateAccountability = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_ACCOUNTABILITY,
            payload: {}
        })
        return Axios.put(`${API_URL}/accountability/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_ACCOUNTABILITY_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_ACCOUNTABILITY_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_ACCOUNTABILITY_DEFAULT }))
    }
}


export const deleteAccountability = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_ACCOUNTABILITY,
            payload: {}
        })
        return Axios.delete(`${API_URL}/accountability/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_ACCOUNTABILITY_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_ACCOUNTABILITY_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_ACCOUNTABILITY_DEFAULT }))
    }
}
