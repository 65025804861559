import {
  GET_BUSINESS_TRIPS,
  GET_BUSINESS_TRIPS_FULFILLED,
  GET_BUSINESS_TRIPS_REJECTED,
  PUT_BUSINESS_TRIP,
  PUT_BUSINESS_TRIP_FULFILLED,
  PUT_BUSINESS_TRIP_REJECTED,
  POST_BUSINESS_TRIP,
  POST_BUSINESS_TRIP_FULFILLED,
  POST_BUSINESS_TRIP_REJECTED,
  DELETE_BUSINESS_TRIP,
  DELETE_BUSINESS_TRIP_FULFILLED,
  DELETE_BUSINESS_TRIP_REJECTED,
  GET_BUSINESS_TRIPS_DEFAULT,
  POST_BUSINESS_TRIP_DEFAULT,
  PUT_BUSINESS_TRIP_DEFAULT,
  DELETE_BUSINESS_TRIP_DEFAULT,
  VERIFY,
  VERIFY_FULFILLED,
  VERIFY_REJECTED,
  VERIFY_DEFAULT
} from '../actions'

export default function businessTrip(state = {
  businessTripsLoading: false,
  businessTripsSuccess: false,
  businessTripsRejected: false,
  businessTripsData: {
    data: []
  },
  verifiedSuccess: false,
  updateBusinessTripLoading: false,
  updateBusinessTripSuccess: false,
  updateBusinessTripRejected: false,
  addBusinessTripLoading: false,
  addBusinessTripSuccess: false,
  addBusinessTripRejected: false,
  deleteBusinessTripLoading: false,
  deleteBusinessTripSuccess: false,
  deleteBusinessTripRejected: false
}, action) {
  switch (action.type) {
    case GET_BUSINESS_TRIPS:
      return {
        ...state,
        businessTripsLoading: true,
        businessTripsSuccess: false,
        businessTripsRejected: false
      }
    case GET_BUSINESS_TRIPS_FULFILLED:
      return {
        ...state,
        businessTripsLoading: false,
        businessTripsSuccess: true,
        businessTripsData: action.payload
      }
    case GET_BUSINESS_TRIPS_REJECTED:
      return {
        ...state,
        businessTripsLoading: false,
        businessTripsRejected: true
      }
    case GET_BUSINESS_TRIPS_DEFAULT:
      return {
        ...state,
        businessTripsLoading: false,
        businessTripsSuccess: false,
        businessTripsRejected: false
      }
    case POST_BUSINESS_TRIP:
      return {
        ...state,
        addBusinessTripLoading: true,
        addBusinessTripSuccess: false,
        addBusinessTripRejected: false
      }
    case POST_BUSINESS_TRIP_FULFILLED:
      return {
        ...state,
        addBusinessTripLoading: false,
        addBusinessTripSuccess: true,
        addBusinessTripData: action.payload
      }
    case POST_BUSINESS_TRIP_REJECTED:
      return {
        ...state,
        addBusinessTripLoading: false,
        addBusinessTripRejected: true
      }
    case POST_BUSINESS_TRIP_DEFAULT:
      return {
        ...state,
        addBusinessTripLoading: false,
        addBusinessTripSuccess: false,
        addBusinessTripRejected: false
      }
    case PUT_BUSINESS_TRIP:
      return {
        ...state,
        updateBusinessTripLoading: true,
        updateBusinessTripSuccess: false,
        updateBusinessTripRejected: false
      }
    case PUT_BUSINESS_TRIP_FULFILLED:
      return {
        ...state,
        updateBusinessTripLoading: false,
        updateBusinessTripSuccess: true,
        updateBusinessTripData: action.payload
      }
    case PUT_BUSINESS_TRIP_REJECTED:
      return {
        ...state,
        updateBusinessTripLoading: false,
        updateBusinessTripRejected: true
      }
    case PUT_BUSINESS_TRIP_DEFAULT:
      return {
        ...state,
        updateBusinessTripLoading: false,
        updateBusinessTripSuccess: false,
        updateBusinessTripRejected: false
      }
    case DELETE_BUSINESS_TRIP:
      return {
        ...state,
        deleteBusinessTripLoading: true,
        deleteBusinessTripSuccess: false,
        deleteBusinessTripRejected: false
      }
    case DELETE_BUSINESS_TRIP_FULFILLED:
      return {
        ...state,
        deleteBusinessTripLoading: false,
        deleteBusinessTripSuccess: true,
      }
    case DELETE_BUSINESS_TRIP_REJECTED:
      return {
        ...state,
        deleteBusinessTripLoading: false,
        deleteBusinessTripRejected: true
      }
    case DELETE_BUSINESS_TRIP_DEFAULT:
      return {
        ...state,
        deleteBusinessTripLoading: false,
        deleteBusinessTripSuccess: false,
        deleteBusinessTripRejected: false
      }
    case VERIFY:
      return {
        ...state,
        verifiedSuccess: false,
      }
    case VERIFY_FULFILLED:
      return {
        ...state,
        verifiedSuccess: true,
      }
    case VERIFY_REJECTED:
      return {
        ...state,
        verifiedSuccess: false,
      }
    default:
      return {
        ...state
      }
  }
}
