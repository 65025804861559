import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS'
export const GET_ANNOUNCEMENTS_FULFILLED = 'GET_ANNOUNCEMENTS_FULFILLED'
export const GET_ANNOUNCEMENTS_REJECTED = 'GET_ANNOUNCEMENTS_REJECTED'

export const VIEWER = 'VIEWER'
export const VIEWER_FULFILLED = 'VIEWER_FULFILLED'
export const VIEWER_REJECTED = 'VIEWER_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadAnnouncements = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_ANNOUNCEMENTS,
            payload: {}
        })
        return Axios.get(`${API_URL}/announcements?${query}`, { headers: headers })
            .then(function (response) {
                dispatch({
                    type: GET_ANNOUNCEMENTS_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                dispatch({
                    type: GET_ANNOUNCEMENTS_REJECTED,
                    payload: error
                })
            })
    }
}

export const viewerAnnouncement = (data) => {
    return dispatch => {
        dispatch({
            type: VIEWER,
            payload: {}
        })
        return Axios.post(`${API_URL}/announcement-viewer`, { ...data }, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: VIEWER_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: VIEWER_REJECTED,
                    payload: error
                })
            })
    }
}

