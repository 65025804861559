import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, notification, Popconfirm, List, Radio, Modal, Divider } from 'antd'
import { updateLeaveType, submitLeaveType, loadLeaveTypes, deleteLeaveType } from '../../actions'
import '../index.scss'
import { ADD, VIEW, NONE } from '../../../../../utilities/actionEnums'

const FormType = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [selectedRow, setSelectedRow] = useState(undefined)

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (!selectedRow) {
            props.onSubmitLeaveType({ ...submitData }).then(res => {
                setLoading(false)
                props.onLoadLeaveTypes({ page: 1, limit: 100, })
                form.resetFields()
                notification.success({
                    message: `Added`,
                    description: 'You have successfully added new leave type.',
                });
            })
        } else {
            const leaveId = selectedRow._id
            props.onUpdateLeaveType({ id: leaveId, ...submitData }).then(res => {
                setLoading(false)
                props.onLoadLeaveTypes({ page: 1, limit: 100 })
                form.resetFields()
                notification.success({
                    message: `Updated`,
                    description: 'You have successfully updated leave type.',
                });
            })
        }

        setSelectedRow(undefined)

    }

    useEffect(() => {
        props.onLoadLeaveTypes({
            page: 1,
            limit: 100,
        })
    }, [])

    function handleDelete(id) {
        props.onDeleteLeaveType(id).then(() => {
            props.onLoadLeaveTypes({ page: 1, limit: 100, })
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted leave type.',
            });
        })
    }

    function handleSelectRow(data) {
        setSelectedRow(data)
        form.setFieldsValue({
            ...data
        })
    }

    return (
        <Modal title={'Type'} visible={props.visible} footer={null} onCancel={() => props.setModalTypeShow(false)} width={600}>
            <Layout className='type-modal-content' style={{ backgroundColor: 'white' }}>
                <Form
                    name={`leave`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please input title!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} lg={24} sm={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <Input placeholder='Optional' />
                            </Form.Item>
                        </Col>

                        <Col md={24} lg={24} sm={24} xs={24}>
                            <h1>Select where to deduct the leave count in users.</h1>
                            <Form.Item
                                name="deductedTo"
                                rules={[{ required: true, message: 'Please choose the process!' }]}
                            >
                                <Radio.Group >
                                    <Radio value={'vacation'}>Vacation Leave</Radio>
                                    <Radio value={'sick'}>Sick Leave</Radio>
                                    <Radio value={'emergency'}>Emergency Leave</Radio>
                                    <Radio value={'others'}>Not Applicable</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row >

                    {
                        selectedRow ?
                            <Row gutter={8} >
                                <Col>
                                    <Form.Item >
                                        <Button className='primary-btn' type="primary" htmlType="submit" loading={props.updateLeaveTypeLoading || loading}  >
                                            Save
                                        </Button>
                                    </Form.Item>

                                </Col>
                                <Col>
                                    <Form.Item >
                                        <Button onClick={() => {
                                            form.resetFields()
                                            setSelectedRow(undefined)
                                        }} >
                                            Cancel
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row> :
                            <Row>
                                <Col md={24} lg={24} sm={24} align={'left'}>
                                    <Form.Item >
                                        <Button className='primary-btn' type="primary" htmlType="submit" style={{ marginTop: 12 }}
                                            loading={props.updateLeaveLoading || loading}   >
                                            Add Leave Type
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                    }

                </Form >
                <Divider />
                <div style={{ height: 400, overflow: 'auto' }}>
                    <List
                        size="small"
                        header={<div>List Of Leave Types</div>}
                        bordered
                        dataSource={props.leaveTypesData.data}
                        renderItem={(item, index) =>
                            <List.Item className={((selectedRow && selectedRow._id) === item._id) ? 'row-item selected' : 'row-item'}>
                                <div >
                                    <h1>{index + 1}. {item.title}</h1>
                                    <p>{item.description}</p>
                                    <p>{item.deductedTo}</p>
                                    <div className='actions'>
                                        <span className='edit' onClick={() => handleSelectRow(item)}>Edit</span>
                                        <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => {
                                            handleDelete(item._id)
                                            setSelectedRow(undefined)
                                            form.resetFields()
                                        }} okText="Yes" cancelText="No">
                                            <span className='delete'>Delete</span>
                                        </Popconfirm>
                                    </div>
                                </div>
                            </List.Item>
                        }
                    />
                </div>

            </Layout >
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateLeaveLoading: state.admin.leave.updateLeaveLoading,
        leaveTypesData: state.admin.leave.leaveTypesData,
        updateLeaveTypeLoading: state.admin.leave.updateLeaveTypeLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadLeaveTypes: (pagination) => dispatch(loadLeaveTypes(pagination)),
        onUpdateLeaveType: (data) => dispatch(updateLeaveType(data)),
        onSubmitLeaveType: (data) => dispatch(submitLeaveType(data)),
        onDeleteLeaveType: (id) => dispatch(deleteLeaveType(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormType)