import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_TIME_REQUESTS = 'GET_TIME_REQUESTS'
export const GET_TIME_REQUESTS_FULFILLED = 'GET_TIME_REQUESTS_FULFILLED'
export const GET_TIME_REQUESTS_REJECTED = 'GET_TIME_REQUESTS_REJECTED'

export const POST_TIME_REQUEST = 'POST_TIME_REQUEST'
export const POST_TIME_REQUEST_FULFILLED = 'POST_TIME_REQUEST_FULFILLED'
export const POST_TIME_REQUEST_REJECTED = 'POST_TIME_REQUEST_REJECTED'
export const POST_TIME_REQUEST_DEFAULT = 'POST_TIME_REQUEST_DEFAULT'

export const PUT_TIME_REQUEST = 'PUT_TIME_REQUEST'
export const PUT_TIME_REQUEST_FULFILLED = 'PUT_TIME_REQUEST_FULFILLED'
export const PUT_TIME_REQUEST_REJECTED = 'PUT_TIME_REQUEST_REJECTED'
export const PUT_TIME_REQUEST_DEFAULT = 'PUT_TIME_REQUEST_DEFAULT'

export const DELETE_TIME_REQUEST = 'DELETE_TIME_REQUEST'
export const DELETE_TIME_REQUEST_FULFILLED = 'DELETE_TIME_REQUEST_FULFILLED'
export const DELETE_TIME_REQUEST_REJECTED = 'DELETE_TIME_REQUEST_REJECTED'
export const DELETE_TIME_REQUEST_DEFAULT = 'DELETE_TIME_REQUEST_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadTimeRequests = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_TIME_REQUESTS,
            payload: {}
        })
        return Axios.get(`${API_URL}/time-requests?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_TIME_REQUESTS_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_TIME_REQUESTS_REJECTED,
                payload: error
            })
        })
    }
}

export const submitTimeRequest = (data) => {
    return dispatch => {
        dispatch({
            type: POST_TIME_REQUEST,
            payload: {}
        })
        return Axios.post(`${API_URL}/time-request`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_TIME_REQUEST_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_TIME_REQUEST_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_TIME_REQUEST_DEFAULT }))
    }
}

export const updateTimeRequest = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_TIME_REQUEST,
            payload: {}
        })
        return Axios.put(`${API_URL}/time-request/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_TIME_REQUEST_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_TIME_REQUEST_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_TIME_REQUEST_DEFAULT }))
    }
}


export const deleteTimeRequest = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_TIME_REQUEST,
            payload: {}
        })
        return Axios.delete(`${API_URL}/time-request/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_TIME_REQUEST_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_TIME_REQUEST_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_TIME_REQUEST_DEFAULT }))
    }
}
