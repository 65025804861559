import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, notification } from 'antd'
import List from './list'
import View from './view'
import './index.scss'
import { VIEW, EDIT, ADD, NONE } from '../../../../utilities/actionEnums'
import Viewer from '../../shared/components/viewer'
const Employee = (props) => {
    const [actionType, setActionType] = useState(NONE)
    const [selectedId, setSelectedId] = useState()
    const [selectedRow, setSelectedRow] = useState()

    useEffect(() => {
        if (props.updateEmployeeSuccess) {
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the employee information.',
            });
        }
    }, [props.updateEmployeeSuccess])

    useEffect(() => {
        if (props.addEmployeeSuccess) {
            notification.success({
                message: `Added`,
                description: 'You have successfully added the employee information.',
            });
            setActionType(NONE)
        }
    }, [props.addEmployeeSuccess])

    useEffect(() => {
        var url_string = window.location.href
        var url = new URL(url_string);
        var empId = url.searchParams.get("empId");
        var view = url.searchParams.get("view");
        if (view === 'true') {
            setSelectedId(empId)
            setActionType(VIEW)
        }
    }, [])

    return (
        <Viewer
            isView={(actionType === VIEW) || (actionType === ADD)}
            listComponent={
                <List
                    setActionType={setActionType}
                    setSelectedRow={setSelectedRow}
                    setSelectedId={setSelectedId}
                />
            }
            viewComponent={
                <View
                    actionType={actionType}
                    setActionType={setActionType}
                    setSelectedRow={setSelectedRow}
                    setSelectedId={setSelectedId}
                    selectedRow={selectedRow}
                    selectedId={selectedId}
                />
            }
        />
    )
}

function mapStateToProps(state) {
    return {
        updateEmployeeSuccess: state.admin.employee.updateEmployeeSuccess,
        addEmployeeSuccess: state.admin.employee.addEmployeeSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Employee)