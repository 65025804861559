import React, { useEffect, useState } from 'react'
import { Layout, Menu, Divider, Form, Avatar, Modal } from 'antd';
import { withRouter, Link } from 'react-router-dom'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faBirthdayCake, faDownload, faEnvelope, faMapMarker, faPhone, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { loadEmployee, GET_EMPLOYEE_FULFILLED } from '../../profile/actions'
import { connect } from 'react-redux';
import { getUser } from '../../../../utilities/token'
import moment from 'moment';
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { formatMoney } from '../../../../utilities/helper';
import Announcements from '../../announcements/components'
import Memorandums from '../../memorandums/components'
import Profile from '../../profile/components'
import Educations from '../../educations/components'
import EmploymentHistories from '../../employmentHistories/components'
import References from '../../references/components'
import Dependents from '../../dependents/components'
import TimeRequests from '../../timeRequests/components'
import FileReports from '../../fileReports/components'
import Performances from '../../performances/components'
import DisciplinaryActions from '../../disciplinaryActions/components'
import Accountabilities from '../../accountabilities/components'
import MedicalHistories from '../../medicalHistories/components'
import PersonalMovements from '../../personalMovements/components'
import Documents from '../../documents/components'
import Allowances from '../../allowances/components'
import BusinessTrips from '../../businessTrips/components'
import Overtimes from '../../overtimes/components'
import Attendances from '../../attendances/components'
import Leaves from '../../leaves/components'
const { Sider } = Layout;

const SiderComponent = (props) => {
    const [form] = Form.useForm()
    const [menuList, setMenuList] = useState([
        {
            name: 'News Feed',
            route: '/news',
        },
        {
            name: 'Memorandums',
            route: '/memorandums',
        },
        {
            name: 'Profile',
            route: '/profile',
        },
        {
            name: 'Attendances',
            route: '/attendance',
        },
        {
            name: 'Allowances',
            route: '/allowances',
        },
        {
            name: 'Educations',
            route: '/educations',
        },
        {
            name: 'Employment Histories',
            route: '/employment-histories',
        },
        {
            name: 'Contact References',
            route: '/contact-references',
        },
        {
            name: 'Appraisal Performances',
            route: '/appraisal-performances',
        },
        {
            name: 'Dependents',
            route: '/dependents',
        },
        {
            name: 'Overtime',
            route: '/overtime',
        },
        {
            name: 'Disciplinary Actions',
            route: '/disciplinary-actions',
        },
        {
            name: 'Accountabilities',
            route: '/accountabilities',
        },
        {
            name: 'Medical Histories',
            route: '/medical-histories',
        },
        {
            name: 'Leave Applications',
            route: '/leave-applications',
        },
        {
            name: 'Personnel Movements',
            route: '/personal-movements',
        },
        {
            name: 'Documents Credentials',
            route: '/documents'
        },
        {
            name: 'Notice of Official Business',
            route: '/notice-of-official-business'
        },
        {
            name: 'Filling Time Confirmation Requests',
            route: '/filling-time-confirmation-requests'
        },
        // {
        //     name: 'Filling Reports',
        //     route: '/filling-reports'
        // },

    ])
    useEffect(() => {
        async function load() {
            const userId = await getUser().id
            props.onLoadEmployee(userId)
        }
        load()
        // eslint-disable-next-line
    }, [])



    function logout() {
        Modal.confirm({
            title: 'Do you want to logout?',
            icon: <ExclamationCircleOutlined />,
            content: 'You will redirected to login page',
            onOk() {
                localStorage.removeItem('CLIENT_SESSION_TOKEN')
                props.history.push('/')
                window.location.reload()
            },
            onCancel() {
            },
        });
    }

    console.log("props.employeeData", props.employeeData)
    return (
        <Sider className='client-sidebar'>
            <div className='sider-header'>
                <h1>HRIS MONITORING SYSTEM</h1>
                <p>YOUR PROPERTY MANAGEMENT PORTAL</p>
            </div>
            <Divider />
            <div className='user-info'>
                <Avatar size={52} src={<ImagePresignedUrl src={props.employeeData.image && props.employeeData.image[0].url} preview={false} style={{ width: 52, height: '100%' }} objectFit='cover' />} />
                <p>{props.employeeData && props.employeeData.firstName} {props.employeeData && props.employeeData.lastName}</p>
                <h6>{props.employeeData && props.employeeData.employeeNumber}</h6>
            </div>
            <Divider />
            <Menu theme="dark" style={{ background: 'transparent' }} onClick={(e) => {
            }} mode="inline"
            >
                {
                    menuList.map((data, i) => {
                        return (
                            <Menu.Item key={`menu_${(i + 1)}`}
                            // icon={data.icon}
                            >
                                <Link to={data.route} style={{ marginLeft: 10, marginRight: 10, color: 'white', fontSize: 13 }}>
                                    {data.name}
                                </Link>
                            </Menu.Item>
                        )
                    })
                }
                <Menu.Item
                    onClick={() => logout()}>
                    <Link style={{ marginLeft: 10, marginRight: 10, color: 'white', fontSize: 13 }}> Logout</Link>
                </Menu.Item>
            </Menu>
            {/* <div className='profile'>
                <ImagePresignedUrl className='image' src={props.employeeData.image && props.employeeData.image[0].url} objectFit='cover' />
            </div>

            <div className='name'>
                <h1>{`${props.employeeData && props.employeeData.firstName || ''} ${props.employeeData && props.employeeData.lastName || ''}`}</h1>
                <p>{`${props.employeeData && props.employeeData.position && props.employeeData.position.title || ''}`}</p>
                <p>{`#${props.employeeData && props.employeeData.employeeNumber || ''}`}</p>
            </div>

            <div className='ratings'>
                <h1>JOB LEVEL</h1>
                <div className='ratings-flex'>
                    <span className='job-level-label'>{props.employeeData && props.employeeData.position && props.employeeData.position.jobLevel.toFixed(1)}</span><Rate value={props.employeeData && props.employeeData.position && props.employeeData.position.jobLevel} style={{ color: '#108ee9', fontSize: 14 }} disabled />
                </div>
            </div>
            <div className='leaves'>
                <Tooltip position='top' title='Remaining VL Days'>
                    <h1>VL: <b>{props.employeeData && props.employeeData.vacationLeaveDays || 0}</b></h1>
                </Tooltip>
                <Tooltip position='top' title='Remaining SL Days'>
                    <h1>SL: <b>{props.employeeData && props.employeeData.sickLeaveDays || 0}</b></h1>
                </Tooltip>
            </div>
            <Divider />
            <div className='allowance'>
                <Tooltip position='top' title={`Your current allowance ₱ ${props.employeeData && formatMoney(props.employeeData.allowance) || 0}`}>
                    <h1>ALLOWANCE: <b>₱ {props.employeeData && formatMoney(props.employeeData.allowance) || 0}</b></h1>
                </Tooltip>
            </div>
            <Divider />
            <div className='actions'>
                <Tooltip position='top' title='Download Payslip'>
                    <FontAwesomeIcon icon={faDownload} />
                </Tooltip>
                <Tooltip position='top' title='Notifications'>
                    <FontAwesomeIcon icon={faBell} />
                </Tooltip>
                <Tooltip position='top' title='Logout'>
                    <FontAwesomeIcon icon={faSignOutAlt} onClick={() => logout()} />
                </Tooltip>
            </div>

            <Divider />
            <div className='contacts'>
                <h1>Contacts</h1>
                <p><FontAwesomeIcon icon={faEnvelope} />{`${props.employeeData.email}`}</p>
                <p><FontAwesomeIcon icon={faPhone} />{props.employeeData && props.employeeData.contact}</p>
            </div>

            <div className='other-info'>
                <h1>Other Informations</h1>
                <p><FontAwesomeIcon icon={faBirthdayCake} />{`${moment(props.employeeData.birthday).format('LL')}`}</p>
                <p><FontAwesomeIcon icon={faMapMarker} />{props.employeeData && props.employeeData.address}</p>
            </div>

            {
                (props.employeeData && props.employeeData.manager) &&
                <div className='manager'>
                    <h1>Manager</h1>
                    <div className='row'>
                        <ImagePresignedUrl src={props.employeeData.manager.image && props.employeeData.manager.image[0].url} objectFit='cover' preview={false} style={{ height: 30, width: 30 }} />
                        <div className='content'>
                            {
                                (props.employeeData && props.employeeData.manager) &&
                                <p>{props.employeeData.manager.firstName} {props.employeeData.manager.lastName}</p>
                            }
                            <p>{props.employeeData.manager && props.employeeData.manager.position && props.employeeData.manager.position.title}</p>
                        </div>
                    </div>
                </div>
            } */}
        </Sider>
    )
}

function mapStateToProps(state) {
    return {
        employeeData: state.client.employee.employeeData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmployee: (id) => dispatch(loadEmployee(id)),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiderComponent))