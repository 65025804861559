import {
    GET_TIME_REQUESTS,
    GET_TIME_REQUESTS_FULFILLED,
    GET_TIME_REQUESTS_REJECTED,
    PUT_TIME_REQUEST,
    PUT_TIME_REQUEST_FULFILLED,
    PUT_TIME_REQUEST_REJECTED,
    POST_TIME_REQUEST,
    POST_TIME_REQUEST_FULFILLED,
    POST_TIME_REQUEST_REJECTED,
    DELETE_TIME_REQUEST,
    DELETE_TIME_REQUEST_FULFILLED,
    DELETE_TIME_REQUEST_REJECTED,
    DELETE_TIME_REQUEST_DEFAULT,
    PUT_TIME_REQUEST_DEFAULT,
    POST_TIME_REQUEST_DEFAULT
} from '../actions'

export default function timeRequest(state = {
    timeRequestsLoading: false,
    timeRequestsSuccess: false,
    timeRequestsRejected: false,
    timeRequestsData: {
        data: []
    },
    verifiedSuccess: false,
    updateTimeRequestLoading: false,
    updateTimeRequestSuccess: false,
    updateTimeRequestRejected: false,
    addTimeRequestLoading: false,
    addTimeRequestSuccess: false,
    addTimeRequestRejected: false,
    deleteTimeRequestLoading: false,
    deleteTimeRequestSuccess: false,
    deleteTimeRequestRejected: false
}, action) {
    switch (action.type) {
        case GET_TIME_REQUESTS:
            return {
                ...state,
                timeRequestsLoading: true,
                timeRequestsSuccess: false,
                timeRequestsRejected: false
            }
        case GET_TIME_REQUESTS_FULFILLED:
            return {
                ...state,
                timeRequestsLoading: false,
                timeRequestsSuccess: true,
                timeRequestsData: action.payload
            }
        case GET_TIME_REQUESTS_REJECTED:
            return {
                ...state,
                timeRequestsLoading: false,
                timeRequestsRejected: true
            }
        case POST_TIME_REQUEST:
            return {
                ...state,
                addTimeRequestLoading: true,
                addTimeRequestSuccess: false,
                addTimeRequestRejected: false
            }
        case POST_TIME_REQUEST_FULFILLED:
            return {
                ...state,
                addTimeRequestLoading: false,
                addTimeRequestSuccess: true,
                addTimeRequestData: action.payload
            }
        case POST_TIME_REQUEST_REJECTED:
            return {
                ...state,
                addTimeRequestLoading: false,
                addTimeRequestRejected: true
            }
        case POST_TIME_REQUEST_DEFAULT:
            return {
                ...state,
                addTimeRequestLoading: false,
                addTimeRequestSuccess: false,
                addTimeRequestRejected: false
            }
        case PUT_TIME_REQUEST:
            return {
                ...state,
                updateTimeRequestLoading: true,
                updateTimeRequestSuccess: false,
                updateTimeRequestRejected: false
            }
        case PUT_TIME_REQUEST_FULFILLED:
            return {
                ...state,
                updateTimeRequestLoading: false,
                updateTimeRequestSuccess: true,
                updateTimeRequestData: action.payload
            }

        case PUT_TIME_REQUEST_REJECTED:
            return {
                ...state,
                updateTimeRequestLoading: false,
                updateTimeRequestRejected: true
            }
        case PUT_TIME_REQUEST_DEFAULT:
            return {
                ...state,
                updateTimeRequestLoading: false,
                updateTimeRequestSuccess: false,
                updateTimeRequestRejected: false
            }

        case DELETE_TIME_REQUEST:
            return {
                ...state,
                deleteTimeRequestLoading: true,
                deleteTimeRequestSuccess: false,
                deleteTimeRequestRejected: false
            }
        case DELETE_TIME_REQUEST_FULFILLED:
            return {
                ...state,
                deleteTimeRequestLoading: false,
                deleteTimeRequestSuccess: true,
            }
        case DELETE_TIME_REQUEST_REJECTED:
            return {
                ...state,
                deleteTimeRequestLoading: false,
                deleteTimeRequestRejected: true
            }
        case DELETE_TIME_REQUEST_DEFAULT:
            return {
                ...state,
                deleteTimeRequestLoading: false,
                deleteTimeRequestSuccess: false,
                deleteTimeRequestRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
