import {
  GET_AUDITS,
  GET_AUDITS_FULFILLED,
  GET_AUDITS_REJECTED,
  PUT_AUDIT,
  PUT_AUDIT_FULFILLED,
  PUT_AUDIT_REJECTED,
  POST_AUDIT,
  POST_AUDIT_FULFILLED,
  POST_AUDIT_REJECTED,
  DELETE_AUDIT,
  DELETE_AUDIT_FULFILLED,
  DELETE_AUDIT_REJECTED,
  GET_AUDITS_DEFAULT,
  POST_AUDIT_DEFAULT,
  PUT_AUDIT_DEFAULT,
  DELETE_AUDIT_DEFAULT
} from '../actions'

export default function audit(state = {
  auditsLoading: false,
  auditsSuccess: false,
  auditsRejected: false,
  auditsData: {
    data: []
  },
  updateAuditLoading: false,
  updateAuditSuccess: false,
  updateAuditRejected: false,
  addAuditLoading: false,
  addAuditSuccess: false,
  addAuditRejected: false,
  deleteAuditLoading: false,
  deleteAuditSuccess: false,
  deleteAuditRejected: false
}, action) {
  switch (action.type) {
    case GET_AUDITS:
      return {
        ...state,
        auditsLoading: true,
        auditsSuccess: false,
        auditsRejected: false
      }
    case GET_AUDITS_FULFILLED:
      return {
        ...state,
        auditsLoading: false,
        auditsSuccess: true,
        auditsData: action.payload
      }
    case GET_AUDITS_REJECTED:
      return {
        ...state,
        auditsLoading: false,
        auditsRejected: true
      }
    case GET_AUDITS_DEFAULT:
      return {
        ...state,
        auditsLoading: false,
        auditsSuccess: false,
        auditsRejected: false
      }
    case POST_AUDIT:
      return {
        ...state,
        addAuditLoading: true,
        addAuditSuccess: false,
        addAuditRejected: false
      }
    case POST_AUDIT_FULFILLED:
      return {
        ...state,
        addAuditLoading: false,
        addAuditSuccess: true,
        addAuditData: action.payload
      }
    case POST_AUDIT_REJECTED:
      return {
        ...state,
        addAuditLoading: false,
        addAuditRejected: true
      }
    case POST_AUDIT_DEFAULT:
      return {
        ...state,
        addAuditLoading: false,
        addAuditSuccess: false,
        addAuditRejected: false
      }
    case PUT_AUDIT:
      return {
        ...state,
        updateAuditLoading: true,
        updateAuditSuccess: false,
        updateAuditRejected: false
      }
    case PUT_AUDIT_FULFILLED:
      return {
        ...state,
        updateAuditLoading: false,
        updateAuditSuccess: true,
        updateAuditData: action.payload
      }
    case PUT_AUDIT_REJECTED:
      return {
        ...state,
        updateAuditLoading: false,
        updateAuditRejected: true
      }
    case PUT_AUDIT_DEFAULT:
      return {
        ...state,
        updateAuditLoading: false,
        updateAuditSuccess: false,
        updateAuditRejected: false
      }
    case DELETE_AUDIT:
      return {
        ...state,
        deleteAuditLoading: true,
        deleteAuditSuccess: false,
        deleteAuditRejected: false
      }
    case DELETE_AUDIT_FULFILLED:
      return {
        ...state,
        deleteAuditLoading: false,
        deleteAuditSuccess: true,
      }
    case DELETE_AUDIT_REJECTED:
      return {
        ...state,
        deleteAuditLoading: false,
        deleteAuditRejected: true
      }
    case DELETE_AUDIT_DEFAULT:
      return {
        ...state,
        deleteAuditLoading: false,
        deleteAuditSuccess: false,
        deleteAuditRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
