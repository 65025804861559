import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_LEAVES = 'GET_LEAVES'
export const GET_LEAVES_FULFILLED = 'GET_LEAVES_FULFILLED'
export const GET_LEAVES_REJECTED = 'GET_LEAVES_REJECTED'
export const GET_LEAVES_DEFAULT = 'GET_LEAVES_DEFAULT'

export const GET_LEAVE = 'GET_LEAVE'
export const GET_LEAVE_FULFILLED = 'GET_LEAVE_FULFILLED'
export const GET_LEAVE_REJECTED = 'GET_LEAVE_REJECTED'
export const GET_LEAVE_DEFAULT = 'GET_LEAVE_DEFAULT'

export const POST_LEAVE = 'POST_LEAVE'
export const POST_LEAVE_FULFILLED = 'POST_LEAVE_FULFILLED'
export const POST_LEAVE_REJECTED = 'POST_LEAVE_REJECTED'
export const POST_LEAVE_DEFAULT = 'POST_LEAVE_DEFAULT'

export const PUT_LEAVE = 'PUT_LEAVE'
export const PUT_LEAVE_FULFILLED = 'PUT_LEAVE_FULFILLED'
export const PUT_LEAVE_REJECTED = 'PUT_LEAVE_REJECTED'
export const PUT_LEAVE_DEFAULT = 'PUT_LEAVE_DEFAULT'

export const DELETE_LEAVE = 'DELETE_LEAVE'
export const DELETE_LEAVE_FULFILLED = 'DELETE_LEAVE_FULFILLED'
export const DELETE_LEAVE_REJECTED = 'DELETE_LEAVE_REJECTED'
export const DELETE_LEAVE_DEFAULT = 'DELETE_LEAVE_DEFAULT'

export const GET_LEAVE_TYPES = 'GET_LEAVE_TYPES'
export const GET_LEAVE_TYPES_FULFILLED = 'GET_LEAVE_TYPES_FULFILLED'
export const GET_LEAVE_TYPES_REJECTED = 'GET_LEAVE_TYPES_REJECTED'
export const GET_LEAVE_TYPES_DEFAULT = 'GET_LEAVE_TYPES_DEFAULT'

export const GET_LEAVE_TYPE = 'GET_LEAVE_TYPE'
export const GET_LEAVE_TYPE_FULFILLED = 'GET_LEAVE_TYPE_FULFILLED'
export const GET_LEAVE_TYPE_REJECTED = 'GET_LEAVE_TYPE_REJECTED'
export const GET_LEAVE_TYPE_DEFAULT = 'GET_LEAVE_TYPE_DEFAULT'

export const POST_LEAVE_TYPE = 'POST_LEAVE_TYPE'
export const POST_LEAVE_TYPE_FULFILLED = 'POST_LEAVE_TYPE_FULFILLED'
export const POST_LEAVE_TYPE_REJECTED = 'POST_LEAVE_TYPE_REJECTED'
export const POST_LEAVE_TYPE_DEFAULT = 'POST_LEAVE_TYPE_DEFAULT'

export const PUT_LEAVE_TYPE = 'PUT_LEAVE_TYPE'
export const PUT_LEAVE_TYPE_FULFILLED = 'PUT_LEAVE_TYPE_FULFILLED'
export const PUT_LEAVE_TYPE_REJECTED = 'PUT_LEAVE_TYPE_REJECTED'
export const PUT_LEAVE_TYPE_DEFAULT = 'PUT_LEAVE_TYPE_DEFAULT'

export const DELETE_LEAVE_TYPE = 'DELETE_LEAVE_TYPE'
export const DELETE_LEAVE_TYPE_FULFILLED = 'DELETE_LEAVE_TYPE_FULFILLED'
export const DELETE_LEAVE_TYPE_REJECTED = 'DELETE_LEAVE_TYPE_REJECTED'
export const DELETE_LEAVE_TYPE_DEFAULT = 'DELETE_LEAVE_TYPE_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadLeaves = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_LEAVES,
      payload: {}
    })
    return Axios.get(`${API_URL}/leaves?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_LEAVES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_LEAVES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_LEAVES_DEFAULT }))
  }
}

export const loadLeave = (id) => {
  return dispatch => {
    dispatch({
      type: GET_LEAVE,
      payload: {}
    })
    return Axios.get(`${API_URL}/leave/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_LEAVE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_LEAVE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_LEAVE_DEFAULT }))
  }
}

export const submitLeave = (data) => {
  return dispatch => {
    dispatch({
      type: POST_LEAVE,
      payload: {}
    })
    return Axios.post(`${API_URL}/leave`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_LEAVE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_LEAVE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_LEAVE_DEFAULT }))
  }
}

export const updateLeave = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_LEAVE,
      payload: {}
    })
    return Axios.put(`${API_URL}/leave/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_LEAVE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_LEAVE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_LEAVE_DEFAULT }))
  }
}

export const deleteLeave = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_LEAVE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/leave/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_LEAVE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_LEAVE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_LEAVE_DEFAULT }))
  }
}





export const loadLeaveTypes = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_LEAVE_TYPES,
      payload: {}
    })
    return Axios.get(`${API_URL}/leave-types?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_LEAVE_TYPES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_LEAVE_TYPES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_LEAVE_TYPES_DEFAULT }))
  }
}

export const loadLeaveType = (id) => {
  return dispatch => {
    dispatch({
      type: GET_LEAVE_TYPE,
      payload: {}
    })
    return Axios.get(`${API_URL}/leave-type/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_LEAVE_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_LEAVE_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_LEAVE_TYPE_DEFAULT }))
  }
}

export const submitLeaveType = (data) => {
  return dispatch => {
    dispatch({
      type: POST_LEAVE_TYPE,
      payload: {}
    })
    return Axios.post(`${API_URL}/leave-type`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_LEAVE_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_LEAVE_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_LEAVE_TYPE_DEFAULT }))
  }
}

export const updateLeaveType = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_LEAVE_TYPE,
      payload: {}
    })
    return Axios.put(`${API_URL}/leave-type/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_LEAVE_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_LEAVE_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_LEAVE_TYPE_DEFAULT }))
  }
}

export const deleteLeaveType = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_LEAVE_TYPE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/leave-type/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_LEAVE_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_LEAVE_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_LEAVE_TYPE_DEFAULT }))
  }
}










