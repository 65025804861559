import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Menu, Dropdown, Popconfirm, Avatar, Modal, DatePicker, Button } from 'antd'
import { loadOvertimes, deleteOvertime } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import { serviceStatusTag } from '../../shared/components/serviceStatusTag'
import moment from 'moment';
import access from '../../../../utilities/access'
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        startDate: '',
        endDate: '',
        search: '',
    })

    useEffect(() => {
        const { onLoadOvertimes } = props
        onLoadOvertimes(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        setTableData([...props.overtimesData.data])
    }, [props.overtimesData])

    function handleDelete(id) {
        props.onDeleteOvertime(id)
    }

    function handleProcess(data) {
        const { onLoadOvertimes } = props
        confirm({
            title: 'Are you sure ?',
            icon: <ExclamationCircleOutlined />,
            content: 'You want to process overtimes data',
            onOk() {
                console.log("DATAAAAAAAAAAAAAAAAAA", pagination.startDate.toString())
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function handleChangeRangeDate(rangeDate) {
        setPagination(prevState => ({ ...prevState, startDate: rangeDate[0], endDate: rangeDate[1] }));
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{item && item.title}</span>)
            }
        },
        {
            title: 'Date Filed',
            dataIndex: 'dateFiled',
            key: 'dateFiled',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{moment(data.dateFiled).format('LLLL')}</span>)
            }
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item) => {
                return (<span>{serviceStatusTag(item)}</span>)
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                            }}>
                                View
                            </Menu.Item>
                            {
                                access.check('overtimes', 'delete') &&
                                <Menu.Item key="1" >
                                    <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                        Delete
                                    </Popconfirm>
                                </Menu.Item>
                            }

                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout className='page' >
            {
                access.check('overtimes', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <RangePicker onChange={(data) => handleChangeRangeDate(data)} style={{ height: 45 }} />
                            <Button onClick={() => handleProcess()} style={{ margin: 15, backgroundColor: '#3B7EC5', color: 'white', height: 45 }}>Process</Button>
                        </Col>
                        {
                            dataSource.length != 0 &&
                            <Col md={24} lg={24} xs={24} sm={24}>
                                <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={props.overtimesData.overtimesLoading} />
                                <Row style={{ marginTop: 30, width: '100%' }}>
                                    <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                        <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.overtimesData.total} />
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        overtimesData: state.admin.overtime.overtimesData,
        overtimesLoading: state.admin.overtime.overtimesLoading,
        updateOvertimeSuccess: state.admin.overtime.updateOvertimeSuccess,
        addOvertimeSuccess: state.admin.overtime.addOvertimeSuccess,
        deleteOvertimeSuccess: state.admin.overtime.deleteOvertimeSuccess,
        verifiedSuccess: state.admin.overtime.verifiedSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadOvertimes: data => dispatch(loadOvertimes(data)),
        onDeleteOvertime: id => dispatch(deleteOvertime(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)