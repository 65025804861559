import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_AUDITS = 'GET_AUDITS'
export const GET_AUDITS_FULFILLED = 'GET_AUDITS_FULFILLED'
export const GET_AUDITS_REJECTED = 'GET_AUDITS_REJECTED'
export const GET_AUDITS_DEFAULT = 'GET_AUDITS_DEFAULT'

export const GET_AUDIT = 'GET_AUDIT'
export const GET_AUDIT_FULFILLED = 'GET_AUDIT_FULFILLED'
export const GET_AUDIT_REJECTED = 'GET_AUDIT_REJECTED'
export const GET_AUDIT_DEFAULT = 'GET_AUDIT_DEFAULT'

export const POST_AUDIT = 'POST_AUDIT'
export const POST_AUDIT_FULFILLED = 'POST_AUDIT_FULFILLED'
export const POST_AUDIT_REJECTED = 'POST_AUDIT_REJECTED'
export const POST_AUDIT_DEFAULT = 'POST_AUDIT_DEFAULT'

export const PUT_AUDIT = 'PUT_AUDIT'
export const PUT_AUDIT_FULFILLED = 'PUT_AUDIT_FULFILLED'
export const PUT_AUDIT_REJECTED = 'PUT_AUDIT_REJECTED'
export const PUT_AUDIT_DEFAULT = 'PUT_AUDIT_DEFAULT'

export const DELETE_AUDIT = 'DELETE_AUDIT'
export const DELETE_AUDIT_FULFILLED = 'DELETE_AUDIT_FULFILLED'
export const DELETE_AUDIT_REJECTED = 'DELETE_AUDIT_REJECTED'
export const DELETE_AUDIT_DEFAULT = 'DELETE_AUDIT_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadAudits = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_AUDITS,
      payload: {}
    })
    return Axios.get(`${API_URL}/audits?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_AUDITS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_AUDITS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_AUDITS_DEFAULT }))
  }
}

export const loadAudit = (id) => {
  return dispatch => {
    dispatch({
      type: GET_AUDIT,
      payload: {}
    })
    return Axios.get(`${API_URL}/audit/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_AUDIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_AUDIT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_AUDIT_DEFAULT }))
  }
}

export const submitAudit = (data) => {
  return dispatch => {
    dispatch({
      type: POST_AUDIT,
      payload: {}
    })
    return Axios.post(`${API_URL}/audit`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_AUDIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_AUDIT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_AUDIT_DEFAULT }))
  }
}

export const updateAudit = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_AUDIT,
      payload: {}
    })
    return Axios.put(`${API_URL}/audit/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_AUDIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_AUDIT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_AUDIT_DEFAULT }))
  }
}

export const deleteAudit = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_AUDIT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/audit/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_AUDIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_AUDIT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_AUDIT_DEFAULT }))
  }
}











