import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_EMPLOYMENT_HISTORIES = 'GET_EMPLOYMENT_HISTORIES'
export const GET_EMPLOYMENT_HISTORIES_FULFILLED = 'GET_EMPLOYMENT_HISTORIES_FULFILLED'
export const GET_EMPLOYMENT_HISTORIES_REJECTED = 'GET_EMPLOYMENT_HISTORIES_REJECTED'

export const POST_EMPLOYMENT_HISTORY = 'POST_EMPLOYMENT_HISTORY'
export const POST_EMPLOYMENT_HISTORY_FULFILLED = 'POST_EMPLOYMENT_HISTORY_FULFILLED'
export const POST_EMPLOYMENT_HISTORY_REJECTED = 'POST_EMPLOYMENT_HISTORY_REJECTED'
export const POST_EMPLOYMENT_HISTORY_DEFAULT = 'POST_EMPLOYMENT_HISTORY_DEFAULT'

export const PUT_EMPLOYMENT_HISTORY = 'PUT_EMPLOYMENT_HISTORY'
export const PUT_EMPLOYMENT_HISTORY_FULFILLED = 'PUT_EMPLOYMENT_HISTORY_FULFILLED'
export const PUT_EMPLOYMENT_HISTORY_REJECTED = 'PUT_EMPLOYMENT_HISTORY_REJECTED'
export const PUT_EMPLOYMENT_HISTORY_DEFAULT = 'PUT_EMPLOYMENT_HISTORY_DEFAULT'

export const DELETE_EMPLOYMENT_HISTORY = 'DELETE_EMPLOYMENT_HISTORY'
export const DELETE_EMPLOYMENT_HISTORY_FULFILLED = 'DELETE_EMPLOYMENT_HISTORY_FULFILLED'
export const DELETE_EMPLOYMENT_HISTORY_REJECTED = 'DELETE_EMPLOYMENT_HISTORY_REJECTED'
export const DELETE_EMPLOYMENT_HISTORY_DEFAULT = 'DELETE_EMPLOYMENT_HISTORY_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadEmploymentHistories = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_EMPLOYMENT_HISTORIES,
            payload: {}
        })
        return Axios.get(`${API_URL}/employment-histories?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_EMPLOYMENT_HISTORIES_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_EMPLOYMENT_HISTORIES_REJECTED,
                payload: error
            })
        })
    }
}

export const submitEmploymentHistory = (data) => {
    return dispatch => {
        dispatch({
            type: POST_EMPLOYMENT_HISTORY,
            payload: {}
        })
        return Axios.post(`${API_URL}/employment-history`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_EMPLOYMENT_HISTORY_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_EMPLOYMENT_HISTORY_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_EMPLOYMENT_HISTORY_DEFAULT }))
    }
}

export const updateEmploymentHistory = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_EMPLOYMENT_HISTORY,
            payload: {}
        })
        return Axios.put(`${API_URL}/employment-history/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_EMPLOYMENT_HISTORY_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_EMPLOYMENT_HISTORY_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_EMPLOYMENT_HISTORY_DEFAULT }))
    }
}


export const deleteEmploymentHistory = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_EMPLOYMENT_HISTORY,
            payload: {}
        })
        return Axios.delete(`${API_URL}/employment-history/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_EMPLOYMENT_HISTORY_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_EMPLOYMENT_HISTORY_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_EMPLOYMENT_HISTORY_DEFAULT }))
    }
}
