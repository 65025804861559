import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, notification, DatePicker, Checkbox } from 'antd'
import { loadDocument, updateDocument, submitDocument, verifyDocument } from '../actions'
import { loadEmployees } from '../../employee/actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import VerifierCheck from '../../shared/components/verifierCheck'
import './index.scss'
import moment from 'moment'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { upload } from '../../../../utilities/filesController'
import { getUser } from '../../../../utilities/token'
import _ from 'lodash'
import access from '../../../../utilities/access'

const { Option } = Select
const FormDocument = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])
    // const [documentFrontFile, setDocumentFrontFile] = useState('')
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {
                    upload(item, item.name, item.uploadDir).then(data => { return resolve(true) }).catch(err => {
                        return notification.error({ message: `Failed to upload image`, description: err, });
                    })
                })
            } else { return resolve(true) }
        })
    }

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            expirationDate: props.selectedRow && moment(props.selectedRow.expirationDate),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            postById: getUser().id,
            ...value,
        }

        if (props.actionType === ADD) {
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onSubmitDocument({ images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        props.setActionType(NONE)
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    const documentId = props.selectedRow._id
                    props.onUpdateDocument({ id: documentId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        setLoading(false)
                        setResetVerifier(true)
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })
        }
    }
    useEffect(() => {
        props.onLoadEmployees({
            page: 1,
            limit: 50,
            id: userId,
        })
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    const isAccessDisabled = (!access.check('documents', 'edit') && (props.actionType === VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {
                ((props.actionType === VIEW) && access.check('documents', 'verify')) &&
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                        <VerifierCheck id={props.selectedRow && props.selectedRow._id} verifier={props.selectedRow && props.selectedRow.verifier} onSubmit={props.onVerifyDocument} reset={resetVerifier} />
                    </Col>
                </Row>
            }
            <Form
                name={`document`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="ID Number"
                            name="idNumber"
                            rules={[{ required: true, message: 'Please input ID number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please input type!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>


                        <Form.Item
                            name="isOriginalCopySubmitted"
                            valuePropName="checked"
                        >
                            <Checkbox disabled={isAccessDisabled}>Is Submitted Original Copy?</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Expiration Date"
                            name="expirationDate"
                            rules={[{ required: true, message: 'Please input expiration date!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        {
                            (props.actionType === VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('documents', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('documents', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('documents', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <CustomDropzoneMultiple
                            disabled={isAccessDisabled}
                            fileName='documents'
                            uploadDir='documents'
                            height={200}
                            width={200}
                            getFile={file => { getFileImage(file) }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <div className='d-flex'>
                            {
                                images.map((item, index) => {
                                    return (
                                        <div className='p2'>
                                            <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                            {
                                                ((access.check('documents', 'add') && (props.actionType === ADD)) || (access.check('documents', 'edit') && (props.actionType === VIEW))) &&
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
                {
                    ((access.check('documents', 'add') && (props.actionType === ADD)) || (access.check('documents', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateDocumentLoading || loading}   >
                                    Submit document
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateDocumentLoading: state.admin.document.updateDocumentLoading,
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadDocument: (id) => dispatch(loadDocument(id)),
        onVerifyDocument: (id) => dispatch(verifyDocument(id)),
        onUpdateDocument: (data) => dispatch(updateDocument(data)),
        onSubmitDocument: (data) => dispatch(submitDocument(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormDocument)