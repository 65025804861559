import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal, notification } from 'antd'
import { updateAccountability, submitAccountability } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { getUser } from '../../../../utilities/token'
import { upload } from '../../../../utilities/filesController'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import './index.scss'
import moment from 'moment'
import { loadProjects } from '../../../admin/projects/actions'
const { Option } = Select
const { RangePicker } = DatePicker;
const FormAccountability = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: props.selectedRow && moment(props.selectedRow.date) || new Date(),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)

        props.onLoadProjects({
            page: 1,
            limit: 100,
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            const userId = await getUser().id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onSubmitAccountability({ userId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        props.setActionType(NONE)
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const accountabilityId = props.selectedRow._id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onUpdateAccountability({ id: accountabilityId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {

                    upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    return (
        <Modal title={props.selectedRow && props.selectedRow.company || 'Add Accountability'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`accountability`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please input title!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Date"
                                name="date"
                                rules={[{ required: true, message: 'Please input date!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Quantity"
                                name="quantity"
                                rules={[{ required: true, message: 'Please input quantity!' }]}
                            >
                                <Input type='number' />
                            </Form.Item>
                            <Form.Item
                                label="Value"
                                name="value"
                                rules={[{ required: true, message: 'Please input value!' }]}
                            >
                                <Input type='number' />
                            </Form.Item>

                            <Form.Item
                                label="Projects"
                                name="projectId"
                                rules={[{ required: true, message: 'Please input project id!' }]}
                            >
                                <Select>
                                    {
                                        props.projectsData.data.map((data, index) => {
                                            return (
                                                <Option value={data._id}>{data.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <CustomDropzoneMultiple
                                fileName='accountabilities'
                                uploadDir='accountabilities'
                                height={200}
                                width={200}
                                getFile={file => { getFileImage(file) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className='d-flex'>
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div className='p2'>
                                                <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateAccountabilityLoading || loading}   >
                                    Submit accountability
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateAccountabilityLoading: state.client.accountability.updateAccountabilityLoading,
        employeesData: state.client.employee.employeesData,
        employeesLoading: state.client.employee.employeesLoading,
        projectsData: state.admin.project.projectsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAccountability: (data) => dispatch(updateAccountability(data)),
        onSubmitAccountability: (data) => dispatch(submitAccountability(data)),
        onLoadProjects: (data) => dispatch(loadProjects(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormAccountability)