import {
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FULFILLED,
  GET_ANNOUNCEMENTS_REJECTED,
  PUT_ANNOUNCEMENT,
  PUT_ANNOUNCEMENT_FULFILLED,
  PUT_ANNOUNCEMENT_REJECTED,
  POST_ANNOUNCEMENT,
  POST_ANNOUNCEMENT_FULFILLED,
  POST_ANNOUNCEMENT_REJECTED,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_FULFILLED,
  DELETE_ANNOUNCEMENT_REJECTED,
  GET_ANNOUNCEMENTS_DEFAULT,
  POST_ANNOUNCEMENT_DEFAULT,
  PUT_ANNOUNCEMENT_DEFAULT,
  DELETE_ANNOUNCEMENT_DEFAULT
} from '../actions'

export default function announcement(state = {
  announcementsLoading: false,
  announcementsSuccess: false,
  announcementsRejected: false,
  announcementsData: {
    data: []
  },
  updateAnnouncementLoading: false,
  updateAnnouncementSuccess: false,
  updateAnnouncementRejected: false,
  addAnnouncementLoading: false,
  addAnnouncementSuccess: false,
  addAnnouncementRejected: false,
  deleteAnnouncementLoading: false,
  deleteAnnouncementSuccess: false,
  deleteAnnouncementRejected: false
}, action) {
  switch (action.type) {
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcementsLoading: true,
        announcementsSuccess: false,
        announcementsRejected: false
      }
    case GET_ANNOUNCEMENTS_FULFILLED:
      return {
        ...state,
        announcementsLoading: false,
        announcementsSuccess: true,
        announcementsData: action.payload
      }
    case GET_ANNOUNCEMENTS_REJECTED:
      return {
        ...state,
        announcementsLoading: false,
        announcementsRejected: true
      }
    case GET_ANNOUNCEMENTS_DEFAULT:
      return {
        ...state,
        announcementsLoading: false,
        announcementsSuccess: false,
        announcementsRejected: false
      }
    case POST_ANNOUNCEMENT:
      return {
        ...state,
        addAnnouncementLoading: true,
        addAnnouncementSuccess: false,
        addAnnouncementRejected: false
      }
    case POST_ANNOUNCEMENT_FULFILLED:
      return {
        ...state,
        addAnnouncementLoading: false,
        addAnnouncementSuccess: true,
        addAnnouncementData: action.payload
      }
    case POST_ANNOUNCEMENT_REJECTED:
      return {
        ...state,
        addAnnouncementLoading: false,
        addAnnouncementRejected: true
      }
    case POST_ANNOUNCEMENT_DEFAULT:
      return {
        ...state,
        addAnnouncementLoading: false,
        addAnnouncementSuccess: false,
        addAnnouncementRejected: false
      }
    case PUT_ANNOUNCEMENT:
      return {
        ...state,
        updateAnnouncementLoading: true,
        updateAnnouncementSuccess: false,
        updateAnnouncementRejected: false
      }
    case PUT_ANNOUNCEMENT_FULFILLED:
      return {
        ...state,
        updateAnnouncementLoading: false,
        updateAnnouncementSuccess: true,
        updateAnnouncementData: action.payload
      }
    case PUT_ANNOUNCEMENT_REJECTED:
      return {
        ...state,
        updateAnnouncementLoading: false,
        updateAnnouncementRejected: true
      }
    case PUT_ANNOUNCEMENT_DEFAULT:
      return {
        ...state,
        updateAnnouncementLoading: false,
        updateAnnouncementSuccess: false,
        updateAnnouncementRejected: false
      }
    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        deleteAnnouncementLoading: true,
        deleteAnnouncementSuccess: false,
        deleteAnnouncementRejected: false
      }
    case DELETE_ANNOUNCEMENT_FULFILLED:
      return {
        ...state,
        deleteAnnouncementLoading: false,
        deleteAnnouncementSuccess: true,
      }
    case DELETE_ANNOUNCEMENT_REJECTED:
      return {
        ...state,
        deleteAnnouncementLoading: false,
        deleteAnnouncementRejected: true
      }
    case DELETE_ANNOUNCEMENT_DEFAULT:
      return {
        ...state,
        deleteAnnouncementLoading: false,
        deleteAnnouncementSuccess: false,
        deleteAnnouncementRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
