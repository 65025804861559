import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Avatar, Tooltip, Row, Col, notification, List } from 'antd'
import { connect } from 'react-redux'
import { loadOvertimes, deleteOvertime } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'
import RemarksRenderer from '../../../../utilities/remarksRenderer'

const OvertimesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updateOvertimeSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the overtime information.',
            });
        }
        if (props.addOvertimeSuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new overtime information.',
            });
        }
        if (props.deleteOvertimeSuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted overtime data.',
            });
        }

        props.onLoadOvertimes(pagination)
    }, [props.updateOvertimeSuccess, props.addOvertimeSuccess, props.deleteOvertimeSuccess])

    function handleDelete(id) {
        props.onDeleteOvertime(id)
    }

    function getAge(item) {
        var a = moment();
        var b = moment(item, 'YYYY');
        var age = a.diff(b, 'years');
        return age
    }

    return (
        <Layout className='overtimes-page'>
            <Card>
                <button className='add-item' onClick={() => {
                    setModalShow(true)
                    setActionType(ADD)
                    setSelectedRow({})
                }}>
                    <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer', fontSize: 20 }} icon={faPlusCircle} />
                    <span>Add Overtime</span>
                </button>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Time
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Reason
                            </Col>
                            <Col md={5} lg={5} xs={24} sm={24}>
                                Project
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={props.overtimesData.data}
                    renderItem={data => <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }}>
                                <Col md={24} lg={24} xs={24} sm={24}>
                                    <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                </Col>
                            </Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item' >
                                    <h1>{moment(data.dateFiled).format('LLL')} <StatusIconDisplay status={data.status} /></h1>
                                    <p className='time'>Time In: {moment(data.timeIn).format('LLLL')}</p>
                                    <p className='time'>Time Out: {moment(data.timeOut).format('LLLL')}</p>

                                </div>
                            </Col>

                            <Col md={9} lg={9} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p className='reason'>{data.reason}</p>
                                </div>
                            </Col>

                            <Col md={5} lg={5} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p className='project'>{data.project && data.project.title}</p>
                                </div>
                            </Col>

                            <Col md={4} lg={4} xs={24} sm={24}>
                                <div className='actions'>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            setModalShow(true)
                                            setActionType(VIEW)
                                            setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>

                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        overtimesData: state.client.overtime.overtimesData,
        addOvertimeSuccess: state.client.overtime.addOvertimeSuccess,
        updateOvertimeSuccess: state.client.overtime.updateOvertimeSuccess,
        deleteOvertimeSuccess: state.client.overtime.deleteOvertimeSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadOvertimes: data => dispatch(loadOvertimes(data)),
        onDeleteOvertime: id => dispatch(deleteOvertime(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OvertimesComponent)
