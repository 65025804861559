import {
    GET_REFERENCES,
    GET_REFERENCES_FULFILLED,
    GET_REFERENCES_REJECTED,
    PUT_REFERENCE,
    PUT_REFERENCE_FULFILLED,
    PUT_REFERENCE_REJECTED,
    POST_REFERENCE,
    POST_REFERENCE_FULFILLED,
    POST_REFERENCE_REJECTED,
    DELETE_REFERENCE,
    DELETE_REFERENCE_FULFILLED,
    DELETE_REFERENCE_REJECTED,
    DELETE_REFERENCE_DEFAULT,
    PUT_REFERENCE_DEFAULT,
    POST_REFERENCE_DEFAULT
} from '../actions'

export default function reference(state = {
    referencesLoading: false,
    referencesSuccess: false,
    referencesRejected: false,
    referencesData: {
        data: []
    },
    verifiedSuccess: false,
    updateReferenceLoading: false,
    updateReferenceSuccess: false,
    updateReferenceRejected: false,
    addReferenceLoading: false,
    addReferenceSuccess: false,
    addReferenceRejected: false,
    deleteReferenceLoading: false,
    deleteReferenceSuccess: false,
    deleteReferenceRejected: false
}, action) {
    switch (action.type) {
        case GET_REFERENCES:
            return {
                ...state,
                referencesLoading: true,
                referencesSuccess: false,
                referencesRejected: false
            }
        case GET_REFERENCES_FULFILLED:
            return {
                ...state,
                referencesLoading: false,
                referencesSuccess: true,
                referencesData: action.payload
            }
        case GET_REFERENCES_REJECTED:
            return {
                ...state,
                referencesLoading: false,
                referencesRejected: true
            }
        case POST_REFERENCE:
            return {
                ...state,
                addReferenceLoading: true,
                addReferenceSuccess: false,
                addReferenceRejected: false
            }
        case POST_REFERENCE_FULFILLED:
            return {
                ...state,
                addReferenceLoading: false,
                addReferenceSuccess: true,
                addReferenceData: action.payload
            }
        case POST_REFERENCE_REJECTED:
            return {
                ...state,
                addReferenceLoading: false,
                addReferenceRejected: true
            }
        case POST_REFERENCE_DEFAULT:
            return {
                ...state,
                addReferenceLoading: false,
                addReferenceSuccess: false,
                addReferenceRejected: false
            }
        case PUT_REFERENCE:
            return {
                ...state,
                updateReferenceLoading: true,
                updateReferenceSuccess: false,
                updateReferenceRejected: false
            }
        case PUT_REFERENCE_FULFILLED:
            return {
                ...state,
                updateReferenceLoading: false,
                updateReferenceSuccess: true,
                updateReferenceData: action.payload
            }

        case PUT_REFERENCE_REJECTED:
            return {
                ...state,
                updateReferenceLoading: false,
                updateReferenceRejected: true
            }
        case PUT_REFERENCE_DEFAULT:
            return {
                ...state,
                updateReferenceLoading: false,
                updateReferenceSuccess: false,
                updateReferenceRejected: false
            }

        case DELETE_REFERENCE:
            return {
                ...state,
                deleteReferenceLoading: true,
                deleteReferenceSuccess: false,
                deleteReferenceRejected: false
            }
        case DELETE_REFERENCE_FULFILLED:
            return {
                ...state,
                deleteReferenceLoading: false,
                deleteReferenceSuccess: true,
            }
        case DELETE_REFERENCE_REJECTED:
            return {
                ...state,
                deleteReferenceLoading: false,
                deleteReferenceRejected: true
            }
        case DELETE_REFERENCE_DEFAULT:
            return {
                ...state,
                deleteReferenceLoading: false,
                deleteReferenceSuccess: false,
                deleteReferenceRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
