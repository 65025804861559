import {
    GET_ANNOUNCEMENTS,
    GET_ANNOUNCEMENTS_FULFILLED,
    GET_ANNOUNCEMENTS_REJECTED
} from '../actions'

export default function announcement(state = {
    announcementsLoading: false,
    announcementsSuccess: false,
    announcementsRejected: false,
    announcementsData: {
        data: []
    }
}, action) {
    switch (action.type) {
        case GET_ANNOUNCEMENTS:
            return {
                ...state,
                announcementsLoading: true,
                announcementsSuccess: false,
                announcementsRejected: false
            }
        case GET_ANNOUNCEMENTS_FULFILLED:
            return {
                ...state,
                announcementsLoading: false,
                announcementsSuccess: true,
                announcementsData: action.payload
            }
        case GET_ANNOUNCEMENTS_REJECTED:
            return {
                ...state,
                announcementsLoading: false,
                announcementsRejected: true
            }
        default:
            return {
                ...state
            }
    }
}
