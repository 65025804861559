import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_DOCUMENTS_FULFILLED = 'GET_DOCUMENTS_FULFILLED'
export const GET_DOCUMENTS_REJECTED = 'GET_DOCUMENTS_REJECTED'

export const POST_DOCUMENT = 'POST_DOCUMENT'
export const POST_DOCUMENT_FULFILLED = 'POST_DOCUMENT_FULFILLED'
export const POST_DOCUMENT_REJECTED = 'POST_DOCUMENT_REJECTED'
export const POST_DOCUMENT_DEFAULT = 'POST_DOCUMENT_DEFAULT'

export const PUT_DOCUMENT = 'PUT_DOCUMENT'
export const PUT_DOCUMENT_FULFILLED = 'PUT_DOCUMENT_FULFILLED'
export const PUT_DOCUMENT_REJECTED = 'PUT_DOCUMENT_REJECTED'
export const PUT_DOCUMENT_DEFAULT = 'PUT_DOCUMENT_DEFAULT'

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const DELETE_DOCUMENT_FULFILLED = 'DELETE_DOCUMENT_FULFILLED'
export const DELETE_DOCUMENT_REJECTED = 'DELETE_DOCUMENT_REJECTED'
export const DELETE_DOCUMENT_DEFAULT = 'DELETE_DOCUMENT_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadDocuments = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_DOCUMENTS,
            payload: {}
        })
        return Axios.get(`${API_URL}/documents?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_DOCUMENTS_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_DOCUMENTS_REJECTED,
                payload: error
            })
        })
    }
}

export const submitDocument = (data) => {
    return dispatch => {
        dispatch({
            type: POST_DOCUMENT,
            payload: {}
        })
        return Axios.post(`${API_URL}/document`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_DOCUMENT_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_DOCUMENT_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_DOCUMENT_DEFAULT }))
    }
}

export const updateDocument = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_DOCUMENT,
            payload: {}
        })
        return Axios.put(`${API_URL}/document/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_DOCUMENT_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_DOCUMENT_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_DOCUMENT_DEFAULT }))
    }
}


export const deleteDocument = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_DOCUMENT,
            payload: {}
        })
        return Axios.delete(`${API_URL}/document/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_DOCUMENT_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_DOCUMENT_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_DOCUMENT_DEFAULT }))
    }
}
