import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal, notification } from 'antd'
import { updateMedicalHistory, submitMedicalHistory } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { getUser } from '../../../../utilities/token'
import { upload } from '../../../../utilities/filesController'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import enumMedicalHistoryType from '../../../../utilities/enumMedicalHistoryType'
import './index.scss'
import moment from 'moment'
const { Option } = Select
const { RangePicker } = DatePicker;
const FormMedicalHistory = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: props.selectedRow && moment(props.selectedRow.date) || new Date(),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)
        // eslint-disable-next-line
    }, [props.selectedRow])

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            const userId = await getUser().id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onSubmitMedicalHistory({ userId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        props.setActionType(NONE)
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const medicalHistoryId = props.selectedRow._id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onUpdateMedicalHistory({ id: medicalHistoryId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {

                    upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    return (
        <Modal title={props.selectedRow && props.selectedRow.company || 'Add Medical History'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`medicalHistory-${props.selectedId}`}
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[{ required: true, message: 'Please input type!' }]}
                            >
                                <Select  >
                                    {
                                        Object.keys(enumMedicalHistoryType).map(function (key, index) {
                                            return (
                                                <Option value={enumMedicalHistoryType[key]} key={index}>{key}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="date"
                                name="date"
                                rules={[{ required: true, message: 'Please input date!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <CustomDropzoneMultiple
                                fileName='medical'
                                uploadDir='medical'
                                height={200}
                                width={200}
                                getFile={file => { getFileImage(file) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className='d-flex'>
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div className='p2'>
                                                <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateMedicalHistoryLoading || loading}   >
                                    Submit medical history
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateMedicalHistoryLoading: state.client.medicalHistory.updateMedicalHistoryLoading,
        employeesData: state.client.employee.employeesData,
        employeesLoading: state.client.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateMedicalHistory: (data) => dispatch(updateMedicalHistory(data)),
        onSubmitMedicalHistory: (data) => dispatch(submitMedicalHistory(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormMedicalHistory)