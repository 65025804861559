import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Checkbox, Alert, notification, Card, Space, Divider } from 'antd'
import { loadIncentive, updateIncentive, submitIncentive, POST_INCENTIVE_FULFILLED } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import './index.scss'
import moment from 'moment'
import { loadProjects } from '../../../admin/projects/actions'
import { loadEmployees, loadEmployee } from '../../../admin/employee/actions'
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select

const FormIncentive = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const [userDetails, setUserDetails] = useState({})
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id


    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            effectiveDate: props.selectedRow && moment(props.selectedRow.effectiveDate),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    function handleSubmit(value) {
        setLoading(true)
        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            props.onSubmitIncentive({  ...submitData }).then(res => {
                if (res.type === POST_INCENTIVE_FULFILLED) {
                    props.setActionType(NONE)
                    form.resetFields()
                }
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const incentiveId = props.selectedRow._id
            props.onUpdateIncentive({ id: incentiveId, ...submitData }).then(res => {
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        props.onLoadEmployees({
            page: 1,
            limit: 50,
            id: userId,
        })

        if (props.actionType === VIEW) {
            props.onLoadEmployee(userId).then(res => {
                setUserDetails(res.payload)
            })
        }
    }, [])

    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`incentive-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="No. of Hours"
                            name="noOfHours"
                            rules={[{ required: true, message: 'Please input no. of hours!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Meal Allowance"
                            name="mealAllowance"
                            rules={[{ required: true, message: 'Please input meal allowance!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Transportation"
                            name="transportation"
                            rules={[{ required: true, message: 'Please input transportation!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Effectivity Date"
                            name="effectiveDate"
                            rules={[{ required: true, message: 'Please input effectivity date!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' type="primary" htmlType="submit"
                                loading={props.updateIncentiveLoading || loading}   >
                                Submit Incentive
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateIncentiveLoading: state.adminPms.incentive.updateIncentiveLoading,
        incentiveTypesData: state.adminPms.incentive.incentiveTypesData,
        employeesData: state.admin.employee.employeesData,
        projectsData: state.admin.project.projectsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadIncentive: (id) => dispatch(loadIncentive(id)),
        onUpdateIncentive: (data) => dispatch(updateIncentive(data)),
        onSubmitIncentive: (data) => dispatch(submitIncentive(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
        onLoadProjects: (data) => dispatch(loadProjects(data)),
        onLoadEmployee: (id) => dispatch(loadEmployee(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormIncentive)