import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import deduction from '../deductions/reducers'
import incentive from '../incentives/reducers'
import thirteenMonthPay from '../13MonthPay/reducers'

const rootReducer = combineReducers({
  form,
  deduction,
  incentive,
  thirteenMonthPay
})

export default rootReducer
