import React from 'react'
import { Tag } from 'antd'
export const serviceStatusTag = (status) => {
    switch (status) {
        case 1:
            return <Tag color="gold">FOR APPROVAL</Tag>
        case 2:
            return <Tag color="lime">APPROVED</Tag>
        case 3:
            return <Tag color="geekblue">ONGOING</Tag>
        case 4:
            return <Tag color="green">COMPLETED</Tag>
        case 5:
            return <Tag color="magenta">CANCELLED</Tag>
        case 6:
            return <Tag color="red">REJECTED</Tag>
        default:
            break;
    }
}
