import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, notification } from 'antd'
import { changePasswordAdmin, CHANGE_PASSWORD_ADMIN_FULFILLED } from '../actions'
import './index.scss'
import moment from 'moment'
import _ from 'lodash'
import { getUser } from '../../../../utilities/token'

const { Option } = Select
const ChangePassword = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            expirationDate: props.selectedRow && moment(props.selectedRow.expirationDate),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        const userId = await getUser().id
        const submitData = {
            userId: userId,
            ...value,
        }
        setLoading(true)
        props.onChangePasswordAdmin(submitData).then(res => {
            if (res.type === CHANGE_PASSWORD_ADMIN_FULFILLED) {
                form.resetFields()
                notification.success({
                    message: `Change Password Successfully`,
                    description: 'You have successfully changed the password.',
                });
            }
            setLoading(false)
        })
    }

    console.log("propssss", props)

    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`changePassword`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            name="oldPassword"
                            label="Old Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your old password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            label="New Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your new password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Confirm New Password"
                            dependencies={['newPassword']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your re-type password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' type="primary" htmlType="submit" loading={loading}  >
                                Change Password
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onChangePasswordAdmin: (data) => dispatch(changePasswordAdmin(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)