import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm, Avatar } from 'antd'
import { loadLeaves, deleteLeave } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import { serviceStatusTag } from '../../shared/components/serviceStatusTag'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import Toolbar from '../../shared/components/toolbar'
import access from '../../../../utilities/access'
import { getUser } from '../../../../utilities/token'
import Papa from 'papaparse'

const List = (props) => {
    let [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        async function initiateSocket() {
            const id = await getUser().id
            props.socket.on(`leaves.${id}`, data => {
                const index = tableData.findIndex(item => item._id === data._id)
                if (data.status === 0) tableData.splice(index, 1) // delete
                else {
                    if (index !== -1) tableData[index] = data // update
                    else {
                        tableData.unshift({ ...data }) // create
                        tableData = tableData.slice(0, pagination.limit) // re-assigning and limit
                    }
                }
                setTableData([...tableData])
            });
        }
        initiateSocket()
    }, [tableData])

    useEffect(() => {
        const { onLoadLeaves } = props
        onLoadLeaves(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }
    useEffect(() => {
        const { onLoadLeaves } = props
        onLoadLeaves(pagination)
        // eslint-disable-next-line
    }, [props.updateLeaveSuccess, props.deleteLeaveSuccess, props.addLeaveSuccess])

    useEffect(() => {
        setTableData([...props.leavesData.data])
    }, [props.leavesData])

    function handleDelete(id) {
        props.onDeleteLeave(id)
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.user && data.user.firstName} ${data.user && data.user.lastName}`,
                "Type": data.type && data.type.title,
                "Date From": moment(data.dateFrom).format('MMMM DD, YYYY , dddd'),
                "Date To": moment(data.dateTo).format('MMMM DD, YYYY , dddd'),
                "Title": data.title,
                "Description": data.title,
                "Reason": data.reason,
                "Remarks": data.remarks,
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `leaves_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `leaves_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Reason For Taking Leave',
            dataIndex: 'reason',
            key: 'reason'
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{item && item.title}</span>)
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{item && item.title}</span>)
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item) => {
                return (<span>{serviceStatusTag(item)}</span>)
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LLLL'))
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                            }}>
                                View
                            </Menu.Item>
                            {
                                access.check('leaveApplications', 'delete') &&
                                <Menu.Item key="1" >
                                    <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                        Delete
                                    </Popconfirm>
                                </Menu.Item>
                            }

                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout className='page' >
            {
                access.check('leaveApplications', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Leave Applications'
                                handleAdd={() => props.setActionType(ADD)}
                                removeAdd={!access.check('leaveApplications', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.leavesData.leavesLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.leavesData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        leavesData: state.admin.leave.leavesData,
        leavesLoading: state.admin.leave.leavesLoading,
        updateLeaveSuccess: state.admin.leave.updateLeaveSuccess,
        addLeaveSuccess: state.admin.leave.addLeaveSuccess,
        deleteLeaveSuccess: state.admin.leave.deleteLeaveSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadLeaves: data => dispatch(loadLeaves(data)),
        onDeleteLeave: id => dispatch(deleteLeave(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)