import {
  GET_FILE_REPORTS,
  GET_FILE_REPORTS_FULFILLED,
  GET_FILE_REPORTS_REJECTED,
  PUT_FILE_REPORT,
  PUT_FILE_REPORT_FULFILLED,
  PUT_FILE_REPORT_REJECTED,
  POST_FILE_REPORT,
  POST_FILE_REPORT_FULFILLED,
  POST_FILE_REPORT_REJECTED,
  DELETE_FILE_REPORT,
  DELETE_FILE_REPORT_FULFILLED,
  DELETE_FILE_REPORT_REJECTED,
  GET_FILE_REPORTS_DEFAULT,
  POST_FILE_REPORT_DEFAULT,
  PUT_FILE_REPORT_DEFAULT,
  DELETE_FILE_REPORT_DEFAULT,
  VERIFY,
  VERIFY_FULFILLED,
  VERIFY_REJECTED,
  VERIFY_DEFAULT
} from '../actions'

export default function fileReport(state = {
  fileReportsLoading: false,
  fileReportsSuccess: false,
  fileReportsRejected: false,
  fileReportsData: {
    data: []
  },
  verifiedSuccess: false,
  updateFileReportLoading: false,
  updateFileReportSuccess: false,
  updateFileReportRejected: false,
  addFileReportLoading: false,
  addFileReportSuccess: false,
  addFileReportRejected: false,
  deleteFileReportLoading: false,
  deleteFileReportSuccess: false,
  deleteFileReportRejected: false
}, action) {
  switch (action.type) {
    case GET_FILE_REPORTS:
      return {
        ...state,
        fileReportsLoading: true,
        fileReportsSuccess: false,
        fileReportsRejected: false
      }
    case GET_FILE_REPORTS_FULFILLED:
      return {
        ...state,
        fileReportsLoading: false,
        fileReportsSuccess: true,
        fileReportsData: action.payload
      }
    case GET_FILE_REPORTS_REJECTED:
      return {
        ...state,
        fileReportsLoading: false,
        fileReportsRejected: true
      }
    case GET_FILE_REPORTS_DEFAULT:
      return {
        ...state,
        fileReportsLoading: false,
        fileReportsSuccess: false,
        fileReportsRejected: false
      }
    case POST_FILE_REPORT:
      return {
        ...state,
        addFileReportLoading: true,
        addFileReportSuccess: false,
        addFileReportRejected: false
      }
    case POST_FILE_REPORT_FULFILLED:
      return {
        ...state,
        addFileReportLoading: false,
        addFileReportSuccess: true,
        addFileReportData: action.payload
      }
    case POST_FILE_REPORT_REJECTED:
      return {
        ...state,
        addFileReportLoading: false,
        addFileReportRejected: true
      }
    case POST_FILE_REPORT_DEFAULT:
      return {
        ...state,
        addFileReportLoading: false,
        addFileReportSuccess: false,
        addFileReportRejected: false
      }
    case PUT_FILE_REPORT:
      return {
        ...state,
        updateFileReportLoading: true,
        updateFileReportSuccess: false,
        updateFileReportRejected: false
      }
    case PUT_FILE_REPORT_FULFILLED:
      return {
        ...state,
        updateFileReportLoading: false,
        updateFileReportSuccess: true,
        updateFileReportData: action.payload
      }
    case PUT_FILE_REPORT_REJECTED:
      return {
        ...state,
        updateFileReportLoading: false,
        updateFileReportRejected: true
      }
    case PUT_FILE_REPORT_DEFAULT:
      return {
        ...state,
        updateFileReportLoading: false,
        updateFileReportSuccess: false,
        updateFileReportRejected: false
      }
    case DELETE_FILE_REPORT:
      return {
        ...state,
        deleteFileReportLoading: true,
        deleteFileReportSuccess: false,
        deleteFileReportRejected: false
      }
    case DELETE_FILE_REPORT_FULFILLED:
      return {
        ...state,
        deleteFileReportLoading: false,
        deleteFileReportSuccess: true,
      }
    case DELETE_FILE_REPORT_REJECTED:
      return {
        ...state,
        deleteFileReportLoading: false,
        deleteFileReportRejected: true
      }
    case DELETE_FILE_REPORT_DEFAULT:
      return {
        ...state,
        deleteFileReportLoading: false,
        deleteFileReportSuccess: false,
        deleteFileReportRejected: false
      }
    case VERIFY:
      return {
        ...state,
        verifiedSuccess: false,
      }
    case VERIFY_FULFILLED:
      return {
        ...state,
        verifiedSuccess: true,
      }
    case VERIFY_REJECTED:
      return {
        ...state,
        verifiedSuccess: false,
      }
    default:
      return {
        ...state
      }
  }
}
