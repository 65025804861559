import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faMinusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'antd'

const StatusIconDisplay = (props) => {
    switch (props.status) {
        case 1:
            return <Tooltip placement="top" title='For Approval'>
                <FontAwesomeIcon style={{ color: 'gold', cursor: 'pointer' }} icon={faMinusCircle} />
            </Tooltip>
        case 2:
            return <Tooltip placement="top" title='Approved'>
                <FontAwesomeIcon style={{ color: 'green', cursor: 'pointer' }} icon={faCheckCircle} />
            </Tooltip>
        case 6:
            return <Tooltip placement="top" title='Rejected'>
                <FontAwesomeIcon style={{ color: 'red', cursor: 'pointer' }} icon={faTimesCircle} />
            </Tooltip>
        case 'autoApproved':
            return <Tooltip placement="top" title='Auto Approved'>
                <FontAwesomeIcon style={{ color: 'green', cursor: 'pointer' }} icon={faCheckCircle} />
            </Tooltip>
        default:
            return null;
    }
}

export default StatusIconDisplay