import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import login from '../login/reducers'
import announcement from '../announcements/reducers'
import education from '../educations/reducers'
import dependent from '../dependents/reducers'
import document from '../documents/reducers'
import personalMovement from '../personalMovements/reducers'
import fileReport from '../fileReports/reducers'
import timeRequest from '../timeRequests/reducers'
import disciplinaryAction from '../disciplinaryActions/reducers'
import employmentHistory from '../employmentHistories/reducers'
import reference from '../references/reducers'
import performance from '../performances/reducers'
import accountability from '../accountabilities/reducers'
import medicalHistory from '../medicalHistories/reducers'
import leave from '../leaves/reducers'
import employee from '../employee/reducers'
import salaries from '../salaries/reducers'
import biometric from '../biometrics/reducers'
import timelog from '../timelogs/reducers'
import project from '../projects/reducers'
import audit from '../audits/reducers'
import position from '../positions/reducers'
import memorandum from '../memorandums/reducers'
import businessTrip from '../businessTrips/reducers'
import job from '../jobs/reducers'
import overtime from '../overtimes/reducers'
import administrator from '../admins/reducers'
import allowanceAllotment from '../allowanceHistories/reducers'

const rootReducer = combineReducers({
  form,
  login,
  announcement,
  memorandum,
  leave,
  employee,
  salaries,
  biometric,
  timelog,
  project,
  position,
  job,
  medicalHistory,
  allowanceAllotment,
  education,
  dependent,
  accountability,
  reference,
  performance,
  employmentHistory,
  fileReport,
  disciplinaryAction,
  personalMovement,
  document,
  businessTrip,
  overtime,
  audit,
  administrator,
  timeRequest
})

export default rootReducer
