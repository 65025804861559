import {
    GET_ALLOWANCES,
    GET_ALLOWANCES_FULFILLED,
    GET_ALLOWANCES_REJECTED,
    PUT_ALLOWANCE,
    PUT_ALLOWANCE_FULFILLED,
    PUT_ALLOWANCE_REJECTED,
    POST_ALLOWANCE,
    POST_ALLOWANCE_FULFILLED,
    POST_ALLOWANCE_REJECTED,
    DELETE_ALLOWANCE,
    DELETE_ALLOWANCE_FULFILLED,
    DELETE_ALLOWANCE_REJECTED,
    DELETE_ALLOWANCE_DEFAULT,
    PUT_ALLOWANCE_DEFAULT,
    POST_ALLOWANCE_DEFAULT,
    GET_ALLOWANCE_HISTORY_TYPES,
    GET_ALLOWANCE_HISTORY_TYPES_FULFILLED,
    GET_ALLOWANCE_HISTORY_TYPES_REJECTED,
    GET_ALLOWANCE_HISTORY_TYPES_DEFAULT,
} from '../actions'

export default function allowance(state = {
    allowancesLoading: false,
    allowancesSuccess: false,
    allowancesRejected: false,
    allowancesData: {
        data: []
    },
    allowanceHistoryTypesData: {
        data: []
    },
    verifiedSuccess: false,
    updateAllowanceLoading: false,
    updateAllowanceSuccess: false,
    updateAllowanceRejected: false,
    addAllowanceLoading: false,
    addAllowanceSuccess: false,
    addAllowanceRejected: false,
    deleteAllowanceLoading: false,
    deleteAllowanceSuccess: false,
    deleteAllowanceRejected: false
}, action) {
    switch (action.type) {
        case GET_ALLOWANCES:
            return {
                ...state,
                allowancesLoading: true,
                allowancesSuccess: false,
                allowancesRejected: false
            }
        case GET_ALLOWANCES_FULFILLED:
            return {
                ...state,
                allowancesLoading: false,
                allowancesSuccess: true,
                allowancesData: action.payload
            }
        case GET_ALLOWANCES_REJECTED:
            return {
                ...state,
                allowancesLoading: false,
                allowancesRejected: true
            }
        case POST_ALLOWANCE:
            return {
                ...state,
                addAllowanceLoading: true,
                addAllowanceSuccess: false,
                addAllowanceRejected: false
            }
        case POST_ALLOWANCE_FULFILLED:
            return {
                ...state,
                addAllowanceLoading: false,
                addAllowanceSuccess: true,
                addAllowanceData: action.payload
            }
        case POST_ALLOWANCE_REJECTED:
            return {
                ...state,
                addAllowanceLoading: false,
                addAllowanceRejected: true
            }
        case POST_ALLOWANCE_DEFAULT:
            return {
                ...state,
                addAllowanceLoading: false,
                addAllowanceSuccess: false,
                addAllowanceRejected: false
            }
        case PUT_ALLOWANCE:
            return {
                ...state,
                updateAllowanceLoading: true,
                updateAllowanceSuccess: false,
                updateAllowanceRejected: false
            }
        case PUT_ALLOWANCE_FULFILLED:
            return {
                ...state,
                updateAllowanceLoading: false,
                updateAllowanceSuccess: true,
                updateAllowanceData: action.payload
            }

        case PUT_ALLOWANCE_REJECTED:
            return {
                ...state,
                updateAllowanceLoading: false,
                updateAllowanceRejected: true
            }
        case PUT_ALLOWANCE_DEFAULT:
            return {
                ...state,
                updateAllowanceLoading: false,
                updateAllowanceSuccess: false,
                updateAllowanceRejected: false
            }

        case DELETE_ALLOWANCE:
            return {
                ...state,
                deleteAllowanceLoading: true,
                deleteAllowanceSuccess: false,
                deleteAllowanceRejected: false
            }
        case DELETE_ALLOWANCE_FULFILLED:
            return {
                ...state,
                deleteAllowanceLoading: false,
                deleteAllowanceSuccess: true,
            }
        case DELETE_ALLOWANCE_REJECTED:
            return {
                ...state,
                deleteAllowanceLoading: false,
                deleteAllowanceRejected: true
            }
        case DELETE_ALLOWANCE_DEFAULT:
            return {
                ...state,
                deleteAllowanceLoading: false,
                deleteAllowanceSuccess: false,
                deleteAllowanceRejected: false
            }
        case GET_ALLOWANCE_HISTORY_TYPES:
            return {
                ...state,
                allowanceHistoryTypesLoading: true,
                allowanceHistoryTypesSuccess: false,
                allowanceHistoryTypesRejected: false
            }
        case GET_ALLOWANCE_HISTORY_TYPES_FULFILLED:
            return {
                ...state,
                allowanceHistoryTypesLoading: false,
                allowanceHistoryTypesSuccess: true,
                allowanceHistoryTypesData: action.payload
            }
        case GET_ALLOWANCE_HISTORY_TYPES_REJECTED:
            return {
                ...state,
                allowanceHistoryTypesLoading: false,
                allowanceHistoryTypesRejected: true
            }
        case GET_ALLOWANCE_HISTORY_TYPES_DEFAULT:
            return {
                ...state,
                allowanceHistoryTypesLoading: false,
                allowanceHistoryTypesSuccess: false,
                allowanceHistoryTypesRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
