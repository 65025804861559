import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Client from './client'
import Admin from './admin'
import Payroll from './pms'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { io } from "socket.io-client";
import { getToken } from '../utilities/token';
const SERVER = process.env.REACT_APP_WEBSOCKET_URL;
class App extends Component {
  constructor() {
    super()
    this.state = {
      componentType: undefined,
    }
  }

  componentWillMount() {
    const { location: { pathname } } = this.props
    console.log("pathname", pathname, this.props)
    if (pathname.includes('/pms')) {
      this.setState({ componentType: 'payroll' })
    }
    else if (pathname.includes('/admin')) {
      this.setState({ componentType: 'admin' })
    } else {
      this.setState({ componentType: 'client' })
    }

    // Initialized Socket IO
    this.socket = io(SERVER, { query: { token: getToken() } });
    console.log("WSS SERVER", SERVER)
  }

  render() {
    const { componentType } = this.state
    switch (componentType) {
      case 'client':
        return <Client socket={this.socket} />
      case 'admin':
        return <Admin socket={this.socket} />
      case 'payroll':
        return <Payroll socket={this.socket} />
      default:
        return null
    }
  }
}

export default withRouter(App)
