import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm, Button, Avatar, Input, Tooltip } from 'antd'
import { loadEmploymentHistories, deleteEmploymentHistory } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import { serviceStatusTag } from '../../shared/components/serviceStatusTag'
import Toolbar from '../../shared/components/toolbar'
import { getUser } from '../../../../utilities/token'
import access from '../../../../utilities/access'
import Papa from 'papaparse'
import moment from 'moment'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadEmploymentHistories } = props
        onLoadEmploymentHistories(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        const { onLoadEmploymentHistories } = props
        onLoadEmploymentHistories(pagination)
        // eslint-disable-next-line
    }, [props.updateEmploymentHistorySuccess, props.deleteEmploymentHistorySuccess, props.addEmploymentHistorySuccess, props.verifiedSuccess])

    useEffect(() => {
        setTableData([...props.employmentHistoriesData.data])
    }, [props.employmentHistoriesData])

    function handleDelete(id) {
        const postById = getUser().id
        props.onDeleteEmploymentHistory(id, postById)
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.user && data.user.firstName} ${data.user && data.user.lastName}`,
                "Company Name": data.companyName,
                "Company Address": data.companyAddress,
                "Phone Number": data.phoneNumber,
                "Position": data.position,
                "Reason For Leaving": data.reasonForLeaving,
                "Year": moment(data.year).format('YYYY'),
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `emp_histories_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `emp_histories_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'employmentHistoryName'
        },
        {
            title: 'Company Address',
            dataIndex: 'companyAddress',
            key: 'companyAddress'
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item) => {
                return (<span>{serviceStatusTag(item)}</span>)
            }
        },
        {
            title: 'Verifier',
            dataIndex: 'verifier',
            key: 'verifier',
            render: (item, data) => {

                return <div>
                    <Avatar.Group>
                        {
                            item.map((data, index) => {
                                return (
                                    <Tooltip title={`${data.firstName} ${data.lastName}`} placement="top" key={index}>
                                        <Avatar icon={<ImagePresignedUrl src={data && data.image[0].url} style={{ width: 32, height: '100%' }} objectFit='cover' preview={false} />} />
                                    </Tooltip>
                                )
                            })
                        }

                    </Avatar.Group>
                </div>
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                            }}>
                                View
                            </Menu.Item>
                            {
                                access.check('employmentHistories', 'delete') &&
                                <Menu.Item key="1" >
                                    <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                        Delete
                                    </Popconfirm>

                                </Menu.Item>
                            }

                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout className='page' >
            {
                access.check('employmentHistories', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Employment Histories'
                                handleAdd={() => props.setActionType(ADD)}
                                removeAdd={!access.check('employmentHistories', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.employmentHistoriesData.employmentHistoriesLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.employmentHistoriesData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        employmentHistoriesData: state.admin.employmentHistory.employmentHistoriesData,
        employmentHistoriesLoading: state.admin.employmentHistory.employmentHistoriesLoading,
        updateEmploymentHistorySuccess: state.admin.employmentHistory.updateEmploymentHistorySuccess,
        addEmploymentHistorySuccess: state.admin.employmentHistory.addEmploymentHistorySuccess,
        deleteEmploymentHistorySuccess: state.admin.employmentHistory.deleteEmploymentHistorySuccess,
        verifiedSuccess: state.admin.employmentHistory.verifiedSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmploymentHistories: data => dispatch(loadEmploymentHistories(data)),
        onDeleteEmploymentHistory: (id, postById) => dispatch(deleteEmploymentHistory(id, postById)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)