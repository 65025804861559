import React from 'react'
import { Button, Layout, Tooltip, Card } from 'antd'
import './index.scss'
import FormReference from './form'
import { NONE } from '../../../../utilities/actionEnums'
import { LeftOutlined } from '@ant-design/icons';

const View = (props) => {
    return (
        <Layout className='page'>
            <div className='back-btn-container'>
                <Tooltip title="Back"><Button className='btn-circle' shape="circle" icon={<LeftOutlined />} onClick={() => props.setActionType(NONE)} /></Tooltip>
            </div>
            <Card title={props.selectedRow && props.selectedRow.title} >
                <FormReference {...props} />
            </Card>
        </Layout>
    )
}

export default View
