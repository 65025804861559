import {
  GET_BIOMETRICS,
  GET_BIOMETRICS_FULFILLED,
  GET_BIOMETRICS_REJECTED,
  PUT_BIOMETRIC,
  PUT_BIOMETRIC_FULFILLED,
  PUT_BIOMETRIC_REJECTED,
  POST_BIOMETRIC,
  POST_BIOMETRIC_FULFILLED,
  POST_BIOMETRIC_REJECTED,
  DELETE_BIOMETRIC,
  DELETE_BIOMETRIC_FULFILLED,
  DELETE_BIOMETRIC_REJECTED,
  GET_BIOMETRICS_DEFAULT,
  POST_BIOMETRIC_DEFAULT,
  PUT_BIOMETRIC_DEFAULT,
  DELETE_BIOMETRIC_DEFAULT
} from '../actions'

export default function biometric(state = {
  biometricsLoading: false,
  biometricsSuccess: false,
  biometricsRejected: false,
  biometricsData: {
    data: []
  },
  updateBiometricLoading: false,
  updateBiometricSuccess: false,
  updateBiometricRejected: false,
  addBiometricLoading: false,
  addBiometricSuccess: false,
  addBiometricRejected: false,
  deleteBiometricLoading: false,
  deleteBiometricSuccess: false,
  deleteBiometricRejected: false
}, action) {
  switch (action.type) {
    case GET_BIOMETRICS:
      return {
        ...state,
        biometricsLoading: true,
        biometricsSuccess: false,
        biometricsRejected: false
      }
    case GET_BIOMETRICS_FULFILLED:
      return {
        ...state,
        biometricsLoading: false,
        biometricsSuccess: true,
        biometricsData: action.payload
      }
    case GET_BIOMETRICS_REJECTED:
      return {
        ...state,
        biometricsLoading: false,
        biometricsRejected: true
      }
    case GET_BIOMETRICS_DEFAULT:
      return {
        ...state,
        biometricsLoading: false,
        biometricsSuccess: false,
        biometricsRejected: false
      }
    case POST_BIOMETRIC:
      return {
        ...state,
        addBiometricLoading: true,
        addBiometricSuccess: false,
        addBiometricRejected: false
      }
    case POST_BIOMETRIC_FULFILLED:
      return {
        ...state,
        addBiometricLoading: false,
        addBiometricSuccess: true,
        addBiometricData: action.payload
      }
    case POST_BIOMETRIC_REJECTED:
      return {
        ...state,
        addBiometricLoading: false,
        addBiometricRejected: true
      }
    case POST_BIOMETRIC_DEFAULT:
      return {
        ...state,
        addBiometricLoading: false,
        addBiometricSuccess: false,
        addBiometricRejected: false
      }
    case PUT_BIOMETRIC:
      return {
        ...state,
        updateBiometricLoading: true,
        updateBiometricSuccess: false,
        updateBiometricRejected: false
      }
    case PUT_BIOMETRIC_FULFILLED:
      return {
        ...state,
        updateBiometricLoading: false,
        updateBiometricSuccess: true,
        updateBiometricData: action.payload
      }
    case PUT_BIOMETRIC_REJECTED:
      return {
        ...state,
        updateBiometricLoading: false,
        updateBiometricRejected: true
      }
    case PUT_BIOMETRIC_DEFAULT:
      return {
        ...state,
        updateBiometricLoading: false,
        updateBiometricSuccess: false,
        updateBiometricRejected: false
      }
    case DELETE_BIOMETRIC:
      return {
        ...state,
        deleteBiometricLoading: true,
        deleteBiometricSuccess: false,
        deleteBiometricRejected: false
      }
    case DELETE_BIOMETRIC_FULFILLED:
      return {
        ...state,
        deleteBiometricLoading: false,
        deleteBiometricSuccess: true,
      }
    case DELETE_BIOMETRIC_REJECTED:
      return {
        ...state,
        deleteBiometricLoading: false,
        deleteBiometricRejected: true
      }
    case DELETE_BIOMETRIC_DEFAULT:
      return {
        ...state,
        deleteBiometricLoading: false,
        deleteBiometricSuccess: false,
        deleteBiometricRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
