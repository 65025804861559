import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ATTENDANCES = 'GET_ATTENDANCES'
export const GET_ATTENDANCES_FULFILLED = 'GET_ATTENDANCES_FULFILLED'
export const GET_ATTENDANCES_REJECTED = 'GET_ATTENDANCES_REJECTED'

export const POST_ATTENDANCE = 'POST_ATTENDANCE'
export const POST_ATTENDANCE_FULFILLED = 'POST_ATTENDANCE_FULFILLED'
export const POST_ATTENDANCE_REJECTED = 'POST_ATTENDANCE_REJECTED'
export const POST_ATTENDANCE_DEFAULT = 'POST_ATTENDANCE_DEFAULT'

export const PUT_ATTENDANCE = 'PUT_ATTENDANCE'
export const PUT_ATTENDANCE_FULFILLED = 'PUT_ATTENDANCE_FULFILLED'
export const PUT_ATTENDANCE_REJECTED = 'PUT_ATTENDANCE_REJECTED'
export const PUT_ATTENDANCE_DEFAULT = 'PUT_ATTENDANCE_DEFAULT'

export const DELETE_ATTENDANCE = 'DELETE_ATTENDANCE'
export const DELETE_ATTENDANCE_FULFILLED = 'DELETE_ATTENDANCE_FULFILLED'
export const DELETE_ATTENDANCE_REJECTED = 'DELETE_ATTENDANCE_REJECTED'
export const DELETE_ATTENDANCE_DEFAULT = 'DELETE_ATTENDANCE_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadAttendances = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_ATTENDANCES,
            payload: {}
        })
        return Axios.get(`${API_URL}/signed?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_ATTENDANCES_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_ATTENDANCES_REJECTED,
                payload: error
            })
        })
    }
}

export const submitAttendance = (data) => {
    return dispatch => {
        dispatch({
            type: POST_ATTENDANCE,
            payload: {}
        })
        return Axios.post(`${API_URL}/sign/${data.userId}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_ATTENDANCE_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_ATTENDANCE_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_ATTENDANCE_DEFAULT }))
    }
}

export const updateAttendance = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_ATTENDANCE,
            payload: {}
        })
        return Axios.put(`${API_URL}/attendance/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_ATTENDANCE_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_ATTENDANCE_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_ATTENDANCE_DEFAULT }))
    }
}


export const deleteAttendance = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_ATTENDANCE,
            payload: {}
        })
        return Axios.delete(`${API_URL}/attendance/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_ATTENDANCE_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_ATTENDANCE_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_ATTENDANCE_DEFAULT }))
    }
}
