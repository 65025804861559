import {
  GET_LEAVES,
  GET_LEAVES_FULFILLED,
  GET_LEAVES_REJECTED,
  PUT_LEAVE,
  PUT_LEAVE_FULFILLED,
  PUT_LEAVE_REJECTED,
  POST_LEAVE,
  POST_LEAVE_FULFILLED,
  POST_LEAVE_REJECTED,
  DELETE_LEAVE,
  DELETE_LEAVE_FULFILLED,
  DELETE_LEAVE_REJECTED,
  GET_LEAVES_DEFAULT,
  POST_LEAVE_DEFAULT,
  PUT_LEAVE_DEFAULT,
  DELETE_LEAVE_DEFAULT,
  GET_LEAVE_TYPES,
  GET_LEAVE_TYPES_FULFILLED,
  GET_LEAVE_TYPES_REJECTED,
  PUT_LEAVE_TYPE,
  PUT_LEAVE_TYPE_FULFILLED,
  PUT_LEAVE_TYPE_REJECTED,
  POST_LEAVE_TYPE,
  POST_LEAVE_TYPE_FULFILLED,
  POST_LEAVE_TYPE_REJECTED,
  DELETE_LEAVE_TYPE,
  DELETE_LEAVE_TYPE_FULFILLED,
  DELETE_LEAVE_TYPE_REJECTED,
  GET_LEAVE_TYPES_DEFAULT,
  POST_LEAVE_TYPE_DEFAULT,
  PUT_LEAVE_TYPE_DEFAULT,
  DELETE_LEAVE_TYPE_DEFAULT,

} from '../actions'

export default function leave(state = {
  leavesLoading: false,
  leavesSuccess: false,
  leavesRejected: false,
  leavesData: {
    data: []
  },
  leaveTypesData: {
    data: []
  },
  updateLeaveLoading: false,
  updateLeaveSuccess: false,
  updateLeaveRejected: false,
  addLeaveLoading: false,
  addLeaveSuccess: false,
  addLeaveRejected: false,
  deleteLeaveLoading: false,
  deleteLeaveSuccess: false,
  deleteLeaveRejected: false
}, action) {
  switch (action.type) {
    case GET_LEAVES:
      return {
        ...state,
        leavesLoading: true,
        leavesSuccess: false,
        leavesRejected: false
      }
    case GET_LEAVES_FULFILLED:
      return {
        ...state,
        leavesLoading: false,
        leavesSuccess: true,
        leavesData: action.payload
      }
    case GET_LEAVES_REJECTED:
      return {
        ...state,
        leavesLoading: false,
        leavesRejected: true
      }
    case GET_LEAVES_DEFAULT:
      return {
        ...state,
        leavesLoading: false,
        leavesSuccess: false,
        leavesRejected: false
      }
    case POST_LEAVE:
      return {
        ...state,
        addLeaveLoading: true,
        addLeaveSuccess: false,
        addLeaveRejected: false
      }
    case POST_LEAVE_FULFILLED:
      return {
        ...state,
        addLeaveLoading: false,
        addLeaveSuccess: true,
        addLeaveData: action.payload
      }
    case POST_LEAVE_REJECTED:
      return {
        ...state,
        addLeaveLoading: false,
        addLeaveRejected: true
      }
    case POST_LEAVE_DEFAULT:
      return {
        ...state,
        addLeaveLoading: false,
        addLeaveSuccess: false,
        addLeaveRejected: false
      }
    case PUT_LEAVE:
      return {
        ...state,
        updateLeaveLoading: true,
        updateLeaveSuccess: false,
        updateLeaveRejected: false
      }
    case PUT_LEAVE_FULFILLED:
      return {
        ...state,
        updateLeaveLoading: false,
        updateLeaveSuccess: true,
        updateLeaveData: action.payload
      }
    case PUT_LEAVE_REJECTED:
      return {
        ...state,
        updateLeaveLoading: false,
        updateLeaveRejected: true
      }
    case PUT_LEAVE_DEFAULT:
      return {
        ...state,
        updateLeaveLoading: false,
        updateLeaveSuccess: false,
        updateLeaveRejected: false
      }
    case DELETE_LEAVE:
      return {
        ...state,
        deleteLeaveLoading: true,
        deleteLeaveSuccess: false,
        deleteLeaveRejected: false
      }
    case DELETE_LEAVE_FULFILLED:
      return {
        ...state,
        deleteLeaveLoading: false,
        deleteLeaveSuccess: true,
      }
    case DELETE_LEAVE_REJECTED:
      return {
        ...state,
        deleteLeaveLoading: false,
        deleteLeaveRejected: true
      }
    case DELETE_LEAVE_DEFAULT:
      return {
        ...state,
        deleteLeaveLoading: false,
        deleteLeaveSuccess: false,
        deleteLeaveRejected: false
      }


    case GET_LEAVE_TYPES:
      return {
        ...state,
        leaveTypesLoading: true,
        leaveTypesSuccess: false,
        leaveTypesRejected: false
      }
    case GET_LEAVE_TYPES_FULFILLED:
      return {
        ...state,
        leaveTypesLoading: false,
        leaveTypesSuccess: true,
        leaveTypesData: action.payload
      }
    case GET_LEAVE_TYPES_REJECTED:
      return {
        ...state,
        leaveTypesLoading: false,
        leaveTypesRejected: true
      }
    case GET_LEAVE_TYPES_DEFAULT:
      return {
        ...state,
        leaveTypesLoading: false,
        leaveTypesSuccess: false,
        leaveTypesRejected: false
      }
    case POST_LEAVE_TYPE:
      return {
        ...state,
        addLeaveTypeLoading: true,
        addLeaveTypeSuccess: false,
        addLeaveTypeRejected: false
      }
    case POST_LEAVE_TYPE_FULFILLED:
      return {
        ...state,
        addLeaveTypeLoading: false,
        addLeaveTypeSuccess: true,
        addLeaveTypeData: action.payload
      }
    case POST_LEAVE_TYPE_REJECTED:
      return {
        ...state,
        addLeaveTypeLoading: false,
        addLeaveTypeRejected: true
      }
    case POST_LEAVE_TYPE_DEFAULT:
      return {
        ...state,
        addLeaveTypeLoading: false,
        addLeaveTypeSuccess: false,
        addLeaveTypeRejected: false
      }
    case PUT_LEAVE_TYPE:
      return {
        ...state,
        updateLeaveTypeLoading: true,
        updateLeaveTypeSuccess: false,
        updateLeaveTypeRejected: false
      }
    case PUT_LEAVE_TYPE_FULFILLED:
      return {
        ...state,
        updateLeaveTypeLoading: false,
        updateLeaveTypeSuccess: true,
        updateLeaveTypeData: action.payload
      }
    case PUT_LEAVE_TYPE_REJECTED:
      return {
        ...state,
        updateLeaveTypeLoading: false,
        updateLeaveTypeRejected: true
      }
    case PUT_LEAVE_TYPE_DEFAULT:
      return {
        ...state,
        updateLeaveTypeLoading: false,
        updateLeaveTypeSuccess: false,
        updateLeaveTypeRejected: false
      }
    case DELETE_LEAVE_TYPE:
      return {
        ...state,
        deleteLeaveTypeLoading: true,
        deleteLeaveTypeSuccess: false,
        deleteLeaveTypeRejected: false
      }
    case DELETE_LEAVE_TYPE_FULFILLED:
      return {
        ...state,
        deleteLeaveTypeLoading: false,
        deleteLeaveTypeSuccess: true,
      }
    case DELETE_LEAVE_TYPE_REJECTED:
      return {
        ...state,
        deleteLeaveTypeLoading: false,
        deleteLeaveTypeRejected: true
      }
    case DELETE_LEAVE_TYPE_DEFAULT:
      return {
        ...state,
        deleteLeaveTypeLoading: false,
        deleteLeaveTypeSuccess: false,
        deleteLeaveTypeRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
