import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, notification, Alert } from 'antd'
import List from './list'
import View from './view'
import { VIEW, EDIT, ADD, NONE } from '../../../../utilities/actionEnums'
import './index.scss'
import RolesModal from './modals/roles'
import access from '../../../../utilities/access'
import Viewer from '../../shared/components/viewer'

const Position = (props) => {
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({
        roles: []
    })
    const [isRolesModalOpen, setIsRolesModalOpen] = useState(false)

    useEffect(() => {
        if (actionType === NONE) setSelectedRow()
    }, [actionType])

    useEffect(() => {
        if (props.updatePositionSuccess) {
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the position information.',
            });
            setIsRolesModalOpen(false)
        }
    }, [props.updatePositionSuccess])

    useEffect(() => {
        if (props.addPositionSuccess) {
            notification.success({
                message: `Added`,
                description: 'You have successfully added new position information.',
            });
        }
    }, [props.addPositionSuccess])

    function handleRedirectToUtilities() {
        props.history.push('/admin/utilities?page=positions')
    }

    return (
        <Layout>
            {
                (!props.rolesManagement && access.check('positions', 'view')) &&
                <Alert
                    style={{ margin: 24 }}
                    message="Note:"
                    description={
                        <Fragment>
                            <span>In this page you can <b>ADD</b>, <b>UPDATE</b>, <b>DELETE</b> data for positions.</span><br />
                            <span>By default, Creating new position data does not have roles yet. If you want to add roles in the specific position, go to <a onClick={() => handleRedirectToUtilities()}><u>Position Roles Management</u></a>.</span>
                        </Fragment>
                    }
                    type="info"
                />
            }

            <Viewer
                isView={(actionType === VIEW) || (actionType === ADD)}
                listComponent={
                    <List
                        setActionType={setActionType}
                        setSelectedRow={setSelectedRow}
                        setIsRolesModalOpen={setIsRolesModalOpen}
                        rolesManagement={props.rolesManagement}
                    />
                }
                viewComponent={
                    <View
                        actionType={actionType}
                        setActionType={setActionType}
                        selectedRow={selectedRow}
                    />
                }
            />

            <RolesModal
                visible={isRolesModalOpen}
                selectedRow={selectedRow}
                onCancel={() => setIsRolesModalOpen(false)}
            />
        </Layout>

    )
}

function mapStateToProps(state) {
    return {
        updatePositionSuccess: state.admin.position.updatePositionSuccess,
        addPositionSuccess: state.admin.position.addPositionSuccess,

    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Position)