import {
    GET_DISCIPLINARY_ACTIONS,
    GET_DISCIPLINARY_ACTIONS_FULFILLED,
    GET_DISCIPLINARY_ACTIONS_REJECTED,
    PUT_DISCIPLINARY_ACTION,
    PUT_DISCIPLINARY_ACTION_FULFILLED,
    PUT_DISCIPLINARY_ACTION_REJECTED,
    POST_DISCIPLINARY_ACTION,
    POST_DISCIPLINARY_ACTION_FULFILLED,
    POST_DISCIPLINARY_ACTION_REJECTED,
    DELETE_DISCIPLINARY_ACTION,
    DELETE_DISCIPLINARY_ACTION_FULFILLED,
    DELETE_DISCIPLINARY_ACTION_REJECTED,
    DELETE_DISCIPLINARY_ACTION_DEFAULT,
    PUT_DISCIPLINARY_ACTION_DEFAULT,
    POST_DISCIPLINARY_ACTION_DEFAULT
} from '../actions'

export default function disciplinaryAction(state = {
    disciplinaryActionsLoading: false,
    disciplinaryActionsSuccess: false,
    disciplinaryActionsRejected: false,
    disciplinaryActionsData: {
        data: []
    },
    verifiedSuccess: false,
    updateDisciplinaryActionLoading: false,
    updateDisciplinaryActionSuccess: false,
    updateDisciplinaryActionRejected: false,
    addDisciplinaryActionLoading: false,
    addDisciplinaryActionSuccess: false,
    addDisciplinaryActionRejected: false,
    deleteDisciplinaryActionLoading: false,
    deleteDisciplinaryActionSuccess: false,
    deleteDisciplinaryActionRejected: false
}, action) {
    switch (action.type) {
        case GET_DISCIPLINARY_ACTIONS:
            return {
                ...state,
                disciplinaryActionsLoading: true,
                disciplinaryActionsSuccess: false,
                disciplinaryActionsRejected: false
            }
        case GET_DISCIPLINARY_ACTIONS_FULFILLED:
            return {
                ...state,
                disciplinaryActionsLoading: false,
                disciplinaryActionsSuccess: true,
                disciplinaryActionsData: action.payload
            }
        case GET_DISCIPLINARY_ACTIONS_REJECTED:
            return {
                ...state,
                disciplinaryActionsLoading: false,
                disciplinaryActionsRejected: true
            }
        case POST_DISCIPLINARY_ACTION:
            return {
                ...state,
                addDisciplinaryActionLoading: true,
                addDisciplinaryActionSuccess: false,
                addDisciplinaryActionRejected: false
            }
        case POST_DISCIPLINARY_ACTION_FULFILLED:
            return {
                ...state,
                addDisciplinaryActionLoading: false,
                addDisciplinaryActionSuccess: true,
                addDisciplinaryActionData: action.payload
            }
        case POST_DISCIPLINARY_ACTION_REJECTED:
            return {
                ...state,
                addDisciplinaryActionLoading: false,
                addDisciplinaryActionRejected: true
            }
        case POST_DISCIPLINARY_ACTION_DEFAULT:
            return {
                ...state,
                addDisciplinaryActionLoading: false,
                addDisciplinaryActionSuccess: false,
                addDisciplinaryActionRejected: false
            }
        case PUT_DISCIPLINARY_ACTION:
            return {
                ...state,
                updateDisciplinaryActionLoading: true,
                updateDisciplinaryActionSuccess: false,
                updateDisciplinaryActionRejected: false
            }
        case PUT_DISCIPLINARY_ACTION_FULFILLED:
            return {
                ...state,
                updateDisciplinaryActionLoading: false,
                updateDisciplinaryActionSuccess: true,
                updateDisciplinaryActionData: action.payload
            }

        case PUT_DISCIPLINARY_ACTION_REJECTED:
            return {
                ...state,
                updateDisciplinaryActionLoading: false,
                updateDisciplinaryActionRejected: true
            }
        case PUT_DISCIPLINARY_ACTION_DEFAULT:
            return {
                ...state,
                updateDisciplinaryActionLoading: false,
                updateDisciplinaryActionSuccess: false,
                updateDisciplinaryActionRejected: false
            }

        case DELETE_DISCIPLINARY_ACTION:
            return {
                ...state,
                deleteDisciplinaryActionLoading: true,
                deleteDisciplinaryActionSuccess: false,
                deleteDisciplinaryActionRejected: false
            }
        case DELETE_DISCIPLINARY_ACTION_FULFILLED:
            return {
                ...state,
                deleteDisciplinaryActionLoading: false,
                deleteDisciplinaryActionSuccess: true,
            }
        case DELETE_DISCIPLINARY_ACTION_REJECTED:
            return {
                ...state,
                deleteDisciplinaryActionLoading: false,
                deleteDisciplinaryActionRejected: true
            }
        case DELETE_DISCIPLINARY_ACTION_DEFAULT:
            return {
                ...state,
                deleteDisciplinaryActionLoading: false,
                deleteDisciplinaryActionSuccess: false,
                deleteDisciplinaryActionRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
