import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Rate, Avatar, Tooltip, Row, Col, notification, List } from 'antd'
import { connect } from 'react-redux'
import { loadDisciplinaryActions, deleteDisciplinaryAction } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'
import RemarksRenderer from '../../../../utilities/remarksRenderer'


const DisciplinaryActionsComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updateDisciplinaryActionSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the disciplinary action information.',
            });
        }
        if (props.addDisciplinaryActionSuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new disciplinary action information.',
            });
        }
        if (props.deleteDisciplinaryActionSuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted disciplinary action data.',
            });
        }

        props.onLoadDisciplinaryActions(pagination)
    }, [props.updateDisciplinaryActionSuccess, props.addDisciplinaryActionSuccess, props.deleteDisciplinaryActionSuccess])

    function handleDelete(id) {
        props.onDeleteDisciplinaryAction(id)
    }

    return (
        <Layout className='disciplinaryActions-page'>
            <Card>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Type
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Description
                            </Col>
                            <Col md={5} lg={5} xs={24} sm={24}>
                                Date
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={props.disciplinaryActionsData.data}
                    renderItem={data => <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }}>
                                <Col md={24} lg={24} xs={24} sm={24}>
                                    <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                </Col>
                            </Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item-ratings' >
                                    <div >
                                        <h1 className='red mt5'>{data && data.type && data.type.title} <StatusIconDisplay status={data.status} /> </h1>
                                    </div>
                                </div>
                            </Col>

                            <Col md={9} lg={9} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p className='description'>{data && data.description}</p>
                                </div>
                            </Col>

                            <Col md={5} lg={5} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p className='date'>From: {moment(data.dateFrom).format('LL')}</p><p className='date'>To: {moment(data.dateTo).format('LL')}</p>
                                </div>
                            </Col>

                            <Col md={4} lg={4} xs={24} sm={24}>

                            </Col>
                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        disciplinaryActionsData: state.client.disciplinaryAction.disciplinaryActionsData,
        addDisciplinaryActionSuccess: state.client.disciplinaryAction.addDisciplinaryActionSuccess,
        updateDisciplinaryActionSuccess: state.client.disciplinaryAction.updateDisciplinaryActionSuccess,
        deleteDisciplinaryActionSuccess: state.client.disciplinaryAction.deleteDisciplinaryActionSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadDisciplinaryActions: data => dispatch(loadDisciplinaryActions(data)),
        onDeleteDisciplinaryAction: id => dispatch(deleteDisciplinaryAction(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisciplinaryActionsComponent)
