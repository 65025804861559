import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal, notification } from 'antd'
import { updateLeave, submitLeave, loadLeaveTypes } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'
import { upload } from '../../../../utilities/filesController'
import _ from 'lodash'
import './index.scss'
import moment from 'moment'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { loadProjects } from '../../../admin/projects/actions'
const { Option } = Select
const { RangePicker } = DatePicker;
const FormLeave = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            dateFrom: props.selectedRow && moment(props.selectedRow.dateFrom) || new Date(),
            dateTo: props.selectedRow && moment(props.selectedRow.dateTo) || new Date(),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)

        props.onLoadProjects({
            page: 1,
            limit: 100,
        })

        props.onLoadLeaveTypes({
            page: 1,
            limit: 100,
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            const userId = await getUser().id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onSubmitLeave({ userId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        props.setActionType(NONE)
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const leaveId = props.selectedRow._id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onUpdateLeave({ id: leaveId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {

                    upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    // do not allowed to edit  'Date from' and 'Date To' when already approved because its been deducted already to the selected user.
    const isDeducted = (props.selectedRow && props.selectedRow.isDeducted)

    return (
        <Modal title={props.selectedRow && props.selectedRow.reason || 'Add Leave'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`leave-${props.selectedId}`}
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Reason For Taking Leave"
                                name="reason"
                                rules={[{ required: true, message: 'Please input reason!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Date From"
                                name="dateFrom"
                                rules={[{ required: true, message: 'Please input date from!' }]}
                            >
                                <DatePicker
                                    disabled={isDeducted}
                                    className='primary-input'
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Type"
                                name="typeId"
                                rules={[{ required: true, message: 'Please input type!' }]}
                            >
                                <Select disabled={isDeducted}  >
                                    {
                                        props.leaveTypesData.data.map((item, index) => {
                                            return (
                                                <Option key={index} value={item._id}>{item.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Date To"
                                name="dateTo"
                                rules={[{ required: true, message: 'Please input date to!' }]}
                            >
                                <DatePicker
                                    disabled={isDeducted}
                                    className='primary-input'
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Projects"
                                name="projectId"
                                rules={[{ required: true, message: 'Please input project id!' }]}
                            >
                                <Select>
                                    {
                                        props.projectsData.data.map((data, index) => {
                                            return (
                                                <Option value={data._id}>{data.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <CustomDropzoneMultiple
                                fileName='leaves'
                                uploadDir='leaves'
                                height={200}
                                width={200}
                                getFile={file => { getFileImage(file) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className='d-flex'>
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div className='p2'>
                                                <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateLeaveLoading || loading}   >
                                    Submit Leave
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateLeaveLoading: state.client.leave.updateLeaveLoading,
        employeesData: state.client.employee.employeesData,
        employeesLoading: state.client.employee.employeesLoading,
        projectsData: state.admin.project.projectsData,
        leaveTypesData: state.admin.leave.leaveTypesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadLeaveTypes: (pagination) => dispatch(loadLeaveTypes(pagination)),
        onUpdateLeave: (data) => dispatch(updateLeave(data)),
        onSubmitLeave: (data) => dispatch(submitLeave(data)),
        onLoadProjects: (data) => dispatch(loadProjects(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormLeave)