import {
    GET_DEPENDENTS,
    GET_DEPENDENTS_FULFILLED,
    GET_DEPENDENTS_REJECTED,
    PUT_DEPENDENT,
    PUT_DEPENDENT_FULFILLED,
    PUT_DEPENDENT_REJECTED,
    POST_DEPENDENT,
    POST_DEPENDENT_FULFILLED,
    POST_DEPENDENT_REJECTED,
    DELETE_DEPENDENT,
    DELETE_DEPENDENT_FULFILLED,
    DELETE_DEPENDENT_REJECTED,
    DELETE_DEPENDENT_DEFAULT,
    PUT_DEPENDENT_DEFAULT,
    POST_DEPENDENT_DEFAULT
} from '../actions'

export default function dependent(state = {
    dependentsLoading: false,
    dependentsSuccess: false,
    dependentsRejected: false,
    dependentsData: {
        data: []
    },
    verifiedSuccess: false,
    updateDependentLoading: false,
    updateDependentSuccess: false,
    updateDependentRejected: false,
    addDependentLoading: false,
    addDependentSuccess: false,
    addDependentRejected: false,
    deleteDependentLoading: false,
    deleteDependentSuccess: false,
    deleteDependentRejected: false
}, action) {
    switch (action.type) {
        case GET_DEPENDENTS:
            return {
                ...state,
                dependentsLoading: true,
                dependentsSuccess: false,
                dependentsRejected: false
            }
        case GET_DEPENDENTS_FULFILLED:
            return {
                ...state,
                dependentsLoading: false,
                dependentsSuccess: true,
                dependentsData: action.payload
            }
        case GET_DEPENDENTS_REJECTED:
            return {
                ...state,
                dependentsLoading: false,
                dependentsRejected: true
            }
        case POST_DEPENDENT:
            return {
                ...state,
                addDependentLoading: true,
                addDependentSuccess: false,
                addDependentRejected: false
            }
        case POST_DEPENDENT_FULFILLED:
            return {
                ...state,
                addDependentLoading: false,
                addDependentSuccess: true,
                addDependentData: action.payload
            }
        case POST_DEPENDENT_REJECTED:
            return {
                ...state,
                addDependentLoading: false,
                addDependentRejected: true
            }
        case POST_DEPENDENT_DEFAULT:
            return {
                ...state,
                addDependentLoading: false,
                addDependentSuccess: false,
                addDependentRejected: false
            }
        case PUT_DEPENDENT:
            return {
                ...state,
                updateDependentLoading: true,
                updateDependentSuccess: false,
                updateDependentRejected: false
            }
        case PUT_DEPENDENT_FULFILLED:
            return {
                ...state,
                updateDependentLoading: false,
                updateDependentSuccess: true,
                updateDependentData: action.payload
            }

        case PUT_DEPENDENT_REJECTED:
            return {
                ...state,
                updateDependentLoading: false,
                updateDependentRejected: true
            }
        case PUT_DEPENDENT_DEFAULT:
            return {
                ...state,
                updateDependentLoading: false,
                updateDependentSuccess: false,
                updateDependentRejected: false
            }

        case DELETE_DEPENDENT:
            return {
                ...state,
                deleteDependentLoading: true,
                deleteDependentSuccess: false,
                deleteDependentRejected: false
            }
        case DELETE_DEPENDENT_FULFILLED:
            return {
                ...state,
                deleteDependentLoading: false,
                deleteDependentSuccess: true,
            }
        case DELETE_DEPENDENT_REJECTED:
            return {
                ...state,
                deleteDependentLoading: false,
                deleteDependentRejected: true
            }
        case DELETE_DEPENDENT_DEFAULT:
            return {
                ...state,
                deleteDependentLoading: false,
                deleteDependentSuccess: false,
                deleteDependentRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
