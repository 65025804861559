import React, { useEffect, useState } from 'react'
import { get } from '../filesController'

const IframePresignedUrl = (props) => {
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        async function getData() {
            const fetchedUrl = await get(props.src)
            setUrl(fetchedUrl)
            setLoading(false)
        }
        getData()
    }, [props.src])

    return (
        !loading && <iframe src={`${url}#view=FitH`} id={props.id} style={props.style} className={props.className} scrolling={props.scrolling} height={props.height} frameBorder={props.frameBorder}></iframe>
    )

}

export default IframePresignedUrl