import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ALLOWANCES = 'GET_ALLOWANCES'
export const GET_ALLOWANCES_FULFILLED = 'GET_ALLOWANCES_FULFILLED'
export const GET_ALLOWANCES_REJECTED = 'GET_ALLOWANCES_REJECTED'

export const POST_ALLOWANCE = 'POST_ALLOWANCE'
export const POST_ALLOWANCE_FULFILLED = 'POST_ALLOWANCE_FULFILLED'
export const POST_ALLOWANCE_REJECTED = 'POST_ALLOWANCE_REJECTED'
export const POST_ALLOWANCE_DEFAULT = 'POST_ALLOWANCE_DEFAULT'

export const PUT_ALLOWANCE = 'PUT_ALLOWANCE'
export const PUT_ALLOWANCE_FULFILLED = 'PUT_ALLOWANCE_FULFILLED'
export const PUT_ALLOWANCE_REJECTED = 'PUT_ALLOWANCE_REJECTED'
export const PUT_ALLOWANCE_DEFAULT = 'PUT_ALLOWANCE_DEFAULT'

export const DELETE_ALLOWANCE = 'DELETE_ALLOWANCE'
export const DELETE_ALLOWANCE_FULFILLED = 'DELETE_ALLOWANCE_FULFILLED'
export const DELETE_ALLOWANCE_REJECTED = 'DELETE_ALLOWANCE_REJECTED'
export const DELETE_ALLOWANCE_DEFAULT = 'DELETE_ALLOWANCE_DEFAULT'

export const GET_ALLOWANCE_HISTORY_TYPES = 'GET_ALLOWANCE_HISTORY_TYPES'
export const GET_ALLOWANCE_HISTORY_TYPES_FULFILLED = 'GET_ALLOWANCE_HISTORY_TYPES_FULFILLED'
export const GET_ALLOWANCE_HISTORY_TYPES_REJECTED = 'GET_ALLOWANCE_HISTORY_TYPES_REJECTED'
export const GET_ALLOWANCE_HISTORY_TYPES_DEFAULT = 'GET_ALLOWANCE_HISTORY_TYPES_DEFAULT'

export const GET_SUPPLIER_TIN = 'GET_SUPPLIER_TIN'
export const GET_SUPPLIER_TIN_FULFILLED = 'GET_SUPPLIER_TIN_FULFILLED'
export const GET_SUPPLIER_TIN_REJECTED = 'GET_SUPPLIER_TIN_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadAllowances = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_ALLOWANCES,
            payload: {}
        })
        return Axios.get(`${API_URL}/allowance-histories?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_ALLOWANCES_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_ALLOWANCES_REJECTED,
                payload: error
            })
        })
    }
}

export const submitAllowance = (data) => {
    return dispatch => {
        dispatch({
            type: POST_ALLOWANCE,
            payload: {}
        })
        return Axios.post(`${API_URL}/allowance-history`, { ...data }, { headers: headers }).then(function (response) {
            return dispatch({
                type: POST_ALLOWANCE_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            return dispatch({
                type: POST_ALLOWANCE_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_ALLOWANCE_DEFAULT }))
    }
}

export const updateAllowance = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_ALLOWANCE,
            payload: {}
        })
        return Axios.put(`${API_URL}/allowance-history/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            return dispatch({
                type: PUT_ALLOWANCE_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            return dispatch({
                type: PUT_ALLOWANCE_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_ALLOWANCE_DEFAULT }))
    }
}


export const deleteAllowance = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_ALLOWANCE,
            payload: {}
        })
        return Axios.delete(`${API_URL}/allowance-history/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_ALLOWANCE_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_ALLOWANCE_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_ALLOWANCE_DEFAULT }))
    }
}


export const loadAllowanceHistoryTypes = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_ALLOWANCE_HISTORY_TYPES,
            payload: {}
        })
        return Axios.get(`${API_URL}/allowance-history-types?${query}`, { headers: headers })
            .then(function (response) {
                dispatch({
                    type: GET_ALLOWANCE_HISTORY_TYPES_FULFILLED,
                    payload: response
                })
            })
            .catch(function (error) {
                dispatch({
                    type: GET_ALLOWANCE_HISTORY_TYPES_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: GET_ALLOWANCE_HISTORY_TYPES_DEFAULT }))
    }
}

export const loadSupplierTin = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_SUPPLIER_TIN,
            payload: {}
        })
        return Axios.get(`${API_URL}/suppliers?${query}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: GET_SUPPLIER_TIN_FULFILLED,
                    payload: response
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: GET_SUPPLIER_TIN_REJECTED,
                    payload: error
                })
            })
    }
}


