import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, notification, Popconfirm, List, Radio, Modal, Divider } from 'antd'
import { updateDeductionType, submitDeductionType, loadDeductionTypes, deleteDeductionType } from '../../actions'
import '../index.scss'
import { ADD, VIEW, NONE } from '../../../../../utilities/actionEnums'

const FormType = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [selectedRow, setSelectedRow] = useState(undefined)

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (!selectedRow) {
            props.onSubmitDeductionType({ ...submitData }).then(res => {
                setLoading(false)
                props.onLoadDeductionTypes({ page: 1, limit: 100, })
                form.resetFields()
                notification.success({
                    message: `Added`,
                    description: 'You have successfully added new deduction type.',
                });
            })
        } else {
            const deductionId = selectedRow._id
            props.onUpdateDeductionType({ id: deductionId, ...submitData }).then(res => {
                setLoading(false)
                props.onLoadDeductionTypes({ page: 1, limit: 100 })
                form.resetFields()
                notification.success({
                    message: `Updated`,
                    description: 'You have successfully updated deduction type.',
                });
            })
        }

        setSelectedRow(undefined)

    }

    useEffect(() => {
        props.onLoadDeductionTypes({
            page: 1,
            limit: 100,
        })
    }, [])

    function handleDelete(id) {
        props.onDeleteDeductionType(id).then(() => {
            props.onLoadDeductionTypes({ page: 1, limit: 100, })
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted deduction type.',
            });
        })
    }

    function handleSelectRow(data) {
        setSelectedRow(data)
        form.setFieldsValue({
            ...data
        })
    }

    return (
        <Modal title={'Type'} visible={props.visible} footer={null} onCancel={() => props.setModalTypeShow(false)} width={600}>
            <Layout className='type-modal-content' style={{ backgroundColor: 'white' }}>
                <Form
                    name={`deduction`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please input title!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} lg={24} sm={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <Input placeholder='Optional' />
                            </Form.Item>
                        </Col>
                    </Row >

                    {
                        selectedRow ?
                            <Row gutter={8}>
                                <Col>
                                    <Form.Item >
                                        <Button className='primary-btn' type="primary" htmlType="submit" loading={props.updateDeductionTypeLoading || loading}   >
                                            Save
                                        </Button>
                                    </Form.Item>

                                </Col>
                                <Col>
                                    <Form.Item >
                                        <Button onClick={() => {
                                            form.resetFields()
                                            setSelectedRow(undefined)
                                        }}  >
                                            Cancel
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row> :
                            <Row>
                                <Col md={24} lg={24} sm={24} align={'left'}>
                                    <Form.Item >
                                        <Button className='primary-btn' type="primary" htmlType="submit"
                                            loading={props.updateDeductionLoading || loading}   >
                                            Add Deduction Type
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                    }

                </Form >
                <Divider />
                <div style={{ height: 400, overflow: 'auto' }}>
                    <List
                        size="small"
                        header={<div>List Of Deduction Types</div>}
                        bordered
                        dataSource={props.deductionTypesData.data}
                        renderItem={(item, index) =>
                            <List.Item className={((selectedRow && selectedRow._id) === item._id) ? 'row-item selected' : 'row-item'}>
                                <div >
                                    <h1>{index + 1}. {item.title}</h1>
                                    <p>{item.description}</p>
                                    <p>{item.deductedTo}</p>
                                    <div className='actions'>
                                        <span className='edit' onClick={() => handleSelectRow(item)}>Edit</span>
                                        <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => {
                                            handleDelete(item._id)
                                            setSelectedRow(undefined)
                                            form.resetFields()
                                        }} okText="Yes" cancelText="No">
                                            <span className='delete'>Delete</span>
                                        </Popconfirm>
                                    </div>
                                </div>
                            </List.Item>
                        }
                    />
                </div>

            </Layout >
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateDeductionLoading: state.adminPms.deduction.updateDeductionLoading,
        deductionTypesData: state.adminPms.deduction.deductionTypesData,
        updateDeductionTypeLoading: state.adminPms.deduction.updateDeductionTypeLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadDeductionTypes: (pagination) => dispatch(loadDeductionTypes(pagination)),
        onUpdateDeductionType: (data) => dispatch(updateDeductionType(data)),
        onSubmitDeductionType: (data) => dispatch(submitDeductionType(data)),
        onDeleteDeductionType: (id) => dispatch(deleteDeductionType(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormType)