import {
  GET_SALARY,
  GET_SALARY_FULFILLED,
  GET_SALARY_REJECTED,
  PUT_SALARY,
  PUT_SALARY_FULFILLED,
  PUT_SALARY_REJECTED,
  POST_SALARY,
  POST_SALARY_FULFILLED,
  POST_SALARY_REJECTED,
  POST_SALARY_DEFAULT,
  DELETE_SALARY_RATE,
  DELETE_SALARY_RATE_FULFILLED,
  DELETE_SALARY_RATE_REJECTED,
  POST_SALARY_RATE,
  POST_SALARY_RATE_FULFILLED,
  POST_SALARY_RATE_REJECTED,
  GET_SALARY_DEFAULT,
  PUT_SALARY_DEFAULT,
  DELETE_SALARY_RATE_DEFAULT,
  POST_SALARY_RATE_DEFAULT
} from '../actions'

export default function salaries(state = {
  salariesLoading: false,
  salariesSuccess: false,
  salariesRejected: false,
  salariesData: {
    rates: []
  },
  updateSalaryLoading: false,
  updateSalarySuccess: false,
  updateSalaryRejected: false,
  addSalaryLoading: false,
  addSalarySuccess: false,
  addSalaryRejected: false,
  deleteSalaryRateLoading: false,
  deleteSalaryRateSuccess: false,
  deleteSalaryRateRejected: false,
  salaryRateLoading: false,
  salaryRateSuccess: false,
  salaryRateRejected: false
}, action) {
  switch (action.type) {
    case GET_SALARY:
      return {
        ...state,
        salariesLoading: true,
        salariesSuccess: false,
        salariesRejected: false
      }
    case GET_SALARY_FULFILLED:
      return {
        ...state,
        salariesLoading: false,
        salariesSuccess: true,
        salariesData: action.payload
      }
    case GET_SALARY_REJECTED:
      return {
        ...state,
        salariesLoading: false,
        salariesRejected: true
      }
    case GET_SALARY_DEFAULT:
      return {
        ...state,
        salariesLoading: false,
        salariesSuccess: false,
        salariesRejected: false
      }
    case POST_SALARY:
      return {
        ...state,
        addSalaryLoading: true,
        addSalarySuccess: false,
        addSalaryRejected: false
      }
    case POST_SALARY_FULFILLED:
      return {
        ...state,
        addSalaryLoading: false,
        addSalarySuccess: true,
        addSalaryData: action.payload
      }
    case POST_SALARY_REJECTED:
      return {
        ...state,
        addSalaryLoading: false,
        addSalaryRejected: true
      }
    case POST_SALARY_DEFAULT:
      return {
        ...state,
        addSalaryLoading: false,
        addSalarySuccess: false,
        addSalaryRejected: false
      }
    case PUT_SALARY:
      return {
        ...state,
        updateSalaryLoading: true,
        updateSalarySuccess: false,
        updateSalaryRejected: false
      }
    case PUT_SALARY_FULFILLED:
      return {
        ...state,
        updateSalaryLoading: false,
        updateSalarySuccess: true,
        updateSalaryData: action.payload
      }
    case PUT_SALARY_REJECTED:
      return {
        ...state,
        updateSalaryLoading: false,
        updateSalaryRejected: true
      }
    case PUT_SALARY_DEFAULT:
      return {
        ...state,
        updateSalaryLoading: false,
        updateSalarySuccess: false,
        updateSalaryRejected: false
      }

    case DELETE_SALARY_RATE:
      return {
        ...state,
        deleteSalaryRateLoading: true,
        deleteSalaryRateSuccess: false,
        deleteSalaryRateRejected: false
      }
    case DELETE_SALARY_RATE_FULFILLED:
      return {
        ...state,
        deleteSalaryRateLoading: false,
        deleteSalaryRateSuccess: true,
      }
    case DELETE_SALARY_RATE_REJECTED:
      return {
        ...state,
        deleteSalaryRateLoading: false,
        deleteSalaryRateRejected: true
      }
    case DELETE_SALARY_RATE_DEFAULT:
      return {
        ...state,
        deleteSalaryRateLoading: false,
        deleteSalaryRateSuccess: false,
        deleteSalaryRateRejected: false
      }
    case POST_SALARY_RATE:
      return {
        ...state,
        salaryRateLoading: true,
        salaryRateSuccess: false,
        salaryRateRejected: false
      }
    case POST_SALARY_RATE_FULFILLED:
      return {
        ...state,
        salaryRateLoading: false,
        salaryRateSuccess: true,
      }
    case POST_SALARY_RATE_REJECTED:
      return {
        ...state,
        salaryRateLoading: false,
        salaryRateRejected: true
      }
    case POST_SALARY_RATE_DEFAULT:
      return {
        ...state,
        salaryRateLoading: false,
        salaryRateSuccess: false,
        salaryRateRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
