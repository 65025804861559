import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, notification } from 'antd'
import List from './list'
import View from './view'
import { VIEW, EDIT, ADD, NONE } from '../../../../utilities/actionEnums'
import './index.scss'

const Incentive = (props) => {
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState()

    useEffect(() => {
        if (actionType === NONE) setSelectedRow()
    }, [actionType])

    useEffect(() => {
        if (props.updateIncentiveSuccess) {
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the incentive information.',
            });
        }
    }, [props.updateIncentiveSuccess])

    useEffect(() => {
        if (props.addIncentiveSuccess) {
            notification.success({
                message: `Added`,
                description: 'You have successfully added new incentive information.',
            });
        }
    }, [props.addIncentiveSuccess])

    return (
        <Layout className='page'>
            <div style={{ display: (actionType !== NONE) && 'none' }}>
                <List
                    setActionType={setActionType}
                    setSelectedRow={setSelectedRow}
                    {...props}
                />
            </div>
            {
                ((actionType === VIEW) || (actionType === EDIT) || (actionType === ADD)) &&
                <View
                    actionType={actionType}
                    setActionType={setActionType}
                    selectedRow={selectedRow}
                />
            }
        </Layout>

    )
}

function mapStateToProps(state) {
    return {
        updateIncentiveSuccess: state.adminPms.incentive.updateIncentiveSuccess,
        addIncentiveSuccess: state.adminPms.incentive.addIncentiveSuccess,

    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Incentive)