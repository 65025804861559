import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ALL_PAYROLL = 'GET_ALL_PAYROLL'
export const GET_ALL_PAYROLL_FULFILLED = 'GET_ALL_PAYROLL_FULFILLED'
export const GET_ALL_PAYROLL_REJECTED = 'GET_ALL_PAYROLL_REJECTED'
export const GET_ALL_PAYROLL_DEFAULT = 'GET_ALL_PAYROLL_DEFAULT'

export const GET_PAYROLL = 'GET_PAYROLL'
export const GET_PAYROLL_FULFILLED = 'GET_PAYROLL_FULFILLED'
export const GET_PAYROLL_REJECTED = 'GET_PAYROLL_REJECTED'
export const GET_PAYROLL_DEFAULT = 'GET_PAYROLL_DEFAULT'

export const GET_LATEST_ACTIVE_PAYROLL = 'GET_LATEST_ACTIVE_PAYROLL'
export const GET_LATEST_ACTIVE_PAYROLL_FULFILLED = 'GET_LATEST_ACTIVE_PAYROLL_FULFILLED'
export const GET_LATEST_ACTIVE_PAYROLL_REJECTED = 'GET_LATEST_ACTIVE_PAYROLL_REJECTED'

export const POST_PAYROLL = 'POST_PAYROLL'
export const POST_PAYROLL_FULFILLED = 'POST_PAYROLL_FULFILLED'
export const POST_PAYROLL_REJECTED = 'POST_PAYROLL_REJECTED'
export const POST_PAYROLL_DEFAULT = 'POST_PAYROLL_DEFAULT'

export const DELETE_PAYROLL = 'DELETE_PAYROLL'
export const DELETE_PAYROLL_FULFILLED = 'DELETE_PAYROLL_FULFILLED'
export const DELETE_PAYROLL_REJECTED = 'DELETE_PAYROLL_REJECTED'
export const DELETE_PAYROLL_DEFAULT = 'DELETE_PAYROLL_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const getLatestActivePayroll = (id) => {
  return dispatch => {
    dispatch({
      type: GET_LATEST_ACTIVE_PAYROLL,
      payload: {}
    })
    return Axios.get(`${API_URL}/get-latest-active-payroll`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_LATEST_ACTIVE_PAYROLL_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_LATEST_ACTIVE_PAYROLL_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPayroll = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PAYROLL,
      payload: {}
    })
    return Axios.post(`${API_URL}/create-payroll`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_PAYROLL_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_PAYROLL_REJECTED,
          payload: error
        })
      })
  }
}

export const loadAllPayroll = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_ALL_PAYROLL,
      payload: {}
    })
    return Axios.get(`${API_URL}/overtimes?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ALL_PAYROLL_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALL_PAYROLL_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ALL_PAYROLL_DEFAULT }))
  }
}

export const loadLPayroll = (id) => {
  return dispatch => {
    dispatch({
      type: GET_PAYROLL,
      payload: {}
    })
    return Axios.get(`${API_URL}/overtime/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_PAYROLL_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_PAYROLL_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_PAYROLL_DEFAULT }))
  }
}



export const deleteLPayroll = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_PAYROLL,
      payload: {}
    })
    return Axios.delete(`${API_URL}/payroll/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_PAYROLL_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_PAYROLL_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_PAYROLL_DEFAULT }))
  }
}












