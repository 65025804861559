import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_PERSONAL_MOVEMENTS = 'GET_PERSONAL_MOVEMENTS'
export const GET_PERSONAL_MOVEMENTS_FULFILLED = 'GET_PERSONAL_MOVEMENTS_FULFILLED'
export const GET_PERSONAL_MOVEMENTS_REJECTED = 'GET_PERSONAL_MOVEMENTS_REJECTED'
export const GET_PERSONAL_MOVEMENTS_DEFAULT = 'GET_PERSONAL_MOVEMENTS_DEFAULT'

export const GET_PERSONAL_MOVEMENT = 'GET_PERSONAL_MOVEMENT'
export const GET_PERSONAL_MOVEMENT_FULFILLED = 'GET_PERSONAL_MOVEMENT_FULFILLED'
export const GET_PERSONAL_MOVEMENT_REJECTED = 'GET_PERSONAL_MOVEMENT_REJECTED'
export const GET_PERSONAL_MOVEMENT_DEFAULT = 'GET_PERSONAL_MOVEMENT_DEFAULT'

export const POST_PERSONAL_MOVEMENT = 'POST_PERSONAL_MOVEMENT'
export const POST_PERSONAL_MOVEMENT_FULFILLED = 'POST_PERSONAL_MOVEMENT_FULFILLED'
export const POST_PERSONAL_MOVEMENT_REJECTED = 'POST_PERSONAL_MOVEMENT_REJECTED'
export const POST_PERSONAL_MOVEMENT_DEFAULT = 'POST_PERSONAL_MOVEMENT_DEFAULT'

export const VERIFY = 'VERIFY'
export const VERIFY_FULFILLED = 'VERIFY_FULFILLED'
export const VERIFY_REJECTED = 'VERIFY_REJECTED'
export const VERIFY_DEFAULT = 'VERIFY_DEFAULT'

export const PUT_PERSONAL_MOVEMENT = 'PUT_PERSONAL_MOVEMENT'
export const PUT_PERSONAL_MOVEMENT_FULFILLED = 'PUT_PERSONAL_MOVEMENT_FULFILLED'
export const PUT_PERSONAL_MOVEMENT_REJECTED = 'PUT_PERSONAL_MOVEMENT_REJECTED'
export const PUT_PERSONAL_MOVEMENT_DEFAULT = 'PUT_PERSONAL_MOVEMENT_DEFAULT'

export const DELETE_PERSONAL_MOVEMENT = 'DELETE_PERSONAL_MOVEMENT'
export const DELETE_PERSONAL_MOVEMENT_FULFILLED = 'DELETE_PERSONAL_MOVEMENT_FULFILLED'
export const DELETE_PERSONAL_MOVEMENT_REJECTED = 'DELETE_PERSONAL_MOVEMENT_REJECTED'
export const DELETE_PERSONAL_MOVEMENT_DEFAULT = 'DELETE_PERSONAL_MOVEMENT_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadPersonalMovements = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_PERSONAL_MOVEMENTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/personal-movements?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_PERSONAL_MOVEMENTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_PERSONAL_MOVEMENTS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_PERSONAL_MOVEMENTS_DEFAULT }))
  }
}

export const loadPersonalMovement = (id) => {
  return dispatch => {
    dispatch({
      type: GET_PERSONAL_MOVEMENT,
      payload: {}
    })
    return Axios.get(`${API_URL}/personal-movement/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_PERSONAL_MOVEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_PERSONAL_MOVEMENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_PERSONAL_MOVEMENT_DEFAULT }))
  }
}

export const submitPersonalMovement = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PERSONAL_MOVEMENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/personal-movement`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_PERSONAL_MOVEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_PERSONAL_MOVEMENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_PERSONAL_MOVEMENT_DEFAULT }))
  }
}

export const updatePersonalMovement = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_PERSONAL_MOVEMENT,
      payload: {}
    })
    return Axios.put(`${API_URL}/personal-movement/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_PERSONAL_MOVEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_PERSONAL_MOVEMENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_PERSONAL_MOVEMENT_DEFAULT }))
  }
}

export const deletePersonalMovement = (id, postById) => {
  return dispatch => {
    dispatch({
      type: DELETE_PERSONAL_MOVEMENT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/personal-movement/${id}?postById=${postById}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_PERSONAL_MOVEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_PERSONAL_MOVEMENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_PERSONAL_MOVEMENT_DEFAULT }))
  }
}


export const verifyPersonalMovement = (data) => {
  return dispatch => {
    dispatch({
      type: VERIFY,
      payload: {}
    })
    return Axios.post(`${API_URL}/personal-movement-verify`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: VERIFY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: VERIFY_REJECTED,
          payload: error
        })
      })
  }
}













