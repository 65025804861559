import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Avatar, Tooltip, Row, Col, notification, List } from 'antd'
import { connect } from 'react-redux'
import { loadLeaves, deleteLeave } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'
import enumLeaveType from '../../../../utilities/enumLeaveType'
import RemarksRenderer from '../../../../utilities/remarksRenderer'
import _ from 'lodash'
import { getUser } from '../../../../utilities/token'

const LeavesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    let [tableData, setTableData] = useState([])

    useEffect(() => {
        async function initiateSocket() {
            const id = await getUser().id
            const type = await getUser().type
            console.log("props.socket=====================>", `leaves.${id}`, type)
            props.socket.on(`leaves.${id}`, data => {
                const index = tableData.findIndex(item => item._id === data._id)
                if (data.status === 0) tableData.splice(index, 1) // delete
                else {
                    if (index !== -1) tableData[index] = data // update
                    else {
                        tableData.unshift({ ...data }) // create
                        tableData = tableData.slice(0, pagination.limit) // re-assigning and limit
                    }
                }
                setTableData([...tableData])
            });
        }
        initiateSocket()
    }, [tableData])

    // useEffect(() => {
    //     async function initiateSocket() {
    //         const id = await getUser().id
    //         const type = await getUser().type
    //         console.log("props.socket=====================>", `notifications.${id}`, type)
    //         props.socket.on(`notifications.${id}`, data => {
    //             console.log("notifications data", data)
    //         });
    //     }
    //     initiateSocket()
    // }, [tableData])

    useEffect(() => {
        setTableData([...props.leavesData.data])
    }, [props.leavesData])

    useEffect(() => {
        if (props.updateLeaveSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the leaves information.',
            });
        }
        if (props.addLeaveSuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new leaves information.',
            });
        }
        if (props.deleteLeaveSuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted leaves data.',
            });
        }

        props.onLoadLeaves(pagination)
    }, [props.updateLeaveSuccess, props.addLeaveSuccess, props.deleteLeaveSuccess])

    function handleDelete(id) {
        props.onDeleteLeave(id)
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    return (
        <Layout className='leaves-page'>
            <Card>
                <button className='add-item' onClick={() => {
                    setModalShow(true)
                    setActionType(ADD)
                    setSelectedRow({})
                }}>
                    <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer', fontSize: 20 }} icon={faPlusCircle} />
                    <span>Add Leave</span>
                </button>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Title
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Reason
                            </Col>
                            <Col md={5} lg={5} xs={24} sm={24}>
                                Project
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={tableData}
                    renderItem={data => <List.Item>
                        <Row style={{ width: '100%' }}>

                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item' >
                                    <h1>{data.type && data.type.title} <StatusIconDisplay status={data.status} /></h1>
                                    <p className='title'>{data.title}</p>
                                    <p className='date'>Date: {moment(data.date).format('LL')}</p>
                                </div>
                                <Row>
                                    <div className='documents'>
                                        {data.images && data.images.length !== 0 && <h1>Images / Documents</h1>}
                                        <Col md={24} lg={24} xs={24} sm={24}>
                                            <div className='d-flex'>
                                                {
                                                    data.images && data.images.map((item, index) => {
                                                        return (
                                                            <div className='p2' key={index}>
                                                                <ImagePresignedUrl src={item.url} style={{ width: 80, height: 100 }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                            </Col>

                            <Col md={9} lg={9} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p >{data.reason}</p>
                                </div>
                            </Col>

                            <Col md={5} lg={5} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p >{data.project && data.project.title}</p>
                                </div>
                            </Col>

                            <Col md={4} lg={4} xs={24} sm={24}>
                                <div className='actions'>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            setModalShow(true)
                                            setActionType(VIEW)
                                            setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>

                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                            </Col>
                            <Row style={{ width: '100%', margin: 12 }}>
                                <Col md={24} lg={24} xs={24} sm={24}>
                                    <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                </Col>
                            </Row>
                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        leavesData: state.client.leave.leavesData,
        addLeaveSuccess: state.client.leave.addLeaveSuccess,
        updateLeaveSuccess: state.client.leave.updateLeaveSuccess,
        deleteLeaveSuccess: state.client.leave.deleteLeaveSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadLeaves: data => dispatch(loadLeaves(data)),
        onDeleteLeave: id => dispatch(deleteLeave(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeavesComponent)
