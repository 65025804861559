import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd';
import './index.scss'
import TabsComponent from './tabs'
import SiderComponent from '../../shared/sider'
const { Header, Footer, Sider, Content } = Layout;

const Dashboard = (props) => {
    const [collapsed, setCollapsed] = useState(true)

    function onCollapse(collapsed) {
        setCollapsed(collapsed)
    };

    console.log("==========>", props)
    return (
        <Layout>
            {/* <Header className='client-header'>
                <h1>USER MANAGEMENT PORTAL</h1>
            </Header> */}
            <Layout>
                <SiderComponent {...props} />
                <Content style={{ backgroundColor: '#F0F2F5' }}>
                    <TabsComponent {...props} />
                </Content>
            </Layout>
            <Footer className='client-header'><h1 style={{ color: 'white' }}>Powered By Twintool</h1></Footer>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
