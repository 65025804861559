import React, { Fragment, useEffect, useState } from 'react'
import { Layout, Tabs, Card, Button, Modal } from 'antd'
import { connect } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Overtimes from '../../overtimes/components/index'
import { getLatestActivePayroll, submitPayroll, deleteLPayroll } from '../actions/index'
import './index.scss'

const Payroll = (props) => {
    const [hasActive, setHasActive] = useState(false)
    const [activePayrollData, setActivePayrollData] = useState({})

    function handleCreateNewPayroll() {
        Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: 'Confirming this, will create new payroll record',
            onOk() {
                console.log('OK');
                props.onSubmitPayroll().then(res => {
                    setHasActive(true)
                    setActivePayrollData(res.payload)
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function handleDeletePayroll() {
        Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: 'Confirming this, will delete the current record payroll. Make sure you know what you are doing. This cant be undone.',
            onOk() {
                console.log('OK', activePayrollData);
                props.onDeleteActivePayroll(activePayrollData._id).then(res => {
                    setHasActive(false)
                    setActivePayrollData({})
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    useEffect(() => {
        props.onGetLatestActivePayroll().then((res) => {
            if (res.payload.status) {
                setHasActive(true)
                setActivePayrollData(res.payload)
            }
        })
    }, [])

    return (
        <Layout className='page'>
            {
                hasActive ?
                    <Fragment>
                        <Card>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={handleDeletePayroll} danger>Delete This Payroll</Button>
                            </div>
                        </Card>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Time Keeping" key="1">
                                <Card>Time Keeping</Card>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Deductions" key="2">
                                <Card>Deductions</Card>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Allowances" key="3">
                                <Card>Allowances</Card>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Overtimes" key="4">
                                <Card>
                                    <Overtimes />
                                </Card>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="SL/VL" key="5">
                                <Card>SL/VL</Card>
                            </Tabs.TabPane>
                        </Tabs>
                    </Fragment> :
                    <Card>
                        <h1>You haven't active payroll. Do you want to create?</h1>
                        <Button type="primary" onClick={handleCreateNewPayroll}>Create Payroll</Button>
                    </Card>
            }
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateOvertimeSuccess: state.admin.overtime.updateOvertimeSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitPayroll: data => dispatch(submitPayroll(data)),
        onGetLatestActivePayroll: data => dispatch(getLatestActivePayroll(data)),
        onDeleteActivePayroll: id => dispatch(deleteLPayroll(id)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Payroll)