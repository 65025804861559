import {
    GET_ACCOUNTABILITIES,
    GET_ACCOUNTABILITIES_FULFILLED,
    GET_ACCOUNTABILITIES_REJECTED,
    PUT_ACCOUNTABILITY,
    PUT_ACCOUNTABILITY_FULFILLED,
    PUT_ACCOUNTABILITY_REJECTED,
    POST_ACCOUNTABILITY,
    POST_ACCOUNTABILITY_FULFILLED,
    POST_ACCOUNTABILITY_REJECTED,
    DELETE_ACCOUNTABILITY,
    DELETE_ACCOUNTABILITY_FULFILLED,
    DELETE_ACCOUNTABILITY_REJECTED,
    DELETE_ACCOUNTABILITY_DEFAULT,
    PUT_ACCOUNTABILITY_DEFAULT,
    POST_ACCOUNTABILITY_DEFAULT
} from '../actions'

export default function accountability(state = {
    accountabilitiesLoading: false,
    accountabilitiesSuccess: false,
    accountabilitiesRejected: false,
    accountabilitiesData: {
        data: []
    },
    verifiedSuccess: false,
    updateAccountabilityLoading: false,
    updateAccountabilitySuccess: false,
    updateAccountabilityRejected: false,
    addAccountabilityLoading: false,
    addAccountabilitySuccess: false,
    addAccountabilityRejected: false,
    deleteAccountabilityLoading: false,
    deleteAccountabilitySuccess: false,
    deleteAccountabilityRejected: false
}, action) {
    switch (action.type) {
        case GET_ACCOUNTABILITIES:
            return {
                ...state,
                accountabilitiesLoading: true,
                accountabilitiesSuccess: false,
                accountabilitiesRejected: false
            }
        case GET_ACCOUNTABILITIES_FULFILLED:
            return {
                ...state,
                accountabilitiesLoading: false,
                accountabilitiesSuccess: true,
                accountabilitiesData: action.payload
            }
        case GET_ACCOUNTABILITIES_REJECTED:
            return {
                ...state,
                accountabilitiesLoading: false,
                accountabilitiesRejected: true
            }
        case POST_ACCOUNTABILITY:
            return {
                ...state,
                addAccountabilityLoading: true,
                addAccountabilitySuccess: false,
                addAccountabilityRejected: false
            }
        case POST_ACCOUNTABILITY_FULFILLED:
            return {
                ...state,
                addAccountabilityLoading: false,
                addAccountabilitySuccess: true,
                addAccountabilityData: action.payload
            }
        case POST_ACCOUNTABILITY_REJECTED:
            return {
                ...state,
                addAccountabilityLoading: false,
                addAccountabilityRejected: true
            }
        case POST_ACCOUNTABILITY_DEFAULT:
            return {
                ...state,
                addAccountabilityLoading: false,
                addAccountabilitySuccess: false,
                addAccountabilityRejected: false
            }
        case PUT_ACCOUNTABILITY:
            return {
                ...state,
                updateAccountabilityLoading: true,
                updateAccountabilitySuccess: false,
                updateAccountabilityRejected: false
            }
        case PUT_ACCOUNTABILITY_FULFILLED:
            return {
                ...state,
                updateAccountabilityLoading: false,
                updateAccountabilitySuccess: true,
                updateAccountabilityData: action.payload
            }

        case PUT_ACCOUNTABILITY_REJECTED:
            return {
                ...state,
                updateAccountabilityLoading: false,
                updateAccountabilityRejected: true
            }
        case PUT_ACCOUNTABILITY_DEFAULT:
            return {
                ...state,
                updateAccountabilityLoading: false,
                updateAccountabilitySuccess: false,
                updateAccountabilityRejected: false
            }

        case DELETE_ACCOUNTABILITY:
            return {
                ...state,
                deleteAccountabilityLoading: true,
                deleteAccountabilitySuccess: false,
                deleteAccountabilityRejected: false
            }
        case DELETE_ACCOUNTABILITY_FULFILLED:
            return {
                ...state,
                deleteAccountabilityLoading: false,
                deleteAccountabilitySuccess: true,
            }
        case DELETE_ACCOUNTABILITY_REJECTED:
            return {
                ...state,
                deleteAccountabilityLoading: false,
                deleteAccountabilityRejected: true
            }
        case DELETE_ACCOUNTABILITY_DEFAULT:
            return {
                ...state,
                deleteAccountabilityLoading: false,
                deleteAccountabilitySuccess: false,
                deleteAccountabilityRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
