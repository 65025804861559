import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Avatar, Tooltip, Row, Col, notification, List, Rate } from 'antd'
import { connect } from 'react-redux'
import { loadPersonalMovements, deletePersonalMovement } from '../actions'
import './index.scss'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'
import enumPersonalMovementType from '../../../../utilities/enumPersonalMovementType'
import RemarksRenderer from '../../../../utilities/remarksRenderer'

const PersonalMovementsComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updatePersonalMovementSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the personalMovements information.',
            });
        }
        if (props.addPersonalMovementSuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new personalMovements information.',
            });
        }
        if (props.deletePersonalMovementSuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted personalMovements data.',
            });
        }

        props.onLoadPersonalMovements(pagination)
    }, [props.updatePersonalMovementSuccess, props.addPersonalMovementSuccess, props.deletePersonalMovementSuccess])

    function handleDelete(id) {
        props.onDeletePersonalMovement(id)
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    return (
        <Layout className='personalMovements-page'>
            <Card>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Information
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Codes
                            </Col>
                            {/* <Col md={5} lg={5} xs={24} sm={24}>
                                Verifier
                            </Col> */}
                        </Row>
                    }
                    footer={null}
                    dataSource={props.personalMovementsData.data}
                    renderItem={data => <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }}>
                                <Col md={24} lg={24} xs={24} sm={24}>
                                    <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                </Col>
                            </Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item' >
                                    <h1>{getKeyByValue(enumPersonalMovementType, data.type)} <StatusIconDisplay status={data.status} /></h1>
                                    <p className='position'>Position: {data.position}</p>
                                    <p className='job-level'>Job Level: {data.jobLevel} </p>
                                    <p className='date'>Effectivity Date: {moment(data.birthDate).format('LL')}</p>
                                </div>
                                <Row>
                                    <div className='documents'>
                                        {data.images && data.images.length !== 0 && <h1>Images / Documents</h1>}
                                        <Col md={24} lg={24} xs={24} sm={24}>
                                            <div className='d-flex'>
                                                {
                                                    data.images && data.images.map((item, index) => {
                                                        return (
                                                            <div className='p2'>
                                                                <ImagePresignedUrl key={index} src={item.url} style={{ width: 80, height: 100 }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                            </Col>

                            <Col md={9} lg={9} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p className='code'>Center Code: {data.centerCode}</p>
                                    <p className='code'>Property Code: {data.propertyCode}</p>
                                    <p className='code'>Division Code: {data.divisionCode}</p>
                                </div>
                            </Col>

                            {/* <Col md={5} lg={5} xs={24} sm={24}>
                                <div className='verifier'>
                                    <Avatar.Group maxCount={2} >
                                        {
                                            data.verifier.map((verify, index) => {
                                                return (
                                                    <Tooltip title={`${verify.firstName} ${verify.lastName}`} placement="top" key={index}>
                                                        {
                                                            Array.isArray(verify.image) &&
                                                            <Avatar icon={<ImagePresignedUrl objectFit='cover' src={verify.image && verify.image[0].url} style={{ width: 32, height: '100%' }} key={index} preview={false} />} />
                                                        }
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </Avatar.Group>
                                </div>

                            </Col> */}

                            <Col md={4} lg={4} xs={24} sm={24}>
                                <div className='actions'>
                                </div>
                            </Col>
                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        personalMovementsData: state.client.personalMovement.personalMovementsData,
        addPersonalMovementSuccess: state.client.personalMovement.addPersonalMovementSuccess,
        updatePersonalMovementSuccess: state.client.personalMovement.updatePersonalMovementSuccess,
        deletePersonalMovementSuccess: state.client.personalMovement.deletePersonalMovementSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPersonalMovements: data => dispatch(loadPersonalMovements(data)),
        onDeletePersonalMovement: id => dispatch(deletePersonalMovement(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalMovementsComponent)
