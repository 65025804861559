import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEE_FULFILLED = 'GET_EMPLOYEE_FULFILLED'
export const GET_EMPLOYEE_REJECTED = 'GET_EMPLOYEE_REJECTED'
export const GET_EMPLOYEE_DEFAULT = 'GET_EMPLOYEE_DEFAULT'

export const PUT_EMPLOYEE = 'PUT_EMPLOYEE'
export const PUT_EMPLOYEE_FULFILLED = 'PUT_EMPLOYEE_FULFILLED'
export const PUT_EMPLOYEE_REJECTED = 'PUT_EMPLOYEE_REJECTED'
export const PUT_EMPLOYEE_DEFAULT = 'PUT_EMPLOYEE_DEFAULT'

export const CHANGE_PASSWORD_ADMIN = 'CHANGE_PASSWORD_ADMIN'
export const CHANGE_PASSWORD_ADMIN_FULFILLED = 'CHANGE_PASSWORD_ADMIN_FULFILLED'
export const CHANGE_PASSWORD_ADMIN_REJECTED = 'CHANGE_PASSWORD_ADMIN_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadEmployee = (id) => {
  return dispatch => {
    dispatch({
      type: GET_EMPLOYEE,
      payload: {}
    })
    return Axios.get(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_EMPLOYEE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_EMPLOYEE_DEFAULT }))
  }
}

export const updateEmployee = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_EMPLOYEE,
      payload: {}
    })
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_EMPLOYEE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_EMPLOYEE_DEFAULT }))
  }
}

export const changePasswordAdmin = (data) => {
  return dispatch => {
    dispatch({
      type: CHANGE_PASSWORD_ADMIN,
      payload: {}
    })
    return Axios.put(`${API_URL}/change-password/${data.userId}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: CHANGE_PASSWORD_ADMIN_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: CHANGE_PASSWORD_ADMIN_REJECTED,
          payload: error
        })
      })
  }
}







