import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm, Rate } from 'antd'
import { loadPositions, deletePosition } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import Toolbar from '../../shared/components/toolbar'
import ViewRoles from './viewRoles'
import access from '../../../../utilities/access'
import Papa from 'papaparse'
const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadPositions } = props
        onLoadPositions(pagination)
        // eslint-disable-next-line
    }, [props.updatePositionSuccess, props.deletePositionSuccess, props.addPositionSuccess])

    useEffect(() => {
        const { onLoadPositions } = props
        onLoadPositions(pagination)
    }, [pagination])

    useEffect(() => {
        setTableData([...props.positionsData.data])
    }, [props.positionsData])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    function handleDelete(id) {
        props.onDeletePosition(id)
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Title": data.title,
                "Description": data.description,
                "Job Level": data.jobLevel,
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `positions_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `positions_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 200,

        },
        props.rolesManagement ? {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            render: (item, data) => {
                return (
                    <ViewRoles item={item} />
                )
            }
        } : {},
        !props.rolesManagement ? {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        } : {},
        {
            title: 'Job Level',
            dataIndex: 'jobLevel',
            key: 'jobLevel',
            // width: 200,
            // render: (item, data) => {
            //     return (
            //         <Rate value={item} style={{ color: '#108ee9' }} disabled />
            //     )
            // }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <Tag color="#108ee9">Active</Tag>
                    </div>
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LL'))
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        {
                            props.rolesManagement ?
                                access.check('utilities', 'manageAccess') ?
                                    <a onClick={() => {
                                        const selected = data.roles ? { ...data } : { ...data, roles: [] }
                                        props.setSelectedRow(selected)
                                        props.setIsRolesModalOpen(true)
                                    }} style={{ whiteSpace: 'nowrap' }}><u>Roles Management</u></a> : null :
                                <Fragment>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            props.setActionType(VIEW)
                                            props.setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        {
                                            access.check('positions', 'delete') &&
                                            <Menu.Item key="2" >
                                                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                    Delete
                                                </Popconfirm>
                                            </Menu.Item>
                                        }
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </Fragment>
                        }
                    </>
                )
            }
        },
    ];
    return (
        <Layout className='page'>
            {
                access.check('positions', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title={`Positions ${props.rolesManagement ? 'with Roles Management' : ''}`}
                                handleAdd={() => props.setActionType(ADD)}
                                removeAdd={props.rolesManagement || !access.check('positions', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                // handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'Active' },
                                    { id: 1, label: 'Inactive' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.positionsData.positionsLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.positionsData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        positionsData: state.admin.position.positionsData,
        positionsLoading: state.admin.position.positionsLoading,
        updatePositionSuccess: state.admin.position.updatePositionSuccess,
        addPositionSuccess: state.admin.position.addPositionSuccess,
        deletePositionSuccess: state.admin.position.deletePositionSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPositions: data => dispatch(loadPositions(data)),
        onDeletePosition: id => dispatch(deletePosition(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)