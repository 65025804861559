import React, { useState, useEffect } from 'react'
import { Row, Col, Tooltip, Button, Input, Dropdown, Menu } from 'antd'
import { PlusOutlined, DownloadOutlined, DownOutlined } from '@ant-design/icons';
import './index.scss'
const Toolbar = (props) => {
    const [filter, setFilter] = useState(props.statusArray || [])
    const [selectedFilter, setSelectedFilter] = useState(filter[0])
    const [sort, setSort] = useState('desc')
    const [gender, setGender] = useState(0)
    const [tenure, setTenure] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            props && props.handleSearch(searchTerm)
        }, 1300)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    function onAdd() {
        props && props.handleAdd()
    }

    function onSort(value) {
        props && props.handleSort(value)
        setSort(value)
    }

    function onGender(value) {
        props && props.handleGender(value)
        setGender(value)
    }

    function genderLabor(value) {
        switch (value) {
            case 0:
                return 'All'
            case 1:
                return 'Male'
            case 2:
                return 'Female'
            default:
                break;
        }
    }

    function onTenure(value) {
        props && props.handleTenure(value)
        setTenure(value)
    }

    return (
        <div className='page-header-toolbar'>
            <Row style={{ width: '100%' }}>
                <Col md={12} lg={12} xs={24} sm={24}>
                    <h1>{props.title}</h1>
                    {
                        (props && (props.handleAdd && !props.removeAdd)) &&
                        <Tooltip title="Add"><Button className='btn-circle' shape="circle" icon={<PlusOutlined />} onClick={() => onAdd()} /></Tooltip>
                    }
                    {
                        props.handleDownload &&
                        <Tooltip title="Download CSV"><Button onClick={() => props.handleDownload()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    }
                </Col>
                <Col md={12} lg={12} xs={24} sm={24} align='right'>
                    <Row>
                        {
                            (props && props.handleSearch) &&
                            <Col md={24} lg={24} xs={24} sm={24}>
                                <Input className='search' placeholder="Search Something..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} allowClear size="large" />
                            </Col>
                        }
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }} >
                        {
                            (props && props.handleStatus) &&
                            <Col md={12} lg={12} xs={24} sm={24}>
                                <Dropdown className='filter' overlay={(
                                    <Menu>
                                        {
                                            filter.map((data, index) => {
                                                return (
                                                    <Menu.Item onClick={() => {
                                                        setSelectedFilter(data)
                                                        props.handleStatus(data.id)
                                                    }} key={index}>{data && data.label}</Menu.Item>
                                                )
                                            })
                                        }
                                    </Menu>
                                )} placement="bottomCenter">
                                    <Button>
                                        <span className='fade'>Status </span> {selectedFilter && selectedFilter.label}<b><DownOutlined /></b>
                                    </Button>
                                </Dropdown>
                            </Col>
                        }
                        {
                            (props && props.handleSort) &&
                            <Col md={12} lg={12} xs={24} sm={24}>
                                <Dropdown className='dropdown-filter' overlay={(
                                    <Menu>
                                        <Menu.Item onClick={() => { onSort('desc') }} >DESC</Menu.Item>
                                        <Menu.Item onClick={() => { onSort('asc') }} >ASC</Menu.Item>
                                    </Menu>
                                )} placement="bottomCenter">
                                    <Button>
                                        <span className='fade'>Sort </span><span className='label'>{sort.toUpperCase()}</span><b><DownOutlined /></b>
                                    </Button>
                                </Dropdown>
                            </Col>
                        }
                        {
                            (props && props.handleGender) &&
                            <Col md={12} lg={12} xs={24} sm={24}>
                                <Dropdown className='dropdown-filter' overlay={(
                                    <Menu>
                                        <Menu.Item onClick={() => { onGender(0) }} >All</Menu.Item>
                                        <Menu.Item onClick={() => { onGender(1) }} >Male</Menu.Item>
                                        <Menu.Item onClick={() => { onGender(2) }} >Female</Menu.Item>
                                    </Menu>
                                )} placement="bottomCenter">
                                    <Button>
                                        <span className='fade'>Gender </span><span className='label'>{genderLabor(gender)}</span><b><DownOutlined /></b>
                                    </Button>
                                </Dropdown>
                            </Col>
                        }
                        {
                            (props && props.handleTenure) &&
                            <Col md={12} lg={12} xs={24} sm={24}>
                                <Dropdown className='dropdown-filter' overlay={(
                                    <Menu>
                                        <Menu.Item onClick={() => { onTenure(0) }} >All</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(5) }} >5 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(10) }} >10 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(15) }} >15 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(25) }} >25 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(30) }} >30 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(35) }} >35 years</Menu.Item>
                                    </Menu>
                                )} placement="bottomCenter">
                                    <Button>
                                        <span className='fade'>Tenure </span><span className='label'>{tenure === 0 ? 'All' : tenure} Years</span><b><DownOutlined /></b>
                                    </Button>
                                </Dropdown>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row >
        </div >
    )
}

export default Toolbar