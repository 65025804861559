const enumServiceStatus = {
    DELETED: 0,
    APPROVAL: 1,
    APPROVED: 2,
    ONGOING: 3,
    COMPLETED: 4,
    CANCELLED: 5,
    REJECTED: 6
}

export default enumServiceStatus