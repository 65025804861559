import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form } from 'antd'
import { loadAudit, updateAudit, submitAudit } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'

import './index.scss'

const FormAudit = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({ ...props.selectedRow })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = { ...value }

        if (props.actionType === ADD) {
            props.onSubmitAudit({ ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const auditId = props.selectedRow._id
            props.onUpdateAudit({ id: auditId, ...submitData }).then(res => {
                setLoading(false)
            })
        }

    }

    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`audit-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            // onFinishFailed={onFinishFailed}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input />
                        </Form.Item>

                    </Col>

                </Row>

                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' type="primary" htmlType="submit"
                                loading={props.updateAuditLoading || loading}
                            >
                                Submit Audit
                            </Button>

                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateAuditLoading: state.admin.audit.updateAuditLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAudit: (id) => dispatch(loadAudit(id)),
        onUpdateAudit: (data) => dispatch(updateAudit(data)),
        onSubmitAudit: (data) => dispatch(submitAudit(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormAudit)