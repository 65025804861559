import {
  GET_EMPLOYEES,
  GET_EMPLOYEES_FULFILLED,
  GET_EMPLOYEES_REJECTED,
  PUT_EMPLOYEE,
  PUT_EMPLOYEE_FULFILLED,
  PUT_EMPLOYEE_REJECTED,
  GET_EMPLOYEES_DEFAULT,
  PUT_EMPLOYEE_DEFAULT,
} from '../actions'

export default function employee(state = {
  employeesLoading: false,
  employeesSuccess: false,
  employeesRejected: false,
  employeesData: {
    data: []
  },
  updateEmployeeLoading: false,
  updateEmployeeSuccess: false,
  updateEmployeeRejected: false,
}, action) {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        employeesLoading: true,
        employeesSuccess: false,
        employeesRejected: false
      }
    case GET_EMPLOYEES_FULFILLED:
      return {
        ...state,
        employeesLoading: false,
        employeesSuccess: true,
        employeesData: action.payload
      }
    case GET_EMPLOYEES_REJECTED:
      return {
        ...state,
        employeesLoading: false,
        employeesRejected: true
      }
    case GET_EMPLOYEES_DEFAULT:
      return {
        ...state,
        employeesLoading: false,
        employeesSuccess: false,
        employeesRejected: false
      }
    case PUT_EMPLOYEE:
      return {
        ...state,
        updateEmployeeLoading: true,
        updateEmployeeSuccess: false,
        updateEmployeeRejected: false
      }
    case PUT_EMPLOYEE_FULFILLED:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeSuccess: true,
        updateEmployeeData: action.payload
      }
    case PUT_EMPLOYEE_REJECTED:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeRejected: true
      }
    case PUT_EMPLOYEE_DEFAULT:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeSuccess: false,
        updateEmployeeRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
