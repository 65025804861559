import {
  GET_DEDUCTIONS,
  GET_DEDUCTIONS_FULFILLED,
  GET_DEDUCTIONS_REJECTED,
  PUT_DEDUCTION,
  PUT_DEDUCTION_FULFILLED,
  PUT_DEDUCTION_REJECTED,
  POST_DEDUCTION,
  POST_DEDUCTION_FULFILLED,
  POST_DEDUCTION_REJECTED,
  DELETE_DEDUCTION,
  DELETE_DEDUCTION_FULFILLED,
  DELETE_DEDUCTION_REJECTED,
  GET_DEDUCTIONS_DEFAULT,
  POST_DEDUCTION_DEFAULT,
  PUT_DEDUCTION_DEFAULT,
  DELETE_DEDUCTION_DEFAULT,
  GET_DEDUCTION_TYPES,
  GET_DEDUCTION_TYPES_FULFILLED,
  GET_DEDUCTION_TYPES_REJECTED,
  PUT_DEDUCTION_TYPE,
  PUT_DEDUCTION_TYPE_FULFILLED,
  PUT_DEDUCTION_TYPE_REJECTED,
  POST_DEDUCTION_TYPE,
  POST_DEDUCTION_TYPE_FULFILLED,
  POST_DEDUCTION_TYPE_REJECTED,
  DELETE_DEDUCTION_TYPE,
  DELETE_DEDUCTION_TYPE_FULFILLED,
  DELETE_DEDUCTION_TYPE_REJECTED,
  GET_DEDUCTION_TYPES_DEFAULT,
  POST_DEDUCTION_TYPE_DEFAULT,
  PUT_DEDUCTION_TYPE_DEFAULT,
  DELETE_DEDUCTION_TYPE_DEFAULT,

} from '../actions'

export default function deduction(state = {
  deductionsLoading: false,
  deductionsSuccess: false,
  deductionsRejected: false,
  deductionsData: {
    data: []
  },
  deductionTypesData: {
    data: []
  },
  updateDeductionLoading: false,
  updateDeductionSuccess: false,
  updateDeductionRejected: false,
  addDeductionLoading: false,
  addDeductionSuccess: false,
  addDeductionRejected: false,
  deleteDeductionLoading: false,
  deleteDeductionSuccess: false,
  deleteDeductionRejected: false
}, action) {
  switch (action.type) {
    case GET_DEDUCTIONS:
      return {
        ...state,
        deductionsLoading: true,
        deductionsSuccess: false,
        deductionsRejected: false
      }
    case GET_DEDUCTIONS_FULFILLED:
      return {
        ...state,
        deductionsLoading: false,
        deductionsSuccess: true,
        deductionsData: action.payload
      }
    case GET_DEDUCTIONS_REJECTED:
      return {
        ...state,
        deductionsLoading: false,
        deductionsRejected: true
      }
    case GET_DEDUCTIONS_DEFAULT:
      return {
        ...state,
        deductionsLoading: false,
        deductionsSuccess: false,
        deductionsRejected: false
      }
    case POST_DEDUCTION:
      return {
        ...state,
        addDeductionLoading: true,
        addDeductionSuccess: false,
        addDeductionRejected: false
      }
    case POST_DEDUCTION_FULFILLED:
      return {
        ...state,
        addDeductionLoading: false,
        addDeductionSuccess: true,
        addDeductionData: action.payload
      }
    case POST_DEDUCTION_REJECTED:
      return {
        ...state,
        addDeductionLoading: false,
        addDeductionRejected: true
      }
    case POST_DEDUCTION_DEFAULT:
      return {
        ...state,
        addDeductionLoading: false,
        addDeductionSuccess: false,
        addDeductionRejected: false
      }
    case PUT_DEDUCTION:
      return {
        ...state,
        updateDeductionLoading: true,
        updateDeductionSuccess: false,
        updateDeductionRejected: false
      }
    case PUT_DEDUCTION_FULFILLED:
      return {
        ...state,
        updateDeductionLoading: false,
        updateDeductionSuccess: true,
        updateDeductionData: action.payload
      }
    case PUT_DEDUCTION_REJECTED:
      return {
        ...state,
        updateDeductionLoading: false,
        updateDeductionRejected: true
      }
    case PUT_DEDUCTION_DEFAULT:
      return {
        ...state,
        updateDeductionLoading: false,
        updateDeductionSuccess: false,
        updateDeductionRejected: false
      }
    case DELETE_DEDUCTION:
      return {
        ...state,
        deleteDeductionLoading: true,
        deleteDeductionSuccess: false,
        deleteDeductionRejected: false
      }
    case DELETE_DEDUCTION_FULFILLED:
      return {
        ...state,
        deleteDeductionLoading: false,
        deleteDeductionSuccess: true,
      }
    case DELETE_DEDUCTION_REJECTED:
      return {
        ...state,
        deleteDeductionLoading: false,
        deleteDeductionRejected: true
      }
    case DELETE_DEDUCTION_DEFAULT:
      return {
        ...state,
        deleteDeductionLoading: false,
        deleteDeductionSuccess: false,
        deleteDeductionRejected: false
      }


    case GET_DEDUCTION_TYPES:
      return {
        ...state,
        deductionTypesLoading: true,
        deductionTypesSuccess: false,
        deductionTypesRejected: false
      }
    case GET_DEDUCTION_TYPES_FULFILLED:
      return {
        ...state,
        deductionTypesLoading: false,
        deductionTypesSuccess: true,
        deductionTypesData: action.payload
      }
    case GET_DEDUCTION_TYPES_REJECTED:
      return {
        ...state,
        deductionTypesLoading: false,
        deductionTypesRejected: true
      }
    case GET_DEDUCTION_TYPES_DEFAULT:
      return {
        ...state,
        deductionTypesLoading: false,
        deductionTypesSuccess: false,
        deductionTypesRejected: false
      }
    case POST_DEDUCTION_TYPE:
      return {
        ...state,
        addDeductionTypeLoading: true,
        addDeductionTypeSuccess: false,
        addDeductionTypeRejected: false
      }
    case POST_DEDUCTION_TYPE_FULFILLED:
      return {
        ...state,
        addDeductionTypeLoading: false,
        addDeductionTypeSuccess: true,
        addDeductionTypeData: action.payload
      }
    case POST_DEDUCTION_TYPE_REJECTED:
      return {
        ...state,
        addDeductionTypeLoading: false,
        addDeductionTypeRejected: true
      }
    case POST_DEDUCTION_TYPE_DEFAULT:
      return {
        ...state,
        addDeductionTypeLoading: false,
        addDeductionTypeSuccess: false,
        addDeductionTypeRejected: false
      }
    case PUT_DEDUCTION_TYPE:
      return {
        ...state,
        updateDeductionTypeLoading: true,
        updateDeductionTypeSuccess: false,
        updateDeductionTypeRejected: false
      }
    case PUT_DEDUCTION_TYPE_FULFILLED:
      return {
        ...state,
        updateDeductionTypeLoading: false,
        updateDeductionTypeSuccess: true,
        updateDeductionTypeData: action.payload
      }
    case PUT_DEDUCTION_TYPE_REJECTED:
      return {
        ...state,
        updateDeductionTypeLoading: false,
        updateDeductionTypeRejected: true
      }
    case PUT_DEDUCTION_TYPE_DEFAULT:
      return {
        ...state,
        updateDeductionTypeLoading: false,
        updateDeductionTypeSuccess: false,
        updateDeductionTypeRejected: false
      }
    case DELETE_DEDUCTION_TYPE:
      return {
        ...state,
        deleteDeductionTypeLoading: true,
        deleteDeductionTypeSuccess: false,
        deleteDeductionTypeRejected: false
      }
    case DELETE_DEDUCTION_TYPE_FULFILLED:
      return {
        ...state,
        deleteDeductionTypeLoading: false,
        deleteDeductionTypeSuccess: true,
      }
    case DELETE_DEDUCTION_TYPE_REJECTED:
      return {
        ...state,
        deleteDeductionTypeLoading: false,
        deleteDeductionTypeRejected: true
      }
    case DELETE_DEDUCTION_TYPE_DEFAULT:
      return {
        ...state,
        deleteDeductionTypeLoading: false,
        deleteDeductionTypeSuccess: false,
        deleteDeductionTypeRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
