import {
  GET_EMPLOYEE,
  GET_EMPLOYEE_FULFILLED,
  GET_EMPLOYEE_REJECTED,
  PUT_EMPLOYEE,
  PUT_EMPLOYEE_FULFILLED,
  PUT_EMPLOYEE_REJECTED,
  PUT_EMPLOYEE_DEFAULT
} from '../actions'

export default function employee(state = {
  employeeLoading: false,
  employeeSuccess: false,
  employeeRejected: false,
  employeeData: {},
  updateEmployeeLoading: false,
  updateEmployeeSuccess: false,
  updateEmployeeRejected: false,
}, action) {
  switch (action.type) {
    case GET_EMPLOYEE:
      return {
        ...state,
        employeeLoading: true,
        employeeSuccess: false,
        employeeRejected: false
      }
    case GET_EMPLOYEE_FULFILLED:
      return {
        ...state,
        employeeLoading: false,
        employeeSuccess: true,
        employeeData: action.payload
      }
    case GET_EMPLOYEE_REJECTED:
      return {
        ...state,
        employeeLoading: false,
        employeeRejected: true
      }


    case PUT_EMPLOYEE:
      return {
        ...state,
        updateEmployeeLoading: true,
        updateEmployeeSuccess: false,
        updateEmployeeRejected: false
      }
    case PUT_EMPLOYEE_FULFILLED:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeSuccess: true,
        updateEmployeeData: action.payload
      }
    case PUT_EMPLOYEE_REJECTED:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeRejected: true
      }
    case PUT_EMPLOYEE_DEFAULT:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeSuccess: false,
        updateEmployeeRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
