import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_SALARIES = 'GET_SALARIES'
export const GET_SALARIES_FULFILLED = 'GET_SALARIES_FULFILLED'
export const GET_SALARIES_REJECTED = 'GET_SALARIES_REJECTED'
export const GET_SALARIES_DEFAULT = 'GET_SALARIES_DEFAULT'

export const GET_SALARY = 'GET_SALARY'
export const GET_SALARY_FULFILLED = 'GET_SALARY_FULFILLED'
export const GET_SALARY_REJECTED = 'GET_SALARY_REJECTED'
export const GET_SALARY_DEFAULT = 'GET_SALARY_DEFAULT'

export const POST_SALARY = 'POST_SALARY'
export const POST_SALARY_FULFILLED = 'POST_SALARY_FULFILLED'
export const POST_SALARY_REJECTED = 'POST_SALARY_REJECTED'
export const POST_SALARY_DEFAULT = 'POST_SALARY_DEFAULT'

export const POST_SALARY_RATE = 'POST_SALARY_RATE'
export const POST_SALARY_RATE_FULFILLED = 'POST_SALARY_RATE_FULFILLED'
export const POST_SALARY_RATE_REJECTED = 'POST_SALARY_RATE_REJECTED'
export const POST_SALARY_RATE_DEFAULT = 'POST_SALARY_RATE_DEFAULT'

export const PUT_SALARY = 'PUT_SALARY'
export const PUT_SALARY_FULFILLED = 'PUT_SALARY_FULFILLED'
export const PUT_SALARY_REJECTED = 'PUT_SALARY_REJECTED'
export const PUT_SALARY_DEFAULT = 'PUT_SALARY_DEFAULT'

export const DELETE_SALARY_RATE = 'DELETE_SALARY_RATE'
export const DELETE_SALARY_RATE_FULFILLED = 'DELETE_SALARY_RATE_FULFILLED'
export const DELETE_SALARY_RATE_REJECTED = 'DELETE_SALARY_RATE_REJECTED'
export const DELETE_SALARY_RATE_DEFAULT = 'DELETE_SALARY_RATE_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadEmployeeSalary = (id) => {
  return dispatch => {
    dispatch({
      type: GET_SALARY,
      payload: {}
    })
    return Axios.get(`${API_URL}/emp-salary/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SALARY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SALARY_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_SALARY_DEFAULT }))
  }
}

export const submitSalary = (data) => {
  return dispatch => {
    dispatch({
      type: POST_SALARY,
      payload: {}
    })
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_SALARY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_SALARY_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_SALARY_DEFAULT }))
  }
}

export const updateSalary = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_SALARY,
      payload: {}
    })
    return Axios.put(`${API_URL}/salary/${data.salaryId}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_SALARY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_SALARY_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_SALARY_DEFAULT }))
  }
}

export const submitSalaryRate = (data) => {
  return dispatch => {
    dispatch({
      type: POST_SALARY_RATE,
      payload: {}
    })
    return Axios.post(`${API_URL}/salary-rate`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_SALARY_RATE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_SALARY_RATE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_SALARY_RATE_DEFAULT }))
  }
}

export const deleteSalaryRate = (salaryRateId) => {
  return dispatch => {
    dispatch({
      type: DELETE_SALARY_RATE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/salary-rate/${salaryRateId}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_SALARY_RATE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_SALARY_RATE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_SALARY_RATE_DEFAULT }))
  }
}











