import {
  GET_DOCUMENTS,
  GET_DOCUMENTS_FULFILLED,
  GET_DOCUMENTS_REJECTED,
  PUT_DOCUMENT,
  PUT_DOCUMENT_FULFILLED,
  PUT_DOCUMENT_REJECTED,
  POST_DOCUMENT,
  POST_DOCUMENT_FULFILLED,
  POST_DOCUMENT_REJECTED,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_FULFILLED,
  DELETE_DOCUMENT_REJECTED,
  GET_DOCUMENTS_DEFAULT,
  POST_DOCUMENT_DEFAULT,
  PUT_DOCUMENT_DEFAULT,
  DELETE_DOCUMENT_DEFAULT,
  VERIFY,
  VERIFY_FULFILLED,
  VERIFY_REJECTED,
  VERIFY_DEFAULT
} from '../actions'

export default function document(state = {
  documentsLoading: false,
  documentsSuccess: false,
  documentsRejected: false,
  documentsData: {
    data: []
  },
  verifiedSuccess: false,
  updateDocumentLoading: false,
  updateDocumentSuccess: false,
  updateDocumentRejected: false,
  addDocumentLoading: false,
  addDocumentSuccess: false,
  addDocumentRejected: false,
  deleteDocumentLoading: false,
  deleteDocumentSuccess: false,
  deleteDocumentRejected: false
}, action) {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documentsLoading: true,
        documentsSuccess: false,
        documentsRejected: false
      }
    case GET_DOCUMENTS_FULFILLED:
      return {
        ...state,
        documentsLoading: false,
        documentsSuccess: true,
        documentsData: action.payload
      }
    case GET_DOCUMENTS_REJECTED:
      return {
        ...state,
        documentsLoading: false,
        documentsRejected: true
      }
    case GET_DOCUMENTS_DEFAULT:
      return {
        ...state,
        documentsLoading: false,
        documentsSuccess: false,
        documentsRejected: false
      }
    case POST_DOCUMENT:
      return {
        ...state,
        addDocumentLoading: true,
        addDocumentSuccess: false,
        addDocumentRejected: false
      }
    case POST_DOCUMENT_FULFILLED:
      return {
        ...state,
        addDocumentLoading: false,
        addDocumentSuccess: true,
        addDocumentData: action.payload
      }
    case POST_DOCUMENT_REJECTED:
      return {
        ...state,
        addDocumentLoading: false,
        addDocumentRejected: true
      }
    case POST_DOCUMENT_DEFAULT:
      return {
        ...state,
        addDocumentLoading: false,
        addDocumentSuccess: false,
        addDocumentRejected: false
      }
    case PUT_DOCUMENT:
      return {
        ...state,
        updateDocumentLoading: true,
        updateDocumentSuccess: false,
        updateDocumentRejected: false
      }
    case PUT_DOCUMENT_FULFILLED:
      return {
        ...state,
        updateDocumentLoading: false,
        updateDocumentSuccess: true,
        updateDocumentData: action.payload
      }
    case PUT_DOCUMENT_REJECTED:
      return {
        ...state,
        updateDocumentLoading: false,
        updateDocumentRejected: true
      }
    case PUT_DOCUMENT_DEFAULT:
      return {
        ...state,
        updateDocumentLoading: false,
        updateDocumentSuccess: false,
        updateDocumentRejected: false
      }
    case DELETE_DOCUMENT:
      return {
        ...state,
        deleteDocumentLoading: true,
        deleteDocumentSuccess: false,
        deleteDocumentRejected: false
      }
    case DELETE_DOCUMENT_FULFILLED:
      return {
        ...state,
        deleteDocumentLoading: false,
        deleteDocumentSuccess: true,
      }
    case DELETE_DOCUMENT_REJECTED:
      return {
        ...state,
        deleteDocumentLoading: false,
        deleteDocumentRejected: true
      }
    case DELETE_DOCUMENT_DEFAULT:
      return {
        ...state,
        deleteDocumentLoading: false,
        deleteDocumentSuccess: false,
        deleteDocumentRejected: false
      }
    case VERIFY:
      return {
        ...state,
        verifiedSuccess: false,
      }
    case VERIFY_FULFILLED:
      return {
        ...state,
        verifiedSuccess: true,
      }
    case VERIFY_REJECTED:
      return {
        ...state,
        verifiedSuccess: false,
      }
    default:
      return {
        ...state
      }
  }
}
