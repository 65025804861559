import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import Admins from '../../admins/components'
import Positions from '../../positions/components'
import './index.scss'
const queryString = require('query-string');

const { TabPane } = Tabs
const Utilities = (props) => {
    const [defaultOpenKey, setDefaultOpenKey] = useState('admins')
    useEffect(() => {
        const query = queryString.parse(props.location.search)
        setDefaultOpenKey(query.page)
    }, [])

    return (
        <div className='utilities-container'>
            <Tabs activeKey={defaultOpenKey} onChange={(e) => setDefaultOpenKey(e)} >
                <TabPane tab="Administrators List" key="admins">
                    <Admins />
                </TabPane>
                <TabPane tab="Position's Access Definition" key="positions">
                    <Positions
                        rolesManagement={true}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Utilities