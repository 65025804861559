import {
  GET_EDUCATIONS,
  GET_EDUCATIONS_FULFILLED,
  GET_EDUCATIONS_REJECTED,
  PUT_EDUCATION,
  PUT_EDUCATION_FULFILLED,
  PUT_EDUCATION_REJECTED,
  POST_EDUCATION,
  POST_EDUCATION_FULFILLED,
  POST_EDUCATION_REJECTED,
  DELETE_EDUCATION,
  DELETE_EDUCATION_FULFILLED,
  DELETE_EDUCATION_REJECTED,
  GET_EDUCATIONS_DEFAULT,
  POST_EDUCATION_DEFAULT,
  PUT_EDUCATION_DEFAULT,
  DELETE_EDUCATION_DEFAULT,
  VERIFY,
  VERIFY_FULFILLED,
  VERIFY_REJECTED,
  VERIFY_DEFAULT
} from '../actions'

export default function education(state = {
  educationsLoading: false,
  educationsSuccess: false,
  educationsRejected: false,
  educationsData: {
    data: []
  },
  verifiedSuccess: false,
  updateEducationLoading: false,
  updateEducationSuccess: false,
  updateEducationRejected: false,
  addEducationLoading: false,
  addEducationSuccess: false,
  addEducationRejected: false,
  deleteEducationLoading: false,
  deleteEducationSuccess: false,
  deleteEducationRejected: false
}, action) {
  switch (action.type) {
    case GET_EDUCATIONS:
      return {
        ...state,
        educationsLoading: true,
        educationsSuccess: false,
        educationsRejected: false
      }
    case GET_EDUCATIONS_FULFILLED:
      return {
        ...state,
        educationsLoading: false,
        educationsSuccess: true,
        educationsData: action.payload
      }
    case GET_EDUCATIONS_REJECTED:
      return {
        ...state,
        educationsLoading: false,
        educationsRejected: true
      }
    case GET_EDUCATIONS_DEFAULT:
      return {
        ...state,
        educationsLoading: false,
        educationsSuccess: false,
        educationsRejected: false
      }
    case POST_EDUCATION:
      return {
        ...state,
        addEducationLoading: true,
        addEducationSuccess: false,
        addEducationRejected: false
      }
    case POST_EDUCATION_FULFILLED:
      return {
        ...state,
        addEducationLoading: false,
        addEducationSuccess: true,
        addEducationData: action.payload
      }
    case POST_EDUCATION_REJECTED:
      return {
        ...state,
        addEducationLoading: false,
        addEducationRejected: true
      }
    case POST_EDUCATION_DEFAULT:
      return {
        ...state,
        addEducationLoading: false,
        addEducationSuccess: false,
        addEducationRejected: false
      }
    case PUT_EDUCATION:
      return {
        ...state,
        updateEducationLoading: true,
        updateEducationSuccess: false,
        updateEducationRejected: false
      }
    case PUT_EDUCATION_FULFILLED:
      return {
        ...state,
        updateEducationLoading: false,
        updateEducationSuccess: true,
        updateEducationData: action.payload
      }
    case PUT_EDUCATION_REJECTED:
      return {
        ...state,
        updateEducationLoading: false,
        updateEducationRejected: true
      }
    case PUT_EDUCATION_DEFAULT:
      return {
        ...state,
        updateEducationLoading: false,
        updateEducationSuccess: false,
        updateEducationRejected: false
      }
    case DELETE_EDUCATION:
      return {
        ...state,
        deleteEducationLoading: true,
        deleteEducationSuccess: false,
        deleteEducationRejected: false
      }
    case DELETE_EDUCATION_FULFILLED:
      return {
        ...state,
        deleteEducationLoading: false,
        deleteEducationSuccess: true,
      }
    case DELETE_EDUCATION_REJECTED:
      return {
        ...state,
        deleteEducationLoading: false,
        deleteEducationRejected: true
      }
    case DELETE_EDUCATION_DEFAULT:
      return {
        ...state,
        deleteEducationLoading: false,
        deleteEducationSuccess: false,
        deleteEducationRejected: false
      }
    case VERIFY:
      return {
        ...state,
        verifiedSuccess: false,
      }
    case VERIFY_FULFILLED:
      return {
        ...state,
        verifiedSuccess: true,
      }
    case VERIFY_REJECTED:
      return {
        ...state,
        verifiedSuccess: false,
      }
    default:
      return {
        ...state
      }
  }
}
