import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ADMINS = 'GET_ADMINS'
export const GET_ADMINS_FULFILLED = 'GET_ADMINS_FULFILLED'
export const GET_ADMINS_REJECTED = 'GET_ADMINS_REJECTED'
export const GET_ADMINS_DEFAULT = 'GET_ADMINS_DEFAULT'

export const GET_ADMIN = 'GET_ADMIN'
export const GET_ADMIN_FULFILLED = 'GET_ADMIN_FULFILLED'
export const GET_ADMIN_REJECTED = 'GET_ADMIN_REJECTED'
export const GET_ADMIN_DEFAULT = 'GET_ADMIN_DEFAULT'

export const POST_ADMIN = 'POST_ADMIN'
export const POST_ADMIN_FULFILLED = 'POST_ADMIN_FULFILLED'
export const POST_ADMIN_REJECTED = 'POST_ADMIN_REJECTED'
export const POST_ADMIN_DEFAULT = 'POST_ADMIN_DEFAULT'

export const PUT_ADMIN = 'PUT_ADMIN'
export const PUT_ADMIN_FULFILLED = 'PUT_ADMIN_FULFILLED'
export const PUT_ADMIN_REJECTED = 'PUT_ADMIN_REJECTED'
export const PUT_ADMIN_DEFAULT = 'PUT_ADMIN_DEFAULT'

export const DELETE_ADMIN = 'DELETE_ADMIN'
export const DELETE_ADMIN_FULFILLED = 'DELETE_ADMIN_FULFILLED'
export const DELETE_ADMIN_REJECTED = 'DELETE_ADMIN_REJECTED'
export const DELETE_ADMIN_DEFAULT = 'DELETE_ADMIN_DEFAULT'

export const CHANGE_PASSWORD_ADMIN = 'CHANGE_PASSWORD_ADMIN'
export const CHANGE_PASSWORD_ADMIN_FULFILLED = 'CHANGE_PASSWORD_ADMIN_FULFILLED'
export const CHANGE_PASSWORD_ADMIN_REJECTED = 'CHANGE_PASSWORD_ADMIN_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadAdmins = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_ADMINS,
      payload: {}
    })
    return Axios.get(`${API_URL}/admins?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ADMINS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ADMINS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ADMINS_DEFAULT }))
  }
}

export const loadAdmin = (id) => {
  return dispatch => {
    dispatch({
      type: GET_ADMIN,
      payload: {}
    })
    return Axios.get(`${API_URL}/admin/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_ADMIN_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_ADMIN_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ADMIN_DEFAULT }))
  }
}

export const submitAdmin = (data) => {
  return dispatch => {
    dispatch({
      type: POST_ADMIN,
      payload: {}
    })
    return Axios.post(`${API_URL}/admin`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_ADMIN_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_ADMIN_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_ADMIN_DEFAULT }))
  }
}

export const updateAdmin = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_ADMIN,
      payload: {}
    })
    return Axios.put(`${API_URL}/admin/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_ADMIN_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_ADMIN_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_ADMIN_DEFAULT }))
  }
}

export const deleteAdmin = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_ADMIN,
      payload: {}
    })
    return Axios.delete(`${API_URL}/admin/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_ADMIN_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_ADMIN_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_ADMIN_DEFAULT }))
  }
}

export const changePasswordAdmin = (data) => {
  return dispatch => {
    dispatch({
      type: CHANGE_PASSWORD_ADMIN,
      payload: {}
    })
    return Axios.put(`${API_URL}/change-password/${data.userId}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: CHANGE_PASSWORD_ADMIN_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: CHANGE_PASSWORD_ADMIN_REJECTED,
          payload: error
        })
      })
  }
}









