import React from 'react'
import { connect } from 'react-redux'
import { Layout, Tabs } from 'antd'
import Employees from './employee'
import './index.scss'
const { TabPane } = Tabs;
const Reports = (props) => {
    return (
        <Layout className='page'>
            <Tabs defaultActiveKey="1"  >
                <TabPane tab="Employees Reports" key="1" style={{ marginTop: 12 }}>
                    <Employees />
                </TabPane>
            </Tabs>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Reports)