import {
  GET_EMPLOYMENT_HISTORIES,
  GET_EMPLOYMENT_HISTORIES_FULFILLED,
  GET_EMPLOYMENT_HISTORIES_REJECTED,
  PUT_EMPLOYMENT_HISTORY,
  PUT_EMPLOYMENT_HISTORY_FULFILLED,
  PUT_EMPLOYMENT_HISTORY_REJECTED,
  POST_EMPLOYMENT_HISTORY,
  POST_EMPLOYMENT_HISTORY_FULFILLED,
  POST_EMPLOYMENT_HISTORY_REJECTED,
  DELETE_EMPLOYMENT_HISTORY,
  DELETE_EMPLOYMENT_HISTORY_FULFILLED,
  DELETE_EMPLOYMENT_HISTORY_REJECTED,
  GET_EMPLOYMENT_HISTORIES_DEFAULT,
  POST_EMPLOYMENT_HISTORY_DEFAULT,
  PUT_EMPLOYMENT_HISTORY_DEFAULT,
  DELETE_EMPLOYMENT_HISTORY_DEFAULT,
  VERIFY,
  VERIFY_FULFILLED,
  VERIFY_REJECTED,
  VERIFY_DEFAULT
} from '../actions'

export default function employmentHistory(state = {
  employmentHistoriesLoading: false,
  employmentHistoriesSuccess: false,
  employmentHistoriesRejected: false,
  employmentHistoriesData: {
    data: []
  },
  verifiedSuccess: false,
  updateEmploymentHistoryLoading: false,
  updateEmploymentHistorySuccess: false,
  updateEmploymentHistoryRejected: false,
  addEmploymentHistoryLoading: false,
  addEmploymentHistorySuccess: false,
  addEmploymentHistoryRejected: false,
  deleteEmploymentHistoryLoading: false,
  deleteEmploymentHistorySuccess: false,
  deleteEmploymentHistoryRejected: false
}, action) {
  switch (action.type) {
    case GET_EMPLOYMENT_HISTORIES:
      return {
        ...state,
        employmentHistoriesLoading: true,
        employmentHistoriesSuccess: false,
        employmentHistoriesRejected: false
      }
    case GET_EMPLOYMENT_HISTORIES_FULFILLED:
      return {
        ...state,
        employmentHistoriesLoading: false,
        employmentHistoriesSuccess: true,
        employmentHistoriesData: action.payload
      }
    case GET_EMPLOYMENT_HISTORIES_REJECTED:
      return {
        ...state,
        employmentHistoriesLoading: false,
        employmentHistoriesRejected: true
      }
    case GET_EMPLOYMENT_HISTORIES_DEFAULT:
      return {
        ...state,
        employmentHistoriesLoading: false,
        employmentHistoriesSuccess: false,
        employmentHistoriesRejected: false
      }
    case POST_EMPLOYMENT_HISTORY:
      return {
        ...state,
        addEmploymentHistoryLoading: true,
        addEmploymentHistorySuccess: false,
        addEmploymentHistoryRejected: false
      }
    case POST_EMPLOYMENT_HISTORY_FULFILLED:
      return {
        ...state,
        addEmploymentHistoryLoading: false,
        addEmploymentHistorySuccess: true,
        addEmploymentHistoryData: action.payload
      }
    case POST_EMPLOYMENT_HISTORY_REJECTED:
      return {
        ...state,
        addEmploymentHistoryLoading: false,
        addEmploymentHistoryRejected: true
      }
    case POST_EMPLOYMENT_HISTORY_DEFAULT:
      return {
        ...state,
        addEmploymentHistoryLoading: false,
        addEmploymentHistorySuccess: false,
        addEmploymentHistoryRejected: false
      }
    case PUT_EMPLOYMENT_HISTORY:
      return {
        ...state,
        updateEmploymentHistoryLoading: true,
        updateEmploymentHistorySuccess: false,
        updateEmploymentHistoryRejected: false
      }
    case PUT_EMPLOYMENT_HISTORY_FULFILLED:
      return {
        ...state,
        updateEmploymentHistoryLoading: false,
        updateEmploymentHistorySuccess: true,
        updateEmploymentHistoryData: action.payload
      }
    case PUT_EMPLOYMENT_HISTORY_REJECTED:
      return {
        ...state,
        updateEmploymentHistoryLoading: false,
        updateEmploymentHistoryRejected: true
      }
    case PUT_EMPLOYMENT_HISTORY_DEFAULT:
      return {
        ...state,
        updateEmploymentHistoryLoading: false,
        updateEmploymentHistorySuccess: false,
        updateEmploymentHistoryRejected: false
      }
    case DELETE_EMPLOYMENT_HISTORY:
      return {
        ...state,
        deleteEmploymentHistoryLoading: true,
        deleteEmploymentHistorySuccess: false,
        deleteEmploymentHistoryRejected: false
      }
    case DELETE_EMPLOYMENT_HISTORY_FULFILLED:
      return {
        ...state,
        deleteEmploymentHistoryLoading: false,
        deleteEmploymentHistorySuccess: true,
      }
    case DELETE_EMPLOYMENT_HISTORY_REJECTED:
      return {
        ...state,
        deleteEmploymentHistoryLoading: false,
        deleteEmploymentHistoryRejected: true
      }
    case DELETE_EMPLOYMENT_HISTORY_DEFAULT:
      return {
        ...state,
        deleteEmploymentHistoryLoading: false,
        deleteEmploymentHistorySuccess: false,
        deleteEmploymentHistoryRejected: false
      }
    case VERIFY:
      return {
        ...state,
        verifiedSuccess: false,
      }
    case VERIFY_FULFILLED:
      return {
        ...state,
        verifiedSuccess: true,
      }
    case VERIFY_REJECTED:
      return {
        ...state,
        verifiedSuccess: false,
      }
    default:
      return {
        ...state
      }
  }
}
