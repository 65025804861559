import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS'
export const GET_ANNOUNCEMENTS_FULFILLED = 'GET_ANNOUNCEMENTS_FULFILLED'
export const GET_ANNOUNCEMENTS_REJECTED = 'GET_ANNOUNCEMENTS_REJECTED'
export const GET_ANNOUNCEMENTS_DEFAULT = 'GET_ANNOUNCEMENTS_DEFAULT'

export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT'
export const GET_ANNOUNCEMENT_FULFILLED = 'GET_ANNOUNCEMENT_FULFILLED'
export const GET_ANNOUNCEMENT_REJECTED = 'GET_ANNOUNCEMENT_REJECTED'
export const GET_ANNOUNCEMENT_DEFAULT = 'GET_ANNOUNCEMENT_DEFAULT'

export const POST_ANNOUNCEMENT = 'POST_ANNOUNCEMENT'
export const POST_ANNOUNCEMENT_FULFILLED = 'POST_ANNOUNCEMENT_FULFILLED'
export const POST_ANNOUNCEMENT_REJECTED = 'POST_ANNOUNCEMENT_REJECTED'
export const POST_ANNOUNCEMENT_DEFAULT = 'POST_ANNOUNCEMENT_DEFAULT'

export const PUT_ANNOUNCEMENT = 'PUT_ANNOUNCEMENT'
export const PUT_ANNOUNCEMENT_FULFILLED = 'PUT_ANNOUNCEMENT_FULFILLED'
export const PUT_ANNOUNCEMENT_REJECTED = 'PUT_ANNOUNCEMENT_REJECTED'
export const PUT_ANNOUNCEMENT_DEFAULT = 'PUT_ANNOUNCEMENT_DEFAULT'

export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT'
export const DELETE_ANNOUNCEMENT_FULFILLED = 'DELETE_ANNOUNCEMENT_FULFILLED'
export const DELETE_ANNOUNCEMENT_REJECTED = 'DELETE_ANNOUNCEMENT_REJECTED'
export const DELETE_ANNOUNCEMENT_DEFAULT = 'DELETE_ANNOUNCEMENT_DEFAULT'
const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadAnnouncements = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_ANNOUNCEMENTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/announcements?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ANNOUNCEMENTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ANNOUNCEMENTS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ANNOUNCEMENTS_DEFAULT }))
  }
}

export const loadAnnouncement = (id) => {
  return dispatch => {
    dispatch({
      type: GET_ANNOUNCEMENT,
      payload: {}
    })
    return Axios.get(`${API_URL}/announcement/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_ANNOUNCEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_ANNOUNCEMENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_ANNOUNCEMENT_DEFAULT }))
  }
}

export const submitAnnouncement = (data) => {
  return dispatch => {
    dispatch({
      type: POST_ANNOUNCEMENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/announcement`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_ANNOUNCEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_ANNOUNCEMENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_ANNOUNCEMENT_DEFAULT }))
  }
}

export const updateAnnouncement = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_ANNOUNCEMENT,
      payload: {}
    })
    return Axios.put(`${API_URL}/announcement/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_ANNOUNCEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_ANNOUNCEMENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_ANNOUNCEMENT_DEFAULT }))
  }
}

export const deleteAnnouncement = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_ANNOUNCEMENT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/announcement/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_ANNOUNCEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_ANNOUNCEMENT_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_ANNOUNCEMENT_DEFAULT }))
  }
}











