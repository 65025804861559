import React, { useEffect, useState } from 'react'
import { Row, Col, Avatar, Pagination, Tooltip, Modal } from 'antd'
import { connect } from 'react-redux'
import TimeAgo from 'javascript-time-ago'
import './index.scss'
import { loadAnnouncements, viewerAnnouncement } from '../actions'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { getUser } from '../../../../utilities/token'
import draftToHtml from 'draftjs-to-html';
import Slider from "react-slick";
// English.
import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)

// Create formatter (English).
const timeAgo = new TimeAgo('en-US')
// Create formatter (English).
// const timeAgo = new TimeAgo('en-US')
const AnnouncementComponents = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [modalViewShow, setModalViewShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    useEffect(() => {
        const { onLoadAnnouncements } = props
        onLoadAnnouncements(pagination)
        // eslint-disable-next-line
    }, [])

    function onChangePagination(page, limit) {
        const { onLoadAnnouncements } = props

        const newPagination = {
            page: page,
            limit: limit,
            search: pagination.search
        }
        onLoadAnnouncements(newPagination)
        setPagination(prevState => ({
            ...prevState,
            page, limit
        }));
    }

    async function handleRowClick(e, data) {
        if (e.target.className !== 'ant-image-mask') {
            setModalViewShow(true)
            setSelectedRow(data)
        }

        const userId = await getUser().id
        const submitData = {
            viewerId: userId,
            docId: data._id
        }
        console.log("submitData", submitData)
        // props.onViewerAnnouncement({ ...submitData })
    }


    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <ImagePresignedUrl src={selectedRow.images[i] && selectedRow.images[i].url} preview={false} />
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className='page-content'>
            {
                props.announcementsData.data && props.announcementsData.data.map((data, index) => {
                    return (
                        <div key={index} onClick={(e) => {
                            handleRowClick(e, data)
                        }} >
                            <Row>
                                <Col className='card' md={24} lg={24} sm={24} xs={24}>
                                    <div className='detail'>
                                        <h1 className='title'>{data.title}</h1>
                                        <p className='description'>{data.description}</p>
                                    </div>
                                    <div className='images'>
                                        {
                                            data.images && data.images.map((item, index) => {
                                                return (
                                                    <div className='image' key={index}>
                                                        <ImagePresignedUrl src={item.url} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p className='posted-at'>{timeAgo.format(new Date(data.createdAt))}</p>
                                    {/* {
                                        data.viewer.length &&
                                        <p className='seen'>Seen</p> || ''
                                    }
                                    <Avatar.Group maxCount={2}   >
                                        {
                                            data.viewer.map((item, index) => {
                                                console.log("item[0].url", item.image[0].url)
                                                return (
                                                    <Tooltip title={`${item.firstName} ${item.lastName}`} placement="top">
                                                        <Avatar icon={<ImagePresignedUrl src={item.image && item.image[0].url} style={{ height: '100%' }} objectFit='cover' preview={false} />} />
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </Avatar.Group> */}
                                </Col>
                            </Row>
                        </div>
                    )
                })
            }
            <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.announcementsData.total} />
                </Col>
            </Row>
            <Modal title={selectedRow.title} visible={modalViewShow} footer={null} onCancel={() => setModalViewShow(false)} width={1000}>
                <p>{selectedRow.description}</p>
                <div className='html-content' >
                    {
                        selectedRow.content &&
                        <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(selectedRow.content)) }} />
                    }
                </div>
                <div className='pb4'>
                    <Slider {...settings}>
                        {
                            selectedRow.images && selectedRow.images.map((image, index) => {
                                return (
                                    <div className='flex-center pb4'>
                                        <ImagePresignedUrl src={image.url} style={{ height: 300, width: 300 }} />
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </Modal>
        </div >
    )
}

function mapStateToProps(state) {
    return {
        announcementsData: state.client.announcement.announcementsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAnnouncements: data => dispatch(loadAnnouncements(data)),
        onViewerAnnouncement: data => dispatch(viewerAnnouncement(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementComponents)