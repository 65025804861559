import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Tag, Table, Avatar } from 'antd'
import { loadTimelogs, deleteTimelog } from '../actions'
import './index.scss'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import moment from 'moment-business-time'
import access from '../../../../utilities/access'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadTimelogs } = props
        onLoadTimelogs(pagination)
        // eslint-disable-next-line
    }, [props.updateTimelogSuccess, props.deleteTimelogSuccess])

    useEffect(() => {
        setTableData([...props.timelogsData.data])
    }, [props.timelogsData])

    function onChangePagination(page, limit) {
        const { onLoadTimelogs } = props

        const newPagination = {
            page: page,
            limit: limit,
            search: pagination.search
        }
        onLoadTimelogs(newPagination)
        setPagination(prevState => ({
            ...prevState,
            page, limit
        }));
    }

    function handleDelete(id) {
        props.onDeleteTimelog(id)
    }

    function typeTag(type) {
        switch (type) {
            case 'IN':
                return <Tag color="green">IN</Tag>
            case 'OUT':
                return <Tag color="geekblue">OUT</Tag>
            case 'BREAK OUT':
                return <Tag color="gold">BREAK OUT</Tag>
            default:
                return;
        }
    }
    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={data && data.employee && data.employee.image && data.employee.image[0] && data.employee.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data && data.employee && data.employee.firstName} {data && data.employee && data.employee.lastName}</span>)
            }
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (item, data) => {
                return (
                    <Fragment>
                        <span style={{ fontWeight: 'bold', color: '#4A9EF7', fontSize: 14 }}>{moment(item).format('LLLL')}</span><br />
                        <span>{data && data.location}</span>
                    </Fragment>
                )
            }
        },
        {
            title: 'Data Source',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: (item, data) => {
                return (
                    <span>{item ? 'BIOMETRICS' : 'WEB APP'}</span>
                )
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (item) => {
                return (
                    <div>
                        {typeTag(item)}
                    </div>
                )
            }
        },
    ];
    return (
        <Layout className='page' >
            {
                access.check('timelogs', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Table columns={columns} dataSource={dataSource} style={{overflow:'scroll'}} rowKey="_id" loading={props.timelogsData.timelogsLoading} />
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        timelogsData: state.admin.timelog.timelogsData,
        timelogsLoading: state.admin.timelog.timelogsLoading,
        updateTimelogSuccess: state.admin.timelog.updateTimelogSuccess,
        deleteTimelogSuccess: state.admin.timelog.deleteTimelogSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTimelogs: data => dispatch(loadTimelogs(data)),
        onDeleteTimelog: id => dispatch(deleteTimelog(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)