import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const GET_EMPLOYEES_FULFILLED = 'GET_EMPLOYEES_FULFILLED'
export const GET_EMPLOYEES_REJECTED = 'GET_EMPLOYEES_REJECTED'
export const GET_EMPLOYEES_DEFAULT = 'GET_EMPLOYEES_DEFAULT'

export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEE_FULFILLED = 'GET_EMPLOYEE_FULFILLED'
export const GET_EMPLOYEE_REJECTED = 'GET_EMPLOYEE_REJECTED'
export const GET_EMPLOYEE_DEFAULT = 'GET_EMPLOYEE_DEFAULT'

export const POST_EMPLOYEE = 'POST_EMPLOYEE'
export const POST_EMPLOYEE_FULFILLED = 'POST_EMPLOYEE_FULFILLED'
export const POST_EMPLOYEE_REJECTED = 'POST_EMPLOYEE_REJECTED'
export const POST_EMPLOYEE_DEFAULT = 'POST_EMPLOYEE_DEFAULT'

export const PUT_EMPLOYEE = 'PUT_EMPLOYEE'
export const PUT_EMPLOYEE_FULFILLED = 'PUT_EMPLOYEE_FULFILLED'
export const PUT_EMPLOYEE_REJECTED = 'PUT_EMPLOYEE_REJECTED'
export const PUT_EMPLOYEE_DEFAULT = 'PUT_EMPLOYEE_DEFAULT'

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const DELETE_EMPLOYEE_FULFILLED = 'DELETE_EMPLOYEE_FULFILLED'
export const DELETE_EMPLOYEE_REJECTED = 'DELETE_EMPLOYEE_REJECTED'
export const DELETE_EMPLOYEE_DEFAULT = 'DELETE_EMPLOYEE_DEFAULT'

export const CHANGE_PASSWORD_EMPLOYEE = 'CHANGE_PASSWORD_EMPLOYEE'
export const CHANGE_PASSWORD_EMPLOYEE_FULFILLED = 'CHANGE_PASSWORD_EMPLOYEE_FULFILLED'
export const CHANGE_PASSWORD_EMPLOYEE_REJECTED = 'CHANGE_PASSWORD_EMPLOYEE_REJECTED'
export const CHANGE_PASSWORD_EMPLOYEE_DEFAULT = 'CHANGE_PASSWORD_EMPLOYEE_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');
export const loadEmployees = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_EMPLOYEES,
      payload: {}
    })
    return Axios.get(`${API_URL}/users?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_EMPLOYEES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_EMPLOYEES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_EMPLOYEES_DEFAULT }))
  }
}

export const loadEmployee = (id) => {
  return dispatch => {
    dispatch({
      type: GET_EMPLOYEE,
      payload: {}
    })
    return Axios.get(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_EMPLOYEE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_EMPLOYEE_DEFAULT }))
  }
}

export const submitEmployee = (data) => {
  return dispatch => {
    dispatch({
      type: POST_EMPLOYEE,
      payload: {}
    })
    return Axios.post(`${API_URL}/user`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_EMPLOYEE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_EMPLOYEE_DEFAULT }))
  }
}

export const updateEmployee = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_EMPLOYEE,
      payload: {}
    })
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_EMPLOYEE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_EMPLOYEE_DEFAULT }))
  }
}

export const deleteEmployee = (id, postById) => {
  return dispatch => {
    dispatch({
      type: DELETE_EMPLOYEE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/user/${id}?postById=${postById}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_EMPLOYEE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_EMPLOYEE_DEFAULT }))
  }
}

export const changePasswordEmployee = (data) => {
  return dispatch => {
    dispatch({
      type: CHANGE_PASSWORD_EMPLOYEE,
      payload: {}
    })
    return Axios.put(`${API_URL}/change-password/${data.userId}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: CHANGE_PASSWORD_EMPLOYEE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: CHANGE_PASSWORD_EMPLOYEE_REJECTED,
          payload: error
        })
      })
  }
}











