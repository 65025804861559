import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal, notification } from 'antd'
import { updateEducation, submitEducation } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import enumEducationType from '../../../../utilities/enumEducationType'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { getUser } from '../../../../utilities/token'
import { upload } from '../../../../utilities/filesController'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import './index.scss'
import moment from 'moment'
const { Option } = Select
const { RangePicker } = DatePicker;
const FormEducation = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            yearFromTo: props.selectedRow && [moment(props.selectedRow.yearFrom || new Date(), 'YYYY'), moment(props.selectedRow.yearTo || new Date(), 'YYYY')],
            expiration: props.selectedRow && moment(props.selectedRow.expiration) || new Date(),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)
        // eslint-disable-next-line
    }, [props.selectedRow])

    function getFileImage(arrayImageObj) {

        setListOfImageObject(arrayImageObj)
    }

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
            yearFrom: moment(value.yearFromTo[0]).format('YYYY'),
            yearTo: moment(value.yearFromTo[1]).format('YYYY')
        }

        if (props.actionType === ADD) {
            const userId = await getUser().id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onSubmitEducation({ userId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        props.setActionType(NONE)
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const educationId = props.selectedRow._id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onUpdateEducation({ id: educationId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        setLoading(false)
                        form.resetFields()
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {

                    upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    return (
        <Modal title={props.selectedRow && props.selectedRow.school || 'Add a school'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`education`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="School"
                                name="school"
                                rules={[{ required: true, message: 'Please input school!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="School Address"
                                name="schoolAddress"
                                rules={[{ required: true, message: 'Please input school address!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Course"
                                name="course"
                                rules={[{ required: true, message: 'Please input school!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="License Number"
                                name="licenseNumber"
                                rules={[{ required: true, message: 'Please input license number!' }]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[{ required: true, message: 'Please input type!' }]}
                            >
                                <Select   >
                                    {
                                        Object.keys(enumEducationType).map(function (key, index) {
                                            return (
                                                <Option value={enumEducationType[key]} key={index}>{key}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Year From - To"
                                name="yearFromTo"
                                rules={[{ required: true, message: 'Please input year from to!' }]}
                            >
                                <RangePicker picker="year" style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                label="Expiration"
                                name="expiration"
                                rules={[{ required: true, message: 'Please input expiration!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <CustomDropzoneMultiple
                                fileName='educations'
                                uploadDir='educations'
                                height={200}
                                width={200}
                                getFile={file => { getFileImage(file) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className='d-flex'>
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div className='p2'>
                                                <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateEducationLoading || loading}   >
                                    Submit education
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        updateEducationLoading: state.client.education.updateEducationLoading,
        employeesData: state.client.employee.employeesData,
        employeesLoading: state.client.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateEducation: (data) => dispatch(updateEducation(data)),
        onSubmitEducation: (data) => dispatch(submitEducation(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormEducation)