import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Checkbox, Alert, notification, Card, Space, Divider } from 'antd'
import { loadLeave, updateLeave, submitLeave, POST_LEAVE_FULFILLED } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import './index.scss'
import moment from 'moment'
import { loadProjects } from '../../projects/actions'
import { loadEmployees, loadEmployee } from '../../employee/actions'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { upload } from '../../../../utilities/filesController'
import access from '../../../../utilities/access'
import FormType from './modals/type'
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select

const FormLeave = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])
    const [modalTypeShow, setModalTypeShow] = useState(false)
    const [userDetails, setUserDetails] = useState({})
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {
                    upload(item, item.name, item.uploadDir).then(data => { return resolve(true) }).catch(err => {
                        return notification.error({ message: `Failed to upload image`, description: err, });
                    })
                })
            } else { return resolve(true) }
        })
    }


    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            dateFrom: props.selectedRow && moment(props.selectedRow.dateFrom),
            dateTo: props.selectedRow && moment(props.selectedRow.dateTo),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)
        // eslint-disable-next-line
    }, [props.selectedRow])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onSubmitLeave({ images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        if (res.type === POST_LEAVE_FULFILLED) {
                            props.setActionType(NONE)
                            form.resetFields()
                        }
                        setLoading(false)
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const leaveId = props.selectedRow._id
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    props.onUpdateLeave({ id: leaveId, images: [...images, ...listOfImageObject], ...submitData }).then(res => {
                        setLoading(false)
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })
        }
    }

    useEffect(() => {
        props.onLoadEmployees({
            page: 1,
            limit: 50,
            id: userId,
        })

        props.onLoadProjects({
            page: 1,
            limit: 100,
        })

        if (props.actionType === VIEW) {
            props.onLoadEmployee(userId).then(res => {
                setUserDetails(res.payload)
            })
        }
    }, [])

    const isAccessDisabled = (!access.check('leaveApplications', 'edit') && (props.actionType === VIEW))
    // do not allowed to edit  'Date from' and 'Date To' when already approved because its been deducted already to the selected user.
    const isDeducted = (props.selectedRow && props.selectedRow.isDeducted)

    const { vacationLeaveDays, sickLeaveDays, othersLeaveDays, emergencyLeaveDays } = userDetails
    console.log("userDetails", userDetails)
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {
                (props.actionType === VIEW) ?
                    <>
                        <div className='preview'>
                            <ImagePresignedUrl src={props.selectedRow && props.selectedRow.user && props.selectedRow.user.image[0].url} style={{ width: 64, height: 64, borderRadius: 32 }} objectFit='cover' preview={false} />
                            <h1>{props.selectedRow.user && props.selectedRow.user.firstName} {props.selectedRow && props.selectedRow.user && props.selectedRow.user.lastName}</h1>
                        </div>
                        <Divider />
                    </> : null
            }
            {
                isDeducted ?
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                            <Alert
                                message={'Date From, Date To And Type'}
                                description={`Not allowed to edit 'Date from', 'Date To' and 'Type' when already approved because it has been deducted to the selected user`}
                                type={"info"}
                                showIcon
                                closable
                            />
                        </Col>
                    </Row> : null
            }
            <Form
                name={`leave-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}>
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Reason For Taking Leave"
                            name="reason"
                            rules={[{ required: true, message: 'Please input reason!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Date From"
                            name="dateFrom"
                            rules={[{ required: true, message: 'Please input date from!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled || isDeducted}
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Date To"
                            name="dateTo"
                            rules={[{ required: true, message: 'Please input date to!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled || isDeducted}
                                className='primary-input'
                                // format="YYYY-MM-DD HH:mm:ss"
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="isOriginalCopySubmitted"
                            valuePropName="checked"
                        >
                            <Checkbox disabled={isAccessDisabled} >Is Submitted Original Copy?</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Row gutter={8} justify='center'>
                            <Col md={22} lg={22} sm={24} xs={24}>
                                <Form.Item
                                    label="Type"
                                    name="typeId"
                                    rules={[{ required: true, message: 'Please input type!' }]}
                                >
                                    <Select disabled={isAccessDisabled || isDeducted}>
                                        {
                                            props.leaveTypesData.data.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item._id}>{item.title}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={2} lg={2} sm={24} xs={24}  >
                                <div className='type-options'>
                                    <Button disabled={isAccessDisabled} className='btn-circle-type' onClick={() => setModalTypeShow(true)} shape="circle" icon={<PlusOutlined />} />
                                </div>
                            </Col>
                        </Row>

                        <Form.Item
                            label="Projects"
                            name="projectId"
                            rules={[{ required: true, message: 'Please input project id!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                {
                                    props.projectsData.data.map((data, index) => {
                                        return (
                                            <Option value={data._id}>{data.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[{ required: true, message: 'Please input status!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                <Option value={1}>FOR APPROVAL</Option>
                                {
                                    access.check('leaveApplications', 'approve') &&
                                    <Option value={2}>APPROVED</Option>
                                }
                                {
                                    access.check('leaveApplications', 'cancel') &&
                                    <Option value={5}>CANCELLED</Option>
                                }
                                {
                                    access.check('leaveApplications', 'reject') &&
                                    <Option value={6}>REJECTED</Option>
                                }
                            </Select>
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <CustomDropzoneMultiple
                            disabled={isAccessDisabled}
                            fileName='leaves'
                            uploadDir='leaves'
                            height={200}
                            width={200}
                            getFile={file => { getFileImage(file) }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <div className='d-flex'>
                            {
                                images.map((item, index) => {
                                    return (
                                        <div className='p2'>
                                            <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                            {
                                                ((access.check('leaveApplications', 'add') && (props.actionType === ADD)) || (access.check('leaveApplications', 'edit') && (props.actionType === VIEW))) &&
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
                {
                    (props.actionType === VIEW) &&
                    <Card className='mb4' title='Available Leaves'>
                        <label>Vacation Leave Days: <b style={{ color: !vacationLeaveDays && 'red' }}>{vacationLeaveDays || 0} Day/s</b></label><br />
                        <label>Sick Leave Days: <b style={{ color: !sickLeaveDays && 'red' }}>{sickLeaveDays || 0} Day/s</b></label><br />
                        <label>Emergency Leave Days: <b style={{ color: !emergencyLeaveDays && 'red' }}>{emergencyLeaveDays || 0} Day/s</b></label><br />
                        <label>Others Leave Days: <b style={{ color: !othersLeaveDays && 'red' }}>{othersLeaveDays || 0} Day/s</b></label><br />
                    </Card>
                }
                {
                    ((access.check('leaveApplications', 'add') && (props.actionType === ADD)) || (access.check('leaveApplications', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit" style={{ marginTop: 12 }}
                                    loading={props.updateLeaveLoading || loading}   >
                                    Submit Leave
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
            <FormType visible={modalTypeShow} setModalTypeShow={setModalTypeShow} />
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateLeaveLoading: state.admin.leave.updateLeaveLoading,
        employeesData: state.admin.employee.employeesData,
        projectsData: state.admin.project.projectsData,
        leaveTypesData: state.admin.leave.leaveTypesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadLeave: (id) => dispatch(loadLeave(id)),
        onUpdateLeave: (data) => dispatch(updateLeave(data)),
        onSubmitLeave: (data) => dispatch(submitLeave(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
        onLoadProjects: (data) => dispatch(loadProjects(data)),
        onLoadEmployee: (id) => dispatch(loadEmployee(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormLeave)