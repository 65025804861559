import {
  GET_INCENTIVES,
  GET_INCENTIVES_FULFILLED,
  GET_INCENTIVES_REJECTED,
  PUT_INCENTIVE,
  PUT_INCENTIVE_FULFILLED,
  PUT_INCENTIVE_REJECTED,
  POST_INCENTIVE,
  POST_INCENTIVE_FULFILLED,
  POST_INCENTIVE_REJECTED,
  DELETE_INCENTIVE,
  DELETE_INCENTIVE_FULFILLED,
  DELETE_INCENTIVE_REJECTED,
  GET_INCENTIVES_DEFAULT,
  POST_INCENTIVE_DEFAULT,
  PUT_INCENTIVE_DEFAULT,
  DELETE_INCENTIVE_DEFAULT,
} from '../actions'

export default function incentive(state = {
  incentivesLoading: false,
  incentivesSuccess: false,
  incentivesRejected: false,
  incentivesData: {
    data: []
  },
  incentiveTypesData: {
    data: []
  },
  updateIncentiveLoading: false,
  updateIncentiveSuccess: false,
  updateIncentiveRejected: false,
  addIncentiveLoading: false,
  addIncentiveSuccess: false,
  addIncentiveRejected: false,
  deleteIncentiveLoading: false,
  deleteIncentiveSuccess: false,
  deleteIncentiveRejected: false
}, action) {
  switch (action.type) {
    case GET_INCENTIVES:
      return {
        ...state,
        incentivesLoading: true,
        incentivesSuccess: false,
        incentivesRejected: false
      }
    case GET_INCENTIVES_FULFILLED:
      return {
        ...state,
        incentivesLoading: false,
        incentivesSuccess: true,
        incentivesData: action.payload
      }
    case GET_INCENTIVES_REJECTED:
      return {
        ...state,
        incentivesLoading: false,
        incentivesRejected: true
      }
    case GET_INCENTIVES_DEFAULT:
      return {
        ...state,
        incentivesLoading: false,
        incentivesSuccess: false,
        incentivesRejected: false
      }
    case POST_INCENTIVE:
      return {
        ...state,
        addIncentiveLoading: true,
        addIncentiveSuccess: false,
        addIncentiveRejected: false
      }
    case POST_INCENTIVE_FULFILLED:
      return {
        ...state,
        addIncentiveLoading: false,
        addIncentiveSuccess: true,
        addIncentiveData: action.payload
      }
    case POST_INCENTIVE_REJECTED:
      return {
        ...state,
        addIncentiveLoading: false,
        addIncentiveRejected: true
      }
    case POST_INCENTIVE_DEFAULT:
      return {
        ...state,
        addIncentiveLoading: false,
        addIncentiveSuccess: false,
        addIncentiveRejected: false
      }
    case PUT_INCENTIVE:
      return {
        ...state,
        updateIncentiveLoading: true,
        updateIncentiveSuccess: false,
        updateIncentiveRejected: false
      }
    case PUT_INCENTIVE_FULFILLED:
      return {
        ...state,
        updateIncentiveLoading: false,
        updateIncentiveSuccess: true,
        updateIncentiveData: action.payload
      }
    case PUT_INCENTIVE_REJECTED:
      return {
        ...state,
        updateIncentiveLoading: false,
        updateIncentiveRejected: true
      }
    case PUT_INCENTIVE_DEFAULT:
      return {
        ...state,
        updateIncentiveLoading: false,
        updateIncentiveSuccess: false,
        updateIncentiveRejected: false
      }
    case DELETE_INCENTIVE:
      return {
        ...state,
        deleteIncentiveLoading: true,
        deleteIncentiveSuccess: false,
        deleteIncentiveRejected: false
      }
    case DELETE_INCENTIVE_FULFILLED:
      return {
        ...state,
        deleteIncentiveLoading: false,
        deleteIncentiveSuccess: true,
      }
    case DELETE_INCENTIVE_REJECTED:
      return {
        ...state,
        deleteIncentiveLoading: false,
        deleteIncentiveRejected: true
      }
    case DELETE_INCENTIVE_DEFAULT:
      return {
        ...state,
        deleteIncentiveLoading: false,
        deleteIncentiveSuccess: false,
        deleteIncentiveRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
