import {
  GET_PROJECTS,
  GET_PROJECTS_FULFILLED,
  GET_PROJECTS_REJECTED,
  PUT_PROJECT,
  PUT_PROJECT_FULFILLED,
  PUT_PROJECT_REJECTED,
  POST_PROJECT,
  POST_PROJECT_FULFILLED,
  POST_PROJECT_REJECTED,
  DELETE_PROJECT,
  DELETE_PROJECT_FULFILLED,
  DELETE_PROJECT_REJECTED,
  GET_PROJECTS_DEFAULT,
  POST_PROJECT_DEFAULT,
  PUT_PROJECT_DEFAULT,
  DELETE_PROJECT_DEFAULT
} from '../actions'

export default function project(state = {
  projectsLoading: false,
  projectsSuccess: false,
  projectsRejected: false,
  projectsData: {
    data: []
  },
  updateProjectLoading: false,
  updateProjectSuccess: false,
  updateProjectRejected: false,
  addProjectLoading: false,
  addProjectSuccess: false,
  addProjectRejected: false,
  deleteProjectLoading: false,
  deleteProjectSuccess: false,
  deleteProjectRejected: false
}, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projectsLoading: true,
        projectsSuccess: false,
        projectsRejected: false
      }
    case GET_PROJECTS_FULFILLED:
      return {
        ...state,
        projectsLoading: false,
        projectsSuccess: true,
        projectsData: action.payload
      }
    case GET_PROJECTS_REJECTED:
      return {
        ...state,
        projectsLoading: false,
        projectsRejected: true
      }
    case GET_PROJECTS_DEFAULT:
      return {
        ...state,
        projectsLoading: false,
        projectsSuccess: false,
        projectsRejected: false
      }
    case POST_PROJECT:
      return {
        ...state,
        addProjectLoading: true,
        addProjectSuccess: false,
        addProjectRejected: false
      }
    case POST_PROJECT_FULFILLED:
      return {
        ...state,
        addProjectLoading: false,
        addProjectSuccess: true,
        addProjectData: action.payload
      }
    case POST_PROJECT_REJECTED:
      return {
        ...state,
        addProjectLoading: false,
        addProjectRejected: true
      }
    case POST_PROJECT_DEFAULT:
      return {
        ...state,
        addProjectLoading: false,
        addProjectSuccess: false,
        addProjectRejected: false
      }
    case PUT_PROJECT:
      return {
        ...state,
        updateProjectLoading: true,
        updateProjectSuccess: false,
        updateProjectRejected: false
      }
    case PUT_PROJECT_FULFILLED:
      return {
        ...state,
        updateProjectLoading: false,
        updateProjectSuccess: true,
        updateProjectData: action.payload
      }
    case PUT_PROJECT_REJECTED:
      return {
        ...state,
        updateProjectLoading: false,
        updateProjectRejected: true
      }
    case PUT_PROJECT_DEFAULT:
      return {
        ...state,
        updateProjectLoading: false,
        updateProjectSuccess: false,
        updateProjectRejected: false
      }
    case DELETE_PROJECT:
      return {
        ...state,
        deleteProjectLoading: true,
        deleteProjectSuccess: false,
        deleteProjectRejected: false
      }
    case DELETE_PROJECT_FULFILLED:
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectSuccess: true,
      }
    case DELETE_PROJECT_REJECTED:
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectRejected: true
      }
    case DELETE_PROJECT_DEFAULT:
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectSuccess: false,
        deleteProjectRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
