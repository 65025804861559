import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_MEDICAL_HISTORIES = 'GET_MEDICAL_HISTORIES'
export const GET_MEDICAL_HISTORIES_FULFILLED = 'GET_MEDICAL_HISTORIES_FULFILLED'
export const GET_MEDICAL_HISTORIES_REJECTED = 'GET_MEDICAL_HISTORIES_REJECTED'

export const POST_MEDICAL_HISTORY = 'POST_MEDICAL_HISTORY'
export const POST_MEDICAL_HISTORY_FULFILLED = 'POST_MEDICAL_HISTORY_FULFILLED'
export const POST_MEDICAL_HISTORY_REJECTED = 'POST_MEDICAL_HISTORY_REJECTED'
export const POST_MEDICAL_HISTORY_DEFAULT = 'POST_MEDICAL_HISTORY_DEFAULT'

export const PUT_MEDICAL_HISTORY = 'PUT_MEDICAL_HISTORY'
export const PUT_MEDICAL_HISTORY_FULFILLED = 'PUT_MEDICAL_HISTORY_FULFILLED'
export const PUT_MEDICAL_HISTORY_REJECTED = 'PUT_MEDICAL_HISTORY_REJECTED'
export const PUT_MEDICAL_HISTORY_DEFAULT = 'PUT_MEDICAL_HISTORY_DEFAULT'

export const DELETE_MEDICAL_HISTORY = 'DELETE_MEDICAL_HISTORY'
export const DELETE_MEDICAL_HISTORY_FULFILLED = 'DELETE_MEDICAL_HISTORY_FULFILLED'
export const DELETE_MEDICAL_HISTORY_REJECTED = 'DELETE_MEDICAL_HISTORY_REJECTED'
export const DELETE_MEDICAL_HISTORY_DEFAULT = 'DELETE_MEDICAL_HISTORY_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadMedicalHistories = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_MEDICAL_HISTORIES,
            payload: {}
        })
        return Axios.get(`${API_URL}/medical-histories?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_MEDICAL_HISTORIES_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_MEDICAL_HISTORIES_REJECTED,
                payload: error
            })
        })
    }
}

export const submitMedicalHistory = (data) => {
    return dispatch => {
        dispatch({
            type: POST_MEDICAL_HISTORY,
            payload: {}
        })
        return Axios.post(`${API_URL}/medical-history`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_MEDICAL_HISTORY_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_MEDICAL_HISTORY_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_MEDICAL_HISTORY_DEFAULT }))
    }
}

export const updateMedicalHistory = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_MEDICAL_HISTORY,
            payload: {}
        })
        return Axios.put(`${API_URL}/medical-history/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_MEDICAL_HISTORY_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_MEDICAL_HISTORY_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_MEDICAL_HISTORY_DEFAULT }))
    }
}


export const deleteMedicalHistory = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_MEDICAL_HISTORY,
            payload: {}
        })
        return Axios.delete(`${API_URL}/medical-history/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_MEDICAL_HISTORY_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_MEDICAL_HISTORY_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_MEDICAL_HISTORY_DEFAULT }))
    }
}
