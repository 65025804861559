import {
  GET_ADMINS,
  GET_ADMINS_FULFILLED,
  GET_ADMINS_REJECTED,
  GET_ADMIN,
  GET_ADMIN_FULFILLED,
  GET_ADMIN_DEFAULT,
  GET_ADMIN_REJECTED,
  PUT_ADMIN,
  PUT_ADMIN_FULFILLED,
  PUT_ADMIN_REJECTED,
  POST_ADMIN,
  POST_ADMIN_FULFILLED,
  POST_ADMIN_REJECTED,
  DELETE_ADMIN,
  DELETE_ADMIN_FULFILLED,
  DELETE_ADMIN_REJECTED,
  GET_ADMINS_DEFAULT,
  POST_ADMIN_DEFAULT,
  PUT_ADMIN_DEFAULT,
  DELETE_ADMIN_DEFAULT
} from '../actions'

export default function admin(state = {
  adminLoading: false,
  adminSuccess: false,
  adminRejected: false,
  adminData: {
    roles: []
  },
  adminRoles: [],
  adminsLoading: false,
  adminsSuccess: false,
  adminsRejected: false,
  adminsData: {
    data: []
  },
  updateAdminLoading: false,
  updateAdminSuccess: false,
  updateAdminRejected: false,
  addAdminLoading: false,
  addAdminSuccess: false,
  addAdminRejected: false,
  deleteAdminLoading: false,
  deleteAdminSuccess: false,
  deleteAdminRejected: false
}, action) {
  switch (action.type) {
    case GET_ADMINS:
      return {
        ...state,
        adminsLoading: true,
        adminsSuccess: false,
        adminsRejected: false
      }
    case GET_ADMINS_FULFILLED:
      return {
        ...state,
        adminsLoading: false,
        adminsSuccess: true,
        adminsData: action.payload
      }
    case GET_ADMINS_REJECTED:
      return {
        ...state,
        adminsLoading: false,
        adminsRejected: true
      }
    case GET_ADMINS_DEFAULT:
      return {
        ...state,
        adminsLoading: false,
        adminsSuccess: false,
        adminsRejected: false
      }
    case GET_ADMIN:
      return {
        ...state,
        adminLoading: true,
        adminSuccess: false,
        adminRejected: false
      }
    case GET_ADMIN_FULFILLED:
      return {
        ...state,
        adminLoading: false,
        adminSuccess: true,
        adminData: action.payload,
        adminRoles: action.payload.roles
      }
    case GET_ADMINS_REJECTED:
      return {
        ...state,
        adminLoading: false,
        adminRejected: true
      }
    case GET_ADMIN_DEFAULT:
      return {
        ...state,
        adminLoading: false,
        adminSuccess: false,
        adminRejected: false
      }
    case POST_ADMIN:
      return {
        ...state,
        addAdminLoading: true,
        addAdminSuccess: false,
        addAdminRejected: false
      }
    case POST_ADMIN_FULFILLED:
      return {
        ...state,
        addAdminLoading: false,
        addAdminSuccess: true,
        addAdminData: action.payload
      }
    case POST_ADMIN_REJECTED:
      return {
        ...state,
        addAdminLoading: false,
        addAdminRejected: true
      }
    case POST_ADMIN_DEFAULT:
      return {
        ...state,
        addAdminLoading: false,
        addAdminSuccess: false,
        addAdminRejected: false
      }
    case PUT_ADMIN:
      return {
        ...state,
        updateAdminLoading: true,
        updateAdminSuccess: false,
        updateAdminRejected: false
      }
    case PUT_ADMIN_FULFILLED:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminSuccess: true,
        updateAdminData: action.payload
      }
    case PUT_ADMIN_REJECTED:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminRejected: true
      }
    case PUT_ADMIN_DEFAULT:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminSuccess: false,
        updateAdminRejected: false
      }
    case DELETE_ADMIN:
      return {
        ...state,
        deleteAdminLoading: true,
        deleteAdminSuccess: false,
        deleteAdminRejected: false
      }
    case DELETE_ADMIN_FULFILLED:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminSuccess: true,
      }
    case DELETE_ADMIN_REJECTED:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminRejected: true
      }
    case DELETE_ADMIN_DEFAULT:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminSuccess: false,
        deleteAdminRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
