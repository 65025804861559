import {
  GET_EMPLOYEES,
  GET_EMPLOYEES_FULFILLED,
  GET_EMPLOYEES_REJECTED,
  PUT_EMPLOYEE,
  PUT_EMPLOYEE_FULFILLED,
  PUT_EMPLOYEE_REJECTED,
  POST_EMPLOYEE,
  POST_EMPLOYEE_FULFILLED,
  POST_EMPLOYEE_REJECTED,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_FULFILLED,
  DELETE_EMPLOYEE_REJECTED,
  GET_EMPLOYEES_DEFAULT,
  POST_EMPLOYEE_DEFAULT,
  PUT_EMPLOYEE_DEFAULT,
  DELETE_EMPLOYEE_DEFAULT
} from '../actions'

export default function employee(state = {
  employeesLoading: false,
  employeesSuccess: false,
  employeesRejected: false,
  employeesData: {
    data: []
  },
  updateEmployeeLoading: false,
  updateEmployeeSuccess: false,
  updateEmployeeRejected: false,
  addEmployeeLoading: false,
  addEmployeeSuccess: false,
  addEmployeeRejected: false,
  deleteEmployeeLoading: false,
  deleteEmployeeSuccess: false,
  deleteEmployeeRejected: false
}, action) {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        employeesLoading: true,
        employeesSuccess: false,
        employeesRejected: false
      }
    case GET_EMPLOYEES_FULFILLED:
      return {
        ...state,
        employeesLoading: false,
        employeesSuccess: true,
        employeesData: action.payload
      }
    case GET_EMPLOYEES_REJECTED:
      return {
        ...state,
        employeesLoading: false,
        employeesRejected: true
      }
    case GET_EMPLOYEES_DEFAULT:
      return {
        ...state,
        employeesLoading: false,
        employeesSuccess: false,
        employeesRejected: false
      }
    case POST_EMPLOYEE:
      return {
        ...state,
        addEmployeeLoading: true,
        addEmployeeSuccess: false,
        addEmployeeRejected: false
      }
    case POST_EMPLOYEE_FULFILLED:
      return {
        ...state,
        addEmployeeLoading: false,
        addEmployeeSuccess: true,
        addEmployeeData: action.payload
      }
    case POST_EMPLOYEE_REJECTED:
      return {
        ...state,
        addEmployeeLoading: false,
        addEmployeeRejected: true
      }
    case POST_EMPLOYEE_DEFAULT:
      return {
        ...state,
        addEmployeeLoading: false,
        addEmployeeSuccess: false,
        addEmployeeRejected: false
      }
    case PUT_EMPLOYEE:
      return {
        ...state,
        updateEmployeeLoading: true,
        updateEmployeeSuccess: false,
        updateEmployeeRejected: false
      }
    case PUT_EMPLOYEE_FULFILLED:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeSuccess: true,
        updateEmployeeData: action.payload
      }
    case PUT_EMPLOYEE_REJECTED:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeRejected: true
      }
    case PUT_EMPLOYEE_DEFAULT:
      return {
        ...state,
        updateEmployeeLoading: false,
        updateEmployeeSuccess: false,
        updateEmployeeRejected: false
      }
    case DELETE_EMPLOYEE:
      return {
        ...state,
        deleteEmployeeLoading: true,
        deleteEmployeeSuccess: false,
        deleteEmployeeRejected: false
      }
    case DELETE_EMPLOYEE_FULFILLED:
      return {
        ...state,
        deleteEmployeeLoading: false,
        deleteEmployeeSuccess: true,
      }
    case DELETE_EMPLOYEE_REJECTED:
      return {
        ...state,
        deleteEmployeeLoading: false,
        deleteEmployeeRejected: true
      }
    case DELETE_EMPLOYEE_DEFAULT:
      return {
        ...state,
        deleteEmployeeLoading: false,
        deleteEmployeeSuccess: false,
        deleteEmployeeRejected: false
      }
    default:
      return {
        ...state
      }
  }
}
