import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Menu, Dropdown, Popconfirm, Avatar } from 'antd'
import { loadTimeRequests, deleteTimeRequest } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'
import Toolbar from '../../shared/components/toolbar'
import access from '../../../../utilities/access'
import moment from 'moment'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadTimeRequests } = props
        onLoadTimeRequests(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }
    useEffect(() => {
        const { onLoadTimeRequests } = props
        onLoadTimeRequests(pagination)
        // eslint-disable-next-line
    }, [props.updateTimeRequestSuccess, props.deleteTimeRequestSuccess, props.addTimeRequestSuccess, props.verifiedSuccess])

    useEffect(() => {
        setTableData([...props.timeRequestsData.data])
    }, [props.timeRequestsData])

    function handleDelete(id) {
        const postById = getUser().id
        props.onDeleteTimeRequest(id, postById)
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={64} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Time In',
            dataIndex: 'timeIn',
            key: 'timeIn',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{moment(item).format('LLLL')}</span>)
            }
        },
        {
            title: 'Time Out',
            dataIndex: 'timeOut',
            key: 'timeOut',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{moment(item).format('LLLL')}</span>)
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                            }}>
                                View
                            </Menu.Item>
                            {
                                access.check('timeRequests', 'delete') &&
                                <Menu.Item key="1" >
                                    <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                        Delete
                                    </Popconfirm>

                                </Menu.Item>
                            }
                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },


    ];
    return (
        <Layout className='page' >
            {
                access.check('timeRequests', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Filling Time Confirmation Requests'
                                handleAdd={() => props.setActionType(ADD)}
                                removeAdd={!access.check('timeRequests', 'add')}
                                // handleDownload={() => handleDownload()}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.timeRequestsData.timeRequestsLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.timeRequestsData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        timeRequestsData: state.admin.timeRequest.timeRequestsData,
        timeRequestsLoading: state.admin.timeRequest.timeRequestsLoading,
        updateTimeRequestSuccess: state.admin.timeRequest.updateTimeRequestSuccess,
        addTimeRequestSuccess: state.admin.timeRequest.addTimeRequestSuccess,
        deleteTimeRequestSuccess: state.admin.timeRequest.deleteTimeRequestSuccess,
        verifiedSuccess: state.admin.timeRequest.verifiedSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTimeRequests: data => dispatch(loadTimeRequests(data)),
        onDeleteTimeRequest: (id, postById) => dispatch(deleteTimeRequest(id, postById)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)