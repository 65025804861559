import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_TIMELOGS = 'GET_TIMELOGS'
export const GET_TIMELOGS_FULFILLED = 'GET_TIMELOGS_FULFILLED'
export const GET_TIMELOGS_REJECTED = 'GET_TIMELOGS_REJECTED'
export const GET_TIMELOGS_DEFAULT = 'GET_TIMELOGS_DEFAULT'

export const GET_TIMELOG = 'GET_TIMELOG'
export const GET_TIMELOG_FULFILLED = 'GET_TIMELOG_FULFILLED'
export const GET_TIMELOG_REJECTED = 'GET_TIMELOG_REJECTED'
export const GET_TIMELOG_DEFAULT = 'GET_TIMELOG_DEFAULT'

export const POST_TIMELOG = 'POST_TIMELOG'
export const POST_TIMELOG_FULFILLED = 'POST_TIMELOG_FULFILLED'
export const POST_TIMELOG_REJECTED = 'POST_TIMELOG_REJECTED'
export const POST_TIMELOG_DEFAULT = 'POST_TIMELOG_DEFAULT'

export const PUT_TIMELOG = 'PUT_TIMELOG'
export const PUT_TIMELOG_FULFILLED = 'PUT_TIMELOG_FULFILLED'
export const PUT_TIMELOG_REJECTED = 'PUT_TIMELOG_REJECTED'
export const PUT_TIMELOG_DEFAULT = 'PUT_TIMELOG_DEFAULT'

export const DELETE_TIMELOG = 'DELETE_TIMELOG'
export const DELETE_TIMELOG_FULFILLED = 'DELETE_TIMELOG_FULFILLED'
export const DELETE_TIMELOG_REJECTED = 'DELETE_TIMELOG_REJECTED'
export const DELETE_TIMELOG_DEFAULT = 'DELETE_TIMELOG_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadTimelogs = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_TIMELOGS,
      payload: {}
    })
    return Axios.get(`${API_URL}/timelogs?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_TIMELOGS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_TIMELOGS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_TIMELOGS_DEFAULT }))
  }
}

export const loadTimelog = (id) => {
  return dispatch => {
    dispatch({
      type: GET_TIMELOG,
      payload: {}
    })
    return Axios.get(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_TIMELOG_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_TIMELOG_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_TIMELOG_DEFAULT }))
  }
}

export const submitTimelog = (data) => {
  return dispatch => {
    dispatch({
      type: POST_TIMELOG,
      payload: {}
    })
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_TIMELOG_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_TIMELOG_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_TIMELOG_DEFAULT }))
  }
}

export const updateTimelog = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_TIMELOG,
      payload: {}
    })
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_TIMELOG_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_TIMELOG_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_TIMELOG_DEFAULT }))
  }
}

export const deleteTimelog = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_TIMELOG,
      payload: {}
    })
    return Axios.delete(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_TIMELOG_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_TIMELOG_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_TIMELOG_DEFAULT }))
  }
}











