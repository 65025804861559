import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { NotFound } from '../admin/notFound'
import Login from '../admin/login/component'
import './index.scss'
import 'antd/dist/antd.css'
import Sidebar from './shared/sider'
import Dashboard from './dashboard/components'
import DeductionMasterFile from './deductions/components'
import Incetives from './incentives/components'
import ThirteenMonthPay from './13MonthPay/components'
import CompanyBonuses from './companyBonus/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import * as _ from 'lodash'
import Axios from 'axios';
import { Layout, Modal, Row, Col, Menu, Dropdown, Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getUser } from '../../utilities/token'
import { ImagePresignedUrl } from '../../utilities/renderer'
import { loadAdmin } from '../admin/admins/actions'
import Payroll from './payroll/components'

const { Content, Footer, Header } = Layout;
const Client = (props) => {
  const [isAuthenticated, setIsLoggedIn] = useState(false)
  let [paths, setPaths] = useState([
    {
      visible: true,
      title: 'Deduction Master File',
      route: '/pms/deductions',
      tag: 'dashboard',
      component: DeductionMasterFile,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faTachometerAlt} />
    },
    {
      visible: true,
      title: '13 Month Pay',
      route: '/pms/13-month-pay',
      tag: 'dashboard',
      component: ThirteenMonthPay,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faTachometerAlt} />
    },
    {
      visible: true,
      title: 'Company Bonuses',
      route: '/pms/company-bonuses',
      tag: 'dashboard',
      component: CompanyBonuses,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faTachometerAlt} />
    },
    {
      visible: true,
      title: 'Incentives',
      route: '/pms/incentives',
      tag: 'dashboard',
      component: Incetives,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faTachometerAlt} />
    },
    {
      visible: true,
      title: 'Payroll',
      route: '/pms/payroll',
      tag: 'dashboard',
      component: Payroll,
      type: 'menu',
      icon: <FontAwesomeIcon icon={faTachometerAlt} />
    },
  ])
  const [userInfo, setUserInfo] = useState({})

  const logoutError = () => {
    localStorage.removeItem('ADMIN_SESSION_TOKEN');
    window.location.replace('/admin');
    window.location.reload();
  };

  const hasLocalStorage = () => {
    return localStorage.getItem('ADMIN_SESSION_TOKEN') !== null
  }

  useEffect(() => {
    hasLocalStorage() && setIsLoggedIn(true)

    Axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (hasLocalStorage()) {
          // return only
          // show backend manual error.
          if ((error && error.response && error.response.status) === 403) {
            Modal.warning({
              title: 'Message.',
              content: `${error && error.response && error.response.data && error.response.data.message}`,
              onOk: () => { },
            });
          } else {
            // logout user if the error cant handle the message
            Modal.error({
              title: 'Oops! Something went wrong.',
              content: 'You will be redirected to login page.',
              onOk: () => logoutError(),
            });
          }
        }
        return Promise.reject(error);
      }
    );
    window.document.title = 'HRIS Admin Management Portal'
  }, [])

  useEffect(() => {
    async function loadUserInfo() {
      const user = await getUser()
      setUserInfo(user)

      const adminId = await getUser() && getUser().id
      props.onLoadAdmin(adminId)
    }
    loadUserInfo()
  }, [])

  useEffect(() => {
    // set visibility of page in sidebar
    props.adminRoles && props.adminRoles.map(role => {
      if (role.visible) {
        const index = paths.findIndex((path) => path.tag === role.title)
        if (index !== -1) paths[index].visible = role.visible
      }
    })
    setPaths([...paths])
  }, [props.adminRoles])


  useEffect(() => {
    if (props.loggedIn) {
      setIsLoggedIn(true)
      window.location.reload()
    }
  }, [props.loggedIn])

  function logout() {
    localStorage.removeItem('ADMIN_SESSION_TOKEN')
    props.history.push('/admin')
    window.location.reload()
  }

  const menu = (
    <Menu style={{ marginTop: 24 }}>
      <Menu.Item key="0" onClick={() => (window.location.href = '/admin')}>
        Admin Management System
      </Menu.Item>
      <Menu.Item key="1" onClick={() => logout()}>
        Logout
      </Menu.Item>
    </Menu>
  );

  switch (isAuthenticated) {
    case false:
      return <Login />
    case true:
      return (
        <Layout className='admin-layout'>
          <Sidebar paths={paths} style={{ backgroundColor: '#007AFB' }} />
          <Layout>
            <Header style={{ backgroundColor: 'white' }}>
              <Row >
                <Col md={24} lg={24} xs={24} sm={24} align='right'>
                  <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      <Avatar size={32} icon={<ImagePresignedUrl src={userInfo.image} preview={false} objectFit='cover' style={{ height: '100%' }} />} />
                      <span style={{ margin: 8 }}><b>{userInfo.firstName} {userInfo.lastName}</b> </span><DownOutlined />
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content className='admin-content' style={{ background: '#f0f2f5' }} >
              <Switch>
                {/* // this will scroll to top when switch changes. */}
                {window.scrollTo(0, 0)}
                {_.map(paths, (path) => {
                  // if (!path.visible) return
                  return (
                    <Route
                      exact={true}
                      path={path.route}
                      render={(prop) => <path.component
                        {...prop}
                        socket={props.socket}
                      />}
                      key={path.route}
                    />
                  )
                })}
                <Route component={NotFound} />
                <Redirect to='/404' />
              </Switch>
            </Content>
            <Footer style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h1 style={{ color: 'gray' }}>Powered By Twintool Business Solutions. All Rights Reserved ©2022.</h1>
            </Footer>
          </Layout>
        </Layout>
      )
    default:
      break
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.admin.login.loggedIn,
    adminRoles: state.admin.administrator.adminRoles,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadAdmin: (id) => dispatch(loadAdmin(id)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Client))
