import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_DEDUCTIONS = 'GET_DEDUCTIONS'
export const GET_DEDUCTIONS_FULFILLED = 'GET_DEDUCTIONS_FULFILLED'
export const GET_DEDUCTIONS_REJECTED = 'GET_DEDUCTIONS_REJECTED'
export const GET_DEDUCTIONS_DEFAULT = 'GET_DEDUCTIONS_DEFAULT'

export const GET_DEDUCTION = 'GET_DEDUCTION'
export const GET_DEDUCTION_FULFILLED = 'GET_DEDUCTION_FULFILLED'
export const GET_DEDUCTION_REJECTED = 'GET_DEDUCTION_REJECTED'
export const GET_DEDUCTION_DEFAULT = 'GET_DEDUCTION_DEFAULT'

export const POST_DEDUCTION = 'POST_DEDUCTION'
export const POST_DEDUCTION_FULFILLED = 'POST_DEDUCTION_FULFILLED'
export const POST_DEDUCTION_REJECTED = 'POST_DEDUCTION_REJECTED'
export const POST_DEDUCTION_DEFAULT = 'POST_DEDUCTION_DEFAULT'

export const PUT_DEDUCTION = 'PUT_DEDUCTION'
export const PUT_DEDUCTION_FULFILLED = 'PUT_DEDUCTION_FULFILLED'
export const PUT_DEDUCTION_REJECTED = 'PUT_DEDUCTION_REJECTED'
export const PUT_DEDUCTION_DEFAULT = 'PUT_DEDUCTION_DEFAULT'

export const DELETE_DEDUCTION = 'DELETE_DEDUCTION'
export const DELETE_DEDUCTION_FULFILLED = 'DELETE_DEDUCTION_FULFILLED'
export const DELETE_DEDUCTION_REJECTED = 'DELETE_DEDUCTION_REJECTED'
export const DELETE_DEDUCTION_DEFAULT = 'DELETE_DEDUCTION_DEFAULT'

export const GET_DEDUCTION_TYPES = 'GET_DEDUCTION_TYPES'
export const GET_DEDUCTION_TYPES_FULFILLED = 'GET_DEDUCTION_TYPES_FULFILLED'
export const GET_DEDUCTION_TYPES_REJECTED = 'GET_DEDUCTION_TYPES_REJECTED'
export const GET_DEDUCTION_TYPES_DEFAULT = 'GET_DEDUCTION_TYPES_DEFAULT'

export const GET_DEDUCTION_TYPE = 'GET_DEDUCTION_TYPE'
export const GET_DEDUCTION_TYPE_FULFILLED = 'GET_DEDUCTION_TYPE_FULFILLED'
export const GET_DEDUCTION_TYPE_REJECTED = 'GET_DEDUCTION_TYPE_REJECTED'
export const GET_DEDUCTION_TYPE_DEFAULT = 'GET_DEDUCTION_TYPE_DEFAULT'

export const POST_DEDUCTION_TYPE = 'POST_DEDUCTION_TYPE'
export const POST_DEDUCTION_TYPE_FULFILLED = 'POST_DEDUCTION_TYPE_FULFILLED'
export const POST_DEDUCTION_TYPE_REJECTED = 'POST_DEDUCTION_TYPE_REJECTED'
export const POST_DEDUCTION_TYPE_DEFAULT = 'POST_DEDUCTION_TYPE_DEFAULT'

export const PUT_DEDUCTION_TYPE = 'PUT_DEDUCTION_TYPE'
export const PUT_DEDUCTION_TYPE_FULFILLED = 'PUT_DEDUCTION_TYPE_FULFILLED'
export const PUT_DEDUCTION_TYPE_REJECTED = 'PUT_DEDUCTION_TYPE_REJECTED'
export const PUT_DEDUCTION_TYPE_DEFAULT = 'PUT_DEDUCTION_TYPE_DEFAULT'

export const DELETE_DEDUCTION_TYPE = 'DELETE_DEDUCTION_TYPE'
export const DELETE_DEDUCTION_TYPE_FULFILLED = 'DELETE_DEDUCTION_TYPE_FULFILLED'
export const DELETE_DEDUCTION_TYPE_REJECTED = 'DELETE_DEDUCTION_TYPE_REJECTED'
export const DELETE_DEDUCTION_TYPE_DEFAULT = 'DELETE_DEDUCTION_TYPE_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadDeductions = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_DEDUCTIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/deductions?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_DEDUCTIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_DEDUCTIONS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_DEDUCTIONS_DEFAULT }))
  }
}

export const loadDeduction = (id) => {
  return dispatch => {
    dispatch({
      type: GET_DEDUCTION,
      payload: {}
    })
    return Axios.get(`${API_URL}/deduction/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_DEDUCTION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_DEDUCTION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_DEDUCTION_DEFAULT }))
  }
}

export const submitDeduction = (data) => {
  return dispatch => {
    dispatch({
      type: POST_DEDUCTION,
      payload: {}
    })
    return Axios.post(`${API_URL}/deduction`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_DEDUCTION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_DEDUCTION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_DEDUCTION_DEFAULT }))
  }
}

export const updateDeduction = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_DEDUCTION,
      payload: {}
    })
    return Axios.put(`${API_URL}/deduction/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_DEDUCTION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_DEDUCTION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_DEDUCTION_DEFAULT }))
  }
}

export const deleteDeduction = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_DEDUCTION,
      payload: {}
    })
    return Axios.delete(`${API_URL}/deduction/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_DEDUCTION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_DEDUCTION_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_DEDUCTION_DEFAULT }))
  }
}





export const loadDeductionTypes = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_DEDUCTION_TYPES,
      payload: {}
    })
    return Axios.get(`${API_URL}/deduction-types?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_DEDUCTION_TYPES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_DEDUCTION_TYPES_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_DEDUCTION_TYPES_DEFAULT }))
  }
}

export const loadDeductionType = (id) => {
  return dispatch => {
    dispatch({
      type: GET_DEDUCTION_TYPE,
      payload: {}
    })
    return Axios.get(`${API_URL}/deduction-type/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_DEDUCTION_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_DEDUCTION_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_DEDUCTION_TYPE_DEFAULT }))
  }
}

export const submitDeductionType = (data) => {
  return dispatch => {
    dispatch({
      type: POST_DEDUCTION_TYPE,
      payload: {}
    })
    return Axios.post(`${API_URL}/deduction-type`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_DEDUCTION_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_DEDUCTION_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_DEDUCTION_TYPE_DEFAULT }))
  }
}

export const updateDeductionType = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_DEDUCTION_TYPE,
      payload: {}
    })
    return Axios.put(`${API_URL}/deduction-type/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_DEDUCTION_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_DEDUCTION_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_DEDUCTION_TYPE_DEFAULT }))
  }
}

export const deleteDeductionType = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_DEDUCTION_TYPE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/deduction-type/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_DEDUCTION_TYPE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_DEDUCTION_TYPE_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_DEDUCTION_TYPE_DEFAULT }))
  }
}










