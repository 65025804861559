import React, { Fragment, useEffect, useState } from 'react'
import { Card, Layout, Modal, Avatar, Tooltip, Row, Col, notification, List, Button, Tag } from 'antd'
import { connect } from 'react-redux'
import { loadAttendances, submitAttendance } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'
import RemarksRenderer from '../../../../utilities/remarksRenderer'
import { getUser } from '../../../../utilities/token'
import { geoCodeReverse } from '../../../../utilities/googleapis'
import { ExclamationCircleOutlined } from '@ant-design/icons';
const AttendancesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})
    const [showLogin, setShowLogin] = useState(false)
    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.addAttendanceSuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated your attendance.',
            });
        }
        props.onLoadAttendances(pagination)
    }, [props.addAttendanceSuccess])

    useEffect(() => {
        if (props.attendancesData.data.length) {
            if (props.attendancesData.data[0].type === 'OUT') {
                setShowLogin(true)
            } else {
                setShowLogin(false)
            }
        } else {
            setShowLogin(true)
        }
    }, [props.attendancesData.data])

    async function handleLogout() {
        const userId = await getUser().id
        let currLoc = {}
        let address = ''
        navigator.geolocation.getCurrentPosition(async function (position) {
            currLoc = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }
            address = await geoCodeReverse(position.coords.latitude, position.coords.longitude)
            const submitData = {
                location: address.formatted_address,
                latitude: currLoc.latitude,
                longitude: currLoc.longitude,
            }

            Modal.confirm({
                title: 'Do you Want to logout?',
                icon: <ExclamationCircleOutlined />,
                content: `Your current location is '${address.formatted_address}' and logout within the current time.`,
                onOk() {
                    props.onSubmitAttendance({ userId, ...submitData }).then(res => {

                    })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        });
    }

    function typeTag(type) {
        switch (type) {
            case 'IN':
                return <Tag color="green">IN</Tag>
            case 'OUT':
                return <Tag color="geekblue">OUT</Tag>
            case 'BREAK OUT':
                return <Tag color="gold">BREAK OUT</Tag>
            default:
                return;
        }
    }

    return (
        <Layout className='attendances-page'>
            <Card>
                {
                    showLogin &&
                    <button className='add-item' onClick={() => {
                        setModalShow(true)
                        setActionType(ADD)
                        setSelectedRow({})
                    }}>
                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer', fontSize: 20 }} icon={faPlusCircle} />
                        <span>Login Today</span>
                    </button>
                }

                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Time
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Type
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={props.attendancesData.data}
                    renderItem={(data, index) => {
                        console.log("DATA", data)
                        return (
                            <List.Item>
                                <Row style={{ width: '100%' }}>
                                    <Row style={{ width: '100%' }}>
                                        <Col md={24} lg={24} xs={24} sm={24}>
                                            <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                        </Col>
                                    </Row>
                                    <Col md={6} lg={6} xs={24} sm={24}>
                                        <div className='row-item' >
                                            <h1>{moment(data.time).format('LLL')} <StatusIconDisplay status={data.status} /></h1>
                                            <p className='birth-date'>Location: {data.location}</p>
                                        </div>
                                    </Col>

                                    <Col md={9} lg={9} xs={24} sm={24}>
                                        <div className='row-item' >
                                            <div>
                                                {typeTag(data.type)}

                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} lg={4} xs={24} sm={24}>
                                        <div className='actions'>
                                            {((data.type === 'IN') && (index === 0)) &&
                                                <Button danger onClick={() => handleLogout()}>Logout</Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </List.Item>
                        )
                    }}
                />
            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        attendancesData: state.client.attendance.attendancesData,
        addAttendanceSuccess: state.client.attendance.addAttendanceSuccess,
        updateAttendanceSuccess: state.client.attendance.updateAttendanceSuccess,
        deleteAttendanceSuccess: state.client.attendance.deleteAttendanceSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAttendances: data => dispatch(loadAttendances(data)),
        onSubmitAttendance: (data) => dispatch(submitAttendance(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendancesComponent)
