import {
    GET_PERFORMANCES,
    GET_PERFORMANCES_FULFILLED,
    GET_PERFORMANCES_REJECTED,
    PUT_PERFORMANCE,
    PUT_PERFORMANCE_FULFILLED,
    PUT_PERFORMANCE_REJECTED,
    POST_PERFORMANCE,
    POST_PERFORMANCE_FULFILLED,
    POST_PERFORMANCE_REJECTED,
    DELETE_PERFORMANCE,
    DELETE_PERFORMANCE_FULFILLED,
    DELETE_PERFORMANCE_REJECTED,
    DELETE_PERFORMANCE_DEFAULT,
    PUT_PERFORMANCE_DEFAULT,
    POST_PERFORMANCE_DEFAULT
} from '../actions'

export default function performance(state = {
    performancesLoading: false,
    performancesSuccess: false,
    performancesRejected: false,
    performancesData: {
        data: []
    },
    verifiedSuccess: false,
    updatePerformanceLoading: false,
    updatePerformanceSuccess: false,
    updatePerformanceRejected: false,
    addPerformanceLoading: false,
    addPerformanceSuccess: false,
    addPerformanceRejected: false,
    deletePerformanceLoading: false,
    deletePerformanceSuccess: false,
    deletePerformanceRejected: false
}, action) {
    switch (action.type) {
        case GET_PERFORMANCES:
            return {
                ...state,
                performancesLoading: true,
                performancesSuccess: false,
                performancesRejected: false
            }
        case GET_PERFORMANCES_FULFILLED:
            return {
                ...state,
                performancesLoading: false,
                performancesSuccess: true,
                performancesData: action.payload
            }
        case GET_PERFORMANCES_REJECTED:
            return {
                ...state,
                performancesLoading: false,
                performancesRejected: true
            }
        case POST_PERFORMANCE:
            return {
                ...state,
                addPerformanceLoading: true,
                addPerformanceSuccess: false,
                addPerformanceRejected: false
            }
        case POST_PERFORMANCE_FULFILLED:
            return {
                ...state,
                addPerformanceLoading: false,
                addPerformanceSuccess: true,
                addPerformanceData: action.payload
            }
        case POST_PERFORMANCE_REJECTED:
            return {
                ...state,
                addPerformanceLoading: false,
                addPerformanceRejected: true
            }
        case POST_PERFORMANCE_DEFAULT:
            return {
                ...state,
                addPerformanceLoading: false,
                addPerformanceSuccess: false,
                addPerformanceRejected: false
            }
        case PUT_PERFORMANCE:
            return {
                ...state,
                updatePerformanceLoading: true,
                updatePerformanceSuccess: false,
                updatePerformanceRejected: false
            }
        case PUT_PERFORMANCE_FULFILLED:
            return {
                ...state,
                updatePerformanceLoading: false,
                updatePerformanceSuccess: true,
                updatePerformanceData: action.payload
            }

        case PUT_PERFORMANCE_REJECTED:
            return {
                ...state,
                updatePerformanceLoading: false,
                updatePerformanceRejected: true
            }
        case PUT_PERFORMANCE_DEFAULT:
            return {
                ...state,
                updatePerformanceLoading: false,
                updatePerformanceSuccess: false,
                updatePerformanceRejected: false
            }

        case DELETE_PERFORMANCE:
            return {
                ...state,
                deletePerformanceLoading: true,
                deletePerformanceSuccess: false,
                deletePerformanceRejected: false
            }
        case DELETE_PERFORMANCE_FULFILLED:
            return {
                ...state,
                deletePerformanceLoading: false,
                deletePerformanceSuccess: true,
            }
        case DELETE_PERFORMANCE_REJECTED:
            return {
                ...state,
                deletePerformanceLoading: false,
                deletePerformanceRejected: true
            }
        case DELETE_PERFORMANCE_DEFAULT:
            return {
                ...state,
                deletePerformanceLoading: false,
                deletePerformanceSuccess: false,
                deletePerformanceRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
