import { combineReducers } from 'redux'

// Client
import clientReducers from '../components/client/reducers'

// Admin
import adminReducers from '../components/admin/reducers'

// AdminPms
import adminPmsReducers from '../components/pms/reducers'

const rootReducer = combineReducers({
  client: clientReducers,
  admin: adminReducers,
  adminPms: adminPmsReducers,
})

export default rootReducer
