import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm, Avatar, Rate } from 'antd'
import { loadEmployees } from '../../employee/actions'
import moment from 'moment'
import Toolbar from '../../shared/components/toolbar'
import { VIEW, ADD } from '../../../../utilities/actionEnums'
import { getUser } from '../../../../utilities/token'
import access from '../../../../utilities/access'
import Papa from 'papaparse'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        tenure: 0,
        gender: 0, // 1 = male, 2 = female
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const { onLoadEmployees } = props
        onLoadEmployees(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        const { onLoadEmployees } = props
        onLoadEmployees(pagination)
        // eslint-disable-next-line
    }, [props.updateEmployeeSuccess, props.deleteEmployeeSuccess, props.addEmployeeSuccess])

    useEffect(() => {
        setTableData([...props.employeesData.data])
    }, [props.employeesData])

    function handleDelete(id) {
        const postById = getUser().id
        props.onDeleteEmployee(id, postById)
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.firstName} ${data.lastName}`,
                "Account Number": data.accountNumber,
                "Address": data.address,
                "Allowance": data.allowance,
                "Birthday": data.birthday,
                "Center Code": data.centerCode,
                "Citizenship": data.citizenship,
                "Contact": data.contact,
                "Date Hired": data.dateHired,
                "Date Resigned": data.dateResigned,
                "Division Code": data.divisionCode,
                "Email": data.email,
                "Employee Number": data.employeeNumber,
                "Employment Status": data.employmentStatus,
                "Gender": data.gender,
                "Marital Status": data.maritalStatus,
                "Pag Ibig Number": data.pagIbigNumber,
                "Pag Ibig Prem": data.pagIbigPrem,
                "Phil Health Number": data.philHealthNumber,
                "Position": data.position && data.position.title,
                "Position Date": data.positionDate,
                "Project": data.project && data.project.title,
                "Property Code": data.propertyCode,
                "Rate Per Hour": data.ratePerHour,
                "Religion": data.religion,
                "Section Code": data.sectionCode,
                "Sick Leave Days": data.sickLeaveDays,
                "SSS Number": data.sssNumber,
                "Tax Status": data.taxStatus,
                "Tin": data.tin,
                "Vacation Leave Days": data.vacationLeaveDays,
                "Working Days Per Month": data.workingDaysPerMonth,
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `employees_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `employees_${unix}.csv`);
        tempLink.click();
    }

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    function employmentStatusLabel(status) {
        switch (status) {
            case 1:
                return <Tag color='gold'>Probationary</Tag>
            case 2:
                return <Tag color='lime'>Regular</Tag>
            case 3:
                return <Tag color='red'>Resigned</Tag>
            default:
                break;
        }
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Employee Number',
            dataIndex: 'employeeNumber',
            key: 'employeeNumber'
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            width: 350,
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <div className='content' >
                            <span className='name'>{data.firstName} {data.lastName}</span><br />
                            <span className='email'>{data.email}</span>
                        </div>
                    </div>
                )
            }
        },

        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <span>{item && item.title}</span>
                    </div>
                )
            }
        },
        {
            title: 'Division',
            dataIndex: 'division',
            key: 'division',
            render: (item, data) => {
                return (
                    <span>{item && item.title}</span>
                )
            }
        },
        {
            title: 'Sex',
            dataIndex: 'gender',
            key: 'gender',
            render: (item, data) => {
                return (
                    <span>{item === 1 ? 'Male' : 'Female'}</span>
                )
            }
        },
        {
            title: 'Remaining VL Days',
            dataIndex: 'vacationLeaveDays',
            key: 'vacationLeaveDays',
            render: (item, data) => {
                return (
                    <span>{item || 0}</span>
                )
            }
        },
        {
            title: 'Working Days Per Month',
            dataIndex: 'workingDaysPerMonth',
            key: 'workingDaysPerMonth',
            render: (item, data) => {
                return (
                    <span>{item || 0}</span>
                )
            }
        },
        {
            title: 'Rate Per Hour',
            dataIndex: 'ratePerHour',
            key: 'ratePerHour',
            render: (item, data) => {
                return (
                    <span>{item || 0}</span>
                )
            }
        },
        {
            title: 'Rate Per Month',
            dataIndex: 'ratePerMonth',
            key: 'ratePerMonth',
            render: (item, data) => {
                return (
                    <span>{item || 0}</span>
                )
            }
        },
        {
            title: 'Birthday / Age',
            dataIndex: 'birthday',
            key: 'birthday',
            render: (item, data) => {
                return (
                    <span>{moment(item).format('LL')}  /  {getAge(item)}</span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'employmentStatus',
            key: 'employmentStatus',
            render: (item, data) => {
                return (
                    <span>{employmentStatusLabel(item)}</span>
                )
            }
        },
        {
            title: 'Hired Date',
            dataIndex: 'dateHired',
            key: 'dateHired',
            render: (item, data) => {
                return (moment(data.dateHired).format('LLLL'))
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LLLL'))
            }
        },
    ];

    return (
        <Layout>
            {
                access.check('employeeMasterfile', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Employees Reports'
                                removeAdd={!access.check('appraisalPerformances', 'add')}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleDownload={() => handleDownload()}
                                handleStatus={(status) => {

                                    console.log("status", status)

                                    setPagination({ ...pagination, status })
                                }}
                                handleGender={(gender) => setPagination({ ...pagination, gender })}
                                handleTenure={(tenure) => setPagination({ ...pagination, tenure })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'Proabationary' },
                                    { id: 2, label: 'Regular' },
                                    { id: 3, label: 'Resigned' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.employeesData.employeesLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination
                                        //  defaultCurrent={pagination.page}
                                        onChange={(page, limit) => onChangePagination(page, limit)} total={props.employeesData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        employeesData: state.admin.employee.employeesData,
        employeesLoading: state.admin.employee.employeesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmployees: data => dispatch(loadEmployees(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)