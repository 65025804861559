import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm, Avatar, Tooltip } from 'antd'
import { loadAllowanceAllotment, deleteAllowanceHistory } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { VIEW, ADD, NONE } from '../../../../utilities/actionEnums'
import { serviceStatusTag } from '../../shared/components/serviceStatusTag'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import Toolbar from '../../shared/components/toolbar'
import access from '../../../../utilities/access'
import ModalAllowanceGroup from './modals/allowanceGroup'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [modalShow, setModalShow] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState('')

    useEffect(() => {
        const { onLoadAllowanceAllotment } = props
        onLoadAllowanceAllotment(pagination)
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        const { onLoadAllowanceAllotment } = props
        onLoadAllowanceAllotment(pagination)
        // eslint-disable-next-line
    }, [props.updateAllowanceHistorySuccess, props.deleteAllowanceHistorySuccess, props.addAllowanceHistorySuccess])

    useEffect(() => {
        setTableData([...props.allowanceAllotmentData.data])
    }, [props.allowanceAllotmentData])

    function handleDelete(id) {
        props.onDeleteAllowanceHistory(id)
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, obj) => {
                console.log("obj", obj)
                return (<Avatar size={64} src={<ImagePresignedUrl src={obj.data.user && obj.data.user[0].image && obj.data.user[0].image[0] && obj.data.user[0].image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, obj) => {
                return (<span style={{ textTransform: 'capitalize' }}>{obj.data.user && obj.data.user[0].firstName} {obj.data.user && obj.data.user[0].lastName}</span>)
            }
        },
        {
            title: 'Description',
            dataIndex: 'allowanceType',
            key: 'allowanceType',
            render: (item, obj) => {
                return (<span style={{ textTransform: 'capitalize' }}>{obj && obj.data.allowanceType[0].title}</span>)
            }
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (item, data) => {
                return (<span>₱ {item && item.toFixed(2)}</span>)
            }
        },
        {
            title: 'Number Of Records',
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, row) => {
                return (
                    <>
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="0" onClick={() => {
                                setModalShow(true)
                                setSelectedUserId(row.data.userId)
                            }}>
                                View User Allowances
                            </Menu.Item>
                            {/* <Menu.Item key="0" onClick={() => {
                                props.setActionType(VIEW)
                                props.setSelectedRow(data)
                            }} disabled>
                                View
                            </Menu.Item> */}
                            {/* {
                                    access.check('allowances', 'delete') &&
                                    <Menu.Item key="1" disabled >
                                        <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                            Delete
                                        </Popconfirm>

                                    </Menu.Item>
                                } */}
                        </Menu>}>
                            <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                        </Dropdown>
                    </>
                )
            }
        },

    ];
    return (
        <Layout className='page'>
            {
                access.check('allowances', 'view') ?
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Allowances'
                                handleAdd={() => props.setActionType(ADD)}
                                removeAdd={!access.check('allowances', 'add')}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                // handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{overflowX:'scroll'}} loading={props.allowanceAllotmentLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={props.allowanceAllotmentData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <Row className='permission-denied'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h1>It appears that you dont have enough permissions to view the contents.</h1>
                        </Col>
                    </Row>
            }

            <ModalAllowanceGroup
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={props.setActionType}
                actionType={props.actionType}
                selectedUserId={selectedUserId}
                setSelectedRow={props.setSelectedRow}
            />
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        allowanceAllotmentData: state.admin.allowanceAllotment.allowanceAllotmentData,
        allowanceAllotmentLoading: state.admin.allowanceAllotment.allowanceAllotmentLoading,
        updateAllowanceHistorySuccess: state.admin.allowanceAllotment.updateAllowanceHistorySuccess,
        addAllowanceHistorySuccess: state.admin.allowanceAllotment.addAllowanceHistorySuccess,
        deleteAllowanceHistorySuccess: state.admin.allowanceAllotment.deleteAllowanceHistorySuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAllowanceAllotment: data => dispatch(loadAllowanceAllotment(data)),
        onDeleteAllowanceHistory: id => dispatch(deleteAllowanceHistory(id)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(List)