import Axios from 'axios'

export const POST_LOGIN_DATA = 'POST_LOGIN_DATA'
export const POST_LOGIN_DATA_FULFILLED = 'POST_LOGIN_DATA_FULFILLED'
export const POST_LOGIN_DATA_REJECTED = 'POST_LOGIN_DATA_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const submitLogin = (data) => {
  return dispatch => {
    dispatch({
      type: POST_LOGIN_DATA,
      payload: {}
    })
    return Axios.post(`${API_URL}/login/admin`, { ...data }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    }).then(function (response) {
      if (response.data.token) {
        localStorage.setItem('ADMIN_SESSION_TOKEN', response.data.token)
        dispatch({
          type: POST_LOGIN_DATA_FULFILLED,
          payload: response.data
        })
      } else {
        dispatch({
          type: POST_LOGIN_DATA_REJECTED
          // payload: error
        })
      }
    }).catch(function (error) {
      dispatch({
        type: POST_LOGIN_DATA_REJECTED,
        payload: error
      })
    })
  }
}

