import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Avatar, Tooltip, Row, Col, notification, List } from 'antd'
import { connect } from 'react-redux'
import { loadEmploymentHistories, deleteEmploymentHistory } from '../actions'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '../../../../utilities/statusIconDisplay'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '../../../../utilities/actionEnums'
import moment from 'moment'
import RemarksRenderer from '../../../../utilities/remarksRenderer'


const EmploymentHistoriesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})

    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        if (props.updateEmploymentHistorySuccess) {
            setModalShow(false)
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the employment history information.',
            });
        }
        if (props.addEmploymentHistorySuccess) {
            setModalShow(false)
            notification.success({
                message: `Added`,
                description: 'You have successfully added new employment history information.',
            });
        }
        if (props.deleteEmploymentHistorySuccess) {
            setModalShow(false)
            notification.success({
                message: `Deleted`,
                description: 'You have successfully deleted employment history data.',
            });
        }

        props.onLoadEmploymentHistories(pagination)
    }, [props.updateEmploymentHistorySuccess, props.addEmploymentHistorySuccess, props.deleteEmploymentHistorySuccess])

    function handleDelete(id) {
        props.onDeleteEmploymentHistory(id)
    }

    return (
        <Layout className='employmentHistories-page'>
            <Card>
                <button className='add-item' onClick={() => {
                    setModalShow(true)
                    setActionType(ADD)
                    setSelectedRow({})
                }}>
                    <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer', fontSize: 20 }} icon={faPlusCircle} />
                    <span>Add a employment history</span>
                </button>
                <List
                    size="small"
                    header={
                        <Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                Company Information
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                                Reason For Leaving
                            </Col>
                            <Col md={5} lg={5} xs={24} sm={24} align='center'>
                                Verifier
                            </Col>
                        </Row>
                    }
                    footer={null}
                    dataSource={props.employmentHistoriesData.data}
                    renderItem={data => <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }}>
                                <Col md={24} lg={24} xs={24} sm={24}>
                                    <RemarksRenderer remarks={data.remarks} status={data.status} closable={true} />
                                </Col>
                            </Row>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <div className='row-item' >
                                    <h1>{data.companyName} <StatusIconDisplay status={data.status} /></h1>
                                    <p className='address'>{data.companyAddress}</p>
                                    <p className='position'>{data.position} ({moment(data.year).format('YYYY')})</p>
                                </div>
                                <Row>
                                    <div className='documents'>
                                        {data.images && data.images.length !== 0 && <h1>Images / Documents</h1>}
                                        <Col md={24} lg={24} xs={24} sm={24}>
                                            <div className='d-flex'>
                                                {
                                                    data.images && data.images.map((item, index) => {
                                                        return (
                                                            <div className='p2' key={index}>
                                                                <ImagePresignedUrl src={item.url} style={{ width: 80, height: 100 }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                            </Col>

                            <Col md={9} lg={9} xs={24} sm={24}>
                                <div className='row-item' >
                                    <p>{data.reasonForLeaving}</p>
                                </div>
                            </Col>

                            <Col md={5} lg={5} xs={24} sm={24}>
                                <div className='verifier'>
                                    <Avatar.Group maxCount={2} >
                                        {
                                            data.verifier.map((verify, index) => {
                                                return (
                                                    <Tooltip title={`${verify.firstName} ${verify.lastName}`} placement="top" key={index}>
                                                        {
                                                            Array.isArray(verify.image) &&
                                                            <Avatar icon={<ImagePresignedUrl objectFit='cover' src={verify.image && verify.image[0].url} style={{ width: 32, height: '100%' }} key={index} preview={false} />} />
                                                        }
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </Avatar.Group>
                                </div>

                            </Col>

                            <Col md={4} lg={4} xs={24} sm={24}>
                                <div className='actions'>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            setModalShow(true)
                                            setActionType(VIEW)
                                            setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>

                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </List.Item>}
                />


            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        employmentHistoriesData: state.client.employmentHistory.employmentHistoriesData,
        addEmploymentHistorySuccess: state.client.employmentHistory.addEmploymentHistorySuccess,
        updateEmploymentHistorySuccess: state.client.employmentHistory.updateEmploymentHistorySuccess,
        deleteEmploymentHistorySuccess: state.client.employmentHistory.deleteEmploymentHistorySuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadEmploymentHistories: data => dispatch(loadEmploymentHistories(data)),
        onDeleteEmploymentHistory: id => dispatch(deleteEmploymentHistory(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentHistoriesComponent)

{/* {
                    props.employmentHistoriesData.data.map((data, index) => {
                        return (
                            <Fragment>
                                <div className='row-item' key={index}>
                                    <div className='data'>
                                        <h1>xxxxxxxxxxxxx <StatusIconDisplay status={data.status} /></h1>
                                        <p className='school-name'>{data.school} (SY: {moment(data.yearFrom).format('YYYY')} - {moment(data.yearTo).format('YYYY')})</p>
                                        {
                                            data.type === 3 && // College
                                            <p className='course'>{data.course}</p>
                                        }
                                        <p className='address'>{data.schoolAddress}</p>
                                    </div>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0" onClick={() => {
                                            setModalShow(true)
                                            setActionType(VIEW)
                                            setSelectedRow(data)
                                        }}>
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>

                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon style={{ color: '#108ee9', cursor: 'pointer' }} icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                                <Row>
                                    <h1>Images / Documents</h1>
                                    <Col md={24} lg={24} xs={24} sm={24}>
                                        <div className='d-flex'>
                                            {
                                                data.images && data.images.map((item, index) => {
                                                    return (
                                                        <div className='p2'>
                                                            <ImagePresignedUrl key={index} src={item.url} style={{ width: 200, height: 150 }} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} lg={24} xs={24} sm={24}>
                                        <Avatar.Group
                                            maxCount={2}
                                        >
                                            {
                                                data.verifier.map((verify, index) => {
                                                    return (
                                                        <Tooltip title={`Verifier: ${verify.firstName}`} placement="top" key={index}>
                                                            {
                                                                Array.isArray(verify.image) &&
                                                                <Avatar size={20}
                                                                    icon={<ImagePresignedUrl objectFit='cover' src={verify.image && verify.image[0].url} style={{ width: 30, height: 30 }} key={index} preview={false} />}
                                                                />
                                                            }
                                                        </Tooltip>
                                                    )
                                                })
                                            }
                                        </Avatar.Group>
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    })
                } */}