import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_BIOMETRICS = 'GET_BIOMETRICS'
export const GET_BIOMETRICS_FULFILLED = 'GET_BIOMETRICS_FULFILLED'
export const GET_BIOMETRICS_REJECTED = 'GET_BIOMETRICS_REJECTED'
export const GET_BIOMETRICS_DEFAULT = 'GET_BIOMETRICS_DEFAULT'

export const GET_BIOMETRIC = 'GET_BIOMETRIC'
export const GET_BIOMETRIC_FULFILLED = 'GET_BIOMETRIC_FULFILLED'
export const GET_BIOMETRIC_REJECTED = 'GET_BIOMETRIC_REJECTED'
export const GET_BIOMETRIC_DEFAULT = 'GET_BIOMETRIC_DEFAULT'

export const POST_BIOMETRIC = 'POST_BIOMETRIC'
export const POST_BIOMETRIC_FULFILLED = 'POST_BIOMETRIC_FULFILLED'
export const POST_BIOMETRIC_REJECTED = 'POST_BIOMETRIC_REJECTED'
export const POST_BIOMETRIC_DEFAULT = 'POST_BIOMETRIC_DEFAULT'

export const PUT_BIOMETRIC = 'PUT_BIOMETRIC'
export const PUT_BIOMETRIC_FULFILLED = 'PUT_BIOMETRIC_FULFILLED'
export const PUT_BIOMETRIC_REJECTED = 'PUT_BIOMETRIC_REJECTED'
export const PUT_BIOMETRIC_DEFAULT = 'PUT_BIOMETRIC_DEFAULT'

export const DELETE_BIOMETRIC = 'DELETE_BIOMETRIC'
export const DELETE_BIOMETRIC_FULFILLED = 'DELETE_BIOMETRIC_FULFILLED'
export const DELETE_BIOMETRIC_REJECTED = 'DELETE_BIOMETRIC_REJECTED'
export const DELETE_BIOMETRIC_DEFAULT = 'DELETE_BIOMETRIC_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadBiometrics = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch({
      type: GET_BIOMETRICS,
      payload: {}
    })
    return Axios.get(`${API_URL}/signed?${query}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_BIOMETRICS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_BIOMETRICS_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_BIOMETRICS_DEFAULT }))
  }
}

export const loadBiometric = (id) => {
  return dispatch => {
    dispatch({
      type: GET_BIOMETRIC,
      payload: {}
    })
    return Axios.get(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_BIOMETRIC_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_BIOMETRIC_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: GET_BIOMETRIC_DEFAULT }))
  }
}

export const submitBiometric = (data) => {
  return dispatch => {
    dispatch({
      type: POST_BIOMETRIC,
      payload: {}
    })
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_BIOMETRIC_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_BIOMETRIC_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: POST_BIOMETRIC_DEFAULT }))
  }
}

export const updateBiometric = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_BIOMETRIC,
      payload: {}
    })
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_BIOMETRIC_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_BIOMETRIC_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: PUT_BIOMETRIC_DEFAULT }))
  }
}

export const deleteBiometric = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_BIOMETRIC,
      payload: {}
    })
    return Axios.delete(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_BIOMETRIC_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_BIOMETRIC_REJECTED,
          payload: error
        })
      }).finally(() => dispatch({ type: DELETE_BIOMETRIC_DEFAULT }))
  }
}











