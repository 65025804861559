import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, notification } from 'antd'
import List from './list'
import View from './view'
import { VIEW, EDIT, ADD, NONE } from '../../../../utilities/actionEnums'
import './index.scss'
import Viewer from '../../shared/components/viewer'
const Overtime = (props) => {
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState()

    useEffect(() => {
        if (actionType === NONE) setSelectedRow()
    }, [actionType])

    useEffect(() => {
        if (props.updateOvertimeSuccess) {
            notification.success({
                message: `Updated`,
                description: 'You have successfully updated the overtime information.',
            });
        }
    }, [props.updateOvertimeSuccess])

    useEffect(() => {
        if (props.addOvertimeSuccess) {
            notification.success({
                message: `Added`,
                description: 'You have successfully added new overtime information.',
            });
        }
    }, [props.addOvertimeSuccess])

    return (
        <Viewer
            isView={(actionType === VIEW) || (actionType === ADD)}
            listComponent={
                <List
                    setActionType={setActionType}
                    setSelectedRow={setSelectedRow}
                />
            }
            viewComponent={
                <View
                    actionType={actionType}
                    setActionType={setActionType}
                    selectedRow={selectedRow}
                />
            }
        />

    )
}

function mapStateToProps(state) {
    return {
        updateOvertimeSuccess: state.admin.overtime.updateOvertimeSuccess,
        addOvertimeSuccess: state.admin.overtime.addOvertimeSuccess,

    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Overtime)