import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_BUSINESS_TRIPS = 'GET_BUSINESS_TRIPS'
export const GET_BUSINESS_TRIPS_FULFILLED = 'GET_BUSINESS_TRIPS_FULFILLED'
export const GET_BUSINESS_TRIPS_REJECTED = 'GET_BUSINESS_TRIPS_REJECTED'

export const POST_BUSINESS_TRIP = 'POST_BUSINESS_TRIP'
export const POST_BUSINESS_TRIP_FULFILLED = 'POST_BUSINESS_TRIP_FULFILLED'
export const POST_BUSINESS_TRIP_REJECTED = 'POST_BUSINESS_TRIP_REJECTED'
export const POST_BUSINESS_TRIP_DEFAULT = 'POST_BUSINESS_TRIP_DEFAULT'

export const PUT_BUSINESS_TRIP = 'PUT_BUSINESS_TRIP'
export const PUT_BUSINESS_TRIP_FULFILLED = 'PUT_BUSINESS_TRIP_FULFILLED'
export const PUT_BUSINESS_TRIP_REJECTED = 'PUT_BUSINESS_TRIP_REJECTED'
export const PUT_BUSINESS_TRIP_DEFAULT = 'PUT_BUSINESS_TRIP_DEFAULT'

export const DELETE_BUSINESS_TRIP = 'DELETE_BUSINESS_TRIP'
export const DELETE_BUSINESS_TRIP_FULFILLED = 'DELETE_BUSINESS_TRIP_FULFILLED'
export const DELETE_BUSINESS_TRIP_REJECTED = 'DELETE_BUSINESS_TRIP_REJECTED'
export const DELETE_BUSINESS_TRIP_DEFAULT = 'DELETE_BUSINESS_TRIP_DEFAULT'

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadBusinessTrips = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch({
            type: GET_BUSINESS_TRIPS,
            payload: {}
        })
        return Axios.get(`${API_URL}/business-trips?${query}`, { headers: headers }).then(function (response) {
            dispatch({
                type: GET_BUSINESS_TRIPS_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: GET_BUSINESS_TRIPS_REJECTED,
                payload: error
            })
        })
    }
}

export const submitBusinessTrip = (data) => {
    return dispatch => {
        dispatch({
            type: POST_BUSINESS_TRIP,
            payload: {}
        })
        return Axios.post(`${API_URL}/business-trip`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: POST_BUSINESS_TRIP_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: POST_BUSINESS_TRIP_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: POST_BUSINESS_TRIP_DEFAULT }))
    }
}

export const updateBusinessTrip = (data) => {
    return dispatch => {
        dispatch({
            type: PUT_BUSINESS_TRIP,
            payload: {}
        })
        return Axios.put(`${API_URL}/business-trip/${data.id}`, { ...data }, { headers: headers }).then(function (response) {
            dispatch({
                type: PUT_BUSINESS_TRIP_FULFILLED,
                payload: response.data
            })
        }).catch(function (error) {
            dispatch({
                type: PUT_BUSINESS_TRIP_REJECTED,
                payload: error
            })
        }).finally(() => dispatch({ type: PUT_BUSINESS_TRIP_DEFAULT }))
    }
}


export const deleteBusinessTrip = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_BUSINESS_TRIP,
            payload: {}
        })
        return Axios.delete(`${API_URL}/business-trip/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch({
                    type: DELETE_BUSINESS_TRIP_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                return dispatch({
                    type: DELETE_BUSINESS_TRIP_REJECTED,
                    payload: error
                })
            }).finally(() => dispatch({ type: DELETE_BUSINESS_TRIP_DEFAULT }))
    }
}
