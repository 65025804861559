import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Rate, Form, Divider, notification, DatePicker, Select } from 'antd'
import { loadAdmin, updateAdmin, submitAdmin, GET_ADMIN_FULFILLED } from '../actions'
import { CustomDropzone } from '../../../../utilities/customDropzone'
import { upload } from '../../../../utilities/filesController'
import { loadAdmins } from '../actions'
import moment from 'moment'
import './index.scss'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import { loadPositions } from '../../positions/actions'
import { getUser } from '../../../../utilities/token'

const { Option } = Select
const FormAdmin = (props) => {
    const [form] = Form.useForm()
    const [userDetails, setUserDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [fileImage, setFileImage] = useState(undefined)
    const [fileImageObj, setFileImageObj] = useState(undefined)
    const [isPositionChanged, setIsPositionChanged] = useState(false)

    useEffect(() => {
        const userId = props.selectedRow && props.selectedRow._id
        if (userId) {
            props.onLoadAdmin(userId).then(res => {
                if (res.type === GET_ADMIN_FULFILLED) {
                    form.setFieldsValue({
                        ...res.payload,
                        dateHired: res.payload && moment(res.payload.dateHired),
                        dateResigned: res.payload && moment(res.payload.dateResigned),
                        birthday: res.payload && moment(res.payload.birthday),
                    })
                    setUserDetails(res.payload)
                }
            })
        }

        props.onLoadPositions({
            page: 1,
            limit: 100,
            // status: 0
        })

        props.onLoadAdmins({
            page: 1,
            limit: 50,
        })
        // eslint-disable-next-line
    }, [props.selectedId])

    function handleSubmit(value) {
        setLoading(true)

        if (props.actionType === ADD) {
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                const submitData = {
                    ...value,
                    postById: getUser().id,
                    positionDate: isPositionChanged ? new Date : undefined
                }
                if (fileImageObj) submitData.image = fileImageObj
                if (isAllResultTrue) {
                    delete value.religion
                    props.onSubmitAdmin({ ...submitData }).then(res => {
                        setLoading(false)
                        props.setActionType(NONE)
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                        // description: err,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const userId = props.selectedRow && props.selectedRow._id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                const submitData = { id: userId, ...value, postById: getUser().id, positionDate: isPositionChanged ? new Date : undefined }
                if (fileImageObj) submitData.image = fileImageObj
                if (isAllResultTrue) {
                    delete value.religion
                    props.onUpdateAdmin({ ...submitData }).then(res => {
                        setLoading(false)
                        props.setActionType(NONE)
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                        // description: err,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (fileImage) {
                upload(fileImage, fileImage.name, 'images').then(data => {
                    return resolve(true)
                }).catch(err => {
                    return notification.error({ message: `Failed to upload image`, description: err, });
                })
            } else {
                return resolve(true)
            }
        })
    }

    function getFileImage(e) {
        const obj = {
            name: e.name,
            url: `images/${e.name}`
        }
        setFileImage(e)
        setFileImageObj(obj)
    }

    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`admin-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            // onFinishFailed={onFinishFailed}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={24} xs={24} lg={24} sm={24}>
                        <div className='user-details' >
                            <div className='user-view-left'>
                                <CustomDropzone
                                    fileName='profile'
                                    height={200}
                                    width={200}
                                    getFile={file => { getFileImage(file) }}
                                    value={userDetails.image && userDetails.image[0] && userDetails.image[0].url}
                                />
                                <div className='details'>
                                    <p className='name'>{`${userDetails.firstName || ''} ${userDetails.lastName || ''}`}</p>
                                    <p className='birthdate'>Birthday:  {userDetails.birthday ? `${moment(userDetails.birthday).format('LL')}` : ''}</p>

                                    <div className='ratings'>
                                        <p>JOB LEVEL</p>
                                        <div className='ratings-flex'>
                                            <span className='job-level-label'>{userDetails.position && userDetails.position.jobLevel && userDetails.position.jobLevel.toFixed(1)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input first name!' }]}
                        >
                            <Input value={userDetails.firstName} onChange={(e) => {
                                setUserDetails({ ...userDetails, firstName: e.target.value })
                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input last name!' }]}
                        >
                            <Input
                                value={userDetails.lastName} onChange={(e) => {
                                    setUserDetails({ ...userDetails, lastName: e.target.value })
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Middle Name"
                            name="middleName"
                            rules={[{ required: true, message: 'Please input middle name!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input email!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Contact"
                            name="contact"
                            rules={[{ required: true, message: 'Please input contact!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: 'Please input address!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Position"
                            name="positionId"
                            rules={[{ required: true, message: 'Please input position!' }]}
                        >
                            <Select onChange={({ }, e) => {
                                const jobLevel = e.data.jobLevel
                                console.log("jobLevel", jobLevel)
                                setIsPositionChanged(true)
                                setUserDetails({
                                    ...userDetails, ...{
                                        position: {
                                            jobLevel
                                        }
                                    }
                                })
                            }}>
                                {
                                    props.positionsData.data.map((item, index) => {
                                        return (
                                            <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Birthday"
                            name="birthday"
                            rules={[{ required: true, message: 'Please input birthday!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                                value={userDetails.birthday} onChange={(e) => {
                                    setUserDetails({ ...userDetails, birthday: e })
                                }}
                            />
                        </Form.Item>


                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[{ required: true, message: 'Please input gender!' }]}
                        >
                            <Select>
                                <Select.Option value={1}>Male</Select.Option>
                                <Select.Option value={2}>Female</Select.Option>
                            </Select>
                        </Form.Item>


                    </Col>
                </Row>

                {
                    (props.actionType === ADD) &&
                    <Row gutter={16}>
                        <Divider />
                        <Col md={8} lg={8} sm={24} xs={24} >
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please input password!' }]}
                            >
                                <Input placeholder='Password' />
                            </Form.Item>
                        </Col>
                    </Row>
                }


                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' type="primary" htmlType="submit"
                                loading={props.updateAdminLoading || loading}
                            >
                                Submit Admin
                            </Button>

                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        updateAdminLoading: state.admin.administrator.updateAdminLoading,
        positionsData: state.admin.position.positionsData,
        adminsData: state.admin.administrator.adminsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAdmin: (id) => dispatch(loadAdmin(id)),
        onUpdateAdmin: (data) => dispatch(updateAdmin(data)),
        onSubmitAdmin: (data) => dispatch(submitAdmin(data)),
        onLoadPositions: (data) => dispatch(loadPositions(data)),
        onLoadAdmins: (data) => dispatch(loadAdmins(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormAdmin)