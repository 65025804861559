import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Alert, Divider, Card } from 'antd'
import { loadAllowanceHistory, updateAllowanceHistory, submitAllowanceHistory, POST_ALLOWANCE_HISTORY_FULFILLED, loadSupplierTin } from '../actions'
import { ADD, VIEW, NONE } from '../../../../utilities/actionEnums'
import moment from 'moment'
import './index.scss'
import access from '../../../../utilities/access'
import FormType from './modals/type'
import { loadEmployees } from '../../employee/actions'
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select

const FormAllowanceHistory = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [modalTypeShow, setModalTypeShow] = useState(false)
    const [tinValue, setTinValue] = useState('')
    const [supplierData, setSupplierData] = useState([])
    const [showSupplierDataItems, setShowSupplierDataItems] = useState(false)
    const [selectedSupplier, setSelectedSupplier] = useState({})
    const [supplierLoading, setSupplierLoading] = useState(false)
    const [supplierDisabled, setSupplierDisabled] = useState(false)
    const userId = props.selectedRow && props.selectedRow.user && props.selectedRow.user._id
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 1,
        sort: 'desc',
        search: '',
    })

    useEffect(() => {
        const subTotalAmount = (+(props.selectedRow && props.selectedRow.vatableAmount) || 0) + (+(props.selectedRow && props.selectedRow.vatableExemptAmount) || 0)
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: props.selectedRow && moment(props.selectedRow.date),
            orDate: props.selectedRow && moment(props.selectedRow.orDate),
            applicationMonth: props.selectedRow && moment(props.selectedRow.applicationMonth),
            supplierName: props.selectedRow && props.selectedRow.supplier && props.selectedRow.supplier.name,
            supplierAddress: props.selectedRow && props.selectedRow.supplier && props.selectedRow.supplier.address,
            subTotalAmount: subTotalAmount
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            props.onSubmitAllowanceHistory({ ...submitData }).then(res => {
                if (res.type === POST_ALLOWANCE_HISTORY_FULFILLED) {
                    props.setActionType(NONE)
                }
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const allowanceHistoryId = props.selectedRow._id
            props.onUpdateAllowanceHistory({ id: allowanceHistoryId, ...submitData }).then(res => {
                props.setActionType(NONE)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            props.onLoadEmployees({
                page: 1,
                limit: 50,
                search: search,
            })
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    useEffect(() => {
        if (!tinValue.length) return
        const delayDebounceFn = setTimeout(() => {
            props.onLoadSupplierTin({ ...pagination, search: tinValue }).then(res => {
                setSupplierLoading(true)
                setSupplierData(res.payload.data.data)
                const foundTin = res.payload.data.data.find(data => data.tin === tinValue)
                if (foundTin) {
                    form.setFieldsValue({
                        supplierName: foundTin.name,
                        supplierAddress: foundTin.address
                    })
                    setSupplierDisabled(true)
                    setShowSupplierDataItems(true)
                } else {
                    form.setFieldsValue({
                        supplierName: '',
                        supplierAddress: ''
                    })
                    setSupplierDisabled(false)
                }
            }).finally(() => {
                setSupplierLoading(false)
            })

        }, 700)
        return () => clearTimeout(delayDebounceFn)
    }, [tinValue])

    function handleSupplierSelect(data) {
        setSupplierData([])
        setSelectedSupplier(data)
        setSupplierDisabled(true)
        form.setFieldsValue({
            tin: data.tin,
            supplierName: data.name,
            supplierAddress: data.address
        })
    }

    function onBlurSupplier(value) {
        var container = document.getElementsByClassName('list')[0];
        document.addEventListener('click', function (event) {
            if (container !== event.target && !container.contains(event.target)) {
                setShowSupplierDataItems(false)
            }
        });
    }

    function onChangeInAmountFields() {
        const vatableAmount = form.getFieldValue('vatableAmount') || 0
        const vatableExemptAmount = form.getFieldValue('vatableExemptAmount') || 0
        const subTotalAmount = (+vatableAmount || 0) + (+vatableExemptAmount || 0)
        const vat = (+vatableAmount) * .12
        form.setFieldsValue({
            subTotalAmount: subTotalAmount,
            vat: vat,
            totalAmount: subTotalAmount + vat
        })
    }

    const isAccessDisabled = (!access.check('allowances', 'edit') && (props.actionType === VIEW))
    const isDeducted = (props.selectedRow && props.selectedRow.isDeducted)
    console.log("props.selectedRow", props.selectedRow)
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            {/* {
                isDeducted ?
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                            <Alert
                                message={'Amount'}
                                description={'The amount could not be edited because it has been already deducted to the allowance of the user.'}
                                type={"info"}
                                showIcon
                                closable
                            />
                        </Col>
                    </Row> : null
            } */}

            <Form
                name={`allowanceHistory-${props.selectedId}`}
                form={form}
                initialValues={{ remember: true }}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (props.actionType === ADD) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}  >
                                    {
                                        props.employeesData.data.map((data, index) => {
                                            return (
                                                <Option key={data._id} value={data._id} data={data} >{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>

                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Row gutter={8} justify='center'>
                            <Col md={10} lg={22} sm={24} xs={24}>
                                <Form.Item
                                    label="Description"
                                    name="allowanceTypeId"
                                    rules={[{ required: true, message: 'Please input description!' }]}
                                >
                                    <Select placeholder='Search Allowance Type' style={{ width: '100%' }} defaultValue={props.selectedRow && props.selectedRow.allowanceTypeId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={props.employeesLoading}  >
                                        {
                                            props.allowanceHistoryTypesData.data.map((data, index) => {
                                                return (
                                                    <Option key={data._id} value={data._id} data={data} >{data.title} </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={2} lg={2} sm={24} xs={24}  >
                                <div className='type-options'>
                                    <Button disabled={isAccessDisabled} className='btn-circle-type' onClick={() => setModalTypeShow(true)} shape="circle" icon={<PlusOutlined />} />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="OR No."
                            name="orNumber"
                            rules={[{ required: true, message: 'Please input OR No.!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="OR Date"
                            name="orDate"
                            rules={[{ required: true, message: 'Please input OR date!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Transaction Date"
                            name="date"
                            rules={[{ required: true, message: 'Please input date!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[{ required: true, message: 'Please input status!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                <Option value={1}>FOR APPROVAL</Option>
                                {
                                    access.check('allowanceAllotment', 'approve') &&
                                    <Option value={2}>APPROVED</Option>
                                }
                                {
                                    access.check('allowanceAllotment', 'cancel') &&
                                    <Option value={5}>CANCELLED</Option>
                                }
                                {
                                    access.check('allowanceAllotment', 'reject') &&
                                    <Option value={6}>REJECTED</Option>
                                }
                            </Select>
                        </Form.Item>
                    </Col> */}

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Application Month"
                            name="applicationMonth"
                            rules={[{ required: true, message: 'Please input application month!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="MMMM"
                                style={{ width: '100%' }}
                                picker="month"
                            />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <h1 style={{ fontSize: 16 }}>Amount / Tax</h1>
                <Row gutter={12}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Vatable Amount"
                            name="vatableAmount"
                            rules={[{ required: true, message: 'Please input vatable amount!' }]}
                        >
                            <Input type='number' onChange={onChangeInAmountFields} />
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Vatable Exempt"
                            name="vatableExemptAmount"
                            rules={[{ required: true, message: 'Please input vatable exempt amount!' }]}
                        >
                            <Input type='number' onChange={onChangeInAmountFields} />
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Sub Total Amount"
                            name="subTotalAmount"
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="VAT (12%)"
                            name="vat"
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Total Amount"
                            name="totalAmount"
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <h1 style={{ fontSize: 16 }}>Supplier</h1>
                <Row gutter={12}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <div className='supplier-select'>
                            <Form.Item
                                label="Suppier TIN"
                                name="tin"
                                rules={[{ required: true, message: 'Please input tin!' }]}
                            >
                                <Input onChange={(e) => setTinValue(e.target.value)} autoComplete="off" onBlur={(e) => onBlurSupplier(e.target.value)} />
                            </Form.Item>
                            <div className='list'>
                                {
                                    (showSupplierDataItems && supplierData.length) ?
                                        <Card>
                                            {
                                                supplierData.map((data, index) => {
                                                    return <h4 onClick={() => handleSupplierSelect(data)} key={index}>{data.tin}</h4>
                                                })
                                            }
                                        </Card> : null
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                <Card className='supplier-card' bordered={false} loading={supplierLoading}>
                    <Row gutter={12}>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Supplier Name"
                                name="supplierName"
                                rules={[{ required: true, message: 'Please input supplier name!' }]}
                            >
                                <Input disabled={supplierDisabled} />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Supplier Address"
                                name="supplierAddress"
                                rules={[{ required: true, message: 'Please input supplier address!' }]}
                            >
                                <Input disabled={supplierDisabled} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                {
                    ((access.check('allowances', 'add') && (props.actionType === ADD)) || (access.check('allowances', 'edit') && (props.actionType === VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'left'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={props.updateAllowanceHistoryLoading || loading}   >
                                    Submit allowance history
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
            <FormType visible={modalTypeShow} setModalTypeShow={setModalTypeShow} />
        </Layout >
    )
}

function mapStateToProps(state) {
    return {
        updateAllowanceHistoryLoading: state.admin.allowanceAllotment.updateAllowanceHistoryLoading,
        employeesData: state.admin.employee.employeesData,
        allowanceHistoryTypesData: state.admin.allowanceAllotment.allowanceHistoryTypesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAllowanceHistory: (id) => dispatch(loadAllowanceHistory(id)),
        onUpdateAllowanceHistory: (data) => dispatch(updateAllowanceHistory(data)),
        onSubmitAllowanceHistory: (data) => dispatch(submitAllowanceHistory(data)),
        onLoadEmployees: (data) => dispatch(loadEmployees(data)),
        onLoadSupplierTin: (pagination) => dispatch(loadSupplierTin(pagination)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FormAllowanceHistory)