import {
    GET_ATTENDANCES,
    GET_ATTENDANCES_FULFILLED,
    GET_ATTENDANCES_REJECTED,
    PUT_ATTENDANCE,
    PUT_ATTENDANCE_FULFILLED,
    PUT_ATTENDANCE_REJECTED,
    POST_ATTENDANCE,
    POST_ATTENDANCE_FULFILLED,
    POST_ATTENDANCE_REJECTED,
    DELETE_ATTENDANCE,
    DELETE_ATTENDANCE_FULFILLED,
    DELETE_ATTENDANCE_REJECTED,
    DELETE_ATTENDANCE_DEFAULT,
    PUT_ATTENDANCE_DEFAULT,
    POST_ATTENDANCE_DEFAULT
} from '../actions'

export default function attendance(state = {
    attendancesLoading: false,
    attendancesSuccess: false,
    attendancesRejected: false,
    attendancesData: {
        data: []
    },
    verifiedSuccess: false,
    updateAttendanceLoading: false,
    updateAttendanceSuccess: false,
    updateAttendanceRejected: false,
    addAttendanceLoading: false,
    addAttendanceSuccess: false,
    addAttendanceRejected: false,
    deleteAttendanceLoading: false,
    deleteAttendanceSuccess: false,
    deleteAttendanceRejected: false
}, action) {
    switch (action.type) {
        case GET_ATTENDANCES:
            return {
                ...state,
                attendancesLoading: true,
                attendancesSuccess: false,
                attendancesRejected: false
            }
        case GET_ATTENDANCES_FULFILLED:
            return {
                ...state,
                attendancesLoading: false,
                attendancesSuccess: true,
                attendancesData: action.payload
            }
        case GET_ATTENDANCES_REJECTED:
            return {
                ...state,
                attendancesLoading: false,
                attendancesRejected: true
            }
        case POST_ATTENDANCE:
            return {
                ...state,
                addAttendanceLoading: true,
                addAttendanceSuccess: false,
                addAttendanceRejected: false
            }
        case POST_ATTENDANCE_FULFILLED:
            return {
                ...state,
                addAttendanceLoading: false,
                addAttendanceSuccess: true,
                addAttendanceData: action.payload
            }
        case POST_ATTENDANCE_REJECTED:
            return {
                ...state,
                addAttendanceLoading: false,
                addAttendanceRejected: true
            }
        case POST_ATTENDANCE_DEFAULT:
            return {
                ...state,
                addAttendanceLoading: false,
                addAttendanceSuccess: false,
                addAttendanceRejected: false
            }
        case PUT_ATTENDANCE:
            return {
                ...state,
                updateAttendanceLoading: true,
                updateAttendanceSuccess: false,
                updateAttendanceRejected: false
            }
        case PUT_ATTENDANCE_FULFILLED:
            return {
                ...state,
                updateAttendanceLoading: false,
                updateAttendanceSuccess: true,
                updateAttendanceData: action.payload
            }

        case PUT_ATTENDANCE_REJECTED:
            return {
                ...state,
                updateAttendanceLoading: false,
                updateAttendanceRejected: true
            }
        case PUT_ATTENDANCE_DEFAULT:
            return {
                ...state,
                updateAttendanceLoading: false,
                updateAttendanceSuccess: false,
                updateAttendanceRejected: false
            }

        case DELETE_ATTENDANCE:
            return {
                ...state,
                deleteAttendanceLoading: true,
                deleteAttendanceSuccess: false,
                deleteAttendanceRejected: false
            }
        case DELETE_ATTENDANCE_FULFILLED:
            return {
                ...state,
                deleteAttendanceLoading: false,
                deleteAttendanceSuccess: true,
            }
        case DELETE_ATTENDANCE_REJECTED:
            return {
                ...state,
                deleteAttendanceLoading: false,
                deleteAttendanceRejected: true
            }
        case DELETE_ATTENDANCE_DEFAULT:
            return {
                ...state,
                deleteAttendanceLoading: false,
                deleteAttendanceSuccess: false,
                deleteAttendanceRejected: false
            }
        default:
            return {
                ...state
            }
    }
}
